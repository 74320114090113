import { faSquareCheck } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Tool } from 'shared/lib/types/api/manufacturing/tools/models';
import { UsageTypes } from 'shared/lib/types/postgres/manufacturing/usage_types';
import { StyleVariant } from '../../../elements/lib/fieldStyles';
import { NumberSelectOption } from '../../../lib/formik';
import { cellClass, getCellClass, headerClass } from '../util/tableUtil';
import SelectToolInstance from './SelectToolInstance';
import ToolBadge from './ToolBadge';

export type ContentErrors = {
  value?: string;
};

interface ToolUsageBaseProps {
  tool: Tool;
  teamId: string;
  isStepComplete: boolean;
  isStepRecorded?: boolean;
  isEnabled: boolean;
  toolInstanceId?: number;
  errors?: ContentErrors;
  usageValue?: string;
  computedValue?: string;
  usageType?: number;
  onToolInstanceChange?: (toolInstanceOption: NumberSelectOption) => void;
  onUsageChange?: (value: string) => void;
  onUsageBlur?: (value: string) => void;
}

const ToolUsageBase = ({
  tool,
  toolInstanceId,
  teamId,
  isStepComplete,
  isStepRecorded,
  isEnabled,
  errors,
  usageValue,
  computedValue,
  usageType,
  onUsageChange,
  onUsageBlur,
  onToolInstanceChange,
}: ToolUsageBaseProps) => {
  return (
    <table className="w-full">
      <thead>
        <tr>
          <td className={`${headerClass} w-5/12`}>Record Tool Usage</td>
          <td className={`${headerClass} w-3/12`}>Instance</td>
          <td className={`${headerClass} w-4/12`}>Usage Value</td>
        </tr>
      </thead>
      <tbody>
        <tr>
          <td className={`${cellClass} p-1 max-w-12 overflow-hidden`}>
            <ToolBadge tool={tool} teamId={teamId} />
          </td>
          <td className={`${cellClass}`}>
            <SelectToolInstance
              tool={tool}
              toolInstanceId={toolInstanceId}
              onToolInstanceChange={onToolInstanceChange}
              isEnabled={isEnabled}
              isComplete={isStepComplete}
              teamId={teamId}
              variant={StyleVariant.Grid}
            />
          </td>
          <td className={getCellClass(!isEnabled)}>
            <div className="flex relative items-center">
              <input
                className="border-0 px-2 w-full disabled:opacity-60 disabled:bg-gray-100"
                type="text"
                name="value"
                value={usageValue}
                // Looks like UsageTypes may be configurable in the DB, but this is assuming fixed enum values
                placeholder={usageType === UsageTypes.Cycles ? 'Enter cycles' : 'Enter time as HH:mm:ss'}
                onChange={(e) => onUsageChange?.(e.target.value)}
                onBlur={(e) => {
                  onUsageBlur?.(e.target.value);
                }}
                disabled={!isEnabled}
              />
              {errors && <div className="absolute -bottom-[3px] left-1 text-red-700 text-xs">{errors.value}</div>}
              {!isStepComplete && <div className="absolute right-2 top-[10px] text-gray-300">{computedValue}</div>}
              {isStepRecorded && (
                <div className="pr-2">
                  <FontAwesomeIcon icon={faSquareCheck} className="text-green-500" aria-label="Usage recorded" />
                </div>
              )}
            </div>
          </td>
        </tr>
      </tbody>
    </table>
  );
};

export default ToolUsageBase;
