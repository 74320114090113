import { faBarcode, faMagnifyingGlass } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { KeyboardEvent, useRef } from 'react';

const DEFAULT_PLACEHOLDER = 'Scan barcode...';

const KEYS = {
  Enter: 'Enter',
  Tab: 'Tab',
} as const;

type BarcodeInputProps = {
  value: string;
  onChange: (value: string) => void;
  placeholder?: string;
};

const onKeyDown = (e: KeyboardEvent<HTMLInputElement>, value: string, onChange: (value: string) => void) => {
  if (e.key === KEYS.Enter || e.key === KEYS.Tab) {
    e.preventDefault();
    onChange(`${value} `); // Append a space to the current value
  }
};

const BarcodeInput = ({ value, onChange, placeholder = DEFAULT_PLACEHOLDER }: BarcodeInputProps) => {
  const searchBarRef = useRef<HTMLInputElement>(null);
  return (
    <div className="relative flex items-center w-full">
      <FontAwesomeIcon
        icon={faMagnifyingGlass}
        aria-label="Search"
        className="absolute left-2 text-blue-500 cursor-pointer hover:text-blue-800"
        onClick={() => searchBarRef.current?.focus()}
      />
      <FontAwesomeIcon icon={faBarcode} className="absolute left-7 text-gray-500 pointer-events-none" />
      <input
        type="text"
        ref={searchBarRef}
        value={value}
        onChange={(e) => onChange(e.target.value)}
        onKeyDown={(e) => onKeyDown(e, value, onChange)}
        className="w-full pl-12 h-[32px] text-base border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-blue-500"
        placeholder={placeholder}
      />
      {value && (
        <button
          className="relative right-6 outline-none text-blue-500 focus:text-blue-800 hover:text-blue-800"
          onClick={() => onChange('')}
        >
          <FontAwesomeIcon icon="times-circle" />
        </button>
      )}
    </div>
  );
};

export default BarcodeInput;
