import { Rule } from 'shared/lib/types/views/procedures';
import { Bounds, Selection, SelectionType } from '../types';
import Plot, { PlotType } from './Plot';

interface RunPlotProps {
  bucketName: string;
  measurementName: string;
  plotType: PlotType;
  bounds: Bounds;
  initialBounds?: Bounds;
  setBounds?: (bounds: Bounds) => void;
  zoomLevel?: number;
  setZoomLevel?: (zoomLevel: number) => void;
  runId: string;
  telemetryRule?: Rule;
  maintainAspectRatio?: boolean;
  shouldPausePlot: boolean;
}

const RunPlot = ({
  bucketName,
  measurementName,
  plotType,
  bounds,
  initialBounds,
  setBounds,
  zoomLevel,
  setZoomLevel,
  runId,
  maintainAspectRatio,
  telemetryRule,
  shouldPausePlot,
}: RunPlotProps) => {
  const selection: Selection = {
    bounds,
    bucket: bucketName,
    measurement: measurementName,
    folder_id: runId,
    type: SelectionType.RunTelemetry,
  };

  return (
    <Plot
      selections={[selection]}
      plotType={plotType}
      bounds={bounds}
      setBounds={setBounds}
      initialBounds={initialBounds}
      zoomLevel={zoomLevel}
      setZoomLevel={setZoomLevel}
      canChangeXAxis={false}
      telemetryRule={telemetryRule}
      maintainAspectRatio={maintainAspectRatio}
      pauseRefreshPlot={shouldPausePlot}
    />
  );
};

export default RunPlot;
