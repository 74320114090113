import useContentType from '../../hooks/useContentType';
import { generateHiddenClassString } from '../../lib/styles';
import InlineImageStandalone from '../InlineImageStandalone';
import SubstepNumber from '../SubstepNumber';
import ThumbnailFile from '../Attachments/ThumbnailFile';
import { BlockValues } from './BlockTypes';

export interface BlockAttachmentProps {
  attachment: BlockValues<'attachment'>;
  isHidden: boolean;
  isSpacerHidden: boolean;
  blockLabel?: string;
}

// Component for rendering an attachment either as an inline image or file link.
const BlockAttachment = ({ attachment, isHidden, isSpacerHidden, blockLabel }: BlockAttachmentProps) => {
  const { shouldRenderImage } = useContentType({ attachment });

  // Layout is intended for CSS grid template defined in Run.js and Procedure.js
  return (
    <tr aria-label="Content Block" role="region">
      {!isSpacerHidden && <td></td>}
      <td colSpan={2} className="w-full align-left">
        <div className={generateHiddenClassString('', isHidden)}></div>
        {/* Limit inline image to column 1 */}
        {shouldRenderImage && (
          <>
            <div className={generateHiddenClassString('mt-2 mr-8 flex page-break', isHidden)}>
              {!isSpacerHidden && <div className="w-2 h-6 mr-2 shrink-0"></div>}
              <SubstepNumber blockLabel={blockLabel} hasExtraVerticalSpacing={false} />
              <InlineImageStandalone attachment={attachment} showCaptionText={true} />
            </div>
            <div className={generateHiddenClassString('mt-2 mr-8 flex', isHidden)}></div>
          </>
        )}
        {!shouldRenderImage && (
          <div className={generateHiddenClassString('mt-2 mr-8 flex page-break', isHidden)}>
            <div className="h-6 ml-2 mr-2 shrink-0"></div>
            <SubstepNumber blockLabel={blockLabel} hasExtraVerticalSpacing={false} />
            <ThumbnailFile attachment={attachment} />
          </div>
        )}
      </td>
    </tr>
  );
};

export default BlockAttachment;
