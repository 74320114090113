import sharedDiffUtil from 'shared/lib/diffUtil';
import DiffContainer from '../../../components/Diff/DiffContainer';
import SubstepNumber from '../../../components/SubstepNumber';
import { generateHiddenClassString } from '../../../lib/styles';
import useLatestParts from '../../hooks/useLatestParts';
import { isPartRestricted } from '../../lib/parts';
import PartUsageBase from '../PartUsageBase';

const ReviewPartUsage = ({ content, blockLabel, teamId, isHidden }) => {
  const { getLatestPartRevision } = useLatestParts();
  const part = content.part ? getLatestPartRevision(sharedDiffUtil.getDiffValue(content.part, 'id', 'new')) : undefined;
  const partRestricted = content.part === null ? false : isPartRestricted(part);

  let usageTypeSelected;
  if (content && content.usage_types && content.usage_types.length > 0) {
    usageTypeSelected = content.usage_types[0];
  }

  return (
    <div className={generateHiddenClassString('mt-3 ml-4 flex flex-wrap page-break', isHidden)}>
      <SubstepNumber blockLabel={blockLabel} hasExtraVerticalSpacing={false} />
      <div className="w-full py-1 mr-8">
        <DiffContainer label="Part usage" diffChangeState={content.diff_change_state} isTextSticky={false}>
          <PartUsageBase
            content={content}
            part={part}
            usageTypeSelected={usageTypeSelected}
            contentPartId={sharedDiffUtil.getDiffValue(content, 'part_id', 'new')}
            teamId={teamId}
            isUsageDisabled={true}
            isPartRestricted={partRestricted}
            isStepComplete={true}
          />
        </DiffContainer>
      </div>
    </div>
  );
};

export default ReviewPartUsage;
