import { useCallback, useEffect, useState, useMemo } from 'react';
import Modal from '../components/Modal';
import ProjectUserRow from '../components/Settings/ProjectUserRow';
import { ProjectUserRoles, UserFormUser, WorkspaceRoles } from '../components/Settings/types';
import { useDatabaseServices } from '../contexts/DatabaseContext';

export type UpdatedUser = {
  id: string;
  project_roles: string[];
};

interface NewProjectModalProps {
  closeModal: () => void;
  onProjectUserSaved: (updatedUser: UpdatedUser, isNewUser: boolean) => Promise<string>;
  projectUsers: ProjectUserRoles[] | null;
  selectedUsers: UserFormUser[] | null;
}

const NewUserProjectModal = ({ closeModal, onProjectUserSaved, projectUsers, selectedUsers }: NewProjectModalProps) => {
  const [availableRoles, setAvailableRoles] = useState<WorkspaceRoles>([]);
  const { services } = useDatabaseServices();

  const user = useMemo(() => {
    if (!projectUsers || !selectedUsers || selectedUsers.length < 1) {
      return undefined;
    }
    const userId = selectedUsers[0].id;
    return projectUsers.find((user) => user.id === userId);
  }, [selectedUsers, projectUsers]);

  useEffect(() => {
    if (!services.roles) {
      return;
    }
    services.roles
      .listRoles()
      .then((users) => setAvailableRoles(users as WorkspaceRoles))
      .catch((error) => Promise.reject(error));
  }, [services.roles]);

  const validateUser = useCallback((updatedNewUser) => {
    // If this is a new user then it must have at least one role
    if ((updatedNewUser.roles.length as number) === 0) {
      return Promise.reject('At least one role must be selected.');
    }
    return Promise.resolve();
  }, []);

  return (
    <Modal title={selectedUsers && selectedUsers?.length > 0 ? 'Edit User' : 'Add User'} isPrimaryActionVisible={false}>
      <div className="flex flex-col w-full">
        <ProjectUserRow
          key="new-user"
          user={user}
          isEnabled={true}
          isNewUser={true}
          onSave={onProjectUserSaved}
          onCancel={closeModal}
          onValidate={validateUser}
          existingUsers={projectUsers && new Set(projectUsers.map((user) => user.id))}
          availableRoles={availableRoles}
          selectedUsers={selectedUsers}
        />
      </div>
    </Modal>
  );
};

export default NewUserProjectModal;
