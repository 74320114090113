import { Dispatch, SetStateAction, useCallback, useMemo } from 'react';
import Button, { BUTTON_TYPES } from '../Button';

const UNASSIGNED_PROJECT_NAME = 'undefined';

export type RowWithProjectInfo = {
  projectName?: string;
  rootProjectName?: string;
};

interface GridExpandCollapseButtonProps {
  rows: Array<RowWithProjectInfo>;
  expandedProjectNames: ReadonlySet<string>;
  setExpandedProjectNames: Dispatch<SetStateAction<ReadonlySet<string>>>;
}

const GridExpandCollapseButton = ({
  rows,
  expandedProjectNames,
  setExpandedProjectNames,
}: GridExpandCollapseButtonProps) => {
  const isFullyCollapsed = useMemo(() => {
    return expandedProjectNames.size === 0;
  }, [expandedProjectNames.size]);

  const toggleExpanded = useCallback(() => {
    if (!isFullyCollapsed) {
      setExpandedProjectNames(new Set());
      return;
    }

    setExpandedProjectNames(
      new Set([...(rows as Array<RowWithProjectInfo>).map((row) => row.rootProjectName || ''), UNASSIGNED_PROJECT_NAME])
    );
  }, [isFullyCollapsed, setExpandedProjectNames, rows]);

  const label = useMemo(() => {
    return isFullyCollapsed ? 'Expand All' : 'Collapse All';
  }, [isFullyCollapsed]);

  return (
    <Button
      type={BUTTON_TYPES.TERTIARY}
      leadingIcon={isFullyCollapsed ? 'expand-alt' : 'down-left-and-up-right-to-center'}
      removePadding={true}
      onClick={toggleExpanded}
      title={label}
      ariaLabel={label}
    />
  );
};

export default GridExpandCollapseButton;
