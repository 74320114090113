import { useEffect, useMemo } from 'react';
import Select, { OptionProps } from 'react-select';
import { getItemTopLabel, getItemLocationLabel, getTotalItemUsage } from '../lib/items';
import { reactSelectNoBorderStyles, reactSelectStyles } from '../../lib/styles';
import { Item, ItemStatus, TotalUsageRecord } from '../types';
import useLocations from '../hooks/useLocations';
import ItemOption from './ItemOption';
import { sortBy } from 'shared/lib/collections';
import _ from 'lodash';
import { Issue } from '../../issues/types';
import { StyleVariant } from '../../elements/lib/fieldStyles';

type InventoryOption = {
  value: string;
  label: string;
  isDisabled: boolean;
  item: Item;
  quantityLabel: string;
  locationLabel?: string;
  usageData?: TotalUsageRecord;
  numIssues?: number;
};

interface InventoryItemSelectProps {
  itemId?: string;
  itemOptions: Array<Item>;
  minAmount?: number;
  onChangeItem?: (Item) => void;
  isDisabled?: boolean;
  variant?: StyleVariant;
  usageTypeName?: string;
  itemIdsToNumIssues?: { [key: string]: number };
  mapOfCriticalIssues?: { [key: string]: Issue[] };
}

const InventoryItemSelect = ({
  itemId,
  itemOptions,
  minAmount = 0,
  onChangeItem,
  isDisabled,
  variant = StyleVariant.Standalone,
  usageTypeName,
  itemIdsToNumIssues,
  mapOfCriticalIssues,
}: InventoryItemSelectProps) => {
  const { getLocationLabel } = useLocations();
  const readOnly = isDisabled === true; // renaming so some of the conditionals below are more intuitive

  const style = variant === StyleVariant.Standalone ? reactSelectStyles : reactSelectNoBorderStyles;

  useEffect(() => {
    if (!readOnly && itemId) {
      const selectedItem = itemOptions?.find((item) => item.id === itemId);
      if (selectedItem && selectedItem.amount < minAmount) {
        onChangeItem?.(null);
      }
    }
  }, [itemId, minAmount, itemOptions, readOnly, onChangeItem]);

  const { enabledOptions, disabledOptions } = useMemo(() => {
    const nonScrappedItemOptions = itemOptions.filter((item) => item.status !== ItemStatus.Scrap);

    const inventoryOptions = nonScrappedItemOptions.map((item) => {
      const amountOfCriticalIssues = mapOfCriticalIssues?.[item.id]?.length ?? 0;

      const quantityLabel = item.status === ItemStatus.InInventory ? `Quantity: ${item.amount}` : '';
      const statusLabel = item.status === ItemStatus.InInventory ? '' : `${item.status}`;
      const itemUsage = getTotalItemUsage(item);
      return {
        value: item.id,
        label: getItemTopLabel(item, getLocationLabel),
        isDisabled: readOnly ? false : item.amount < minAmount || amountOfCriticalIssues > 0,
        item,
        quantityLabel,
        statusLabel,
        locationLabel: getItemLocationLabel(item, getLocationLabel),
        usageData: itemUsage.find((usageType) => usageType.name === usageTypeName),
        numIssues: itemIdsToNumIssues?.[item.id] ?? 0,
        numCriticalIssues: amountOfCriticalIssues,
        _sort_top_label: getItemTopLabel(item, getLocationLabel),
        _sort_bottom_label: getItemLocationLabel(item, getLocationLabel),
      };
    });
    const sortedOptions = sortBy(inventoryOptions, ['_sort_top_label', '_sort_bottom_label']);

    const [enabled, disabled]: InventoryOption[][] = _.partition(sortedOptions, (option) => !option.isDisabled);
    return { enabledOptions: enabled, disabledOptions: disabled };
  }, [getLocationLabel, itemIdsToNumIssues, itemOptions, minAmount, readOnly, usageTypeName, mapOfCriticalIssues]);

  const selectedValue = enabledOptions?.find((option: OptionProps) => option.value === itemId) || null;

  const onChange = (option) => {
    onChangeItem?.(option?.item);
  };

  const customFilter = ({ label, data }, inputValue: string): boolean => {
    const normalizedInput = inputValue.toLowerCase();
    return (
      label.toLowerCase().includes(normalizedInput) ||
      (data.bottomLabel && data.bottomLabel.toLowerCase().includes(normalizedInput))
    );
  };

  return (
    <div>
      <Select
        styles={style}
        classNamePrefix="react-select"
        options={[...enabledOptions, ...disabledOptions]}
        components={{ Option: ItemOption }}
        value={selectedValue}
        onChange={onChange}
        isDisabled={isDisabled}
        isSearchable={true}
        isClearable={true}
        filterOption={customFilter}
        placeholder="Search inventory"
      />
    </div>
  );
};

export default InventoryItemSelect;
