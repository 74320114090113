import superlogin from './superlogin';
import { API_URL } from '../config';
import { isEmptyValue } from 'shared/lib/text';
import _ from 'lodash';
import { EventEmitter2, ListenerFn } from 'eventemitter2';
import { onSelectorChanged } from './observers';
import { ImportConfig } from '../lib/views/settings';
import {
  Snippet,
  SnippetSection,
  SnippetStep,
} from 'shared/lib/types/views/procedures';
import { ExternalData } from '../lib/models/postgres/externalData';
import { AxiosResponse } from 'axios';
import {
  Config,
  ConfigLists,
  ConfigStepDetails,
  Project,
  Projects,
  Tags,
  Users,
} from 'shared/lib/types/couch/settings';
import { CouchLikeOperations } from 'shared/lib/types/operations';
import { Integrations } from '../lib/models/postgres/integrations';
import { OperationsPagination } from '../contexts/SettingsContext';
import {
  DefaultView,
  UserPreference,
  UserPreferences,
} from 'shared/lib/types/postgres/users';
import { RecentExportData } from 'shared/lib/types/exim';
import { InstallConfig } from './types';
import { RunTagsResponse } from 'shared/lib/types/api/runs/responses';

export const DOC_ID_PROJECTS = 'projects';
export const DOC_ID_EXTERNAL_DATA = 'external_data';
export const DOC_TYPE_SNIPPET = 'snippet';
export const SNIPPET_TYPE_STEP = 'step';
export const SNIPPET_TYPE_SECTION = 'section';

export type CancelFunc = {
  cancel: () => void;
};

class SettingsService {
  static instances = {};

  teamId: string;
  name: string;
  emitter: EventEmitter2;
  observer: { cancel: () => void } | null;

  static getInstance = (teamId: string): SettingsService => {
    if (!SettingsService.instances[teamId]) {
      SettingsService.instances[teamId] = new SettingsService(teamId);
    }

    return SettingsService.instances[teamId];
  };

  static removeInstance = (teamId: string): void => {
    delete SettingsService.instances[teamId];
  };

  constructor(teamId: string) {
    this.teamId = teamId;
    this.name = `settings_${teamId}`;
    this.emitter = new EventEmitter2();
    this.observer = null;
  }

  startChangeFeed(): void {
    if (this.observer) {
      return;
    }
    const notifyListeners = (callbackValue) => {
      for (const change of callbackValue.results) {
        this.emitter.emit(change.id, callbackValue);
      }
    };
    this.observer = onSelectorChanged(
      this.name,
      null,
      null,
      notifyListeners,
      null
    );
  }

  async close(): Promise<void> {
    if (this.observer) {
      this.observer.cancel();
      this.observer = null;
    }

    SettingsService.removeInstance(this.teamId);
  }

  onDocChanged(id: string, callback: ListenerFn): CancelFunc {
    this.startChangeFeed();
    if (callback) {
      this.emitter.on(id, callback);
      return {
        cancel: () => {
          this.emitter.off(id, callback);
        },
      };
    } else {
      return {
        cancel: () => {
          /* no-op */
        },
      };
    }
  }

  async getConfig(): Promise<Config> {
    const url = `${API_URL}/teams/${this.teamId}/settings/config`;
    return superlogin
      .getHttp()
      .get(url)
      .then((resp) => resp.data);
  }

  async getUsers(): Promise<Users> {
    const url = `${API_URL}/teams/${this.teamId}/users`;
    const response = await superlogin.getHttp().get(url);
    return response.data;
  }

  async getDefaultView(): Promise<DefaultView> {
    try {
      const response = await superlogin
        .getHttp()
        .get(`${API_URL}/users/${this.teamId}/default-view`);
      return response.data.default_view;
    } catch (message) {
      throw new Error(message.response.data.error);
    }
  }

  async getUserPreferences(): Promise<UserPreferences> {
    try {
      const response = await superlogin
        .getHttp()
        .get(`${API_URL}/teams/${this.teamId}/user-preferences`);
      return response.data;
    } catch (message) {
      throw new Error(message.response.data.error);
    }
  }

  async getExternalData(): Promise<AxiosResponse<ExternalData>> {
    const url = `${API_URL}/teams/${this.teamId}/external-data`;
    return superlogin
      .getHttp()
      .get(url)
      .then((resp) => resp.data);
  }

  onOperationsChanged(callback: ListenerFn): CancelFunc {
    return this.onDocChanged('operations', callback);
  }

  onConfigChanged(callback: ListenerFn): CancelFunc {
    return this.onDocChanged('config', callback);
  }

  onUsersChanged(callback: ListenerFn): CancelFunc {
    return this.onDocChanged('users', callback);
  }

  onDefaultViewChanged(callback: ListenerFn): CancelFunc {
    return this.onDocChanged('default_view', callback);
  }

  async updateConfig(config: Config): Promise<AxiosResponse> {
    const url = `${API_URL}/teams/${this.teamId}/settings/config`;
    return superlogin.getHttp().post(url, config);
  }

  async updateDefaultView(doc: DefaultView): Promise<AxiosResponse> {
    const url = `${API_URL}/users/${this.teamId}/default-view`;
    return superlogin.getHttp().post(url, doc);
  }

  async updateUserPreference(doc: UserPreference): Promise<AxiosResponse> {
    const url = `${API_URL}/teams/${this.teamId}/user-preferences`;
    return superlogin.getHttp().post(url, doc);
  }

  /**
   * Fetches and returns the projects document.
   */
  async getProjects(): Promise<Projects> {
    const url = `${API_URL}/teams/${this.teamId}/projects`;
    return superlogin
      .getHttp()
      .get(url)
      .then((resp) => resp.data);
  }

  /**
   * Adds new project into the projects document.
   */
  async addProject(project: Project): Promise<Projects> {
    if (isEmptyValue(project.name)) {
      return Promise.reject(new Error('Please enter project name'));
    }
    if (isEmptyValue(project.code)) {
      return Promise.reject(new Error('Please enter project code'));
    }

    const url = `${API_URL}/teams/${this.teamId}/projects`;
    await superlogin.getHttp().post(url, project);
    return this.getProjects();
  }

  /**
   * Updates project in the projects document.
   */
  async updateProject(project: Project, projectId: string): Promise<Projects> {
    if (isEmptyValue(projectId)) {
      return Promise.reject(new Error('Invalid projectId'));
    }

    const url = `${API_URL}/teams/${this.teamId}/projects/${projectId}/settings`;
    await superlogin.getHttp().patch(url, project);
    return this.getProjects();
  }

  /**
   * Adds a listener to the projects document.
   */
  onProjectsChanged(callback: ListenerFn): CancelFunc {
    return this.onDocChanged(DOC_ID_PROJECTS, callback);
  }

  getTeamId(): string {
    return this.teamId;
  }

  async _updateConfigWithSetting(
    config: Config,
    key: string,
    value: ConfigLists | ConfigStepDetails | boolean | string
  ): Promise<AxiosResponse> {
    const updated = _.cloneDeep(config);
    updated[key] = value;
    return this.updateConfig(updated);
  }

  /**
   * Adds or updates setting in config.
   *
   * @param key - name of setting, should be snake case and should not start
   *              with underscore to avoid collision with fields used by
   *              couchdb, e.g., _deleted.
   * @param value - object containing fields specific to setting.
   */
  async setSetting(
    key: string,
    value: ConfigLists | ConfigStepDetails | boolean | string
  ): Promise<AxiosResponse> {
    if (!key || key[0] === '_') {
      return Promise.reject(new Error('Invalid settings key'));
    }
    const config = await this.getConfig();
    return this._updateConfigWithSetting(config, key, value);
  }

  async getEventLogs(start: string, end: string): Promise<string> {
    const url = `${API_URL}/teams/${this.teamId}/history/events/csv`;
    const params = {
      start,
      end,
    };

    const response = await superlogin.getHttp().get(url, { params });
    return response.data;
  }

  async getUserLoginReport(): Promise<string> {
    const url = `${API_URL}/teams/${this.teamId}/user-login-report`;

    const response = await superlogin.getHttp().get(url);
    return response.data;
  }

  async getPendingActionsReport(): Promise<string> {
    const url = `${API_URL}/teams/${this.teamId}/runs/pending-actions`;

    const response = await superlogin.getHttp().get(url);
    return response.data;
  }

  async getWorkspaceExportFile(
    selectedProjectIds: string[],
    selectedTagKeys: string[]
  ): Promise<string> {
    const url = `${API_URL}/teams/${this.teamId}/export-workspace`;

    const response = await superlogin.getHttp().get(url, {
      params: {
        selectedProjectIds: JSON.stringify(selectedProjectIds),
        selectedTagKeys: JSON.stringify(selectedTagKeys),
      },
      responseType: 'blob', // Set the response type to 'blob' for binary data
    });

    return response.data;
  }
  async downloadExportFile(exportId: string): Promise<string> {
    const url = `${API_URL}/teams/${this.teamId}/downloads/exports/${exportId}`;

    const response = await superlogin.getHttp().get(url, {
      responseType: 'blob', // Set the response type to 'blob' for binary data
    });

    return response.data;
  }
  async getRecentExports(): Promise<RecentExportData[]> {
    const url = `${API_URL}/teams/${this.teamId}/exports/recent`;

    const response = await superlogin.getHttp().get(url);

    return response.data.data;
  }
  async startProcedureLibraryExport(
    selectedProjectIds: string[],
    selectedTagKeys: string[]
  ): Promise<string> {
    const url = `${API_URL}/teams/${this.teamId}/export/procedure-library`;
    const response = await superlogin.getHttp().post(url, {
      selectedProjectIds,
      selectedTagKeys,
    });

    return response.data;
  }

  async importWorkspaceFromExportFile(
    file: File,
    importConfig: ImportConfig
  ): Promise<string> {
    const url = `${API_URL}/teams/${this.teamId}/import-workspace`;

    const bodyFormData = new FormData();
    bodyFormData.append('file', file);
    bodyFormData.append('importConfig', JSON.stringify(importConfig));
    const response = await superlogin.getHttp().post(url, bodyFormData, {
      headers: { 'Content-Type': 'multipart/form-data' },
    });

    return response.data;
  }

  async getEximStatus(): Promise<string> {
    const url = `${API_URL}/teams/${this.teamId}/exim-status`;
    const response = await superlogin.getHttp().get(url);
    return response.data.eximStatus;
  }

  /**
   * Save or update a step snippet doc.
   */
  async saveStepSnippet({
    id,
    name,
    description,
    step,
    isTestSnippet,
  }: {
    id: string;
    name: string;
    description: string;
    step: SnippetStep;
    isTestSnippet?: boolean;
  }): Promise<AxiosResponse> {
    const url = `${API_URL}/teams/${this.teamId}/snippets/${id}`;
    const snippet = {
      type: DOC_TYPE_SNIPPET,
      snippet_type: SNIPPET_TYPE_STEP,
      name,
      description,
      step,
    };

    const fullUrl = `${url}?test_snippet=${encodeURIComponent(
      isTestSnippet ? true : false
    )}`;

    return superlogin.getHttp().post(fullUrl, snippet);
  }

  /**
   * Save or update a section snippet doc.
   */
  async saveSectionSnippet({
    id,
    name,
    description,
    section,
    isTestSnippet,
  }: {
    id: string;
    name: string;
    description: string;
    section: SnippetSection;
    isTestSnippet?: boolean;
  }): Promise<AxiosResponse> {
    const url = `${API_URL}/teams/${this.teamId}/snippets/${id}`;
    const snippet = {
      type: DOC_TYPE_SNIPPET,
      snippet_type: SNIPPET_TYPE_SECTION,
      name,
      description,
      section,
    };

    const fullUrl = `${url}?test_snippet=${encodeURIComponent(
      isTestSnippet ? true : false
    )}`;

    return superlogin.getHttp().post(fullUrl, snippet);
  }

  /**
   * Delete (soft delete) a snippet by id.
   */
  async deleteSnippet(id: string): Promise<AxiosResponse> {
    const url = `${API_URL}/teams/${this.teamId}/snippets/${id}`;
    return superlogin.getHttp().delete(url);
  }

  /**
   * List all snippets.
   */
  async listSnippets(
    {
      includeDeleted,
      snippetIds,
      isTestSnippet,
    }: {
      includeDeleted?: boolean;
      snippetIds?: Array<string>;
      isTestSnippet?: boolean;
    } = {
      includeDeleted: false,
      snippetIds: [],
      isTestSnippet: false,
    }
  ): Promise<Array<Snippet>> {
    const url = `${API_URL}/teams/${this.teamId}/snippets`;
    const params = {
      ...(includeDeleted ? { include_deleted: true } : {}),
      snippet_ids: snippetIds,
      test_snippet: isTestSnippet,
    };
    return superlogin
      .getHttp()
      .get(url, { params })
      .then((response) => response.data.data);
  }

  async getSnippet({
    snippetId,
    isTestSnippet,
  }: {
    snippetId: string;
    isTestSnippet?: boolean;
  }): Promise<Snippet> {
    const url = `${API_URL}/teams/${this.teamId}/snippets/${snippetId}`;
    const params = {
      test_snippet: Boolean(isTestSnippet),
    };

    return superlogin
      .getHttp()
      .get(url, { params })
      .then((response) => response.data);
  }

  async getPaginatedOperationsDoc(
    pagination?: OperationsPagination,
    searchTerm?: string
  ): Promise<CouchLikeOperations> {
    const url = new URL(`${API_URL}/teams/${this.teamId}/operations`);
    if (pagination) {
      url.searchParams.append(
        'planning_page',
        pagination.planning_page.toString()
      );
      url.searchParams.append(
        'running_page',
        pagination.running_page.toString()
      );
      url.searchParams.append('ended_page', pagination.ended_page.toString());
      url.searchParams.append('limit', pagination.limit.toString());
    }
    if (searchTerm) {
      url.searchParams.append('search_term', searchTerm);
    }
    return superlogin
      .getHttp()
      .get(url.toString())
      .then((resp) => resp.data);
  }

  async getTagsDoc(): Promise<Tags> {
    const url = `${API_URL}/teams/${this.teamId}/settings/tags`;
    return superlogin
      .getHttp()
      .get(url)
      .then((resp) => resp.data);
  }

  async getRunTagsDoc(): Promise<RunTagsResponse> {
    const url = `${API_URL}/teams/${this.teamId}/runs/tags`;
    return superlogin
      .getHttp()
      .get(url)
      .then((resp) => resp.data);
  }

  /**
   * Saves a new tags document, updating it if it already exists.
   */
  async saveTags(tags: Tags): Promise<AxiosResponse> {
    const url = `${API_URL}/teams/${this.teamId}/settings/tags`;
    return superlogin.getHttp().post(url, { tags });
  }

  async getEnabledModules(): Promise<string[]> {
    const url = `${API_URL}/teams/${this.teamId}/modules`;
    const response = await superlogin.getHttp().get(url);
    return response.data.keys;
  }

  async getIntegrations(): Promise<Integrations> {
    const url = `${API_URL}/teams/${this.teamId}/integrations`;
    const response = await superlogin.getHttp().get(url);
    return {
      _id: 'integrations',
      integrations: response.data,
    };
  }

  async installIntegration(
    integration: string,
    config?: InstallConfig
  ): Promise<AxiosResponse> {
    const url = `${API_URL}/teams/${this.teamId}/integrations/install`;
    return superlogin.getHttp().post(url, { integration, ...config });
  }

  async uninstallIntegration(integration: string): Promise<AxiosResponse> {
    const url = `${API_URL}/teams/${this.teamId}/integrations/uninstall`;
    return superlogin.getHttp().post(url, { integration });
  }

  /**
   * Fetches the oauth2 authorization url for the given integration and opens a new window with the url.
   */
  async startOAuthAuthorization(
    integration: string,
    userId?: string
  ): Promise<string> {
    const url = `${API_URL}/teams/${this.teamId}/oauth/authorize`;
    const response = await superlogin
      .getHttp()
      .post(url, { integration, user_id: userId });
    return response.data.url;
  }

  async revokeOAuthAuthorization(
    integration: string,
    userId?: string
  ): Promise<AxiosResponse> {
    const url = `${API_URL}/teams/${this.teamId}/oauth/revoke`;
    return superlogin.getHttp().post(url, { integration, user_id: userId });
  }

  async isAuthorizationComplete(
    integration: string,
    userId?: string
  ): Promise<boolean> {
    const url = `${API_URL}/teams/${this.teamId}/oauth/verify`;
    const response = await superlogin
      .getHttp()
      .post(url, { integration, user_id: userId });
    return response.data.isAuthorized;
  }
}

export default SettingsService;
