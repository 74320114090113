import { AlertSubtype } from 'shared/lib/types/views/procedures';
import { BlockType, PropertyType } from 'shared/lib/types/blockTypes';
import { IconType } from '../../elements/Icon';

const metaKey = window.navigator.userAgent.indexOf('Mac') > -1 ? 'Cmd' : 'Ctrl';
export const Shortcuts = {
  copy: metaKey.concat('+C'),
  cut: metaKey.concat('+X'),
  paste: metaKey.concat('+V'),
  remove: 'Del',
};

export type MenuGroup =
  | 'Content Blocks'
  | 'Inputs'
  | 'External'
  | 'Logic'
  | 'Add Content'
  | 'Parts'
  | 'Tools'
  | 'Actions'
  | 'Testing';

export enum Actions {
  'AddStepHeader',
  'AddStep',
  'InsertSnippet',
  'AddSectionHeader',
  'AddSectionDependencies',
  'AddSection',
  'DeleteStep',
  'DeleteStepHeader',
  'DeleteSection',
  'DeleteSectionHeader',
  'CopySection',
  'CopyStep',
  'CopyBlock',
  'CutSection',
  'CutStep',
  'CutBlock',
  'PasteSection',
  'PasteStep',
  'PasteBlock',
  'SaveAsSnippet',
  'CollapseAllStepsInSection',
  'ExpandAllStepsInSection',
  'AddBlock',
  'DeleteBlock',
  'AddProcedureVariable',
  'AddRisk',
  'AddProcedureHeader',
  'AddPartBuild',
  'AddPartKit',
  'AddPartInventory',
  'AddToolCheckOut',
  'AddToolCheckIn',
  'AddTestCases',
}

export const isLabel = (
  menuItem: ContentMenuItem
): menuItem is MenuLabelItem => {
  return (menuItem as MenuActionItem).action === undefined;
};

export const isAction = (
  menuItem: ContentMenuItem
): menuItem is MenuActionItem => {
  return (menuItem as MenuActionItem).action !== undefined;
};

export const addGroupings = (
  items: Array<MenuActionItem>
): Array<ContentMenuItem> => {
  let currentGroup = items[items.length - 1].group || '';
  let i = items.length - 1;
  const grouped: Array<ContentMenuItem> = [];
  while (i >= 0) {
    if (!items[i].hidden) {
      if (items[i].group !== currentGroup) {
        grouped.unshift({ label: currentGroup });
        currentGroup = items[i].group || '';
      }
      grouped.unshift(items[i]);
    }
    i--;
  }
  grouped.unshift({ label: currentGroup });
  return grouped;
};

type BaseMenuItem = {
  key?: string;
  label: string;
};

export type MenuLabelItem = BaseMenuItem;

export type MenuActionItem = BaseMenuItem & {
  action: Actions;
  icon?: IconType;
  description?: string;
  group?: MenuGroup;
  blockType?:
    | BlockType
    | Extract<PropertyType, 'dependencies' | 'conditionals' | 'part_list'>;
  subType?: AlertSubtype | BlockType;
  hidden?: boolean;
  keyboardShortcut?: string;
};

export type ContentMenuItem = MenuLabelItem | MenuActionItem;
