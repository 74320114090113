import { useCallback } from 'react';
import { Project } from 'shared/lib/types/couch/settings';
import { useSettings } from '../contexts/SettingsContext';
import { useAuth } from '../contexts/AuthContext';
import { ACCESS } from 'shared/lib/constants/permissions';

interface useProjectsReturns {
  getProjectOptions: (permissionKey: string) => Array<projectOption>;
  getProjectsForAccessLevel: ({
    projectAccessLevel,
  }: {
    projectAccessLevel?: ACCESS;
  }) => Array<Project>;
}

type projectOption = {
  id: string;
  name: string;
  value: Project;
};

const useProjects = (): useProjectsReturns => {
  const { projects: allProjects } = useSettings();
  const { auth } = useAuth();
  const getProjectOptions = useCallback(
    (permissionKey: string): Array<projectOption> => {
      if (!allProjects) {
        return [];
      }

      const projectOptions = [
        ...Object.values(allProjects.projects)
          .filter((project) =>
            typeof auth.hasPermission === 'function'
              ? auth.hasPermission(permissionKey, project.id)
              : true
          )
          .map((project: Project) => ({
            id: project.id,
            name: project.name,
            value: project,
          })),
      ];
      return projectOptions;
    },
    [allProjects, auth]
  );

  const getProjectsForAccessLevel = useCallback(
    ({
      projectAccessLevel,
    }: {
      projectAccessLevel?: ACCESS;
    }): Array<Project> => {
      if (!allProjects?.projects) {
        return [];
      }

      let projectsForAccessLevel = Object.values(allProjects.projects);
      if (
        projectAccessLevel === ACCESS.EDITOR &&
        auth.hasProjectOnlyEditPermissions()
      ) {
        const validProjectIds = new Set(auth.projectsWithEditPermission());
        projectsForAccessLevel = projectsForAccessLevel.filter((project) =>
          validProjectIds.has(project.id)
        );
      } else if (
        projectAccessLevel === ACCESS.OPERATOR &&
        auth.hasProjectOnlyOperatorPermissions()
      ) {
        const validProjectIds = new Set(auth.projectsWithOperatorPermission());
        projectsForAccessLevel = projectsForAccessLevel.filter((project) =>
          validProjectIds.has(project.id)
        );
      }
      return projectsForAccessLevel.sort((a, b) =>
        a.name.localeCompare(b.name)
      );
    },
    [allProjects, auth]
  );

  return {
    getProjectOptions,
    getProjectsForAccessLevel,
  };
};

export default useProjects;
