import { Config } from './types/couch/settings';
import { OperatorRoles } from './types/settings';
import lodash from 'lodash';

type CheckFunctionParams = {
  userOperatorRoles: Array<string>;
  entityOperatorRoles?: Array<string>;
  operatorRoles: OperatorRoles | null;
  config: Config | null;
};
export type CheckFunction = ({
  userOperatorRoles,
  entityOperatorRoles,
  operatorRoles,
  config,
}: CheckFunctionParams) => boolean;

const configUtil = {
  hasOperatorAuthorization: ({
    settingName,
    userOperatorRoles,
    operatorRoles,
  }: {
    settingName: 'can_suggest_edits' | 'can_skip_steps' | 'can_repeat_steps';
    userOperatorRoles: Array<string>;
    operatorRoles: OperatorRoles | null;
  }): boolean => {
    if (!operatorRoles) {
      return false;
    }

    // Allow the action if there are no operator roles
    if (operatorRoles.operators.length === 0) {
      return true;
    }

    // Otherwise ensure the user has at least one of the enabled roles for the entity.
    const userOperatorRolesSet = new Set(userOperatorRoles);
    return operatorRoles.operators.some(
      (operator) =>
        userOperatorRolesSet.has(operator.code) &&
        operator[settingName] !== false
    );
  },

  _getValidUserRolesForEntity: ({
    userOperatorRoles,
    entityOperatorRoles = [],
  }: Pick<
    CheckFunctionParams,
    'userOperatorRoles' | 'entityOperatorRoles'
  >): Array<string> => {
    return entityOperatorRoles.length === 0
      ? userOperatorRoles // If no entity roles are specified, all user roles are valid for the entity.
      : lodash.intersection(userOperatorRoles, entityOperatorRoles);
  },

  _canPerformEntityOperatorRequiredAction: ({
    userOperatorRoles,
    entityOperatorRoles,
    operatorRoles,
    config,
    globalSetting,
    operatorRoleSetting,
  }: CheckFunctionParams & {
    globalSetting:
      | 'skip_step_roles_control_enabled'
      | 'repeat_step_roles_control_enabled';
    operatorRoleSetting: 'can_skip_steps' | 'can_repeat_steps';
  }): boolean => {
    if (!config) {
      return false;
    }
    const validUserOperatorRoles = configUtil._getValidUserRolesForEntity({
      userOperatorRoles,
      entityOperatorRoles,
    });
    // If there are entity roles, valid users are required regardless of setting values.
    if (
      entityOperatorRoles &&
      entityOperatorRoles.length > 0 &&
      validUserOperatorRoles.length === 0
    ) {
      return false;
    }
    if (!config[globalSetting]) {
      return true;
    }
    return configUtil.hasOperatorAuthorization({
      settingName: operatorRoleSetting,
      userOperatorRoles: validUserOperatorRoles,
      operatorRoles,
    });
  },

  canSkipSteps: ({
    userOperatorRoles,
    entityOperatorRoles,
    operatorRoles,
    config,
  }: CheckFunctionParams): boolean => {
    return configUtil._canPerformEntityOperatorRequiredAction({
      userOperatorRoles,
      entityOperatorRoles,
      operatorRoles,
      config,
      globalSetting: 'skip_step_roles_control_enabled',
      operatorRoleSetting: 'can_skip_steps',
    });
  },

  canRepeatSteps: ({
    userOperatorRoles,
    entityOperatorRoles,
    operatorRoles,
    config,
  }: CheckFunctionParams): boolean => {
    return configUtil._canPerformEntityOperatorRequiredAction({
      userOperatorRoles,
      entityOperatorRoles,
      operatorRoles,
      config,
      globalSetting: 'repeat_step_roles_control_enabled',
      operatorRoleSetting: 'can_repeat_steps',
    });
  },

  canSuggestEdits: ({
    userOperatorRoles,
    operatorRoles,
  }: Omit<CheckFunctionParams, 'config'>): boolean => {
    return configUtil.hasOperatorAuthorization({
      settingName: 'can_suggest_edits',
      userOperatorRoles,
      operatorRoles,
    });
  },

  getUserParticipantType: (config: Config): 'participant' | 'viewer' => {
    let userParticipantType: 'participant' | 'viewer' = 'participant';
    if ('participate_by_default' in config) {
      userParticipantType = config.participate_by_default
        ? 'participant'
        : 'viewer';
    }
    return userParticipantType;
  },
};

export default configUtil;
