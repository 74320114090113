import { useQuery } from '@tanstack/react-query';
import { useDatabaseServices } from '../contexts/DatabaseContext';
import { DatabaseServices } from '../contexts/proceduresSlice';
import { Dictionary } from '../lib/models/postgres/dictionary';

const QUERY_KEY = 'dictionaries';

interface DictionaryState {
  dictionaries: Dictionary[];
  getDictionaryName: (id?: string | number) => string | undefined;
}

const useDictionaries = (): DictionaryState => {
  const { services }: { services: DatabaseServices } = useDatabaseServices();

  const fetchAll = async () => {
    return services.dictionary.listDictionaries();
  };

  const { data } = useQuery({
    queryKey: [QUERY_KEY],
    queryFn: fetchAll,
  });

  const getDictionaryName = (id?: string | number) => {
    const found = data?.find((entity) => entity.id === id);
    return found?.name;
  };

  return {
    dictionaries: data || [],
    getDictionaryName,
  };
};

export default useDictionaries;
