import { useCallback, useState } from 'react';
import Modal from '../components/Modal';
import { useSettings } from '../contexts/SettingsContext';
import Tooltip from '../elements/Tooltip';
import { Project } from 'shared/lib/types/couch/settings';

interface NewProjectModalProps {
  closeModal: () => void;
  onProjectSaved?: () => void;
  parent_project?: Project;
}

const UNIQUE_PROJECT_CODE_ERROR = 'Please enter a unique project code.';

const NewProjectModal = ({ closeModal, onProjectSaved, parent_project }: NewProjectModalProps) => {
  const { projects, addProject } = useSettings();

  const [name, setName] = useState<string>('');
  const [code, setCode] = useState<string>('');
  const [description, setDescription] = useState<string>('');
  const [codeError, setCodeError] = useState<boolean>(false);
  const [submitted, setSubmitted] = useState<boolean>(false);

  // Returns true if project code does not match any previously saved project codes.
  const isProjectUnique = useCallback(
    (project) => {
      // If no other projects exist, it is unique.
      if (!projects || !projects.projects) {
        return true;
      }

      /**
       * Remove matching project from array to not provide a false negative.
       * (in cases where only the name of the project was changed, the code would show up as a duplicate)
       */
      const previousProjectCodes = Object.values(projects.projects)
        .filter((p) => p.id !== project.id)
        .map((p) => p.code);

      return !previousProjectCodes.includes(project.code);
    },
    [projects]
  );

  /**
   * If project is a new project, add it
   *
   * @param {Object} project - a project object with id, name and code values.
   */
  const onProjectSave = useCallback(async () => {
    setSubmitted(true);
    const project = parent_project
      ? { id: '', name, code, description, parent_id: parent_project.id }
      : { id: '', name, code, description };

    // If code matches any previous project codes, show an error.
    if (!isProjectUnique(project)) {
      setCodeError(true);
      return Promise.reject(UNIQUE_PROJECT_CODE_ERROR);
    }

    return addProject(project).then(onProjectSaved).then(closeModal);
  }, [isProjectUnique, addProject, onProjectSaved, name, code, description, closeModal, parent_project]);

  return (
    <Modal
      title="Create Project"
      primaryActionTitle="Save"
      secondaryActionTitle="Cancel"
      onPrimaryAction={onProjectSave}
      onSecondaryAction={closeModal}
      size="md"
    >
      <div className="flex flex-col w-full">
        <div className="flex items-center mb-2 mt-2">
          {/* name */}
          <div className="w-1/2 flex flex-col mr-2">
            <label className="field-title">Name</label>
            <input
              name="name"
              type="text"
              placeholder="Enter Project Name"
              className=" border-1 border-gray-300 rounded"
              onChange={(e) => setName(e.target.value)}
            />

            {!name && submitted && <div className="text-red-700">Required</div>}
          </div>

          {/* code */}
          <div className="w-1/2 flex flex-col mr-2">
            <label className="field-title">Code</label>
            <div className="flex flex-col">
              <div className="flex items-center rounded">
                {parent_project && (
                  <Tooltip content={`${parent_project?.code}`}>
                    <span className="px-2 mr-1 whitespace-nowrap">
                      {parent_project?.code && parent_project.code.length > 10
                        ? `${parent_project.code.slice(0, 10)}...`
                        : parent_project?.code}
                      -
                    </span>
                  </Tooltip>
                )}

                <input
                  name="code"
                  type="text"
                  placeholder="Enter Project Code"
                  className="border-1 border-gray-300 rounded w-full"
                  onChange={(e) => setCode(e.target.value)}
                />
              </div>
            </div>

            {!code && !codeError && submitted && <div className="text-red-700">Required</div>}
            {codeError && submitted && <div className="text-red-700">{UNIQUE_PROJECT_CODE_ERROR} </div>}
          </div>
        </div>
        {/* description */}
        <div className="w-full flex flex-col mr-2">
          <label className="field-title">Description</label>
          <input
            name="description"
            type="text"
            placeholder="Enter Project Description"
            className=" border-1 border-gray-300 rounded"
            onChange={(e) => setDescription(e.target.value)}
          />
        </div>
      </div>
    </Modal>
  );
};

export default NewProjectModal;
