import { useCallback, useEffect, useMemo, useState } from 'react';
import { SelectColumn } from 'react-data-grid';
import { Link } from 'react-router-dom';
import { filterByKeywords, sortBy } from 'shared/lib/collections';
import quantitiesUtil from 'shared/lib/manufacturing/inventory/util/quantities';
import ThumbnailImageDisplay from '../../components/Attachments/ThumbnailImageDisplay';
import Button from '../../components/Button';
import ExpandCollapseCaret from '../../components/ExpandCollapse/ExpandCollapseCaret';
import Label from '../../components/Label';
import UnitDisplay from '../../components/Settings/Units/UnitDisplay';
import { useDatabaseServices } from '../../contexts/DatabaseContext';
import { useNavState } from '../../contexts/NavContext';
import Grid, { GridColumn, TextAlign } from '../../elements/Grid';
import { ITEM_REFERENCE_TYPE } from '../../issues/constants';
import useIssues from '../../issues/hooks/useIssues';
import apm from '../../lib/apm';
import { inventoryItemPath } from '../../lib/pathUtil';
import projectUtil from '../../lib/projectUtil';
import IssueIndicator from '../components/IssueIndicator';
import Revision from '../components/Revision';
import SortableGridColumnHeader, { SortConfig } from '../components/SortableGridColumnHeader';
import useItems from '../hooks/useItems';
import useLocations from '../hooks/useLocations';
import { getTrackingValue } from '../lib/items';
import { InventoryTab } from '../screens/Inventory';
import { EMPTY_TRACKING_DISPLAY_VALUE, Item, ItemStatus } from '../types';
import ItemStatusTag from './ItemStatusTag';
import { NO_LOCATION_KEY } from './LocationTreeSelect';
import { useSettings } from '../../contexts/SettingsContext';

const MAIN_VERTICAL_PADDING = 190;
const ROW_HEIGHT = 60;

const PART_COL_WIDTH = '27.5%';
const PART_COL_NO_SELECT_WIDTH = '30.4%';

type DisplayItem = Item & { part_no_and_rev: string; project_id?: string | null };

interface InventoryGridProps {
  searchTerm?: string;
  setSearchTerm?: (searchTerm: string) => void;
  locationIdsFilter?: string[];
  shouldRefresh?: boolean;
  verticalPadding?: number;
  onRefreshComplete?: () => void;
  activeTab?: InventoryTab;
  selectedRows?: ReadonlySet<string>;
  setSelectedRows?: (selectedRows: ReadonlySet<string>) => void;
  showSelectRows?: boolean;
}

const InventoryGrid = ({
  searchTerm,
  setSearchTerm,
  locationIdsFilter,
  shouldRefresh,
  verticalPadding = MAIN_VERTICAL_PADDING,
  onRefreshComplete,
  activeTab = InventoryTab.Available,
  selectedRows,
  setSelectedRows,
  showSelectRows = true,
}: InventoryGridProps) => {
  const { projects: projectsDoc } = useSettings();
  const projects = projectUtil.toProjectsArray(projectsDoc);
  const { projectsFilter, persistedView } = useNavState();
  const { currentTeamId } = useDatabaseServices();
  const { issues: itemIssues } = useIssues(ITEM_REFERENCE_TYPE);
  const { getLocationLabel } = useLocations();
  const { allItems, refreshItems } = useItems();
  const [sortConfig, setSortConfig] = useState<SortConfig>({ sortColumn: 'Part', sortDirection: 'asc' });
  const [expandedGroupIds, setExpandedGroupIds] = useState<ReadonlySet<string>>(new Set());

  useEffect(() => {
    const refreshIfNeeded = async () => {
      if (onRefreshComplete && shouldRefresh) {
        await refreshItems();
        onRefreshComplete();
      }
    };
    refreshIfNeeded().catch((err) => apm.captureError(err));
  }, [shouldRefresh, onRefreshComplete, refreshItems]);

  const rowKeyGetter = (row) => {
    return row.id;
  };

  const displayItems: DisplayItem[] = useMemo(() => {
    if (!allItems) {
      return [];
    }

    let projectItems = allItems;

    // Filter by project selection in navigation
    if (projectsFilter) {
      projectItems = projectItems.filter((item) => {
        if (!item.part.project_id) {
          return true;
        }
        return projectsFilter.projectIds.includes(item.part.project_id);
      });
    } else {
      const activeProjectIds = projectUtil.getActiveProjectIds(projects);
      projectItems = projectItems.filter((item) => {
        if (!item.part.project_id) {
          return true;
        }
        return activeProjectIds.includes(item.part.project_id);
      });
    }

    // Filter by project selection in header
    projectItems = projectItems.filter((item) => {
      const filter = persistedView.selectedProjectIds;
      return filter.size === 0 || filter.has(item.part.project_id || null);
    });

    const selectedItems =
      activeTab === InventoryTab.Available
        ? projectItems.filter((item) => item.amount > 0 && item.status === ItemStatus.InInventory)
        : projectItems.filter(
            (item) =>
              item.status === ItemStatus.CheckedOut ||
              item.status === ItemStatus.Consumed ||
              item.status === ItemStatus.Scrap
          );
    const mappedItems = selectedItems.map((item) => {
      let projectName = '';
      if (item.part.project_id) {
        projectName = projectUtil.getProjectName(projectsDoc, item.part.project_id) || '';
      }
      return {
        ...item,
        part_no_and_rev: `${item.part.part_no} ${item.part.rev}`.toLowerCase(),
        project_id: item.part.project_id,
        project_name: projectName,
        _sort_tracking_value: getTrackingValue(item),
        _search_part_name: item.part.name,
        _search_tracking: getTrackingValue(item) !== EMPTY_TRACKING_DISPLAY_VALUE ? getTrackingValue(item) : '',
        _search_location: item.location_id ? getLocationLabel(item.location_id) : '',
      };
    });
    const sortedItems = sortBy(
      mappedItems,
      ['part_no_and_rev', '_sort_tracking_value', 'created_at'],
      sortConfig.sortDirection
    );
    const sortedAndFilteredItems =
      (locationIdsFilter ?? []).length === 0
        ? sortedItems
        : sortedItems.filter((i) => {
            const locationId = i.location_id || NO_LOCATION_KEY;
            return locationIdsFilter?.includes(locationId);
          });
    if (!searchTerm) {
      return sortedAndFilteredItems;
    }
    const keywords = searchTerm.trim().split(/[\s]+/);
    return filterByKeywords(
      sortedAndFilteredItems,
      ['part_no_and_rev', 'project_name', '_search_part_name', '_search_tracking', '_search_location'],
      keywords
    );
  }, [
    allItems,
    activeTab,
    sortConfig.sortDirection,
    locationIdsFilter,
    searchTerm,
    projects,
    projectsFilter,
    persistedView.selectedProjectIds,
    getLocationLabel,
    projectsDoc,
  ]);

  const allPartAndRevs = useMemo(() => new Set(displayItems.map((item) => item.part_no_and_rev)), [displayItems]);
  const numPartAndRevs = useMemo(() => allPartAndRevs.size, [allPartAndRevs]);

  const updateExpanded = useCallback(() => {
    if (!displayItems) {
      return [];
    }
    if (expandedGroupIds.size < numPartAndRevs) {
      setExpandedGroupIds(allPartAndRevs);
    } else {
      setExpandedGroupIds(new Set());
    }
  }, [allPartAndRevs, displayItems, expandedGroupIds.size, numPartAndRevs]);

  const columns: readonly GridColumn<DisplayItem>[] = useMemo(
    () => [
      ...(showSelectRows ? [SelectColumn] : []),
      {
        key: 'part_no_and_rev',
        name: <SortableGridColumnHeader columnName="Part" sortConfig={sortConfig} onSortConfigChange={setSortConfig} />,
        width: showSelectRows ? PART_COL_WIDTH : PART_COL_NO_SELECT_WIDTH,
        renderGroupCell({ childRows, isExpanded }) {
          const row = childRows[0];
          return (
            <div className="flex flex-row items-center gap-x-1">
              <ExpandCollapseCaret isExpanded={isExpanded} ariaLabel="Expand item" />
              <ThumbnailImageDisplay size="md" attachment={row.part.image} />
              <div className="p-1 leading-5">
                <div className="flex items-center">
                  <div className="text-lg">{row.part.part_no}</div>
                  {row.part.rev && <Revision revision={row.part.rev} ml={1} size="sm" />}
                </div>
                <div className="">{row.part.name}</div>
              </div>
            </div>
          );
        },
      },
      {
        key: 'tracking',
        name: 'Tracking / Creation Date',
        renderCell({ row }) {
          const issuesTiedToItem = itemIssues.filter((issue) => issue.referenceId === row.id);
          return (
            <div className="ml-2">
              <Link to={inventoryItemPath(currentTeamId, row.id)}>
                <div className="flex flex-col items-start justify-center h-full leading-4">
                  <div className="flex flex-row items-center">
                    <div className=" text-blue-600 hover:underline mr-2">{getTrackingValue(row)}</div>
                    <IssueIndicator numIssues={issuesTiedToItem.length} />
                  </div>
                  <div className="text-gray-500 text-xs">{row.created_at}</div>
                </div>
              </Link>
            </div>
          );
        },
        renderGroupCell({ childRows, isExpanded }) {
          if (isExpanded) {
            return;
          }
          for (const row of childRows) {
            if (getTrackingValue(row)) {
              return <span className="ml-2">...</span>;
            }
          }
        },
      },
      {
        key: 'project',
        name: 'Project',
        renderGroupCell({ childRows }) {
          for (const row of childRows) {
            if (!row.project_id) {
              return <></>;
            }
            const projectName = projectUtil.getProjectName(projectsDoc, row.project_id) || '';
            return (
              <Label
                text={projectName}
                color="bg-gray-200"
                clickable={true}
                onClick={() => setSearchTerm && setSearchTerm(projectName)}
              />
            );
          }
        },
      },
      ...(activeTab === InventoryTab.Available
        ? [
            {
              key: 'amount',
              name: 'Quantity',
              align: TextAlign.Right,
              renderCell({ row }) {
                return (
                  <div className="w-full flex flex-row items-center justify-end">
                    <span>{row.amount}</span>
                  </div>
                );
              },
              renderGroupCell({ childRows, isExpanded }) {
                if (isExpanded) {
                  return;
                }
                const quantity = childRows
                  .map((row) => row.amount)
                  .reduce((sum, quantity) => quantitiesUtil.add(sum, quantity), 0);
                // Bug in RDG group formatter doesn't pass through className so we have to wrap this for right-align
                return (
                  <div className="w-full flex flex-row items-center justify-end">
                    <span>{quantity}</span>
                  </div>
                );
              },
            },
            {
              key: 'units',
              name: '',
              align: TextAlign.Left,
              renderCell({ row }) {
                if (!row.part.units) {
                  return null;
                }
                return <UnitDisplay unit={row.part.units} />;
              },
              renderGroupCell({ childRows, isExpanded }) {
                if (isExpanded) {
                  return;
                }
                let units: string | null = null;
                if (childRows.length > 0) {
                  units = childRows[0].part.units;
                }
                if (!units) {
                  return null;
                }
                return <UnitDisplay unit={units} />;
              },
            },
          ]
        : [
            {
              key: 'status',
              name: 'Status',
              align: TextAlign.Left,
              renderCell({ row }) {
                return (
                  <div className="w-full flex flex-row items-left justify-start">
                    <ItemStatusTag itemStatus={row.status} />
                  </div>
                );
              },
            },
          ]),
      {
        key: 'location',
        name: 'Location',
        renderHeaderCell() {
          return (
            <div className="flex flex-row w-full items-center justify-between">
              <div>Location</div>
              <div>
                <Button
                  type="tertiary"
                  leadingIcon={
                    expandedGroupIds.size < numPartAndRevs ? 'expand-alt' : 'down-left-and-up-right-to-center'
                  }
                  onClick={updateExpanded}
                  ariaLabel="Expand"
                />
              </div>
            </div>
          );
        },
        renderCell({ row }) {
          if (row.status === ItemStatus.Consumed) {
            return null;
          }
          const location = row.location_id ? getLocationLabel(row.location_id) : '';
          return <span>{location}</span>;
        },
        renderGroupCell({ childRows, isExpanded }) {
          if (isExpanded) {
            return;
          }
          for (const row of childRows) {
            if (row.status !== ItemStatus.Consumed && row.location_id && getLocationLabel(row.location_id)) {
              return <span className="ml-2">...</span>;
            }
          }
        },
      },
    ],
    [
      activeTab,
      currentTeamId,
      expandedGroupIds.size,
      getLocationLabel,
      itemIssues,
      numPartAndRevs,
      projectsDoc,
      setSearchTerm,
      showSelectRows,
      sortConfig,
      updateExpanded,
    ]
  );

  const gridKey = useMemo(() => columns.map((c) => c.key).join('-'), [columns]);

  return (
    <Grid
      gridKey={`${gridKey}-${projectsFilter?.project.id}`}
      columns={columns}
      rows={displayItems}
      rowGrouping={{
        groupBy: ['part_no_and_rev'],
        expandedGroupIds,
        onExpandedGroupIdsChange: setExpandedGroupIds,
      }}
      usedVerticalSpace={verticalPadding}
      rowHeight={ROW_HEIGHT}
      emptyRowMessage="No inventory found"
      selectedRows={selectedRows}
      onSelectedRowsChange={setSelectedRows}
      rowKeyGetter={rowKeyGetter}
    />
  );
};
export default InventoryGrid;
