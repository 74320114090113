import Tag from '../../Tag';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Tag as GlobalTag } from 'shared/lib/types/api/settings/tags/models';

type RemovableTagProps = {
  tag: GlobalTag;
  onRemove: (tag: GlobalTag) => void;
  isDisabled: boolean;
};

const RemovableTag = ({ tag, onRemove, isDisabled }: RemovableTagProps) => {
  return (
    <div className="relative group max-w-[7rem]">
      <Tag key={tag.id} id={tag.id} name={tag.name} color={tag.color} description={tag.description} />
      {!isDisabled && <RemoveTagButton onClick={() => onRemove(tag)} />}
    </div>
  );
};

const RemoveTagButton = ({ onClick }: { onClick: () => void }) => {
  return (
    <FontAwesomeIcon
      onClick={onClick}
      aria-label="Remove Tag"
      icon="times-circle"
      className="absolute -top-3 right-0 text-gray-400 hover:text-gray-500 opacity-0 group-hover:opacity-100 hover:cursor-pointer bg-white rounded-full border-2 border-white"
    />
  );
};

type RemovableTagsDisplayProps = {
  tags: Array<GlobalTag>;
  onRemove: (tag: GlobalTag) => void;
  isDisabled: boolean;
};

const RemovableTagsDisplay = ({ tags, onRemove, isDisabled }: RemovableTagsDisplayProps) => {
  return (
    <div className="flex flex-row flex-wrap items-center">
      {tags.map((tag) => (
        <RemovableTag key={tag.id} tag={tag} onRemove={onRemove} isDisabled={isDisabled} />
      ))}
    </div>
  );
};

export default RemovableTagsDisplay;
