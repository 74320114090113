import { faTimesCircle } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { Link } from 'react-router-dom';
import { isEmptyValue } from 'shared/lib/text';
import { Part } from 'shared/lib/types/postgres/manufacturing/types';
import { KitItem } from 'shared/lib/types/views/procedures';
import Button, { BUTTON_TYPES } from '../../components/Button';
import UnitDisplay from '../../components/Settings/Units/UnitDisplay';
import { StyleVariant } from '../../elements/lib/fieldStyles';
import apm from '../../lib/apm';
import { inventoryItemPath } from '../../lib/pathUtil';
import PartBadge from '../components/PartBadge';
import useItems from '../hooks/useItems';
import useLocations from '../hooks/useLocations';
import { getItemTopLabel } from '../lib/items';
import { isPartRestricted } from '../lib/parts';
import { Item } from '../types';
import ItemQuantityInput from './ItemQuantityInput';
import ItemSelect from './ItemSelect';
import RestrictedInfo, { RESTRICTED_TEXT } from './RestrictedInfo';
import { cellClass, getCellClass } from './util/tableUtil';

interface StepCompletedPartKitProps {
  teamId: string;
  amount?: number;
  itemId?: string;
}

const StepCompletedPartKit = ({ teamId, amount, itemId }: StepCompletedPartKitProps) => {
  const { getLocationLabel } = useLocations();
  const { getItem } = useItems();
  const empty = (
    <>
      <td className={cellClass}></td>
      <td className={cellClass}></td>
      <td></td>
    </>
  );
  if (itemId) {
    const hydratedItem = getItem(itemId);
    if (!hydratedItem) {
      return empty;
    }
    return (
      <>
        <td className={`${cellClass} p-1`}>
          <div className="flex items-center gap-x-1">
            {amount}
            {hydratedItem.part.units && <UnitDisplay unit={hydratedItem.part.units} />}
          </div>
        </td>
        <td className={`${cellClass} p-1`}>
          <div className="flex items-center justify-between">
            <Link to={inventoryItemPath(teamId, hydratedItem.id)}>
              <div className="text-blue-600">{getItemTopLabel(hydratedItem as Item, getLocationLabel)}</div>
            </Link>
            <div>
              <FontAwesomeIcon icon="check-square" className=" text-green-500" />
            </div>
          </div>
        </td>
        <td></td>
      </>
    );
  }
  return empty;
};

type FieldInputInventoryItemProps = {
  part?: Part;
  /** Authored version of this item */
  item: KitItem;
  /** Authored version + values captured during run for this item */
  recorded?: KitItem;
  isEnabled: boolean;
  teamId: string;
  onRecordValuesChanged?: (recorded: KitItem) => void;
  isStepComplete: boolean;
  selectedItemIds?: string[];
  onRemove?: () => void;

  /** Set to recorded values of previous tab, if part of batch steps */
  previousTabRecorded?: KitItem;
};

const FieldInputInventoryItem = ({
  part,
  item,
  recorded,
  isEnabled,
  teamId,
  onRecordValuesChanged,
  isStepComplete,
  selectedItemIds,
  onRemove,
  previousTabRecorded,
}: FieldInputInventoryItemProps) => {
  const { activeItems, refreshItems } = useItems();

  const partRestricted = isPartRestricted(part);

  const onChangeItem = useCallback(
    (inventoryItem: Item) => {
      const values = {
        ...item,
        ...recorded,
        item_id: inventoryItem?.id || undefined,
      };
      onRecordValuesChanged?.(values);
    },
    [item, recorded, onRecordValuesChanged]
  );

  const updateAmount = (amount) => {
    const values = {
      ...item,
      ...recorded,
      amount,
    };
    onRecordValuesChanged?.(values);
  };

  const onUpdateRev = (newRevLabel: string, newRevId?: string) => {
    const values = {
      ...item,
      ...recorded,
      item_id: undefined,
      revision: newRevLabel,
      revision_id: newRevId,
    };
    onRecordValuesChanged?.(values);
  };

  const amount = useMemo(() => {
    let amount = item.amount;
    if (!isEmptyValue(recorded?.amount)) {
      amount = recorded?.amount as number;
    }
    return amount;
  }, [item.amount, recorded?.amount]);

  // refresh inventory in batch mode when switching tabs
  const [itemIdForLastRefresh, setItemIdForLastRefresh] = useState(item.id);
  useEffect(() => {
    if (itemIdForLastRefresh !== item.id) {
      setItemIdForLastRefresh(item.id);
      refreshItems().catch((err) => apm.captureError(err));
    }
  }, [item.id, itemIdForLastRefresh, refreshItems]);

  /** propagate item checked out on previously completed tab to current tab as long as enough available quantity remains */
  useEffect(() => {
    if (item.tracking === 'serial') {
      return;
    }
    const itemIdToPropagate = previousTabRecorded?.item_id;
    if (recorded || !itemIdToPropagate) {
      return;
    }
    const inventoryItem = activeItems?.find((activeItem) => activeItem.id === itemIdToPropagate);
    if (inventoryItem && inventoryItem.amount >= item.amount) {
      onChangeItem(inventoryItem);
    }
  }, [item.tracking, previousTabRecorded, recorded, activeItems, item.amount, onChangeItem]);

  const itemOptions = useMemo(() => {
    if (!(isEnabled && selectedItemIds !== undefined)) {
      return undefined;
    }
    return (activeItems ?? []).filter((itemOption) => {
      if (recorded?.item_id === itemOption.id) {
        return true;
      }

      if (item.revision_id && item.revision_id !== itemOption.part_revision_id) {
        return false;
      }
      if (item.revision && item.revision !== itemOption.part.rev) {
        return false;
      }
      if (item.part_id !== itemOption.part.id) {
        return false;
      }

      const alreadySelectedElsewhere = selectedItemIds.some((selectedId) => selectedId === itemOption.id);
      return !alreadySelectedElsewhere;
    });
  }, [isEnabled, activeItems, recorded?.item_id, item.revision_id, item.revision, item.part_id, selectedItemIds]);

  if (partRestricted) {
    return (
      <tr>
        <td className={`${cellClass} p-1`}>
          <RestrictedInfo text={RESTRICTED_TEXT} />
        </td>
        <td className={`${cellClass} p-1`}>
          <RestrictedInfo />
        </td>
        <td className={`${cellClass} p-1`}>
          <RestrictedInfo />
        </td>
        <td></td>
      </tr>
    );
  }

  return (
    <tr>
      <td className={`${cellClass} p-1`}>
        <PartBadge
          inline={true}
          part={part}
          teamId={teamId}
          onRevChange={isEnabled && onRemove ? onUpdateRev : undefined}
        />
      </td>
      {!isStepComplete && (
        <>
          <td className={getCellClass(!isEnabled)}>
            <div className="flex flex-row items-center">
              <ItemQuantityInput
                value={amount}
                allowDecimalValue={part?.allow_decimal_quantities === true}
                allowNull={false}
                onChange={updateAmount}
                disabled={!isEnabled}
                min={0}
                max={part?.tracking === 'serial' ? 1 : undefined}
                placeholder="Quantity"
                textSize="sm"
                variant={StyleVariant.Grid}
              />
              {part?.units && (
                <div className="mx-1">
                  <UnitDisplay unit={part.units} />
                </div>
              )}
            </div>
          </td>
          <td className={getCellClass(!isEnabled)}>
            <ItemSelect
              itemId={recorded?.item_id}
              itemOptions={itemOptions}
              minAmount={amount}
              onChangeItem={onChangeItem}
              isDisabled={!isEnabled}
              variant={StyleVariant.Grid}
            />
          </td>
          <td className="w-5">
            {isEnabled && onRemove && (
              <Button
                leadingIcon={faTimesCircle}
                iconTextColor="text-gray-400"
                type={BUTTON_TYPES.TERTIARY}
                onClick={onRemove}
              />
            )}
          </td>
        </>
      )}
      {isStepComplete && <StepCompletedPartKit teamId={teamId} amount={recorded?.amount} itemId={recorded?.item_id} />}
    </tr>
  );
};

export default FieldInputInventoryItem;
