import { Field, useFormikContext } from 'formik';
import { useSettings } from '../contexts/SettingsContext';
import ProjectsEditSelect from './ProjectsEditSelect/ProjectsEditSelect';
import useAutoProcedureId from '../hooks/useAutoProcedureId';
import projectUtil from '../lib/projectUtil';

interface FieldSetProcedureProjectProps {
  isProjectClearable: boolean;
  isAutoProcedureIdEnabled?: boolean;
  warnUserOnIdChange?: boolean;
  isDisabled?: boolean;
}

const CONFIRM_ID_UPDATE_MESSAGE = 'Procedure ID will be updated. OK to proceed?';

const FieldSetProcedureProject = ({
  isProjectClearable,
  isAutoProcedureIdEnabled,
  warnUserOnIdChange = false,
}: FieldSetProcedureProjectProps) => {
  const { projects: projectsDoc } = useSettings();
  const projects = projectUtil.toProjectsArray(projectsDoc);
  const { values, setFieldValue, setValues } = useFormikContext<{ project_id?: string; code?: string }>();
  const { generatePlaceholderId } = useAutoProcedureId();

  const onUpdateProject = (projectId?: string): void => {
    // Update project only
    if (!isAutoProcedureIdEnabled) {
      setFieldValue('project_id', projectId);
      return;
    }

    // Generate and update procedure id
    if (!warnUserOnIdChange || window.confirm(CONFIRM_ID_UPDATE_MESSAGE)) {
      const project = projects.find((project) => project.id === projectId);
      const parentId = project && project.parent_id;
      setValues((prev) => ({
        ...prev,
        code: generatePlaceholderId({ projectId, parentId }),
        project_id: projectId,
      }));
    }
  };

  return (
    <Field
      name="project_id"
      className="h-full border border-gray-400 rounded"
      component={ProjectsEditSelect}
      value={values.project_id}
      isClearable={isProjectClearable}
      onUpdateProject={onUpdateProject}
    />
  );
};

export default FieldSetProcedureProject;
