import React, { Fragment, useCallback, useMemo, useRef, useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { VirtualScroller } from 'primereact/virtualscroller';

export type BasicOption<T> = {
  id?: string | number;
  name: string;
  value?: T;
  onClick?: () => void;
};

export type ToggleOption<T> = BasicOption<T> & {
  isDefault?: boolean;
  tooltip?: string;
  isDisabled?: boolean;
  onRestoreDefault?: () => void;
};

export type OptionType<T, U> = U extends ToggleOption<T> ? ToggleOption<T> : BasicOption<T>;

interface ListWithSearchProps<T, U> {
  title?: string;
  options: Array<OptionType<T, U>>;
  onSelect: (option: OptionType<T, U>) => Promise<void>;
  onCreate?: (name: string) => Promise<void>;
  disallowedTerms?: string[];
  focusOnMount?: boolean;
  disableSearch?: boolean;
  Components?: {
    ListItem?: (option: OptionType<T, U>, searchTerm: string) => JSX.Element;
    TitleActionButton?: () => JSX.Element;
  };
  fitContent?: boolean;
  showCursorPointer?: boolean;
  maxItemLength?: number;
}

const ListWithSearch = <T, U = BasicOption<T>>({
  title,
  options,
  onSelect,
  onCreate,
  disallowedTerms,
  focusOnMount = false,
  disableSearch = false,
  Components = {},
  fitContent = false,
  showCursorPointer = true,
  maxItemLength = undefined,
}: ListWithSearchProps<T, U>) => {
  const inputRef = useRef<HTMLInputElement>(null);
  const [searchTerm, setSearchTerm] = useState<string>('');

  const listItemRenderer = useMemo(() => {
    if (Components.ListItem) {
      return Components.ListItem;
    }

    return (label) => <div>{label}</div>;
  }, [Components.ListItem]);

  const clearSearchTerm = useCallback(() => {
    setSearchTerm('');
  }, []);

  const onInputChange = useCallback((event) => {
    setSearchTerm(event.target.value);
  }, []);

  const handleInputKeyDown = useCallback(
    (e: React.KeyboardEvent<HTMLInputElement>) => {
      if (
        e.key === 'Enter' &&
        searchTerm &&
        searchTerm.trim().length > 0 &&
        onCreate &&
        !disallowedTerms?.some((term) => term.toLowerCase() === searchTerm.toLowerCase().trim())
      ) {
        e.preventDefault();
        void onCreate(searchTerm.trim());
        setSearchTerm('');
      }
    },
    [searchTerm, onCreate, disallowedTerms]
  );

  const filteredOptions = useMemo(() => {
    if (!searchTerm) {
      return options;
    }

    return options.filter((option) => option.name.toLowerCase().includes(searchTerm.toLowerCase().trim()));
  }, [options, searchTerm]);

  const showCreateOption = useMemo(() => {
    return (
      onCreate &&
      searchTerm &&
      searchTerm.trim()?.length > 0 &&
      !disallowedTerms?.some((term) => term.toLowerCase() === searchTerm.toLowerCase().trim())
    );
  }, [disallowedTerms, onCreate, searchTerm]);

  const _options = useMemo(() => {
    if (!showCreateOption) {
      return filteredOptions;
    }

    return [
      ...filteredOptions,
      {
        isCreateOption: true,
        name: searchTerm?.trim(),
      },
    ];
  }, [filteredOptions, showCreateOption, searchTerm]);

  const itemTemplate = useCallback(
    (option) => {
      if (option.isCreateOption) {
        return (
          <button
            tabIndex={0}
            className="flex flex-row space-x-2 items-start w-full p-2 bg-white focus:brightness-90 focus:outline-none hover:brightness-90"
            onClick={() => onCreate && onCreate(option.name)}
            onKeyDown={(e) => e.key === 'Enter' && onCreate && onCreate(option.name)}
          >
            <span>Create</span>
            {listItemRenderer(option, searchTerm)}
          </button>
        );
      }

      if (option.onClick) {
        // onClick is handled within the list item
        return (
          <div className="grow flex items-center w-full bg-white focus:brightness-90 focus:outline-none hover:brightness-90">
            {listItemRenderer(option, searchTerm)}
          </div>
        );
      }

      return (
        <button
          tabIndex={0}
          key={option.id}
          className="flex items-start w-full p-2 bg-white focus:brightness-90 focus:outline-none hover:brightness-90"
          onClick={() => onSelect(option)}
          onKeyDown={(e) => {
            if (e.key === 'Enter') {
              e.preventDefault();
              return onSelect(option);
            }
          }}
        >
          {listItemRenderer(option, searchTerm)}
        </button>
      );
    },
    [listItemRenderer, onSelect, onCreate, searchTerm]
  );

  return (
    <div
      className={`flex flex-col ${
        fitContent ? 'w-fit p-2' : 'p-2 min-w-[240px] max-w-[240px]'
      } gap-y-2 overflow-x-hidden`}
      data-testid="list-with-search"
    >
      {title && (
        <div className="self-start flex flex-row items-center justify-between w-full  py-1 -mb-2 text-sm font-semibold text-gray-500">
          <span className="px-2">{title}</span>
          {Components.TitleActionButton && (
            <div className="px-1">
              <Components.TitleActionButton />
            </div>
          )}
        </div>
      )}
      {!disableSearch && (
        <div className="relative">
          <input
            ref={inputRef}
            className="rounded self-center p-2 pr-7 border border-gray-300 w-56"
            value={searchTerm}
            onChange={onInputChange}
            autoFocus={focusOnMount}
            onKeyDown={handleInputKeyDown}
            maxLength={maxItemLength}
          />
          {searchTerm && (
            <div
              className="absolute top-0 bottom-0 h-5 m-auto right-2 outline-none text-gray-400 hover:text-gray-700  cursor-pointer"
              onClick={clearSearchTerm}
            >
              <FontAwesomeIcon icon="times-circle" />
            </div>
          )}
        </div>
      )}
      {_options.length > 0 && (
        <div
          className={`${fitContent ? 'h-fit' : 'max-h-[200px]'} overflow-y-auto ${
            showCursorPointer ? 'cursor-pointer' : ''
          }`}
        >
          {_options.length <= 10 && (
            <>
              {_options.map((option, index) => (
                <Fragment key={`${option.name}-${index}`}>{itemTemplate(option)}</Fragment>
              ))}
            </>
          )}
          {_options.length > 10 && (
            <VirtualScroller
              items={_options}
              itemSize={36}
              itemTemplate={itemTemplate}
              scrollHeight={`${Math.min(_options.length * 36, 200)}px`}
              style={{ height: '1px' }}
            />
          )}
        </div>
      )}
    </div>
  );
};

export default React.memo(ListWithSearch) as typeof ListWithSearch;
