import { useCallback, useEffect, useRef, useState } from 'react';
import sharedDiffUtil from 'shared/lib/diffUtil';
import { AttachmentResponse } from '../attachments/types';
import { useDatabaseServices } from '../contexts/DatabaseContext';
import { DatabaseServices } from '../contexts/proceduresSlice';
import attachmentUtil from '../lib/attachmentUtil';
import { downloadBlob } from '../lib/fileUtil';
import { AttachmentMetadata } from '../lib/views/attachments';

export type AttachmentHook = {
  url?: string;
  name?: string;
  contentType?: string;
  metadata?: AttachmentMetadata;
  error?: Error;
  isFetching?: boolean;
};

export type UseAttachmentReturns = AttachmentHook & {
  downloadAttachment: () => void;
  fetchAttachment: () => void;
};

export type Attachment = {
  attachment_id: string;
  name?: string;
  content_type?: string;
};

export interface UseAttachmentProps {
  attachment?: Attachment;
  file?: File;
  isDiff?: boolean;
}

const useAttachment = ({
  attachment,
  file,
  isDiff = false,
}: UseAttachmentProps): UseAttachmentReturns => {
  const isMounted = useRef(true);
  const blobUrl = useRef<string | undefined>(undefined);
  const { services }: { services: DatabaseServices } = useDatabaseServices();
  const [state, setState] = useState<AttachmentHook>({
    url: undefined,
    name: undefined,
    contentType: undefined,
    metadata: undefined,
    error: undefined,
    isFetching: undefined,
  });

  const _updateAttachment = useCallback(
    (
      attachment: Attachment | undefined,
      attachmentName: string | undefined,
      attachmentResponse: AttachmentResponse | null
    ): string | undefined => {
      if (!attachmentResponse || !isMounted.current) {
        return;
      }

      const objectUrl = attachmentUtil.createObjectURL(attachmentResponse.blob);
      setState({
        url: objectUrl,
        name: attachmentName,
        contentType: attachment
          ? isDiff
            ? sharedDiffUtil.getDiffValue(attachment, 'content_type', 'new')
            : attachment.content_type
          : undefined,
        isFetching: false,
      });

      return objectUrl;
    },
    [isDiff]
  );

  useEffect(
    () => () => {
      isMounted.current = false;

      // if url was created outside of a side effect, explicitly revoke it to prevent leak
      if (blobUrl.current) {
        attachmentUtil.revokeObjectURL(blobUrl.current);
      }
    },
    []
  );

  // Load attachment data and object blob.
  useEffect(() => {
    const attachmentName = attachment
      ? isDiff
        ? sharedDiffUtil.getDiffValue<string>(attachment, 'name', 'new')
        : attachment.name
      : '';
    const attachmentId = attachment
      ? isDiff
        ? sharedDiffUtil.getDiffValue<string>(
            attachment,
            'attachment_id',
            'new'
          )
        : attachment.attachment_id
      : undefined;
    let objectUrl: string | undefined;
    // Show selected file or current attachment object
    if (file && typeof file === 'object') {
      objectUrl = URL.createObjectURL(file);
      setState({
        url: objectUrl,
        name: file.name,
        contentType: file.type,
        isFetching: false,
      });
    } else if (attachmentId) {
      // for video content, do not fetch the attachment unless it's already in cache
      if (attachment?.content_type?.includes('video')) {
        services.attachments
          .getCachedAttachment(attachmentId)
          .then((attachmentResponse: AttachmentResponse | null) => {
            objectUrl = _updateAttachment(
              attachment,
              attachmentName,
              attachmentResponse
            );
          })
          .catch((error: Error) => {
            // This can catch either metadata or attachment file load errors
            setState({ error });
          });
      } else {
        services.attachments
          .getAttachment(attachmentId)
          .then((attachmentResponse: AttachmentResponse) => {
            objectUrl = _updateAttachment(
              attachment,
              attachmentName,
              attachmentResponse
            );
          })
          .catch((error: Error) => {
            // This can catch either metadata or attachment file load errors
            setState({ error });
          });
      }
    }
    return () => {
      if (objectUrl) {
        attachmentUtil.revokeObjectURL(objectUrl);
      }
    };
  }, [services.attachments, attachment, file, isDiff, _updateAttachment]);

  const downloadAttachment = useCallback(() => {
    if (!attachment) {
      return;
    }
    const attachmentName = isDiff
      ? sharedDiffUtil.getDiffValue<string>(attachment, 'name', 'new')
      : attachment.name;
    services.attachments
      .getAttachment(
        isDiff
          ? sharedDiffUtil.getDiffValue(attachment, 'attachment_id', 'new')
          : attachment.attachment_id
      )
      .then((resp: AttachmentResponse) =>
        downloadBlob(resp.blob, attachmentName)
      )
      .catch((err) => {
        /* noop */
      });
  }, [services.attachments, attachment, isDiff]);

  const fetchAttachment = useCallback(() => {
    const attachmentName = attachment
      ? isDiff
        ? sharedDiffUtil.getDiffValue<string>(attachment, 'name', 'new')
        : attachment.name
      : '';
    const attachmentId = attachment
      ? isDiff
        ? sharedDiffUtil.getDiffValue<string>(
            attachment,
            'attachment_id',
            'new'
          )
        : attachment.attachment_id
      : undefined;

    if (attachmentId) {
      setState((oldState) => ({
        ...oldState,
        isFetching: true,
      }));
      services.attachments
        .getAttachment(attachmentId)
        .then((attachmentResponse: AttachmentResponse) => {
          const objectUrl = _updateAttachment(
            attachment,
            attachmentName,
            attachmentResponse
          );
          blobUrl.current = objectUrl;
        })
        .catch((error: Error) => {
          // This can catch either metadata or attachment file load errors
          setState({ error });
        });
    }
  }, [isDiff, attachment, services.attachments, _updateAttachment]);

  return {
    fetchAttachment,
    downloadAttachment,
    ...state,
  };
};

export default useAttachment;
