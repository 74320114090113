import { RowWithProjectInfo } from '../../components/Home/GridExpandCollapseButton';
import Label from '../../components/Label';

interface ProjectRendererProps<T extends RowWithProjectInfo> {
  row: T;
  setSearchTerm?: (searchTerm: string) => void;
}

const ProjectRenderer = <T extends RowWithProjectInfo>({ row, setSearchTerm }: ProjectRendererProps<T>) => {
  return !row.projectName ? (
    <></>
  ) : (
    <Label
      text={row.projectName}
      color="bg-gray-200"
      clickable={true}
      onClick={() => row.projectName && setSearchTerm?.(row.projectName)}
    />
  );
};

export default ProjectRenderer;
