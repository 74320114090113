import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Item } from '../../types';
import SingleLineInventoryItemBadge from '../SingleLineInventoryItemBadge';
import { useState } from 'react';

type SelectedItemsProps = {
  selectedItems: Item[];
  onRemoveItem: (itemId: string) => void;
};

const SelectedItems = ({ selectedItems, onRemoveItem }: SelectedItemsProps) => {
  const [activeItemId, setActiveItemId] = useState<string | null>(null);
  return (
    <div className="flex flex-col w-full overflow-x-hidden">
      <div className="h-fit overflow-y-auto">
        {selectedItems.map(({ id }) => (
          <div
            className="flex items-center justify-between py-2 w-full border-b border-slate-200"
            onMouseEnter={() => setActiveItemId(id)}
            onMouseLeave={() => setActiveItemId(null)}
          >
            <SingleLineInventoryItemBadge itemId={id} key={id} />
            {activeItemId === id && (
              <button
                aria-label="remove-item-under-hover"
                type="button"
                className="secondary"
                onClick={() => onRemoveItem(id)}
              >
                <FontAwesomeIcon className=" text-gray-400" icon="circle-xmark" />
              </button>
            )}
          </div>
        ))}
      </div>
    </div>
  );
};

export default SelectedItems;
