import { Part } from 'shared/lib/types/postgres/manufacturing/types';
import { partPath } from '../../lib/pathUtil';
import { getPartRevisionId } from '../lib/parts';
import { Link } from 'react-router-dom';
import RevisionHistory from './PartDetail/RevisionHistory';
import Revision from './Revision';

interface PartLabelProps {
  teamId: string;
  part: Part;
  inline?: boolean;
  onChange?: (revisionId) => void;
}

const PartLabel = ({ teamId, part, inline = false, onChange }: PartLabelProps) => {
  const nameLabel = part.name;
  const style = inline ? 'flex flex-row items-center gap-x-2' : 'leading-4 flex flex-col gap-y-1';
  const partRevisionId = getPartRevisionId(part);
  const path = partPath(teamId, part.id, partRevisionId);
  const isLatestRevision = part?.revisions && part.revisions[0].revision === part.rev;

  return (
    <div className={style}>
      <div className="flex flex-row items-center">
        <Link to={path} className="inline-block">
          <div className="text-blue-600">{part.part_no}</div>
        </Link>
        {onChange ? (
          <RevisionHistory
            revision={part.rev}
            state={part.release_state}
            onLatestRevision={isLatestRevision}
            revisions={part.revisions}
            onChange={onChange}
          />
        ) : (
          <Revision revision={part.rev} size="sm" ml={1} />
        )}
      </div>
      <div>{nameLabel}</div>
    </div>
  );
};

export default PartLabel;
