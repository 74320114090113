import { useMemo } from 'react';
import { Analysis } from 'shared/lib/types/testing';
import Tooltip from '../../elements/Tooltip';
import { AssessmentColor, getAssessmentColor, getMatrixColumnName, getMatrixRowName } from './matrixUtils';

interface AssessmentIndicatorProps {
  analysis: Analysis;
}

const AssessmentIndicator = ({ analysis }: AssessmentIndicatorProps) => {
  const displayString = `${getMatrixRowName(analysis)} and ${getMatrixColumnName(analysis)}`;
  const { backgroundColor, backgroundColorName }: AssessmentColor = useMemo(() => {
    return getAssessmentColor(analysis);
  }, [analysis]);

  return (
    <Tooltip content={displayString}>
      <div
        aria-label={`${displayString}${backgroundColorName ? `:${backgroundColorName}` : ''}`}
        className="flex items-center justify-center h-3 w-3 shrink-0 rounded-full"
        style={{ backgroundColor }}
      />
    </Tooltip>
  );
};

export default AssessmentIndicator;
