import { Link } from 'react-router-dom';
import { ProcedureTableRowModel } from '../types';
import Tooltip from '../../../elements/Tooltip';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Tag from '../../Tag';
import { IconProp } from '@fortawesome/fontawesome-svg-core';
import Pluralize from 'pluralize';
import TagsDisplay from '../../Settings/Tags/TagsDisplay';

const getIconColor = (iconColor) => {
  return iconColor || 'text-gray-400';
};

const renderProcedureLinkWithIcon = ({ row }: { row: ProcedureTableRowModel }) => {
  const numberOfSuggestedEdits = row.numberOfSuggestedEdits;
  const link = row.procedureTitle.link;
  const label = row.procedureTitle.label;
  const name = row.procedureTitle.name;
  const icon = row.procedureTitle.icon;
  const iconLink = row.procedureTitle.link;
  const iconTitle = row.procedureTitle.iconTitle;
  const iconColor = row.procedureTitle.iconColor;
  const tags = row.procedureTitle.tags;
  const globalTags = row.procedureTitle.globalTags;

  return (
    <div className="leading-4 w-full">
      <div className="flex flex-row">
        <Tooltip content={label} visible={label?.length > 50}>
          <Link to={link} className="mr-1 font-medium text-sm text-blue-600 tracking-wider hover:underline truncate">
            {label}
          </Link>
        </Tooltip>
        {icon && iconLink && (
          <Tooltip content={iconTitle}>
            <div className="mx-1 inline-block group">
              <Link to={iconLink}>
                <FontAwesomeIcon
                  icon={icon as IconProp}
                  className={getIconColor(iconColor)}
                  aria-label={iconTitle}
                  role="img"
                />
              </Link>
            </div>
          </Tooltip>
        )}
        {icon && !iconLink && (
          <Tooltip content={iconTitle}>
            <div className="mx-1 inline-block group">
              <FontAwesomeIcon
                icon={icon as IconProp}
                className={getIconColor(iconColor)}
                aria-label={iconTitle}
                role="img"
              />
            </div>
          </Tooltip>
        )}
        {numberOfSuggestedEdits && (
          <Tooltip content={`${Pluralize('Suggested Edit', numberOfSuggestedEdits, true)}`}>
            <div className="mx-1 inline-block group">
              <FontAwesomeIcon icon="strikethrough" className="text-red-500" aria-label="Suggested Edits Indicator" />
            </div>
          </Tooltip>
        )}
      </div>
      <div className="mt-0 text-gray-900 truncate">
        <Tooltip content={name} visible={name?.length > 50}>
          <Link to={link}>{name}</Link>
        </Tooltip>
      </div>
      <div className="flex">
        {tags && tags.map((tag) => <Tag clickable={true} key={tag.key} id={tag.key} name={tag.name} />)}
        {globalTags && <TagsDisplay tags={globalTags} isClickable={true} />}
      </div>
    </div>
  );
};

export default renderProcedureLinkWithIcon;
