import { Part, UsageType } from 'shared/lib/types/postgres/manufacturing/types';
import { RunPartUsageBlock, RunPartUsageRecorded } from 'shared/lib/types/views/procedures';
import { StyleVariant } from '../../elements/lib/fieldStyles';
import RunControlledInput from '../../elements/RunControlledInput';
import { getUsageTypeLabel } from '../lib/parts';
import { Item } from '../types';
import ItemSelect from './ItemSelect';
import PartAndUsageTypeSelect from './PartAndUsageTypeSelect';
import PartBadge from './PartBadge';
import { cellClass, getCellClass, headerClass } from './util/tableUtil';
import { PartUsageContentErrors } from '../../lib/types';

const Columns = ({ isSelectingAtRuntime }: { isSelectingAtRuntime: boolean }) => {
  return isSelectingAtRuntime ? (
    <tr>
      <td className={`${headerClass} w-4/12`}>Record Part Usage</td>
      <td className={`${headerClass} w-3/12`}>Usage Type</td>
      <td className={`${headerClass} w-3/12`}>Inventory</td>
      <td className={`${headerClass} w-2/12`}>Usage</td>
    </tr>
  ) : (
    <tr>
      <td className={`${headerClass} w-7/12`}>Record Part Usage</td>
      <td className={`${headerClass} w-3/12`}>Inventory</td>
      <td className={`${headerClass} w-2/12`}>Usage</td>
    </tr>
  );
};

interface PartUsageBaseProps {
  content?: RunPartUsageBlock;
  part?: Part;
  contentPartId?: string;
  contentPartRev?: string;
  itemIdSelected?: string;
  usageTypeSelected?: UsageType;
  recorded?: Array<RunPartUsageRecorded>;
  isStepComplete: boolean;
  isPartSelectorEnabled?: boolean;
  isUsageDisabled?: boolean;
  teamId: string;
  projectId?: string;
  isPartRestricted?: boolean;
  errors?: PartUsageContentErrors;
  onChangePart?: (part: Part | null) => void;
  onChangeItem?: (itemId: Item) => void;
  onChangeUsage?: (usageType: UsageType) => void;
  onRecordAmount?: (amount: string) => void;
}

const PartUsageBase = ({
  content,
  part,
  contentPartId,
  contentPartRev,
  itemIdSelected,
  usageTypeSelected,
  recorded,
  isStepComplete,
  isPartSelectorEnabled = false,
  isUsageDisabled,
  teamId,
  projectId,
  isPartRestricted,
  errors,
  onChangePart,
  onChangeItem,
  onChangeUsage,
  onRecordAmount,
}: PartUsageBaseProps) => {
  const isSelectingAtRuntime = !content?.part;

  return (
    <table className="w-full">
      <thead>
        <Columns isSelectingAtRuntime={isSelectingAtRuntime} />
      </thead>
      <tbody>
        <tr>
          {isSelectingAtRuntime ? (
            <PartAndUsageTypeSelect
              content={content}
              projectId={projectId}
              onChangePartDuringRun={onChangePart}
              onChangeUsage={onChangeUsage}
              recorded={recorded}
              isEnabled={isPartSelectorEnabled}
              isStepComplete={isStepComplete}
            />
          ) : (
            <td className={`${cellClass} p-1`}>
              <PartBadge inline={true} part={part} teamId={teamId} partRestricted={isPartRestricted} />
            </td>
          )}

          <td className={getCellClass(isUsageDisabled)}>
            {!isPartRestricted && (
              <ItemSelect
                itemId={itemIdSelected}
                partId={contentPartId}
                partRevision={contentPartRev}
                isDisabled={isUsageDisabled}
                onChangeItem={onChangeItem}
                usageTypeName={usageTypeSelected?.name}
                includeInactive={true}
                variant={StyleVariant.Grid}
              />
            )}
          </td>
          <td className={getCellClass(isUsageDisabled)}>
            {!isPartRestricted && (
              <>
                <div className="flex flex-row items-center">
                  <RunControlledInput
                    type="number"
                    min={0}
                    data-testid="usage-amount"
                    className="border-0 disabled:bg-gray-100 w-full text-sm"
                    disabled={isUsageDisabled}
                    onRecordValue={onRecordAmount}
                    recorded={`${recorded?.[0]?.amount}`}
                  />
                  {usageTypeSelected ? <div className="px-1">{getUsageTypeLabel(usageTypeSelected)}</div> : ''}
                </div>
                {errors && errors.amount && <div className="whitespace-nowrap text-red-700">{errors.amount}</div>}
              </>
            )}
          </td>
        </tr>
      </tbody>
    </table>
  );
};

export default PartUsageBase;
