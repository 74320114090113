import sharedDiffUtil from 'shared/lib/diffUtil';
import { ReviewToolUsageBlock } from 'shared/lib/types/views/procedures';
import DiffContainer from '../../../components/Diff/DiffContainer';
import SubstepNumber from '../../../components/SubstepNumber';
import diffUtil from '../../../lib/diffUtil';
import { generateHiddenClassString } from '../../../lib/styles';
import useTool from '../../hooks/useTool';
import ToolUsageBase from './ToolUsageBase';

interface ReviewToolUsageProps {
  content: ReviewToolUsageBlock;
  teamId: string;
  blockLabel?: string;
  isHidden?: boolean;
}

const ReviewToolUsage = ({ content, teamId, blockLabel, isHidden }: ReviewToolUsageProps) => {
  const toolId = sharedDiffUtil.getDiffValue<number>(content, 'tool_id', 'new');
  const usageTypeId = sharedDiffUtil.getDiffValue<number>(content, 'usage_type_id', 'new');
  const { tool } = useTool({ toolId });

  if (!tool) {
    return null;
  }

  const diffChangeState =
    content.diff_change_state === '~'
      ? diffUtil.getDiffChangeStateForChangesOnly(content, 'tool_id')
      : diffUtil.getDiffChangeStateForAddedRemovedOnly(content);

  return (
    <div className={generateHiddenClassString('mt-3 ml-4 flex flex-wrap page-break', isHidden)}>
      <SubstepNumber blockLabel={blockLabel} hasExtraVerticalSpacing={false} />
      <div className="w-full py-1 mr-8">
        <DiffContainer label="Tool usage" diffChangeState={diffChangeState} isTextSticky={false}>
          <ToolUsageBase
            tool={tool}
            usageType={usageTypeId}
            teamId={teamId}
            isStepComplete={false}
            isStepRecorded={false}
            isEnabled={false}
          />
        </DiffContainer>
      </div>
    </div>
  );
};

export default ReviewToolUsage;
