import { useCallback, useEffect, useMemo, useState } from 'react';

import configUtil from 'shared/lib/configUtil';
import runUtil from '../lib/runUtil';
import { PERM } from '../lib/auth';
import { RUN_STATE } from 'shared/lib/runUtil';
import { useAuth } from '../contexts/AuthContext';
import { useMixpanel } from '../contexts/MixpanelContext';
import { useSettings } from '../contexts/SettingsContext';

export interface RedlineState {
  isActive: boolean;
  dirtyContent: object;
  dirtyFields: object;
  dirtyComment: boolean;
  isEditing: boolean;
}

export const INITIAL_REDLINE_STATE: RedlineState = {
  isActive: false,
  dirtyContent: {},
  dirtyFields: {},
  dirtyComment: false,
  isEditing: false,
};

export interface UseRedlineProps {
  docState: string;
  isPreviewMode: boolean;
  isRedlineDisabledBecauseOfSectionSnippet: boolean;
  isRedlineDisabledBecauseOfStepSnippet: boolean;
  isRedlineDisabledBecauseOfRepeat: boolean;
  isRedlineFeatureEnabled: boolean;
  isUserParticipant: boolean;
  projectId: string | null;
}

export type UseRedlineReturns = {
  canSuggestEdits: boolean;
  isRedlineEnabled: boolean;
  isRedlineStateDirty: (redlineState) => boolean;
  onToggleRedline: () => void;
  reasonRedlineIsDisabled: string | null;
  redlineState: RedlineState;
  setRedlineState: React.Dispatch<React.SetStateAction<RedlineState>>;
  showsRedlineButton: boolean;
  clearRedlineState: () => void;
  setIsEditing: (isEditing: boolean) => void;
};

export const useRedline = ({
  docState,
  isPreviewMode,
  isRedlineDisabledBecauseOfSectionSnippet,
  isRedlineDisabledBecauseOfStepSnippet,
  isRedlineDisabledBecauseOfRepeat,
  isRedlineFeatureEnabled,
  isUserParticipant,
  projectId,
}: UseRedlineProps): UseRedlineReturns => {
  const { auth } = useAuth();
  const { operatorRoles } = useSettings();
  const { mixpanel } = useMixpanel();

  const [redlineState, setRedlineState] = useState(INITIAL_REDLINE_STATE);

  const canSuggestEdits = useMemo(() => {
    if (
      configUtil.canSuggestEdits({
        userOperatorRoles: auth.getOperatorRoles(),
        operatorRoles,
      })
    ) {
      return true;
    }
    /*
     * If access is revoked, immediately reset redline editing and discard suggestions.
     * Also check config for null and redline state to not end up in an infinite re-render loop.
     */
    if (operatorRoles && redlineState !== INITIAL_REDLINE_STATE) {
      setRedlineState(INITIAL_REDLINE_STATE);
    }
    return false;
  }, [auth, operatorRoles, redlineState]);

  const showsRedlineButton = useMemo(() => {
    return (
      isRedlineFeatureEnabled &&
      !isPreviewMode &&
      canSuggestEdits &&
      runUtil.isRunStateActive(docState) &&
      auth.hasPermission(PERM.RUNS_EDIT, projectId)
    );
  }, [
    auth,
    canSuggestEdits,
    docState,
    isPreviewMode,
    isRedlineFeatureEnabled,
    projectId,
  ]);

  const isRedlineEnabled = useMemo(() => {
    return (
      isUserParticipant &&
      docState === RUN_STATE.RUNNING &&
      !isRedlineDisabledBecauseOfSectionSnippet &&
      !isRedlineDisabledBecauseOfStepSnippet &&
      !isRedlineDisabledBecauseOfRepeat
    );
  }, [
    docState,
    isRedlineDisabledBecauseOfSectionSnippet,
    isRedlineDisabledBecauseOfStepSnippet,
    isRedlineDisabledBecauseOfRepeat,
    isUserParticipant,
  ]);

  const reasonRedlineIsDisabled = useMemo(() => {
    if (isRedlineEnabled) {
      return null;
    }
    if (isRedlineDisabledBecauseOfStepSnippet) {
      return 'Suggested edits are not currently supported for step snippets.';
    }
    if (isRedlineDisabledBecauseOfSectionSnippet) {
      return 'Suggested edits are not currently supported for section snippets.';
    }
    if (isRedlineDisabledBecauseOfRepeat) {
      return 'Suggested edits can only be made on the latest repeated step.';
    }

    return null;
  }, [
    isRedlineEnabled,
    isRedlineDisabledBecauseOfSectionSnippet,
    isRedlineDisabledBecauseOfStepSnippet,
    isRedlineDisabledBecauseOfRepeat,
  ]);

  // Clear redline state and discard any unsaved edits when step is ended.
  useEffect(() => {
    if (!isRedlineEnabled) {
      setRedlineState(INITIAL_REDLINE_STATE);
    }
  }, [isRedlineEnabled]);

  // Helper method for checking if any content or field is dirty during redlining.
  const isRedlineStateDirty = useCallback((redlineState: RedlineState) => {
    // Content is dirty.
    if (Object.values(redlineState.dirtyContent).some(Boolean)) {
      return true;
    }
    // Step field is dirty.
    if (Object.values(redlineState.dirtyFields).some(Boolean)) {
      return true;
    }
    // Step comment is dirty
    if (redlineState.dirtyComment) {
      return true;
    }
    return false;
  }, []);

  const clearRedlineState = useCallback(() => {
    setRedlineState({
      isActive: false,
      dirtyContent: {},
      dirtyFields: {},
      dirtyComment: false,
      isEditing: false,
    });
  }, []);

  const onToggleRedline = useCallback(() => {
    setRedlineState((state) => {
      if (state.isActive) {
        // Check if there are unsaved redline edits before cancelling
        const message = 'Are you sure? Your unsaved edits will be discarded.';
        if (isRedlineStateDirty(state) && !window.confirm(message)) {
          return state;
        }
        // Set redline inactive
        if (mixpanel) {
          mixpanel.track('Suggested Edits Cancelled or Closed');
        }
        return {
          isActive: false,
          dirtyContent: {},
          dirtyFields: {},
          dirtyComment: false,
          isEditing: false,
        };
      } else {
        // Set redline active
        if (mixpanel) {
          mixpanel.track('Suggested Edits Started');
        }
        return {
          isActive: true,
          dirtyContent: {},
          dirtyFields: {},
          dirtyComment: false,
          isEditing: false,
        };
      }
    });
  }, [isRedlineStateDirty, mixpanel, setRedlineState]);

  const setIsEditing = useCallback(
    (isEditing: boolean) => {
      setRedlineState((state) => ({
        ...state,
        isEditing,
      }));
    },
    [setRedlineState]
  );

  return {
    canSuggestEdits,
    isRedlineEnabled,
    isRedlineStateDirty,
    onToggleRedline,
    reasonRedlineIsDisabled,
    redlineState,
    setRedlineState,
    showsRedlineButton,
    clearRedlineState,
    setIsEditing,
  };
};
