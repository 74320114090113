import { useEffect, useMemo, useState } from 'react';
import { Column, RowHeightArgs } from 'react-data-grid';
import { ViewTab } from 'shared/lib/types/postgres/users';
import { ProcedureMetadata } from 'shared/lib/types/views/procedures';
import { RowWithProjectInfo } from '../../../components/Home/GridExpandCollapseButton';
import renderDateTime from '../../../components/Home/Renderers/DateTime';
import renderRunStatus from '../../../components/Home/Renderers/Status';
import { RowAction } from '../../../components/Home/types';
import LoadingScreen from '../../../components/LoadingScreen';
import { useDatabaseServices } from '../../../contexts/DatabaseContext';
import { useNavState } from '../../../contexts/NavContext';
import { useSettings } from '../../../contexts/SettingsContext';
import Grid, { DEFAULT_GROUP_ROW_HEIGHT_PX } from '../../../elements/Grid';
import projectGroupCell from '../../../elements/renderers/ProjectGroupCell';
import ProjectRenderer from '../../../elements/renderers/ProjectRenderer';
import RunNameLinkRenderer from '../../../elements/renderers/RunNameLinkRenderer';
import {
  NO_TAGS_ROW_HEIGHT,
  TAGS_ROW_HIGHT,
  filterByField,
  filterBySearchTerm,
  projectGrouping,
} from '../../../lib/gridUtils';
import projectUtil from '../../../lib/projectUtil';
import { goToProcedure } from '../../libs/testPlanUtil';
import { PLAN_VERTICAL_PADDING } from '../../screens/Plans';

export type PlanProcedureMetadata = ProcedureMetadata & {
  draftAction?: RowAction;
};

type PlanRow = RowWithProjectInfo & PlanProcedureMetadata;
export interface PlansGridProps {
  searchTerm?: string;
  setSearchTerm: (searchTerm: string) => void;
  selectedProjects?: ReadonlySet<string | null>;
  viewTab?: ViewTab;
}

const ArchivedPlansGrid = ({
  searchTerm = '',
  setSearchTerm,
  selectedProjects,
  viewTab = ViewTab.List,
}: PlansGridProps) => {
  const { projectsFilter, filterByProject, persistedView } = useNavState();
  const { projects } = useSettings();
  const { services, currentTeamId } = useDatabaseServices();
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [archivedTestPlans, setArchivedTestPlans] = useState<Array<PlanRow>>([]);
  const { expandedProjectNames, setExpandedProjectNames } = persistedView;

  const grouping = useMemo(
    () => projectGrouping<PlanRow>({ viewTab, expandedProjectNames, setExpandedProjectNames }),
    [expandedProjectNames, setExpandedProjectNames, viewTab]
  );
  const rowHeightGetter = useMemo(() => {
    const nonGroupedHeightGetter = (row: PlanRow) => (row?.tags?.length ? TAGS_ROW_HIGHT : NO_TAGS_ROW_HEIGHT);
    if (viewTab === ViewTab.Tree) {
      return ({ type, row }: RowHeightArgs<PlanRow>) => {
        if (type === 'GROUP') {
          return DEFAULT_GROUP_ROW_HEIGHT_PX;
        }
        return nonGroupedHeightGetter(row);
      };
    }
    return nonGroupedHeightGetter;
  }, [viewTab]);

  useEffect(() => {
    services.procedures.getAllProceduresMetadata().then((procedures: ProcedureMetadata[]) => {
      const filteredProcedures = procedures.filter((procedure) => procedure.procedure_type && procedure.archived);
      const projectProcedures = filterByProject(filteredProcedures);
      setArchivedTestPlans(
        projectProcedures.map((procedure) => ({
          ...procedure,
          projectName: projectUtil.getProjectName(projects, procedure.project_id),
          rootProjectName: projectUtil.getRootProjectName(projects, procedure.project_id),
        }))
      );
      setIsLoading(false);
    });
  }, [filterByProject, projects, services.procedures]);

  const displayProcedures = useMemo(() => {
    const filteredRows = filterBySearchTerm({
      searchTerm,
      allData: archivedTestPlans,
      getStrings: (procedure: PlanRow) => [procedure.name, procedure.code, procedure.projectName, procedure.version],
    });
    return filterByField({
      rows: filteredRows,
      fieldName: 'project_id',
      values: selectedProjects,
    });
  }, [searchTerm, archivedTestPlans, selectedProjects]);

  const columns: Array<Column<PlanRow>> = [
    {
      key: 'name',
      name: 'Plan',
      width: '30%',
      sortable: true,
      renderCell: ({ row }) =>
        RunNameLinkRenderer({
          code: row.code,
          link: goToProcedure(row, currentTeamId),
          name: row.name,
          tags: row.tags || [],
          globalTags: [],
        }),
    },
    {
      key: viewTab === 'list' ? 'projectName' : 'rootProjectName',
      name: 'Project',
      width: '12%',
      sortable: true,
      renderGroupCell: projectGroupCell,
      renderCell: ({ row }) => ProjectRenderer({ row, setSearchTerm }),
    },
    {
      key: 'status',
      name: 'Status',
      width: '12%',
      renderCell: ({ row }) => {
        return renderRunStatus('Archived', false);
      },
    },
    {
      key: 'editedAt',
      name: 'Last Updated',
      width: '25%',
      sortable: true,
      renderCell: ({ row }) => renderDateTime(row.editedAt),
    },
    {
      key: 'version',
      name: 'Version',
    },
  ];

  return (
    <>
      {isLoading && <LoadingScreen />}
      {!isLoading && (
        <Grid<PlanRow>
          key={projectsFilter?.project.id}
          columns={columns}
          rows={displayProcedures}
          rowHeight={rowHeightGetter}
          emptyRowMessage="No matching plans found"
          usedVerticalSpace={PLAN_VERTICAL_PADDING}
          rowGrouping={grouping}
        />
      )}
    </>
  );
};

export default ArchivedPlansGrid;
