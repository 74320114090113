import { useCallback, useState } from 'react';
import { Snippet } from 'shared/lib/types/views/procedures';
import validateUtil from '../lib/validateUtil';
import { selectProceduresMetadata } from '../contexts/proceduresSlice';
import { useStore } from 'react-redux';

interface UseSnippetValidationReturn {
  validateSnippet: () => Promise<boolean>;
  getSnippet: () => Snippet | null;
  validationErrors: Record<string, unknown>;
}

export const useSnippetValidation = (
  snippet: Snippet | null,
  currentTeamId: string
): UseSnippetValidationReturn => {
  const [validationErrors, setValidationErrors] = useState<
    Record<string, unknown>
  >({});
  const store = useStore();

  const validateSnippet = useCallback(async () => {
    if (!snippet) return false;

    let errors: Record<string, unknown> = {};

    if (!snippet.name?.trim()) {
      errors.snippetName = 'Name is required';
    }

    const proceduresMetadata = selectProceduresMetadata(
      store.getState(),
      currentTeamId
    );
    if (snippet.snippet_type === 'step') {
      const { errors: validationErrors } = await validateUtil.validateStep({
        step: snippet.step,
        teamId: currentTeamId,
        proceduresMetadata,
      });
      errors = { ...errors, ...validationErrors };
    } else {
      const { errors: validationErrors } = await validateUtil.validateSection({
        section: snippet.section,
        teamId: currentTeamId,
        proceduresMetadata,
      });
      errors = { ...errors, ...validationErrors };
    }

    setValidationErrors(errors);
    return Object.keys(errors).length === 0;
  }, [snippet, store, currentTeamId]);

  const getSnippet = useCallback(() => snippet, [snippet]);

  return {
    validateSnippet,
    getSnippet,
    validationErrors,
  };
};
