import DataGrid, { SelectColumn } from 'react-data-grid';
import { Project } from 'shared/lib/types/couch/settings';
import { UserFormUser } from '../Settings/types';
import { useCallback, useEffect, useMemo, useState } from 'react';
import UserProjectRoles from './UserProjectRoles';
import RolePill from './RolePill';
import userUtil from '../../lib/userUtil';
import { cloneDeep } from 'lodash';
import Avatar from '../../elements/Avatar';
import useUsers from '../../hooks/useUsers';

interface UsersTableProps {
  visibleUsers: Array<UserFormUser>;
  allUsers: Array<UserFormUser>;
  projects: Array<Project>;
  operatorDescriptionByKey: {
    [description: string]: string;
  };
  setSelectedUsers: (users: Array<UserFormUser>) => void;
  showCheckboxes: boolean;
}

const MAIN_VERTICAL_PADDING = 220;
const BASE_ROW_HEIGHT = 35;
const ADDITIONAL_ROW_HEIGHT = 24;
const rowHeight = BASE_ROW_HEIGHT + ADDITIONAL_ROW_HEIGHT;

const EmptyRowsRenderer = () => {
  return <div className="col-span-full pt-5 font-medium text-center text-gray-400">No users found</div>;
};

const getColumns = (
  projects: Array<Project>,
  showCheckboxes: boolean,
  expanded: Map<string, boolean>,
  setExpanded: (current: Map<string, boolean>) => void,
  getUserNameDisplayText: (userId?: string | undefined) => string
) => {
  const cols = [
    {
      key: 'user',
      name: <div>User</div>,
      width: showCheckboxes ? '31.1%' : '34%',
      renderCell({ row }: { row: UserFormUser }) {
        return (
          <>
            <div className="flex flex-row">
              <div className="pt-4 mr-1">
                <Avatar key={row.id} userId={row.id} />
              </div>
              {getUserNameDisplayText(row.id)}
            </div>
          </>
        );
      },
    },
    {
      key: 'access',
      name: 'Workspace Access',
      width: '33%',
      renderCell({ row }: { row: UserFormUser }) {
        return (
          <div>
            <RolePill defaultAccess={row.workspace_role} role={row.workspace_role} showPillView={false} />
          </div>
        );
      },
    },
    {
      key: 'project_roles',
      name: (
        <div className="flex flex-row w-full items-center justify-between">
          <div>Project Access</div>
        </div>
      ),
      width: '33%',
      renderCell({ row }: { row: UserFormUser }) {
        return (
          <UserProjectRoles
            row={row}
            projects={projects}
            defaultAccess={row.workspace_role}
            expanded={expanded.get(row.id)}
            showProjectNames={false}
            showPillView={false}
            setExpanded={(current) => {
              const newExpanded = cloneDeep(expanded);
              newExpanded.set(row.id, !current);
              setExpanded(newExpanded);
            }}
            abilityToExpand={false}
          />
        );
      },
    },
  ];

  return showCheckboxes ? [SelectColumn, ...cols] : cols;
};

const ProjectUsersTable = ({ visibleUsers, allUsers, projects, setSelectedUsers, showCheckboxes }: UsersTableProps) => {
  const [selectedRows, setSelectedRows] = useState((): ReadonlySet<number> => new Set());
  const [mainHeight, setMainHeight] = useState<number>(window.innerHeight - MAIN_VERTICAL_PADDING);
  const [rowHashes, setRowHashes] = useState<Set<number>>(new Set());
  const [expanded, setExpanded] = useState<Map<string, boolean>>(new Map());
  const { getUserNameDisplayText } = useUsers();

  useEffect(() => setExpanded(new Map(visibleUsers.map((user) => [user.id, true]))), [visibleUsers]);

  useEffect(() => {
    let resizeTimeoutId: ReturnType<typeof setTimeout>;
    const handleViewportResize = () => {
      clearTimeout(resizeTimeoutId);
      resizeTimeoutId = setTimeout(() => {
        setMainHeight(window.innerHeight - MAIN_VERTICAL_PADDING);
      }, 100);
    };

    window.addEventListener('resize', handleViewportResize);
    handleViewportResize();

    return () => {
      window.removeEventListener('resize', handleViewportResize);
    };
  }, []);

  const updateSelectedUsers = useCallback(
    (rowHashes: Set<number>) => {
      setSelectedUsers(
        Array.from(rowHashes)
          .map((rowHash) => allUsers.find((user) => userUtil.rowKeyGetter(user) === rowHash))
          // deleted rows still show up in rowHashes, so we need to filter those out
          .filter((user) => user) as Array<UserFormUser>
      );
    },
    [allUsers, setSelectedUsers]
  );

  const onSelectedRowsChange = useCallback(
    (rowHashes: Set<number>) => {
      setSelectedRows(rowHashes);
      setRowHashes(rowHashes);
      updateSelectedUsers(rowHashes);
    },
    [updateSelectedUsers]
  );

  // update selected users if visible users change
  useEffect(() => {
    updateSelectedUsers(rowHashes);
  }, [rowHashes, updateSelectedUsers]);

  const columns = useMemo(
    () => getColumns(projects, showCheckboxes, expanded, setExpanded, getUserNameDisplayText),
    [projects, showCheckboxes, expanded, setExpanded, getUserNameDisplayText]
  );

  return (
    <DataGrid
      key={JSON.stringify(columns.map((c) => c.width))}
      className="fill-grid rdg-light"
      style={{ height: mainHeight }}
      columns={columns}
      rows={visibleUsers}
      rowHeight={rowHeight}
      renderers={{
        noRowsFallback: <EmptyRowsRenderer />,
      }}
      selectedRows={selectedRows}
      onSelectedRowsChange={onSelectedRowsChange}
      rowKeyGetter={userUtil.rowKeyGetter<UserFormUser>}
      defaultColumnOptions={{ resizable: true }}
    />
  );
};

export default ProjectUsersTable;
