import React, { useMemo } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import Avatar from '../../elements/Avatar';
import DateTimeDisplay from '../DateTimeDisplay';
import { wasEdited } from 'shared/lib/comment';
import EditCommentButton from '../EditCommentButton';
import TextLinkify from '../TextLinkify';
import FormComment from './FormComment';
import { AttachmentValue, RunStepComment } from 'shared/lib/types/views/procedures';
import AttachmentsContainer from './AttachmentsContainer';

type CommentProps = {
  comment: RunStepComment;
  canEdit: boolean;
  isEditing: boolean;
  onEdit: () => void;
  onSubmit: (FormCommentStateType) => Promise<void>;
  onCancel: () => void;
};

const Comment = ({ comment, canEdit, isEditing, onEdit, onSubmit, onCancel }: CommentProps) => {
  const attachments = useMemo(() => {
    const _attachments = [] as Array<AttachmentValue>;

    if (comment.attachment) {
      _attachments.push(comment.attachment);
    }

    if (comment.attachments) {
      _attachments.push(...comment.attachments);
    }

    return _attachments;
  }, [comment.attachment, comment.attachments]);

  return (
    <div className="bg-slate-200 rounded-lg flex flex-row nowrap gap-x-1 p-2">
      {/** Left column */}
      <div className="justify-center items-start pt-1">
        <Avatar userId={comment.user} />
      </div>
      {/** Right Column */}
      <div className="flex flex-col grow">
        {/** Header */}
        <div className="flex flex-row justify-between items-start nowrap">
          {/** Left Column */}
          <div className="flex flex-row gap-x-2 text-sm">
            <div className="font-semibold">{comment.user}</div>
            <div className="flex flex-row gap-x-2 text-gray-500">
              <DateTimeDisplay timestamp={comment.timestamp} />
              {wasEdited(comment.timestamp, comment.updated_at) && <div>(edited)</div>}
            </div>
          </div>
          {/** Right Column */}
          <div className="relative">
            {canEdit && onEdit && (
              <div className="absolute top-0 right-0">
                <EditCommentButton comment={comment} onEdit={onEdit} />
              </div>
            )}
          </div>
        </div>
        {/** Body */}
        <div>
          {!isEditing && (
            <div className="flex flex-col gap-y-1">
              {comment.text && (
                <TextLinkify mentionsEnabled={true} mentionList={comment.mention_list}>
                  <span className="whitespace-pre-line">{comment.text}</span>
                </TextLinkify>
              )}
              <AttachmentsContainer attachments={attachments} />
            </div>
          )}
          {isEditing && (
            <div className="relative p-2">
              <FormComment
                initialComment={{
                  text: comment.text,
                  mentions: comment.mention_list || [],
                  attachments: comment.attachments || [],
                }}
                onSubmit={onSubmit}
                focusOnMount={true}
                fileSource="runs:comments"
              />
              <button
                aria-label="Cancel editing comment"
                className="absolute top-2 right-3 text-gray-400 py-1 px-1 rounded-full bg-white hover:text-gray-500"
                onClick={onCancel}
              >
                <FontAwesomeIcon size="lg" icon="circle-xmark" />
              </button>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default React.memo(Comment);
