import { useMemo } from 'react';
import { ACCESS, NONE_ACCESS } from '../../lib/auth';
import { capitalizeFirstLetter } from 'shared/lib/text';

interface RolePillProps {
  defaultAccess?: string;
  role?: string;
  showPillView?: boolean;
}

const RolePill = ({ defaultAccess = NONE_ACCESS, role = NONE_ACCESS, showPillView = true }: RolePillProps) => {
  const color = useMemo(() => {
    switch (role.toLowerCase()) {
      case ACCESS.ADMIN:
        return 'blue';
      case ACCESS.EDITOR:
        return 'green';
      case ACCESS.OPERATOR:
        return 'red';
      case ACCESS.VIEWER:
        return 'orange';
      case NONE_ACCESS.toLowerCase():
        return 'gray';
    }
  }, [role]);

  const isSameRole = defaultAccess?.toLowerCase() === role?.toLowerCase();
  const displayText = capitalizeFirstLetter(role ?? 'None');
  const isNone = displayText === 'None';

  return showPillView ? (
    <span
      className={`whitespace-nowrap text-gray-800 text-sm px-1.5 py-0.5 border border-${color}-200 rounded-xl bg-${color}-200`}
      aria-label={capitalizeFirstLetter(role)}
    >
      {displayText}
    </span>
  ) : (
    <span className={`${isSameRole ? 'text-gray-400' : 'text-gray-900 font-medium'} ${isNone ? 'italic' : ''}`}>
      {displayText}
    </span>
  );
};

export default RolePill;
