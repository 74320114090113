import React, { useMemo } from 'react';
import LabelDisplay from './LabelDisplay';
import { AttachmentValue, RunFieldInputAttachmentBlock } from 'shared/lib/types/views/procedures';
import ReferenceTokenTooltip from './ReferenceTokenTooltip';
import FieldInputAttachments from '../FieldInput/FieldInputAttachments';
import { getRecordedAttachments } from 'shared/lib/runStepUtil';

interface AttachmentDisplayProps {
  block: RunFieldInputAttachmentBlock;
  referenceId?: string;
  referenceFieldIndex?: number;
}

const AttachmentDisplay = ({ block, referenceId, referenceFieldIndex }: AttachmentDisplayProps) => {
  const recordedAttachments = useMemo(() => {
    if (!block.recorded) {
      return [];
    }

    return getRecordedAttachments(block.recorded);
  }, [block]);

  return (
    <div className="flex flex-row space-x-2 items-start">
      <div className="mt-1">
        <LabelDisplay text={block.name} />
      </div>
      <div className="group">
        {referenceId && <ReferenceTokenTooltip referenceId={referenceId} referenceFieldIndex={referenceFieldIndex} />}
        <FieldInputAttachments attachments={recordedAttachments as AttachmentValue[]} isEnabled={false} />
      </div>
    </div>
  );
};

export default AttachmentDisplay;
