const HEX_WHITE = /** @type { const } */ ('#ffffff');
const HEX_BLACK = /** @type { const } */ ('#000000');
const HEX_LIME_500 = /** @type { const } */ ('#84cc16');
const HEX_YELLOW_300 = /** @type { const } */ ('#fde047');
const HEX_ORANGE_500 = /** @type { const } */ ('#f97316');
const HEX_RED_500 = /** @type { const } */ ('#ef4444');
const HEX_PURPLE_700 = /** @type { const } */ ('#7e22ce');
const HEX_BLUE_200 = /** @type { const } */ ('#bfdbfe');
const HEX_SLATE_200 = /** @type { const } */ ('#e2e8f0');

const generateHiddenClassString = function (classString, isHidden) {
  let string;

  if (isHidden) {
    string = classString ? `${classString} hidden` : 'hidden';
  } else {
    string = classString;
  }

  return string ? string : null;
};

const commonDesign = {
  indicatorSeparator: () => ({ display: 'none' }),
  menuPortal: (base) => ({ ...base, zIndex: 240 }),
  dropdownIndicator: (provided) => ({
    ...provided,
    padding: 0,
  }),
  indicatorsContainer: (provided) => ({
    ...provided,
    padding: 0,
  }),
};

const inputStyles = {
  common: {
    border: 'none',
    boxShadow: 'none',
  },
  standard: {
    minWidth: '148px',
  },
  standardUnassigned: {
    minWidth: '110px',
    maxWidth: '110px',
  },
  severity: {
    minWidth: '78px',
    maxWidth: '78px',
  },
  severityCritical: {
    minWidth: '87px',
    maxWidth: '87px',
  },
  severityUnassigned: {
    minWidth: '118px',
    maxWidth: '118px',
  },
  design: {
    ...commonDesign,
    menu: (base) => ({ ...base, width: 120, zIndex: 250 }),
  },
  severityDesign: {
    ...commonDesign,
    menu: (base) => ({ ...base, width: 95, zIndex: 250 }),
  },
};

const getSelectStyles = (additionalStyles) => ({
  control: (provided) => ({
    ...provided,
    ...inputStyles.common,
    ...additionalStyles,
    minHeight: 'initial',
  }),

  ...inputStyles.design,
});

const assigneeSelectStyles = getSelectStyles(inputStyles.standard);
const assigneeUnassignedSelectStyles = getSelectStyles(inputStyles.standardUnassigned);
const getStatusSelectStyles = (additionalStyles) => ({
  control: (provided) => ({
    ...provided,
    ...inputStyles.common,
    ...additionalStyles,
  }),

  ...inputStyles.severityDesign,
});

const statusSeveritySelectStyles = getStatusSelectStyles(inputStyles.severity);
const statusCriticalSeveritySelectStyles = getStatusSelectStyles(inputStyles.severityCritical);
const statusSeverityUnassignedSelectStyles = getStatusSelectStyles(inputStyles.severityUnassigned);

const reactSelectStyles = {
  control: (base, { isDisabled }) => ({
    ...base,
    borderColor: 'rgba(209, 213, 219)', // matches tailwind border-gray-300
    backgroundColor: isDisabled
      ? 'rgb(243, 244, 246)' // matches tailwind border-gray-100
      : 'white',
    minHeight: 32,
  }),
  dropdownIndicator: (styles) => ({
    ...styles,
    paddingTop: 4,
    paddingBottom: 4,
  }),
  clearIndicator: (styles) => ({
    ...styles,
    paddingTop: 4,
    paddingBottom: 4,
  }),
  menu: (base) => ({ ...base, zIndex: 250 }),
  menuPortal: (base) => ({ ...base, zIndex: 240 }),
};

const gridReactSelectStyles = {
  ...reactSelectStyles,
  control: (base) => ({
    ...base,
    paddingTop: '0.5rem',
    border: '1px solid rgba(107, 114, 128, 0.5)', // equivalent to border-gray-500 with 50% opacity
  }),
};

const disabledGridReactSelectStyles = {
  ...reactSelectStyles,
  control: (base) => ({
    ...base,
    paddingTop: '0.5rem',
    background: 'rgba(209, 213, 219, 0.5)',
    border: '1px solid rgba(107, 114, 128, 0.5)', // equivalent to border-gray-500 with 50% opacity
    borderRadius: '0.25rem', // equivalent to rounded
  }),
};

const reactSelectNoBorderStyles = {
  ...reactSelectStyles,
  control: (base, { isDisabled }) => ({
    ...base,
    border: 'none',
    borderRadius: '0px',
    backgroundColor: isDisabled
      ? 'rgb(243, 244, 246)' // matches tailwind border-gray-100
      : 'transparent',
    height: '100%',
  }),
};

const selectValueSelectedStyles = {
  ...reactSelectStyles,
  singleValue: (base) => ({
    ...base,
    color: 'black',
  }),
};

const selectCreatableDisabledStyles = {
  ...reactSelectStyles,
  multiValueRemove: (base) => ({
    ...base,
    display: 'none',
  }),
};

const selectWithFixedOptionsStyles = {
  ...reactSelectStyles,
  multiValue: (base, state) => {
    if (state.data.isFixed) {
      return {
        ...base,
        backgroundColor: 'rgb(229 231 235)', // bg-gray-200
      };
    } else {
      return {
        ...base,
        backgroundColor: 'rgb(219 234 254)', // bg-blue-100
      };
    }
  },
  multiValueLabel: (base, state) => {
    if (state.data.isFixed) {
      return {
        ...base,
        color: 'rgb(156 163 175)', // text-gray-400
        paddingRight: 6,
      };
    } else {
      return base;
    }
  },
  multiValueRemove: (base, state) => {
    if (state.data.isFixed) {
      return {
        ...base,
        display: 'none',
      };
    } else {
      return base;
    }
  },
  input: (base) => ({
    ...base,
    'input:focus': { boxShadow: 'none' },
  }),
};

export {
  HEX_WHITE,
  HEX_BLACK,
  HEX_LIME_500,
  HEX_YELLOW_300,
  HEX_ORANGE_500,
  HEX_RED_500,
  HEX_PURPLE_700,
  HEX_BLUE_200,
  HEX_SLATE_200,
  generateHiddenClassString,
  reactSelectStyles,
  reactSelectNoBorderStyles,
  selectValueSelectedStyles,
  selectCreatableDisabledStyles,
  selectWithFixedOptionsStyles,
  assigneeSelectStyles,
  statusSeveritySelectStyles,
  assigneeUnassignedSelectStyles,
  statusSeverityUnassignedSelectStyles,
  statusCriticalSeveritySelectStyles,
  gridReactSelectStyles,
  disabledGridReactSelectStyles,
};
