import React, { useMemo, useRef, useState } from 'react';
import { Formik, Form, Field, FormikProps } from 'formik';
import FieldSetViewModeEditSelect from './FieldSetViewModeEditSelect';
import FieldSetUserSelect from './FieldSetUserSelect';
import { Draft, Procedure, SignoffType } from 'shared/lib/types/views/procedures';
import FieldSetSignoffArray from './FieldSetSignoffArray';
import ProcedureSettingsTagSelector from './ProcedureSettingsTagSelector';
import procedureUtil, { INITIAL_PROCEDURE } from '../lib/procedureUtil';
import Modal from './Modal';
import { useUserInfo } from '../contexts/UserContext';
import validateUtil from '../lib/validateUtil';
import { useDatabaseServices } from '../contexts/DatabaseContext';
import FieldSetProcedureCode from './FieldSetProcedureCode';
import useAutoProcedureId from '../hooks/useAutoProcedureId';
import FieldSetProcedureProject from './FieldSetProcedureProject';
import { useAuth } from '../contexts/AuthContext';
import ProcedureSettingsToggles from './ProcedureSettingsToggles';
import { useNavState } from '../contexts/NavContext';
import { useSettings } from '../contexts/SettingsContext';
import EntityTagManager from './Settings/Tags/EntityTagManager';
import { EntityType } from 'shared/lib/types/api/settings/tags/models';
import useProjects from '../hooks/useProjects';
import { ACCESS } from 'shared/lib/constants/permissions';
import HardcodeReviewSettingsSelectReviewType from './Review/HardcodeReviewSettingsSelectReviewType';

interface NewProcedureModalProps {
  procedure?: Draft;
  isProjectClearable?: boolean;
  procedures: { [id: string]: Procedure };
  onNewProcedure: (draft: Draft) => void;
  closeModal: () => void;
  isAutomationUIEnabled: boolean;
}

interface ModalErrors {
  version?: string;
  code?: string;
  name?: string;
}

const NewProcedureModal = ({
  procedure = INITIAL_PROCEDURE,
  isProjectClearable = true,
  procedures,
  onNewProcedure,
  closeModal,
}: NewProcedureModalProps) => {
  const { projectsFilter } = useNavState();
  const { userInfo } = useUserInfo();
  const { currentTeamId } = useDatabaseServices();
  const userId = userInfo.session.user_id;
  const formRef = useRef<FormikProps<Draft>>(null);
  const { generatePlaceholderId, tryUpdateDocWithUniqueId, isAutoProcedureIdEnabled } = useAutoProcedureId();
  const { auth } = useAuth();
  const { isGlobalTagsEnabled, isHardcodedReviewersEnabled } = useSettings();
  const { getProjectsForAccessLevel } = useProjects();

  const [modalErrors, setModalErrors] = useState<ModalErrors | null>(null);

  const initialValues = useMemo(() => {
    const values = { ...procedure };
    values.owner = userId;
    if (!values.project_id) {
      if (projectsFilter) {
        values.project_id = projectsFilter?.project.id;
      }
      if (auth.hasProjectOnlyEditPermissions()) {
        values.project_id = auth.projectsWithEditPermission()[0];
      }
    }
    if (isAutoProcedureIdEnabled) {
      values.auto_procedure_id_enabled = true;
      const project = getProjectsForAccessLevel({ projectAccessLevel: ACCESS.EDITOR }).find(
        (project) => project.id === values.project_id
      );
      const parentProjectId = project?.parent_id;
      values.code = generatePlaceholderId({
        projectId: values.project_id,
        parentId: parentProjectId,
      });
    } else {
      values.auto_procedure_id_enabled = false;
    }

    values._id = procedureUtil.generateProcedureId();

    return values;
  }, [
    procedure,
    userId,
    isAutoProcedureIdEnabled,
    projectsFilter,
    auth,
    getProjectsForAccessLevel,
    generatePlaceholderId,
  ]);

  const sanitizeInput = (value: string) => value.trim();

  const handlePrimaryAction = () => {
    if (formRef.current && !formRef.current.isSubmitting) {
      const sanitizedValues = {
        ...formRef.current.values,
        code: sanitizeInput(formRef.current.values.code || ''),
        name: sanitizeInput(formRef.current.values.name || ''),
      };
      formRef.current.setValues(sanitizedValues);
      formRef.current.handleSubmit();
    }
  };

  return (
    <Modal
      title="Create New Procedure"
      primaryActionTitle="Create Procedure"
      secondaryActionTitle="Cancel"
      onPrimaryAction={handlePrimaryAction}
      onSecondaryAction={closeModal}
      size="md"
    >
      <Formik
        innerRef={formRef}
        initialValues={initialValues}
        onSubmit={async (values) => {
          const errors = await validateUtil.validateProcedureDetails(
            values,
            currentTeamId,
            procedures,
            isAutoProcedureIdEnabled
          );
          delete errors.version;
          setModalErrors(errors);
          if (Object.keys(errors).length === 0) {
            const draftDoc = { ...values };
            await tryUpdateDocWithUniqueId(draftDoc);
            onNewProcedure(draftDoc);
          }
        }}
        validateOnChange={true}
        validate={(values) => {
          // No Op
        }}
        enableReinitialize
      >
        {({ values, setFieldValue, setValues }) => (
          <Form className="w-full">
            <div className="mx-2 my-2">
              <div className="w-full flex-row items-start">
                <div className="flex flex-row">
                  {/* Unique Id */}
                  <div className="w-1/3 flex flex-col" style={{ minWidth: '12rem' }}>
                    <label className="font-semibold ">Unique Id*</label>
                    <FieldSetProcedureCode isAutoGenerated={isAutoProcedureIdEnabled} />
                    {modalErrors?.code && (
                      <div className="flex  text-red-700 ml-1">
                        <div>{modalErrors.code}</div>
                      </div>
                    )}
                  </div>
                  {/* Name */}
                  <div className="w-2/3 flex flex-col ml-2">
                    <label className="font-semibold ">Name*</label>
                    <Field
                      name="name"
                      type="text"
                      placeholder="Procedure name"
                      className=" border-1 border-gray-400 rounded"
                    />
                    {modalErrors?.name && (
                      <div className="flex  text-red-700 ml-1">
                        <div>{modalErrors.name}</div>
                      </div>
                    )}
                  </div>
                </div>
                <div className="flex flex-row mt-2">
                  {/* Project */}
                  <div className="w-1/2 flex flex-col">
                    <label className="font-semibold ">Project</label>
                    <FieldSetProcedureProject
                      isProjectClearable={isProjectClearable}
                      isAutoProcedureIdEnabled={isAutoProcedureIdEnabled}
                    />
                  </div>
                  {/* Tags */}
                  {!isGlobalTagsEnabled() && (
                    <div className="w-1/2 flex flex-col ml-2">
                      <Field
                        name="tags"
                        className="h-full border border-gray-400 rounded"
                        component={ProcedureSettingsTagSelector}
                        value={values.tags}
                      />
                    </div>
                  )}
                  {isGlobalTagsEnabled() && (
                    <div className="w-1/2 flex flex-col ml-2 mt-3">
                      <EntityTagManager entityType={EntityType.PROCEDURE} entityId={values._id} />
                    </div>
                  )}
                </div>
                <div className="flex flex-row mt-2">
                  {/* Owner */}
                  <div className="w-1/2 flex flex-col">
                    <label className="font-semibold ">Owner</label>
                    <Field
                      name="owner"
                      className="h-full border border-gray-400 rounded"
                      component={FieldSetUserSelect}
                      value={values.owner}
                    />
                  </div>
                  {/* Default View */}
                  <div className="w-1/2 flex flex-col ml-2">
                    <label className="font-semibold ">Default View</label>
                    <Field
                      name="default_view_mode"
                      className="h-full border border-gray-400 rounded"
                      component={FieldSetViewModeEditSelect}
                      value={values.default_view_mode}
                    />
                  </div>
                </div>
                <div className="flex flex-row flex-wrap mt-2">
                  {/* Start Procedure */}
                  <div className="flex items-start space-x-2">
                    <div className="flex flex-col">
                      <label className="font-semibold ">Start Procedure</label>
                      <Field
                        name="start_run_signoffs_groups"
                        className="h-full border border-gray-400 rounded"
                        component={FieldSetSignoffArray}
                        signoffs={values.start_run_signoffs_groups}
                        signoffType={SignoffType.Start}
                      />
                    </div>
                  </div>
                  {/* End Procedure */}
                  <div className="flex items-start space-x-2 ml-1">
                    <div className="flex flex-col">
                      <label className="font-semibold ">End Procedure</label>
                      <Field
                        name="end_run_signoffs_groups"
                        className="h-full border border-gray-400 rounded"
                        component={FieldSetSignoffArray}
                        signoffs={values.end_run_signoffs_groups}
                        signoffType={SignoffType.End}
                      />
                    </div>
                  </div>
                  {/* Review Type Group */}
                  {isHardcodedReviewersEnabled() && (
                    <div className="flex items-start space-x-2 ml-1">
                      <div className="flex flex-col">
                        <label className="font-semibold ">Review Type Group</label>
                        <Field
                          className="h-full border border-gray-400 rounded"
                          component={HardcodeReviewSettingsSelectReviewType}
                        />
                      </div>
                    </div>
                  )}
                </div>
                <div className="grid grid-rows-2 grid-flow-col gap-2 mt-4">
                  <ProcedureSettingsToggles
                    values={values}
                    procedure={procedure}
                    setFieldValue={setFieldValue}
                    setValues={setValues}
                  />
                </div>
              </div>
            </div>
          </Form>
        )}
      </Formik>
    </Modal>
  );
};

export default React.memo(NewProcedureModal);
