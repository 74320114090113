import { useEffect, useMemo, useRef, useState } from 'react';
import { SortColumn } from 'react-data-grid';
import { useDispatch, useSelector } from 'react-redux';
import { ViewTab } from 'shared/lib/types/postgres/users';
import { Observer } from '../../api/realtime';
import { useDatabaseServices } from '../../contexts/DatabaseContext';
import { useSettings } from '../../contexts/SettingsContext';
import {
  fetchAllActiveRunsMetadata,
  selectActiveRunsLoading,
  selectActiveRunsMetadata,
} from '../../contexts/runsSlice';
import LoadingBadge from '../LoadingBadge';
import HomeScreenTableRDG from './HomeScreenTableRDG';
import { getColumns, getRows } from './runGridUtils';
import projectUtil from '../../lib/projectUtil';
import { getTagNames } from '../../lib/tagsUtil';
import apm from '../../lib/apm';
import { useNavState } from '../../contexts/NavContext';
import { useRealtimeContext } from '../../contexts/RealtimeContext';

const emptyListText = 'No Running Procedures';
const MAIN_VERTICAL_PADDING = 220;
const RUNNING_PROCEDURES_DEFAULT_SORT: Array<SortColumn> = [
  {
    columnKey: 'startTime',
    direction: 'DESC',
  },
];

interface RunningProceduresGridProps {
  searchTerm: string;
  viewTab: ViewTab;
  setSearchTerm: (newSearchTerm: string) => void;
}

const RunningProceduresGrid = ({ searchTerm, viewTab, setSearchTerm }: RunningProceduresGridProps) => {
  const { filterByProject, persistedView } = useNavState();
  const [sortPreference, setSortPreference] = useState<Array<SortColumn>>(RUNNING_PROCEDURES_DEFAULT_SORT);
  const [loading, setLoading] = useState(false);
  const { services, currentTeamId } = useDatabaseServices();
  const { expandedProjectNames, setExpandedProjectNames } = persistedView;
  const activeRuns = useSelector((state) => selectActiveRunsMetadata(state, currentTeamId));
  const isActiveRunsLoading = useSelector((state) => selectActiveRunsLoading(state, currentTeamId));
  const { config, projects, tags, runTags, globalTags, defaultView, isPostgresOnlyEnabled, isGlobalTagsEnabled } =
    useSettings();
  const dispatch = useDispatch();
  const isMounted = useRef(true);
  const { realtimeService } = useRealtimeContext();

  const headers = getColumns({ state: 'running', includeOperations: false, view: viewTab });

  // Realtime sync for run and procedure docs from backend.
  useEffect(() => {
    if (!services.runs || !realtimeService) {
      return;
    }

    setLoading(true);
    const refreshActiveRuns = async () => {
      await dispatch(fetchAllActiveRunsMetadata({ services }));
      if (!isMounted.current) {
        return;
      }
      setLoading(false);
    };

    let observer: Observer;
    if (isPostgresOnlyEnabled()) {
      observer = realtimeService.onRunsEvent(refreshActiveRuns);
    } else {
      observer = services.runs.onActiveRunsChanged(refreshActiveRuns);
    }
    refreshActiveRuns().catch((err) => apm.captureError(err));

    return () => {
      if (observer) {
        observer.cancel();
      }
    };
  }, [services, dispatch, currentTeamId, realtimeService, isPostgresOnlyEnabled]);

  const projectNameFilter = useMemo(
    () => projectUtil.getProjectNames(new Set(defaultView?.project_ids), projects),
    [defaultView, projects]
  );

  const tagNameFilter = useMemo(() => {
    if (isGlobalTagsEnabled() && globalTags) {
      return new Set(Object.values(globalTags.tags).map((tag) => tag.name));
    }
    return getTagNames(new Set(defaultView?.run_tags), tags, runTags);
  }, [defaultView?.run_tags, runTags, tags, isGlobalTagsEnabled, globalTags]);

  const rows = getRows({
    runs: Object.values(activeRuns),
    filterByProject,
    searchTerm,
    context: {
      projects,
      config,
      currentTeamId,
      isGlobalTagsEnabled,
      globalTags: globalTags?.tags ?? {},
    },
  });

  return (
    <>
      {loading && isActiveRunsLoading && (
        <div className="absolute z-200 inset-y-1/2 inset-x-1/2 flex items-center justify-center h-16">
          <LoadingBadge />
        </div>
      )}

      <HomeScreenTableRDG
        rowCountLabel="runs"
        headers={headers}
        rows={rows}
        emptyListText={emptyListText}
        searchTerm={searchTerm}
        setSearchTerm={setSearchTerm}
        projectNamesFilter={projectNameFilter}
        tagNamesFilter={tagNameFilter}
        sortPreference={sortPreference}
        setSortPreference={setSortPreference}
        showParentChildRelation={true}
        mainVerticalPadding={MAIN_VERTICAL_PADDING}
        viewTab={viewTab}
        expandedProjectNames={expandedProjectNames}
        setExpandedProjectNames={setExpandedProjectNames}
      />
    </>
  );
};

export default RunningProceduresGrid;
