import { useCallback, useMemo } from 'react';
import { EntityType, ServerTag, Tag, TagStatus } from 'shared/lib/types/api/settings/tags/models';
import { DatabaseServices } from '../../../contexts/proceduresSlice';
import { useDatabaseServices } from '../../../contexts/DatabaseContext';
import { useTags } from '../../../hooks/useTags';
import useRealtimeUpdates, { useRealtimeUpdatesProps } from '../../../hooks/useRealtimeUpdates';
import { RealtimeData } from 'shared/lib/types/realtimeUpdatesTypes';
import TagManager from './TagManager';

type EntityTagManagerProps = {
  entityId: string;
  entityType: EntityType;
  isDisabled?: boolean;
  removePadding?: boolean;
};

const EntityTagManager = ({
  entityId,
  entityType,
  isDisabled = false,
  removePadding = false,
}: EntityTagManagerProps) => {
  const { services }: { services: DatabaseServices } = useDatabaseServices();
  const { tags } = useTags({ includeDeleted: false });

  const getTags = useCallback(async () => {
    if (!services) {
      return;
    }
    return services.entityTags.getTagsForEntity(entityType, entityId);
  }, [entityId, entityType, services]);

  const { realtimeData: entityServerTags } = useRealtimeUpdates<RealtimeData & ServerTag>({
    entityType: 'entity_tags',
    initialGetter: getTags,
  } as useRealtimeUpdatesProps);

  const entityTags: Tag[] = useMemo(() => {
    return (entityServerTags as Array<RealtimeData & ServerTag>)
      .filter((tag) => tag.status !== TagStatus.DELETED)
      .map(({ created_at, updated_at, ...tag }) => ({
        ...tag,
        createdAt: created_at,
        updatedAt: updated_at,
      }));
  }, [entityServerTags]);

  return (
    <TagManager
      entityTags={entityTags}
      globalTags={tags}
      entityId={entityId}
      entityType={entityType}
      isDisabled={isDisabled}
      removePadding={removePadding}
    />
  );
};

export default EntityTagManager;
