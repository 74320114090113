import { Link, useParams } from 'react-router-dom';
import { useDatabaseServices } from '../../../contexts/DatabaseContext';
import { EMPTY_REVISION_DISPLAY_VALUE, PartParams } from '../../types';
import { partPath } from '../../../lib/pathUtil';
import { PartRevisionSummary, ReleaseState } from 'shared/lib/types/postgres/manufacturing/types';

type RevisionSelectProps = {
  options: PartRevisionSummary[];
  onSelect?: (revisionLabel: string, revisionId?: string) => void;
};

const MenuContent = ({ option, state }) => {
  return (
    <div className="text-left text-sm text-slate-800 truncate text-ellipsis">
      {option.revision || EMPTY_REVISION_DISPLAY_VALUE}
      {state && ` (${state})`}
    </div>
  );
};

const containerStyle = 'w-full p-2 cursor-pointer hover:bg-gray-100';

const RevisionSelect = ({ options, onSelect }: RevisionSelectProps) => {
  const { partId } = useParams<PartParams>();
  const { currentTeamId } = useDatabaseServices();

  const getState = (state?: ReleaseState, isApproved?: boolean) => {
    switch (state) {
      case 'draft':
        return 'Draft';
      case 'in_review':
        return isApproved ? 'Ready to Release' : 'In Review';
      default:
        return '';
    }
  };

  return (
    <div
      className="max-h-[300px] w-[180px] overflow-y-auto unobtrusive-scrollbar flex flex-col items-start rounded-md"
      aria-label="Revision Selector"
    >
      {options.map((option) => {
        const state = getState(option.release_state, option.is_approved);
        if (onSelect) {
          return (
            <button key={option.id} onClick={() => onSelect(option.revision, option.id)} className={containerStyle}>
              <MenuContent option={option} state={state} />
            </button>
          );
        }

        return (
          <Link key={option.id} to={partPath(currentTeamId, partId, option.id)} className={containerStyle}>
            <MenuContent option={option} state={state} />
          </Link>
        );
      })}
    </div>
  );
};

export default RevisionSelect;
