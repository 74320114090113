import { useCallback, useState } from 'react';
import { Procedure, Release } from 'shared/lib/types/views/procedures';
import usePendingChangesPrompt from './usePendingChangesPrompt';
import { newRunDoc } from 'shared/lib/runUtil';
import { useHistory } from 'react-router-dom';
import { useMixpanel } from '../contexts/MixpanelContext';
import { useUserInfo } from '../contexts/UserContext';
import { useDatabaseServices } from '../contexts/DatabaseContext';
import externalDataUtil from '../lib/externalDataUtil';
import { INVALID_ITEMS_MESSAGE } from '../components/ButtonsProcedure';
import { runViewPath } from '../lib/pathUtil';
import { useDispatch, useStore } from 'react-redux';
import { startRun } from '../contexts/runsSlice';
import { isProcedureWithBatchSteps, prepareBatchStepsForRun } from '../lib/batchSteps';
import { cloneDeep } from 'lodash';
import { selectProcedureById } from '../contexts/proceduresSlice';
import { useSettings } from '../contexts/SettingsContext';
import configUtil from 'shared/lib/configUtil';
import { selectOfflineInfo } from '../app/offline';

interface useProcedureListActionsReturns {
  showBatchRunModal: boolean;
  onStartRun: (procedureId: string, event: React.MouseEvent<HTMLButtonElement>) => Promise<void>;
  onStartBatchRun: (batchsize: number) => void;
  onCancelBatchRun: () => void;
}

const useProcedureListActions = (): useProcedureListActionsReturns => {
  const confirmPendingChanges = usePendingChangesPrompt();
  const history = useHistory();
  const dispatch = useDispatch();
  const store = useStore();
  const { mixpanel } = useMixpanel();
  const { userInfo } = useUserInfo();
  const { services, currentTeamId } = useDatabaseServices();
  const { config } = useSettings();

  const userId = userInfo.session.user_id;

  const [showBatchRunModal, setShowBatchRunModal] = useState(false);
  const [procedureForBatchRun, setProcedureForBatchRun] = useState<Procedure | null>(null);

  const createRun = useCallback(
    async (procedure) => {
      let userParticipantType;
      if (config) {
        userParticipantType = configUtil.getUserParticipantType(config);
      }
      let run = newRunDoc({
        procedure,
        userId,
        userParticipantType,
      });
      try {
        const externalItems = await services.externalData.getAllExternalItems(run);
        run = externalDataUtil.updateProcedureWithItems(run, externalItems);
      } catch (error) {
        // Ignore any errors and fall back to using procedure without dynamic data.
      }
      return run;
    },
    [config, services.externalData, userId]
  );

  const mixpanelTrack = useCallback(
    (trackingKey: string, properties?: object | undefined) => {
      if (mixpanel) {
        mixpanel.track(trackingKey, properties);
      }
    },
    [mixpanel]
  );

  const validateAndStartRun = useCallback(
    (run, event) => {
      const hasInvalidExternalItems = externalDataUtil.hasInvalidExternalItems(run);
      if (hasInvalidExternalItems && !window.confirm(INVALID_ITEMS_MESSAGE)) {
        if (event) {
          event.target.disabled = false;
        }
        return;
      }

      dispatch(
        startRun({
          teamId: currentTeamId,
          run,
        })
      );
      mixpanelTrack('Run Procedure', { Source: 'Master Procedure List' });
      history.push(runViewPath(currentTeamId, run._id));
    },
    [currentTeamId, dispatch, history, mixpanelTrack]
  );

  const _getLatestProcedure = useCallback(
    async (procedureId: string) => {
      const isOnline = selectOfflineInfo(store.getState()).online;
      if (isOnline) {
        return services.procedures.getProcedure(procedureId);
      }

      // TODO EPS-5335: we are currently depending on the caller to guarantee the procedure in the store is the latest procedure
      return selectProcedureById(store.getState(), currentTeamId, procedureId);
    },
    [currentTeamId, store, services.procedures]
  );

  const onStartRun = useCallback(
    async (procedureId, event) => {
      event.target.disabled = true;
      const procedure = (await _getLatestProcedure(procedureId)) as Release;
      if (!procedure || !(await confirmPendingChanges(procedure))) {
        event.target.disabled = false;
        return;
      }

      if (isProcedureWithBatchSteps(procedure)) {
        setProcedureForBatchRun(procedure);
        setShowBatchRunModal(true);
        event.target.disabled = false;
        return;
      }

      const run = await createRun(procedure);
      validateAndStartRun(run, event);
    },
    [_getLatestProcedure, confirmPendingChanges, createRun, validateAndStartRun]
  );

  const onStartBatchRun = useCallback(
    async (batchSize: number) => {
      const procedureToRun = cloneDeep(procedureForBatchRun) as Procedure;
      prepareBatchStepsForRun(procedureToRun, batchSize);
      const run = await createRun(procedureToRun);
      validateAndStartRun(run, undefined);
    },
    [procedureForBatchRun, createRun, validateAndStartRun]
  );

  const onCancelBatchRun = useCallback(() => {
    setProcedureForBatchRun(null);
    setShowBatchRunModal(false);
  }, [setProcedureForBatchRun, setShowBatchRunModal]);

  return {
    showBatchRunModal,
    onStartRun,
    onStartBatchRun,
    onCancelBatchRun,
  };
};

export default useProcedureListActions;
