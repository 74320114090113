import { Part } from 'shared/lib/types/postgres/manufacturing/types';
import AttachmentImage from '../../components/Attachments/AttachmentImage';
import PartLabel from './PartLabel';
import RestrictedInfo, { RESTRICTED_TEXT } from './RestrictedInfo';

interface PartBadgeProps {
  part?: Part;
  teamId: string;
  showImage?: boolean;
  partRestricted?: boolean;
  inline?: boolean;
  onRevChange?: (revisionLabel: string, revisionId?: string) => void;
}

const PartBadge = ({
  part,
  teamId,
  showImage = true,
  partRestricted = false,
  inline = false,
  onRevChange,
}: PartBadgeProps) => {
  const image = partRestricted ? undefined : part?.image;
  const imageSize = inline ? 'h-5 w-5' : 'h-10 w-10';
  const description =
    partRestricted || !part ? (
      <RestrictedInfo text={RESTRICTED_TEXT} />
    ) : (
      <PartLabel part={part} teamId={teamId} inline={inline} onChange={onRevChange} />
    );

  return (
    <div className="flex gap-x-2 items-center">
      {showImage && (
        <div className={`${imageSize} rounded shrink-0`}>
          <AttachmentImage attachment={image} />
        </div>
      )}
      <div>{description}</div>
    </div>
  );
};

export default PartBadge;
