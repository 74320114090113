import { ProcedureMetadata } from 'shared/lib/types/views/procedures';
import GridComparators from '../../elements/lib/gridComparators';
import renderAction from './Renderers/Action';
import renderDateTime from './Renderers/DateTime';
import renderProcedureLinkWithIcon from './Renderers/ProcedureLinkWithIcons';
import renderProject from './Renderers/Project';
import renderText from './Renderers/Text';
import { HeaderDefinition, ProcedureTableRowModel } from './types';
import homeUtil from '../../lib/homeUtil';
import { Projects } from 'shared/lib/types/couch/settings';
import { filterBySearchTerm } from '../../lib/gridUtils';
import { isNotTestingProcedure } from 'shared/lib/procedureUtil';
import { PERM } from '../../lib/auth';
import { DocSynced, isProcedureSynced } from '../../contexts/proceduresSlice';
import projectUtil from '../../lib/projectUtil';
import apm from '../../lib/apm';
import { Tag as GlobalTag } from 'shared/lib/types/api/settings/tags/models';
import { FilterByProjectFunction } from '../../contexts/NavContext';

type AuthContextInterface = {
  hasPermission: (permission: string, projectId: string | undefined) => boolean;
  getOperatorRolesSet: () => Set<string>;
};

interface ProcedureMappingContext {
  projects: Projects | null;
  currentTeamId: string;
  proceduresSynced?: Record<string, DocSynced>;
  auth: AuthContextInterface;
  redlinesMetadataMap: Record<string, number>;
  allProceduresMetadata: Array<ProcedureMetadata>;
  onStartRun: (
    procedureId: string,
    event: React.MouseEvent<HTMLButtonElement>
  ) => Promise<void>;
  onUnarchive?: (procedure: ProcedureMetadata, procedureLink: string) => void;
  isOnline: boolean;
  offlineIds?: Set<string>;
  globalTags: Record<string, GlobalTag>;
  isGlobalTagsEnabled: () => boolean;
}

const _mapToTableRowModel = (
  procedureMetadata: ProcedureMetadata,
  context: ProcedureMappingContext
): ProcedureTableRowModel => {
  const id = procedureMetadata._id;
  const synced = context.proceduresSynced
    ? isProcedureSynced(context.proceduresSynced, id, procedureMetadata._rev)
    : undefined;
  const hasEntityEditPermission = context.auth.hasPermission(
    PERM.PROCEDURES_EDIT,
    procedureMetadata?.project_id
  );
  const hasEntityRunPermission = context.auth.hasPermission(
    PERM.RUNS_EDIT,
    procedureMetadata?.project_id
  );
  const procedureTitle = homeUtil.getProcedureTitle(
    procedureMetadata,
    synced,
    context.currentTeamId,
    context.globalTags,
    context.isGlobalTagsEnabled,
    context.offlineIds?.has(procedureMetadata._id)
  );
  const releasedVersion = homeUtil.getReleasedVersion(
    procedureMetadata,
    context.allProceduresMetadata
  );

  const projectId = procedureMetadata.project_id;
  const projectName = projectUtil.getProjectName(context.projects, projectId);
  const rootProjectName = projectUtil.getRootProjectName(
    context.projects,
    projectId
  );

  const numberOfSuggestedEdits = context.redlinesMetadataMap[id];

  let draftAction;
  if (
    hasEntityEditPermission ||
    homeUtil.hasReviewVersion(procedureMetadata, context.allProceduresMetadata)
  ) {
    // all users can review procedures
    draftAction = homeUtil.getDraftAction(
      procedureMetadata,
      context.allProceduresMetadata,
      context.currentTeamId,
      context.isOnline
    );
  }
  let releasedAction;
  if (hasEntityRunPermission) {
    releasedAction = homeUtil.getRunAction({
      procedureMetadata,
      canStartRun: synced || context.isOnline,
      allStartRunSignoffOperators: procedureMetadata?.start_run_signoffs_groups,
      userOperatorRolesSet: context.auth.getOperatorRolesSet(),
      onClick: (event) => {
        context.onStartRun(id, event).catch((err) => apm.captureError(err));
      },
    });
  }
  let unarchiveAction;
  if (hasEntityEditPermission) {
    const procedureLink = homeUtil.getProcedureLink(
      procedureMetadata,
      context.currentTeamId
    );
    unarchiveAction = {
      componentType: 'button',
      label: 'Unarchive',
      onClick: (event) => {
        context.onUnarchive &&
          context.onUnarchive(procedureMetadata, procedureLink);
        event.currentTarget.disabled = true;
      },
    };
  }

  return {
    id,
    procedureTitle,
    projectName,
    rootProjectName,
    releasedVersion,
    draftAction,
    releasedAction,
    unarchiveAction,
    numberOfSuggestedEdits,
    archivedDate: procedureMetadata.editedAt,
    modified: procedureMetadata?.editedAt,
  };
};

const _mapMetadataToRows = (
  procedures: Array<ProcedureMetadata>,
  context: ProcedureMappingContext
) => {
  return procedures
    .filter((procedure) => isNotTestingProcedure(procedure.procedure_type))
    .map((procedure) => _mapToTableRowModel(procedure, context));
};

export const getRows = ({
  procedures,
  filterByProject,
  searchTerm,
  context,
}: {
  procedures: Array<ProcedureMetadata>;
  filterByProject: FilterByProjectFunction;
  searchTerm: string;
  context: ProcedureMappingContext;
}): Array<ProcedureTableRowModel> => {
  const projectProcedures = filterByProject(procedures);
  const mappedProcedures = _mapMetadataToRows(projectProcedures, context);

  return filterBySearchTerm({
    searchTerm,
    allData: mappedProcedures,
    getStrings: (procedure: ProcedureTableRowModel) => {
      return [
        procedure.procedureTitle.label,
        procedure.procedureTitle.name,
        (context.isGlobalTagsEnabled()
          ? procedure.procedureTitle.globalTags.map((tag) => tag.name)
          : procedure.procedureTitle.tags.map((tag) => tag.name)
        ).join(' , '),
        procedure.projectName,
        procedure.draftAction?.label,
        procedure.releasedVersion,
      ];
    },
  }).sort((a, b) => (b.modified ?? '').localeCompare(a.modified ?? ''));
};

const activeListColumns = [
  {
    name: 'Procedure',
    key: 'procedureTitle',
    componentType: 'link_with_icon',
    width: '45%',
    renderCell: renderProcedureLinkWithIcon,
    comparator: (a: ProcedureTableRowModel, b: ProcedureTableRowModel) =>
      a.procedureTitle.label
        .toLowerCase()
        .localeCompare(b.procedureTitle.label.toLowerCase()),
  },
  {
    name: 'Project',
    key: 'projectName',
    componentType: 'project_label',
    width: '15%',
    renderCell: ({ row }) => renderProject(row.projectName),
    comparator: GridComparators.project,
  },
  {
    name: 'State',
    key: 'draftAction',
    componentType: 'action',
    renderCell: ({ row }) => renderAction(row.draftAction),
    comparator: (a: ProcedureTableRowModel, b: ProcedureTableRowModel) =>
      (a.draftAction?.label?.toLowerCase() ?? '').localeCompare(
        b.draftAction?.label?.toLowerCase() ?? ''
      ),
  },
  {
    name: 'Released',
    key: 'releasedVersion',
    componentType: 'text',
    renderCell: ({ row }) => renderText(row.releasedVersion),
  },
  {
    name: 'Run',
    key: 'releasedAction',
    componentType: 'action',
    sortable: false,
    width: '70px',
    renderCell: ({ row }) => renderAction(row.releasedAction),
  },
];

const activeTreeColumns = [
  {
    name: 'Procedure',
    key: 'procedureTitle',
    componentType: 'link_with_icon',
    width: '45%',
    renderCell: renderProcedureLinkWithIcon,
    comparator: (a: ProcedureTableRowModel, b: ProcedureTableRowModel) =>
      a.procedureTitle.label
        .toLowerCase()
        .localeCompare(b.procedureTitle.label.toLowerCase()),
  },
  {
    name: 'Project',
    key: 'rootProjectName',
    componentType: 'project_label',
    width: '15%',
    renderCell: ({ row }) => renderProject(row.rootProjectName),
    comparator: GridComparators.project,
  },
  {
    name: 'State',
    key: 'draftAction',
    componentType: 'action',
    renderCell: ({ row }) => renderAction(row.draftAction),
    comparator: (a: ProcedureTableRowModel, b: ProcedureTableRowModel) =>
      (a.draftAction?.label?.toLowerCase() ?? '').localeCompare(
        b.draftAction?.label?.toLowerCase() ?? ''
      ),
  },
  {
    name: 'Released',
    key: 'releasedVersion',
    componentType: 'text',
    renderCell: ({ row }) => renderText(row.releasedVersion),
  },
  {
    name: 'Run',
    key: 'releasedAction',
    componentType: 'action',
    sortable: false,
    width: '70px',
    renderCell: ({ row }) => renderAction(row.releasedAction),
  },
];

const archivedListColumns = [
  {
    name: 'Procedure',
    key: 'procedureTitle',
    componentType: 'link_with_icon',
    width: '45%',
    renderCell: renderProcedureLinkWithIcon,
    comparator: (a: ProcedureTableRowModel, b: ProcedureTableRowModel) =>
      a.procedureTitle.label
        .toLowerCase()
        .localeCompare(b.procedureTitle.label.toLowerCase()),
  },
  {
    name: 'Project',
    key: 'projectName',
    componentType: 'project_label',
    width: '15%',
    renderCell: ({ row }) => renderProject(row.projectName),
    comparator: GridComparators.project,
  },
  {
    name: 'Released',
    key: 'releasedVersion',
    componentType: 'text',
    renderCell: ({ row }) => renderText(row.releasedVersion),
  },
  {
    name: 'Archived',
    key: 'archivedDate',
    componentType: 'timestamp',
    renderCell: ({ row }) => renderDateTime(row.archivedDate),
  },
  {
    name: 'Unarchive',
    key: 'unarchiveAction',
    componentType: 'action',
    sortable: false,
    width: '100px',
    renderCell: ({ row }) => renderAction(row.unarchiveAction),
  },
];

const archivedTreeColumns = [
  {
    name: 'Procedure',
    key: 'procedureTitle',
    componentType: 'link_with_icon',
    width: '45%',
    renderCell: renderProcedureLinkWithIcon,
    comparator: (a: ProcedureTableRowModel, b: ProcedureTableRowModel) =>
      a.procedureTitle.label
        .toLowerCase()
        .localeCompare(b.procedureTitle.label.toLowerCase()),
  },
  {
    name: 'Project',
    key: 'rootProjectName',
    componentType: 'project_label',
    width: '15%',
    renderCell: ({ row }) => renderProject(row.rootProjectName),
    comparator: GridComparators.project,
  },
  {
    name: 'Released',
    key: 'releasedVersion',
    componentType: 'text',
    renderCell: ({ row }) => renderText(row.releasedVersion),
  },
  {
    name: 'Archived',
    key: 'archivedDate',
    componentType: 'timestamp',
    renderCell: ({ row }) => renderDateTime(row.archivedDate),
  },
  {
    name: 'Unarchive',
    key: 'unarchiveAction',
    componentType: 'action',
    sortable: false,
    width: '100px',
    renderCell: ({ row }) => renderAction(row.unarchiveAction),
  },
];

export const getColumns = ({
  state,
  view,
}: {
  state: 'active' | 'archived';
  view: 'list' | 'tree';
}): Array<HeaderDefinition> => {
  if (state === 'active' && view === 'tree') {
    return activeTreeColumns;
  }
  if (state === 'archived' && view === 'list') {
    return archivedListColumns;
  }
  if (state === 'archived' && view === 'tree') {
    return archivedTreeColumns;
  }
  return activeListColumns;
};
