import { TreeNode } from 'primereact/treenode';
import { useCallback, useEffect, useMemo, useState } from 'react';
import projectUtil, { DEFAULT_PROJECT_ID } from '../lib/projectUtil';
import { useSettings } from '../contexts/SettingsContext';
import { TreeSelect, TreeSelectEventNodeEvent, TreeSelectExpandedKeysType } from 'primereact/treeselect';
import {
  faAngleDown,
  faAngleRight,
  faFolderClosed as fasFolderClosed,
  faFolderOpen as fasFolderOpen,
} from '@fortawesome/free-solid-svg-icons';
import { faFile as farFile } from '@fortawesome/free-regular-svg-icons';
import Icon from './Icon';
import { Tree } from 'primereact/tree';
import { StyleVariant } from './lib/fieldStyles';
import { Dictionary, groupBy } from 'lodash';
import { getProjectsTreeFlat } from '../lib/projectTreeUtil';

export type EntityWithProject = {
  id: string | number;
  projectId?: string;
  children?: Array<object>;
  icon?: JSX.Element;
};

export enum TreeType {
  Select,
  Popup,
}

export type CustomizedTreeNode = TreeNode & {
  isProject?: boolean;
  toggleIcon?: TreeNode['icon'];
};

interface ExpandCollapseIconProps {
  expanded: boolean;
}

const ExpandCollapseIcon = ({ expanded }: ExpandCollapseIconProps) => {
  if (expanded) {
    return <Icon element={faAngleDown} />;
  }
  return <Icon element={faAngleRight} />;
};

const createEntityNode = <T extends EntityWithProject>({
  entity,
  labelFormatter,
}: {
  entity: T;
  labelFormatter: (entity: T) => string;
}) => {
  const hasChildren = entity.children && entity.children.length > 0;
  return {
    data: entity,
    key: entity.id,
    id: `${entity.id}`,
    label: labelFormatter(entity),
    ...(hasChildren && { children: entity.children }),
    icon: !hasChildren && <div className="min-w-4">{entity.icon ?? <Icon element={farFile} className="px-2" />}</div>,
    toggleIcon: hasChildren && entity.icon,
  };
};

const labelComparator = (a: CustomizedTreeNode, b: CustomizedTreeNode) => (a.label ?? '').localeCompare(b.label ?? '');
const createProjectNode = ({
  projectNode,
  childProjectNodes = [],
  childEntityNodes = [],
  sortChildren = true,
}: {
  projectNode: CustomizedTreeNode;
  childProjectNodes?: Array<CustomizedTreeNode>;
  childEntityNodes?: Array<CustomizedTreeNode>;
  sortChildren?: boolean;
}): CustomizedTreeNode | undefined => {
  if (sortChildren) {
    childEntityNodes.sort(labelComparator);
  }

  return {
    ...projectNode,
    selectable: false,
    children: [...childProjectNodes, ...childEntityNodes],
    isProject: true,
  };
};

interface ProjectTreeSelectorProps<T extends EntityWithProject> {
  entities: Array<T>;
  currentProjectId?: string;
  currentEntityId?: string | number;
  isDisabled?: boolean;
  labelFormatter: (entity: T) => string;
  onSelect: (key?: string | number, data?: T) => void;
  nodeTemplate?: (entity: T) => JSX.Element | string;
  valueTemplate?: (entity: T) => JSX.Element | string;
  placeholder?: string;
  sortChildren?: boolean;
  type?: TreeType;
  variant?: StyleVariant;
}

const ProjectTreeSelector = <T extends EntityWithProject>({
  entities,
  currentProjectId,
  currentEntityId,
  isDisabled = false,
  labelFormatter,
  onSelect,
  nodeTemplate,
  valueTemplate,
  placeholder = 'Select...',
  sortChildren = true,
  type = TreeType.Select,
  variant = StyleVariant.Standalone,
}: ProjectTreeSelectorProps<T>) => {
  const { projects } = useSettings();
  const [selectedNodeKey, setSelectedNodeKey] = useState<string>();
  const [expandedKeys, setExpandedKeys] = useState<TreeSelectExpandedKeysType>();

  const currentProjectParentId = useMemo(() => {
    return currentProjectId && projects?.projects[currentProjectId].parent_id;
  }, [currentProjectId, projects]);

  const ptStyle = { root: {}, labelContainer: { className: 'flex items-center w-60 truncate' } };
  if (variant === StyleVariant.Grid) {
    ptStyle.root = {
      style: { border: 'none', boxShadow: 'none' },
    };
  }

  const entitiesByProject = useMemo(() => {
    // Group by projectId
    if (!entities || entities.length === 0) {
      return [];
    }
    const activeEntities = projectUtil.filterByActive(entities, projectUtil.toProjectsArray(projects));
    const activeEntitiesByProjectId: Dictionary<Array<T>> = groupBy(
      activeEntities,
      (entity) => entity.projectId || DEFAULT_PROJECT_ID
    );
    const activeProjectsWithEntities = projects
      ? Object.keys(activeEntitiesByProjectId)
          .map((projectId) => projects.projects[projectId])
          .filter((p) => Boolean(p))
      : [];

    const tree: Array<CustomizedTreeNode> = getProjectsTreeFlat(activeProjectsWithEntities)
      .map((projectNode) => {
        if (!projectNode.id) {
          return undefined;
        }
        const childProjectNodes: Array<CustomizedTreeNode> = (projectNode.children ?? [])
          .map((childProjectNode) => {
            if (!childProjectNode.id) {
              return undefined;
            }
            const entitiesForChildProject = (activeEntitiesByProjectId[childProjectNode.id] ?? []).map((entity) =>
              createEntityNode({
                entity,
                labelFormatter,
              })
            );

            return createProjectNode({
              projectNode: childProjectNode,
              childEntityNodes: entitiesForChildProject,
              sortChildren,
            });
          })
          .filter((n): n is CustomizedTreeNode => Boolean(n));

        const entityNodesForParentProject = (activeEntitiesByProjectId[projectNode.id] ?? []).map((entity) =>
          createEntityNode({
            entity,
            labelFormatter,
          })
        );

        return createProjectNode({
          projectNode,
          childProjectNodes,
          childEntityNodes: entityNodesForParentProject,
          sortChildren,
        });
      })
      .filter((n): n is CustomizedTreeNode => Boolean(n));

    tree.sort((a, b) => {
      if (a.key === currentProjectId || (currentProjectParentId && a.key === currentProjectParentId)) {
        return -1;
      }
      if (b.key === currentProjectId || (currentProjectParentId && b.key === currentProjectParentId)) {
        return 1;
      }
      return labelComparator(a, b);
    });
    return tree;
  }, [entities, currentProjectId, labelFormatter, projects, sortChildren, currentProjectParentId]);

  useEffect(() => {
    const initiallyExpanded = currentProjectId ?? DEFAULT_PROJECT_ID;

    setExpandedKeys({ [initiallyExpanded]: true, ...(currentProjectParentId && { [currentProjectParentId]: true }) });
  }, [currentProjectId, currentProjectParentId]);

  useEffect(() => {
    setSelectedNodeKey(`${currentEntityId}` || '');
  }, [currentEntityId]);

  const customNodeTemplate = (node: CustomizedTreeNode) => {
    const nodeStyle = node.key === DEFAULT_PROJECT_ID ? 'italic' : '';
    const useTemplate = nodeTemplate && node.data;
    return (
      <>
        {useTemplate && (
          <div data-pc-section="selector-node" className="flex flex-col min-h-8 max-w-96 break-words leading-4">
            {nodeTemplate(node.data)}
          </div>
        )}
        {!useTemplate && (
          <div data-pc-section="selector-node" className="flex items-center min-h-8 max-w-96 break-words leading-4">
            <div className={`flex flex-row ${nodeStyle}`}>{node.label}</div>
          </div>
        )}
      </>
    );
  };

  const customValueTemplate = (nodes: CustomizedTreeNode | CustomizedTreeNode[]) => {
    const node = nodes[0];
    return node ? (
      <div className="w-56 flex flex-col">
        {valueTemplate && node.data ? valueTemplate(node.data) : <div className="flex flex-row">{node.label}</div>}
      </div>
    ) : (
      <div className="">{placeholder}</div>
    );
  };

  const togglerTemplate = (node: CustomizedTreeNode, options) => {
    if (node.children?.length) {
      return (
        <div className="flex flex-row items-center gap-x-1 mr-1 ">
          <div
            role="button"
            aria-expanded={options.expanded}
            data-pc-section="triggericon"
            aria-label={`toggle-${node.label}`}
            className="flex items-center justify-center justify-items-center h-8 w-6 hover:bg-gray-200 rounded"
            onClick={(event) => {
              options.onClick(event);
            }}
          >
            <ExpandCollapseIcon expanded={options.expanded} />
          </div>
          {node.isProject && options.expanded && <Icon element={fasFolderOpen} className="w-4" />}
          {node.isProject && !options.expanded && <Icon element={fasFolderClosed} className="w-4" />}
          {!node.isProject && node.toggleIcon}
        </div>
      );
    }
  };

  const onNodeSelect = (e: TreeSelectEventNodeEvent) => {
    const selectedKey = e.node.key;
    setSelectedNodeKey(selectedKey as string);
    onSelect(selectedKey, e.node.data);
  };

  const onToggle = useCallback((event) => {
    setExpandedKeys(event.value);
  }, []);

  if (type === TreeType.Select) {
    return (
      <TreeSelect
        data-testid="Project Tree Select"
        pt={ptStyle}
        className={`${isDisabled ? 'dropdown-disabled' : ''}`}
        value={selectedNodeKey}
        expandedKeys={expandedKeys}
        options={entitiesByProject}
        onNodeSelect={onNodeSelect}
        onToggle={onToggle}
        togglerTemplate={togglerTemplate}
        aria-label={placeholder}
        placeholder={placeholder}
        nodeTemplate={customNodeTemplate}
        valueTemplate={customValueTemplate}
        disabled={isDisabled}
        filter
        resetFilterOnHide
      />
    );
  }

  return (
    <Tree
      data-testid="Project Tree"
      pt={{ container: { className: 'h-[16rem] overflow-y-auto unobtrusive-scrollbar' } }}
      value={entitiesByProject}
      expandedKeys={expandedKeys}
      onSelect={onNodeSelect}
      selectionMode="single"
      onToggle={onToggle}
      togglerTemplate={togglerTemplate}
      aria-label={placeholder}
      nodeTemplate={customNodeTemplate}
      disabled={isDisabled}
      filter
    />
  );
};

export default ProjectTreeSelector;
