import AssessmentIndicator from '../AssessmentMatrix/AssessmentIndicator';
import React, { useState } from 'react';
import { TestCase } from '../../testing/types';
import TestCaseView from '../../testing/components/TestCaseView';
import TestingPopup from '../Popup';
import { Hazard } from 'shared/lib/types/testing';
import HazardPopup from '../../testing/components/HazardPopup';

interface TestPointCellProps {
  testPoint: TestCase;
  backgroundColor?: string;
}

const TestPointCell = ({ testPoint, backgroundColor = 'bg-gray-100' }: TestPointCellProps) => {
  const [testPointSelected, setTestPointSelected] = useState<TestCase | null>(null);
  const [hazardSelected, setHazardSelected] = useState<Hazard | null>(null);

  return (
    <>
      {testPointSelected && (
        <TestingPopup
          isPopUpShown={Boolean(testPointSelected)}
          onPopupClose={() => setTestPointSelected(null)}
          header={testPointSelected?.name}
        >
          <TestCaseView testCase={testPointSelected} isPopup={true} />
        </TestingPopup>
      )}
      {hazardSelected && (
        <HazardPopup
          isPopUpShown={Boolean(hazardSelected)}
          onPopupClose={() => setHazardSelected(null)}
          hazard={hazardSelected}
        />
      )}
      <div className="h-full w-full text-sm overflow-hidden flex flex-row flex-wrap gap-px bg-gray-300">
        <div className={`grow flex ${backgroundColor}`}>
          <button
            className="w-fit text-start whitespace-normal h-full px-2  text-blue-600 hover:underline truncate"
            onClick={() => setTestPointSelected(testPoint)}
          >
            {testPoint.name}
          </button>
        </div>

        <div
          className="flex flex-row flex-wrap gap-px"
          style={{ flexGrow: (testPoint.test_case_conditions ?? []).length }}
        >
          {testPoint.test_case_conditions?.map((condition, index) => (
            <div
              className={`min-w-fit flex-grow relative px-2 flex flex-col gap-y-0.5 ${backgroundColor}`}
              aria-label={condition.name}
              key={condition.condition_id}
            >
              <div className="h-fit flex-grow mt-0.5 text-sm text-gray-500 break-all">{condition.name}</div>
              <div className="h-fit flex-grow  text-black break-normal">
                {condition.value} {condition.units}
              </div>
              {/*This extra absolute div is needed because flex-grow does not make the div stretch quite enough*/}
              {index === testPoint.test_case_conditions.length - 1 && (
                <div className={`absolute -right-[1px] h-full w-px ${backgroundColor}`} />
              )}
            </div>
          ))}
          <div />
        </div>

        {testPoint.hazards && testPoint.hazards.length > 0 && (
          <div className={`grow w-fit px-2 break-all ${backgroundColor}`}>
            <div className="h-fit mt-0.5 text-sm text-gray-500">Hazards</div>
            <div className="grow flex flex-col items-start">
              {testPoint.hazards?.map((hazard) => (
                <button
                  key={hazard.id}
                  className="w-fit px-2  text-blue-600 hover:underline whitespace-normal"
                  onClick={() => setHazardSelected(hazard)}
                >
                  <div key={hazard.id} className="flex flex-row items-center gap-x-2">
                    <AssessmentIndicator analysis={hazard} />
                    <span className="grow text-left">{hazard.name}</span>
                  </div>
                </button>
              ))}
            </div>
          </div>
        )}
      </div>
    </>
  );
};

export default TestPointCell;
