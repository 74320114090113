import { RunTag } from 'shared/lib/types/couch/settings';
import RunLabel from '../../components/RunLabel';
import Tag from '../../components/Tag';
import { Tag as GlobalTag } from 'shared/lib/types/api/settings/tags/models';
import TagsDisplay from '../../components/Settings/Tags/TagsDisplay';

interface NameLinkRendererProps {
  code: string;
  runNumber?: number;
  link: string;
  name: string;
  tags: Array<RunTag>;
  globalTags: Array<GlobalTag>;
  isLastChild?: boolean;
  isChild?: boolean;
}

const RunNameLinkRenderer = ({
  code,
  runNumber,
  link,
  name,
  tags,
  globalTags,
  isLastChild = false,
  isChild = false,
}: NameLinkRendererProps) => {
  return (
    <>
      {isLastChild && <div className="flex -mt-8 h-3/4 w-3 border-blue-400 ml-3 border-l-2 border-b-2 mr-3" />}
      {isChild && <div className="flex h-full w-3 border-blue-400 ml-3 border-l-2 mr-3" />}
      <div className="flex flex-col h-full w-full leading-4 mt-4">
        <RunLabel code={code} name={name} runNumber={runNumber} link={link} />
        {tags && tags.length > 0 && (
          <div className="flex">
            {tags.map((tag) => (
              <Tag clickable={true} key={tag.key} id={tag.key} name={tag.name} />
            ))}
          </div>
        )}
        {globalTags && globalTags.length > 0 && (
          <div className="flex">
            <TagsDisplay tags={globalTags} isClickable={true} />
          </div>
        )}
      </div>
    </>
  );
};

export default RunNameLinkRenderer;
