import React, { useCallback, useMemo } from 'react';
import { RunStepComment } from 'shared/lib/types/views/procedures';
import useCommentHandler from '../hooks/useCommentHandler';
import { CommentInterface } from 'shared/lib/comment';
import FormComment, { FormCommentStateType } from './Comments/FormComment';
import useSaveComment from '../hooks/useSaveComment';
import Comment from './Comments/Comment';

interface ChildrenCommentProps {
  childComments?: Array<RunStepComment>;
  parentId: string;
  sectionId: string;
  stepId: string;
  saveNewComment?: (comment: RunStepComment) => Promise<void>;
  editComment: (comment: CommentInterface, commentId?: string) => Promise<void>;
  isRun?: boolean;
  isCommentingDisabled?: boolean;
}

const ChildrenComments = ({
  childComments,
  parentId,
  sectionId,
  stepId,
  saveNewComment,
  editComment,
  isRun,
  isCommentingDisabled,
}: ChildrenCommentProps) => {
  const { onSaveComment } = useSaveComment({
    sectionId,
    parentId,
    stepId,
    onSaveNewComment: saveNewComment,
  });
  const { editCommentContext, handleEditClick, handleCancel, submitCommentWithAttachments } = useCommentHandler({
    onEditComment: editComment,
    autoFocus: true,
  });

  const onEditComment = useCallback(
    async (formCommentState: FormCommentStateType) => {
      const values = {
        comment: formCommentState.text,
        mentions: formCommentState.mentions,
        attachments: formCommentState.attachments,
        edited: true,
      };

      return submitCommentWithAttachments(values).then(() => handleCancel());
    },
    [submitCommentWithAttachments, handleCancel]
  );

  const onSubmitNewComment = useCallback(
    (comment: FormCommentStateType) => {
      return onSaveComment({
        text: comment.text,
        mentions: comment.mentions,
        attachments: comment.attachments,
      });
    },
    [onSaveComment]
  );

  const isCommentingEnabled = useMemo(() => {
    return Boolean(isRun && saveNewComment && !isCommentingDisabled);
  }, [isRun, saveNewComment, isCommentingDisabled]);

  return (
    <>
      <div className="flex flex-col">
        {/* Render Child Comments */}
        {childComments?.map((comment, index) => (
          <div key={index} className="ml-6 mt-1">
            <Comment
              comment={comment}
              canEdit={isCommentingEnabled}
              isEditing={comment.id === editCommentContext.id}
              onEdit={() => handleEditClick(comment)}
              onSubmit={onEditComment}
              onCancel={handleCancel}
            />
          </div>
        ))}
      </div>

      {isCommentingEnabled && (
        <div className={`py-1 ${childComments?.length ? 'ml-6' : 'ml-0'}`}>
          <FormComment onSubmit={onSubmitNewComment} commentButtonLabel="Reply" fileSource="runs:comments" />
        </div>
      )}
    </>
  );
};

export default React.memo(ChildrenComments);
