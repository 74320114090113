export const HIDDEN_TEXT = '--';
export const RESTRICTED_TEXT = 'Restricted';

interface RestrictedInfoProps {
  text?: string;
}

const RestrictedInfo = ({ text = HIDDEN_TEXT }: RestrictedInfoProps) => {
  return <div className="text-gray-400 italic">{text}</div>;
};

export default RestrictedInfo;
