import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useMemo } from 'react';
import { ProcedureState } from 'shared/lib/types/views/procedures';
import { useSelectionContext } from '../contexts/Selection';
import { ScrollEntry } from '../hooks/useScroll';
import stickyHeaderUtil from '../lib/stickyHeaderUtil';
import Button, { BUTTON_TYPES } from './Button';
import ExpandCollapseButtons from './ExpandCollapse/ExpandCollapseButtons';
import Label from './Label';
import NavigationRedlines from './NavigationRedlines';
import LastSavedDisplay from './Time/LastSavedDisplay';
import Divider from './Toolbar/Divider';
import LeftSide from './Toolbar/LeftSide';
import RightSide from './Toolbar/RightSide';
import Spacer from './Toolbar/Spacer';
import Title from './Toolbar/Title';
import Toolbar from './Toolbar/Toolbar';

export const EDIT_STICKY_HEADER_HEIGHT_REM = 1;

export interface EditToolbarProps {
  procedureTitle: string;
  procedureState?: ProcedureState;
  showSubmitError: boolean;
  onGoToError: () => void;
  submissionErrors: string | null;
  isDirty: boolean;
  isSubmitting: boolean;
  lastSavedTime: Date | null;
  currentTab: string;
  setCurrentTab: (newTab: string) => void;
  hasPast: boolean;
  hasFuture: boolean;
  onUndo: () => void;
  onRedo: () => void;
  onExpandAll: () => void;
  onCollapseAll: () => void;
  onPreview: () => void;
  isPreviewDisabled: boolean;
  onSave: () => void;
  isSaveDisabled: boolean;
  onValidateReview: (settings?: { reviewTypeId?: string }) => void;
  isReviewDisabled: boolean;
  goToRedline: (entry: ScrollEntry) => void;
  redlineFieldList: Array<ScrollEntry>;
  unresolvedActionsCount: number;
}

const EditToolbar = ({
  procedureTitle,
  procedureState,
  showSubmitError,
  onGoToError,
  submissionErrors,
  isDirty,
  isSubmitting,
  lastSavedTime,
  currentTab,
  setCurrentTab,
  hasPast,
  hasFuture,
  onUndo,
  onRedo,
  onExpandAll,
  onCollapseAll,
  onPreview,
  isPreviewDisabled,
  onSave,
  isSaveDisabled,
  onValidateReview,
  isReviewDisabled,
  goToRedline,
  redlineFieldList,
  unresolvedActionsCount,
}: EditToolbarProps) => {
  const isDraft = useMemo(() => {
    if (!procedureState) {
      return false;
    }
    return procedureState === 'draft';
  }, [procedureState]);

  const onReviewButtonClicked = () => {
    onValidateReview();
  };

  const { clearSelections } = useSelectionContext();

  const handleComponentClick = (e) => {
    if (!e.target.closest('.selectable-block')) {
      clearSelections();
    }
  };

  return (
    <Toolbar onClick={handleComponentClick}>
      <LeftSide>
        <button
          className={stickyHeaderUtil.getButtonClassNames(false, currentTab === 'list')}
          type="button"
          title="Edit Draft"
          disabled={currentTab === 'list'}
          onClick={() => setCurrentTab('list')}
        >
          <FontAwesomeIcon icon="pencil-alt" />
        </button>
        <button
          className={stickyHeaderUtil.getButtonClassNames(isPreviewDisabled, false)}
          type="button"
          title="Preview Procedure"
          aria-label="Preview Procedure"
          disabled={isPreviewDisabled}
          onClick={onPreview}
        >
          <FontAwesomeIcon icon={'fa-solid fa-eye' as IconProp} />
        </button>
        <Divider ml={1} />
        {isDraft && <Label text="Draft" color="bg-gray-300" enableTruncate={false} />}
        <Spacer />
        <Title title={procedureTitle} />
      </LeftSide>

      <div className="h-full flex grow justify-end gap-x-2 text-sm text-slate-700 font-medium whitespace-nowrap">
        {/* Show user validation errors */}
        {showSubmitError && (
          <button
            className="flex flex-row gap-x-1 items-center px-2 my-2 rounded-md hover:bg-slate-300"
            onClick={onGoToError}
          >
            <FontAwesomeIcon className="text-red-500" icon="triangle-exclamation" />
            <div className="text-gray-800 ">Missing or Invalid Fields</div>
            <FontAwesomeIcon icon="arrow-down" className="text-slate-700" />
          </button>
        )}

        {/* Show user any network errors */}
        {submissionErrors && (
          <div className="flex flex-row gap-x-1 items-center">
            <FontAwesomeIcon className="text-red-500" icon="bug" />
            <div className="text-gray-800">{submissionErrors}</div>
          </div>
        )}

        {/* If no errors exist, show the user the last saved timestamp */}
        {!(showSubmitError || submissionErrors) && (
          <div className="self-center whitespace-nowrap hidden lg:block">
            <LastSavedDisplay lastSavedTime={lastSavedTime} isSubmitting={isSubmitting} isDirty={isDirty} />
          </div>
        )}
      </div>

      <RightSide>
        {(redlineFieldList.length > 0 || unresolvedActionsCount > 0) && (
          <>
            <Divider ml={1} mr={1} />
            <NavigationRedlines
              redlineFieldList={redlineFieldList}
              goToRedline={goToRedline}
              unresolvedActionsCount={unresolvedActionsCount}
            />
          </>
        )}
        <Divider ml={3} mr={1} />
        <button
          className={stickyHeaderUtil.getButtonClassNames(!hasPast, false)}
          type="button"
          title="Undo"
          disabled={!hasPast}
          onClick={onUndo}
        >
          <FontAwesomeIcon icon="undo" />
        </button>
        <button
          className={stickyHeaderUtil.getButtonClassNames(!hasFuture, false)}
          type="button"
          title="Redo"
          disabled={!hasFuture}
          onClick={onRedo}
        >
          <FontAwesomeIcon icon="redo" />
        </button>
        <Divider ml={1} mr={1} />
        <ExpandCollapseButtons
          buttonClasses={stickyHeaderUtil.getButtonClassNames(currentTab === 'chart', false)}
          onExpand={onExpandAll}
          onCollapse={onCollapseAll}
          isDisabled={currentTab === 'chart'}
        />
        <Divider ml={1} mr={1} />
        <button
          className={stickyHeaderUtil.getButtonClassNames(isSaveDisabled, false)}
          type="button"
          title="Save Procedure"
          aria-label="Save Procedure"
          disabled={isSaveDisabled}
          onClick={onSave}
        >
          <FontAwesomeIcon icon="save" />
        </button>
        <Button
          type={BUTTON_TYPES.PRIMARY}
          ariaLabel="Review"
          isDisabled={isReviewDisabled}
          onClick={onReviewButtonClicked}
          size="sm"
        >
          Review
        </Button>
      </RightSide>
    </Toolbar>
  );
};

export default EditToolbar;
