import { useMemo } from 'react';
import { useSettings } from '../../contexts/SettingsContext';
import SearchFilter from '../lib/SearchFilter';
import { useNavState } from '../../contexts/NavContext';
import projectUtil from '../../lib/projectUtil';

const ProjectsFilter = () => {
  const { projects: projectsDoc } = useSettings();
  const projects = projectUtil.toProjectsArray(projectsDoc);
  const { projectsFilter, persistedView } = useNavState();

  const projectOptions = useMemo(() => {
    if (projectsFilter) {
      return projectsFilter.subprojects.map(projectUtil.getOptionFromProject);
    }
    const activeParentProjects = projectUtil.getActiveParentProjects(projects);
    return activeParentProjects.map(projectUtil.getOptionFromProject);
  }, [projects, projectsFilter]);

  // Show subprojects when parent projects are selected
  const onProjectsChange = (ids: ReadonlySet<string | null>): void => {
    const idsArray = Array.from(ids) as Array<string>;
    const parentProjectIds = idsArray.filter((id) => projectUtil.isParentProject(id, projects));
    const subprojectIds: Array<string> = [];
    for (const projectId of parentProjectIds) {
      subprojectIds.push(...projectUtil.getActiveSubprojectIds(projectId, projects));
    }
    const updatedIds = [...parentProjectIds, ...subprojectIds];
    persistedView.setSelectedProjectIds(new Set(updatedIds));
  };

  // Filter out subprojects when passing ids back to filter
  const selectedProjectIds = useMemo(() => {
    const idsArray = Array.from(persistedView.selectedProjectIds) as Array<string>;
    const parentProjectIds = idsArray.filter((id) => projectUtil.isParentProject(id, projects));
    return new Set(parentProjectIds);
  }, [persistedView.selectedProjectIds, projects]);

  return (
    <SearchFilter
      filterTitle="Projects"
      filterOptions={projectOptions}
      selectedFilterIds={selectedProjectIds}
      onFilterIdsChange={onProjectsChange}
      ariaLabel="Projects Filter"
    />
  );
};

export default ProjectsFilter;
