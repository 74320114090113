import React, { useCallback, useMemo, useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useDatabaseServices } from '../contexts/DatabaseContext';
import ParentComment from './ParentComment';
import StepActionDisplay from './StepAction';
import Button from './Button';
import { getStepState, ACTION_TYPE, STEP_STATE } from 'shared/lib/runUtil';
import { cloneDeep } from 'lodash';
import useSaveComment from '../hooks/useSaveComment';
import { RunIssue, RunStep, RunStepComment, StepAction } from 'shared/lib/types/views/procedures';
import CommentButton from './CommentButton';
import { commentToActivity } from 'shared/lib/comment';
import { timestampSort } from 'shared/lib/sort';
import { AUTO_COLLAPSE_STEP_TIMELINE_KEY, useSettings } from '../contexts/SettingsContext';
import FormComment, { FormCommentStateType } from './Comments/FormComment';

interface StepTimelineProps {
  step: RunStep;
  isRun: boolean;
  sectionId: string;
  issues?: Record<string, RunIssue>;
  isEditing?: boolean;
  isCommentingDisabled?: boolean;
  saveNewComment?: (comment: RunStepComment) => Promise<void>;
  editComment?: (comment: RunStepComment) => void;
}

interface StepCommentAction {
  type: 'comment';
  timestamp: string;
  content: {
    id: string;
  };
}

type LocalStepAction = StepCommentAction | StepAction;

const StepTimeline = ({
  step,
  issues,
  isRun,
  sectionId,
  isCommentingDisabled,
  saveNewComment,
  editComment,
}: StepTimelineProps) => {
  const { getSetting } = useSettings();
  const isCollapsedByDefault = useMemo(() => getSetting(AUTO_COLLAPSE_STEP_TIMELINE_KEY, false), [getSetting]);

  const { currentTeamId } = useDatabaseServices();
  const [showActivities, setShowActivities] = useState(!isCollapsedByDefault);
  const [shouldFocusOnCommentInput, setShouldFocusOnCommentInput] = useState(false);

  const { onSaveComment } = useSaveComment({
    sectionId,
    stepId: step.id,
    onSaveNewComment: saveNewComment,
  });

  const toggleActivities = () => {
    setShouldFocusOnCommentInput(false);
    setShowActivities(!showActivities);
  };

  const handleCommentClick = () => {
    if (showActivities === false) {
      setShowActivities(true);
      setShouldFocusOnCommentInput(true);
    } else {
      setShowActivities(false);
      setShouldFocusOnCommentInput(false);
    }
  };

  const stepComments = useMemo(() => {
    return step.comments?.filter((comment) => !comment.reference_id);
  }, [step.comments]);

  const stepCommentActivities = useMemo(() => (stepComments ?? []).map(commentToActivity), [stepComments]);

  const { actions, parentChildMap, parentCommentsMap } = useMemo(() => {
    const stepState = getStepState(step);
    const actionsList: LocalStepAction[] = step.actions ? cloneDeep(step.actions) : [];
    const parentChildMap = {};
    const parentCommentsMap = {};

    if (stepState === STEP_STATE.SKIPPED) {
      actionsList.push({
        type: ACTION_TYPE.SKIP,
        user_id: step.skippedUserId ?? '',
        timestamp: step.skippedAt ?? '',
      });
    }

    if (stepComments && stepComments.length > 0) {
      stepComments.forEach((comment) => {
        if (!comment.parent_id) {
          parentCommentsMap[comment.id] = comment;
          parentChildMap[comment.id] = parentChildMap[comment.id] || [];
          actionsList.push({
            type: 'comment',
            timestamp: comment.timestamp,
            content: comment,
          });
        } else {
          if (!parentChildMap[comment.parent_id]) {
            parentChildMap[comment.parent_id] = [];
          }
          parentChildMap[comment.parent_id].push(comment);
        }
      });
    }

    return {
      actions: actionsList.sort(timestampSort()),
      parentChildMap,
      parentCommentsMap,
    };
  }, [step, stepComments]);

  const saveEditComment = useCallback(
    async (editedComment, commentId) => {
      if (!editComment) {
        return;
      }

      const existingComment = stepComments?.find((comment) => comment.id === commentId);

      if (existingComment) {
        const comment = {
          ...existingComment,
          sectionId,
          stepId: step.id,
          text: editedComment.comment,
          mention_list: editedComment.mentions,
          parent_id: existingComment?.parent_id ?? '',
          updated_at: editedComment.updated_at,
          attachments: editedComment.attachments,
        };
        return editComment(comment);
      }
    },
    [editComment, sectionId, step.id, stepComments]
  );

  const onSubmitNewComment = useCallback(
    async (comment: FormCommentStateType) => {
      await onSaveComment({
        text: comment.text,
        mentions: comment.mentions,
        attachments: comment.attachments,
      });

      return Promise.resolve();
    },
    [onSaveComment]
  );

  return (
    <div className="relative text-sm">
      <div className="flex justify-between items-center">
        <Button
          type="tertiary"
          leadingIcon={showActivities ? 'minus' : 'plus'}
          onClick={toggleActivities}
          ariaLabel="activity-button"
          removePadding={true}
        >
          {showActivities ? 'Hide Activity' : 'Show Activity'}
        </Button>
        {!isCommentingDisabled && stepCommentActivities.length > 0 && (
          <div className="absolute -top-1 right-1">
            <CommentButton comments={stepCommentActivities} onClick={handleCommentClick} showNewCommentIcon={true} />
          </div>
        )}
      </div>
      {actions.length > 1 && (
        <div className="absolute top-10 bottom-12 bg-app-gray-400 z-0" style={{ left: '1.2rem', width: '0.1rem' }} />
      )}
      {showActivities && (
        <>
          <div className="ml-3 mr-8" aria-label="timeline">
            {actions.map((item, index) => (
              <div key={index} className="flex w-full items-start relative">
                {item.type === 'comment' ? (
                  <>
                    <div className="flex-shrink-0 mt-1">
                      <div className="rounded-full bg-gray-200 w-4 h-4 inline-flex items-center justify-center">
                        <FontAwesomeIcon icon="comment" className="text-gray-700 text-xs" />
                      </div>
                    </div>
                    <div className="flex-grow ml-1">
                      <ParentComment
                        key={item.content.id}
                        childComments={parentChildMap[item.content.id]}
                        parentId={item.content.id}
                        parentComment={parentCommentsMap[item.content.id]}
                        sectionId={sectionId}
                        stepId={step.id}
                        saveNewComment={saveNewComment}
                        editComment={saveEditComment}
                        isRun={isRun}
                        isCommentingDisabled={isCommentingDisabled}
                      />
                    </div>
                  </>
                ) : (
                  <StepActionDisplay item={item} issues={issues} currentTeamId={currentTeamId} />
                )}
              </div>
            ))}
            {actions.length === 0 && <div className="flex justify-center items-center italic">No Actions</div>}
          </div>
          {isRun && !isCommentingDisabled && (
            <div className="relative flex items-center my-2 mr-3">
              {saveNewComment && (
                <FormComment
                  initialComment={undefined}
                  onSubmit={onSubmitNewComment}
                  focusOnMount={shouldFocusOnCommentInput}
                  fileSource="runs:comments"
                />
              )}
            </div>
          )}
        </>
      )}
    </div>
  );
};

export default StepTimeline;
