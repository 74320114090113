import useUsers from '../../hooks/useUsers';
import DateTimeDisplay from '../DateTimeDisplay';
import EditCommentButton from '../EditCommentButton';
import TextLinkify from '../TextLinkify';
import { UserComment, wasEdited } from 'shared/lib/comment';
import AttachmentsContainer from '../Comments/AttachmentsContainer';

interface CommentDisplayProps {
  activity: UserComment;
  onEdit: (comment: UserComment) => void;
  canEdit?: boolean;
}

const CommentDisplay = ({ activity, onEdit, canEdit = true }: CommentDisplayProps) => {
  const { getUserDisplayText } = useUsers();

  const modifiedActivity = {
    ...activity,
    user_id: getUserDisplayText(activity.user_id),
  };

  return (
    <span className="w-full ">
      <div className="flex flex-col gap-1 px-2 bg-slate-200 rounded-lg">
        <div className="flex justify-between items-center flex-1">
          <div className="text-sm py-1">
            <span className="font-semibold">{modifiedActivity.user_id} </span>
            <span className="text-sm text-gray-500">
              <DateTimeDisplay timestamp={activity.timestamp} />
            </span>
            {wasEdited(activity.timestamp, activity.updated_at) && <span className="ml-1 text-gray-400">(edited)</span>}
          </div>
          {canEdit && <EditCommentButton comment={modifiedActivity} onEdit={onEdit} />}
        </div>
        <div className="pb-1">
          <TextLinkify mentionsEnabled={true} mentionList={activity.mentions}>
            <span className="whitespace-pre-line">{activity.comment}</span>
          </TextLinkify>
          <AttachmentsContainer attachments={activity.attachments || []} />
        </div>
      </div>
    </span>
  );
};

export default CommentDisplay;
