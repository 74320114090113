import { ReactElement } from 'react';
import ThumbnailImageDisplay from '../../components/Attachments/ThumbnailImageDisplay';
import { Attachment } from '../../hooks/useAttachment';

type SingleLineBadgeProps = {
  attachment?: Attachment;
  title: ReactElement;
  subtitle: ReactElement;
};

const SingleLineBadge = ({ attachment, title, subtitle }: SingleLineBadgeProps) => {
  return (
    <div className="flex items-center gap-x-2">
      <ThumbnailImageDisplay size="sm" attachment={attachment} />
      {title}
      {subtitle}
    </div>
  );
};

export default SingleLineBadge;
