import React, { useEffect, useMemo, useState } from 'react';
import { Part } from 'shared/lib/types/postgres/manufacturing/types';
import { InventoryDetailInputBlock, RunInventoryDetailInputRecorded } from 'shared/lib/types/views/procedures';
import SubstepNumber from '../../components/SubstepNumber';
import { useDatabaseServices } from '../../contexts/DatabaseContext';
import { generateHiddenClassString } from '../../lib/styles';
import useLatestParts from '../hooks/useLatestParts';
import { getPartIdForRevisionId, isPartRestricted } from '../lib/parts';
import { Item, ItemDetail } from '../types';
import InventoryDetailBase from './InventoryDetailBase';

interface InventoryDetailInputProps {
  content: InventoryDetailInputBlock;
  recorded?: RunInventoryDetailInputRecorded;
  onRecordValuesChanged: (contentId: string, recorded: RunInventoryDetailInputRecorded) => void;
  isEnabled?: boolean;
  isHidden?: boolean;
  blockLabel?: string;
  teamId: string;
}

const InventoryDetailInput = React.memo(
  ({
    content,
    recorded,
    onRecordValuesChanged,
    isEnabled,
    isHidden,
    blockLabel,
    teamId,
  }: InventoryDetailInputProps) => {
    const { services } = useDatabaseServices();
    const { parts, getLatestPartRevision } = useLatestParts();
    const [part, setPart] = useState<Part | null>(null);
    const [itemDetails, setItemDetails] = useState<Array<ItemDetail>>([]);
    const restrictedPart = isPartRestricted(getLatestPartRevision(content.part_id ?? ''));

    useEffect(() => {
      services.manufacturing
        .listItemDetails()
        .then(setItemDetails)
        .catch(() => {
          /* no-op */
        });
    }, [services.manufacturing]);

    // Part load
    useEffect(() => {
      if (content.part_revision_id) {
        const partId = getPartIdForRevisionId(content.part_revision_id, parts ?? []);
        if (partId) {
          services.manufacturing.getPart(partId, { revisionId: content.part_revision_id }).then(setPart);
        }
      }
    }, [content.part_revision_id, parts, services.manufacturing]);

    const updateDetailValue = (value: string) => {
      onRecordValuesChanged(content.id, {
        item_id: '',
        ...recorded,
        value,
      });
    };

    const handleChangeItem = (item: Item) => {
      // TODO: Update ItemSelect to avoid calling onChangeItem on load
      if (item === null) {
        return;
      }
      onRecordValuesChanged(content.id, {
        value: '',
        ...recorded,
        item_id: item?.id,
      });
    };

    const detailName = useMemo(() => {
      const detail = itemDetails.find((detail) => detail.id === content.detail_id);
      return detail?.name ?? '';
    }, [itemDetails, content.detail_id]);

    /**
     * unset value must be blank string as 'null' is not allowed by prop type
     * and 'undefined' leads to subtle bugs.
     */
    const detailValue: string = recorded?.value ?? '';

    const isLoading = useMemo(() => !detailName || !part, [detailName, part]);

    return isLoading ? null : (
      <tr>
        <td></td>
        <td colSpan={2}>
          <div className={generateHiddenClassString('', isHidden)} />
          <div className={generateHiddenClassString('mt-3 ml-4 flex flex-wrap page-break', isHidden)}>
            <SubstepNumber blockLabel={blockLabel} hasExtraVerticalSpacing={false} />
            <div className="w-full py-1 mr-8">
              <InventoryDetailBase
                part={part}
                detailName={detailName}
                detailValue={detailValue}
                itemId={recorded?.item_id}
                isEnabled={isEnabled}
                isRestricted={restrictedPart}
                handleChangeItem={handleChangeItem}
                updateDetailValue={updateDetailValue}
                teamId={teamId}
              />
            </div>
          </div>
        </td>
      </tr>
    );
  }
);

export default InventoryDetailInput;
