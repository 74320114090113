import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useMemo } from 'react';
import { useSettings } from '../../contexts/SettingsContext';
import PopupListWithSearch from '../../elements/PopupListWithSearch';
import UserId from '../../elements/UserId';
import UsersStack from '../../elements/UsersStack';
import useUsers from '../../hooks/useUsers';

interface AssigneeMultiSelectorProps {
  assignees: string[];
  isDisabled: boolean;
  onChange: (userId: string) => Promise<void>;
}

const AssigneeMultiSelector = ({ assignees, isDisabled, onChange }: AssigneeMultiSelectorProps) => {
  const { users } = useSettings();
  const { getUserDisplayText } = useUsers();

  const options = useMemo(
    () =>
      Object.values(users?.users ?? {})
        .map((user) => user.id)
        .filter((id) => id !== undefined)
        .map((userId) => {
          const id = userId as string; // guaranteed by preceding filter
          const name = getUserDisplayText(id);
          return { id, name };
        }),
    [users, getUserDisplayText]
  );

  return (
    <PopupListWithSearch
      options={options}
      isDisabled={isDisabled}
      dismissOnSelect={false}
      onSelect={(option) => onChange(option.id as string)}
      Components={{
        Trigger: () => (
          <button
            type="button"
            className="flex flex-row gap-x-1 items-center hover:brightness-90"
            aria-label="Update Assignees"
          >
            <UsersStack userIds={assignees} />
            {!isDisabled && <FontAwesomeIcon icon="caret-down" className="text-gray-400" />}
          </button>
        ),
        ListItem: (option) => {
          const optionId = option.id as string;
          return (
            <div className="flex flex-row items-center w-full whitespace-nowrap">
              {assignees.includes(optionId) && <FontAwesomeIcon className="mr-2" icon={['far', 'check-square']} />}
              {!assignees.includes(optionId) && <FontAwesomeIcon className="mr-2" icon={['far', 'square']} />}
              <UserId userId={optionId} />
            </div>
          );
        },
      }}
    />
  );
};

export default AssigneeMultiSelector;
