import { ProcedureMetadata, Run, Section, StepBlock } from './views/procedures';

export type ContentBlockValidateMapping = Record<
  BlockType,
  // we can document these as we go although maybe an overkill TODO PLU-1548
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  (block: StepBlock, additionalInfo?: AdditionalValidationInfo) => any
>;

export type BlockType =
  (typeof ProcedureContentBlockTypes)[keyof typeof ProcedureContentBlockTypes];

// Block type definitions. These are stored in each content Block, eg { type: 'text' }
export const ProcedureContentBlockTypes = Object.freeze({
  Alert: 'alert',
  Attachment: 'attachment',
  Commanding: 'commanding',
  Expression: 'expression',
  ExternalItem: 'external_item',
  FieldInput: 'input',
  FieldInputTable: 'field_input_table',
  InventoryDetailInput: 'inventory_detail_input',
  JumpTo: 'jump_to',
  PartBuild: 'part_build',
  PartKit: 'part_kit',
  PartUsage: 'part_usage',
  ProcedureLink: 'procedure_link',
  Reference: 'reference',
  Requirement: 'requirement',
  TableInput: 'table_input',
  TestCases: 'test_cases',
  Telemetry: 'telemetry',
  Text: 'text',
  ToolCheckIn: 'tool_check_in',
  ToolCheckOut: 'tool_check_out',
  ToolUsage: 'tool_usage',
});

export type BaseBlockContent<T extends BlockType = BlockType> = {
  id: string;
  type: T;
  original_id?: string;
  added?: boolean;
};

export type AdditionalValidationInfo =
  | undefined
  | {
      teamId?: string;
      sectionAndStepIds?: Array<string>;
      procedureMap?: {
        [id: string]: StepBlock | Section;
      };
      proceduresMetadata?: Record<string, ProcedureMetadata>;
      run?: Run;
    };

export type ProcedurePropertyValidateMapping = Record<
  PropertyType,
  // we can document these as we go although maybe an overkill TODO PLU-1548
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  (property: any, additionalInfo?: AdditionalValidationInfo) => any
>;

export type PropertyType = (typeof PropertyTypes)[keyof typeof PropertyTypes];

export const PropertyTypes = Object.freeze({
  Id: '_id',
  Rev: '_rev',
  LockedAt: 'locked_at',
  LockedBy: 'locked_by',
  State: 'state',
  EditedAt: 'editedAt',
  EditedUserId: 'editedUserId',
  ReviewerGroups: 'reviewer_groups',
  ReviewerActionsHistory: 'reviewer_actions_history',
  Type: 'type',
  Comments: 'comments',
  Actions: 'actions',
  ProcedureRevNumber: 'procedure_rev_num',
  InitialRevNumber: 'initial_rev_num',
  ReviewType: 'review_type',
  ReleaseNote: 'release_note',
  Conditionals: 'conditionals',
  Dependencies: 'dependencies',
  PartList: 'part_list',
});
