import { faPlusCircle } from '@fortawesome/free-solid-svg-icons';
import { OverlayPanel } from 'primereact/overlaypanel';
import { useCallback, useEffect, useRef } from 'react';
import { Part } from 'shared/lib/types/postgres/manufacturing/types';
import Button, { BUTTON_TYPES } from '../../components/Button';
import { useDatabaseServices } from '../../contexts/DatabaseContext';
import { DatabaseServices } from '../../contexts/proceduresSlice';
import { TreeType } from '../../elements/ProjectTreeSelector';
import apm from '../../lib/apm';
import { PartSelection } from './PartAndRevisionIdSelect';
import PartSelect from './PartSelect';

interface AddPartsButtonProps {
  projectId?: string;
  onAddParts: (parts: Part[]) => void;
}

const AddPartsButton = ({ projectId, onAddParts }: AddPartsButtonProps) => {
  const { services }: { services: DatabaseServices } = useDatabaseServices();

  const hideList = useCallback(() => {
    overlayPanelRef.current?.hide();
  }, []);

  const showList = useCallback((e) => {
    overlayPanelRef.current?.show(e, e.currentTarget);
  }, []);

  const onAdd = useCallback(
    (parts) => {
      onAddParts(parts);
    },
    [onAddParts]
  );

  const onSelect = useCallback(
    (selected: PartSelection | null) => {
      if (!selected) {
        return;
      }

      overlayPanelRef?.current?.hide();

      const { part, partRevisionId } = selected;
      if (partRevisionId !== part.revisions?.[0].id) {
        const options = { revisionId: partRevisionId };
        services.manufacturing
          .getPart(part.id, options)
          .then((part) => onAdd([part]))
          .catch((err) => apm.captureError(err));
      } else {
        onAdd([part]);
      }
    },
    [onAdd, services.manufacturing]
  );

  const overlayPanelRef = useRef<OverlayPanel>(null);
  useEffect(() => {
    if (overlayPanelRef.current) {
      document.addEventListener(
        'scroll',
        (e) => {
          const target = e.target as HTMLElement;
          if (!target.className.includes('p-tree-container')) {
            hideList();
          }
        },
        { capture: true }
      );
    }
    // Remove listener when menu visibility changes or component unmounts
    return () => {
      document.removeEventListener('scroll', hideList, { capture: true });
    };
  }, [hideList]);

  return (
    <div className="flex">
      <Button ariaLabel="Add Parts" type={BUTTON_TYPES.TERTIARY} leadingIcon={faPlusCircle} onClick={showList}>
        Add
      </Button>

      <OverlayPanel ref={overlayPanelRef}>
        <PartSelect type={TreeType.Popup} projectId={projectId} selected={null} onSelect={onSelect} />
      </OverlayPanel>
    </div>
  );
};

export default AddPartsButton;
