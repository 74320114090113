import { Dispatch, SetStateAction, useEffect, useMemo, useState } from 'react';
import { useSettings } from '../../contexts/SettingsContext';
import { ViewTab } from 'shared/lib/types/postgres/users';
import { isEqual } from 'lodash';
import { useMixpanel } from '../../contexts/MixpanelContext';
import { PurchaseOrderState } from 'shared/lib/types/postgres/manufacturing/orders';

export interface usePersistedViewReturns {
  saveSuccess: string | null;
  setSaveSuccess: Dispatch<SetStateAction<string | null>>;
  saveError: string | null;
  setSaveError: Dispatch<SetStateAction<string | null>>;
  isSaveDefaultViewEnabled: boolean;
  saveDefaultView: () => void;
  areAnyFiltersSet: boolean;

  // TODO: make these more generic during search revamp
  selectedProjectIds: ReadonlySet<string | null>;
  setSelectedProjectIds: Dispatch<SetStateAction<ReadonlySet<string | null>>>;
  selectedTagKeys: ReadonlySet<string | null>;
  setSelectedTagKeys: Dispatch<SetStateAction<ReadonlySet<string | null>>>;
  selectedRunTagKeys: ReadonlySet<string | null>;
  setSelectedRunTagKeys: Dispatch<SetStateAction<ReadonlySet<string | null>>>;
  viewTab: ViewTab;
  setViewTab: Dispatch<SetStateAction<ViewTab>>;
  searchTerm: string;
  setSearchTerm: Dispatch<SetStateAction<string>>;
  expandedProjectNames: ReadonlySet<string>;
  setExpandedProjectNames: Dispatch<SetStateAction<ReadonlySet<string>>>;
  selectedOperationKeys: ReadonlySet<string | null>;
  setSelectedOperationKeys: Dispatch<SetStateAction<ReadonlySet<string | null>>>;
  selectedPriorityIds: ReadonlySet<string | null>;
  setSelectedPriorityIds: Dispatch<SetStateAction<ReadonlySet<string | null>>>;
  selectedTypeIds: ReadonlySet<string | null>;
  setSelectedTypeIds: Dispatch<SetStateAction<ReadonlySet<string | null>>>;
  selectedLocationIds: Array<string>;
  setSelectedLocationIds: Dispatch<SetStateAction<Array<string>>>;
  selectedPOTags: Array<string>;
  setSelectedPOTags: Dispatch<SetStateAction<Array<string>>>;
  selectedPOStatuses: Array<PurchaseOrderState>;
  setSelectedPOStatuses: Dispatch<SetStateAction<Array<PurchaseOrderState>>>;
}

const usePersistedView = (): usePersistedViewReturns => {
  const { mixpanel } = useMixpanel();
  const { defaultView, updateDefaultView } = useSettings();

  const [saveSuccess, setSaveSuccess] = useState<string | null>(null);
  const [saveError, setSaveError] = useState<string | null>(null);
  const [selectedProjectIds, setSelectedProjectIds] = useState<ReadonlySet<string | null>>(
    new Set(defaultView?.project_ids || [])
  );
  const [selectedTagKeys, setSelectedTagKeys] = useState<ReadonlySet<string | null>>(new Set(defaultView?.tags || []));
  const [selectedRunTagKeys, setSelectedRunTagKeys] = useState<ReadonlySet<string | null>>(
    new Set(defaultView?.run_tags || [])
  );

  // These are not yet persisted to a default view
  const [selectedOperationKeys, setSelectedOperationKeys] = useState<ReadonlySet<string | null>>(new Set());
  const [selectedPriorityIds, setSelectedPriorityIds] = useState<ReadonlySet<string | null>>(new Set());
  const [selectedTypeIds, setSelectedTypeIds] = useState<ReadonlySet<string | null>>(new Set());
  const [selectedLocationIds, setSelectedLocationIds] = useState<Array<string>>([]);
  const [selectedPOTags, setSelectedPOTags] = useState<Array<string>>([]);
  const [selectedPOStatuses, setSelectedPOStatuses] = useState<Array<PurchaseOrderState>>([]);

  const [viewTab, setViewTab] = useState<ViewTab>(defaultView?.view_tab || ViewTab.List);
  const [searchTerm, setSearchTerm] = useState(defaultView?.search_term || '');
  const [expandedProjectNames, setExpandedProjectNames] = useState<ReadonlySet<string>>(
    defaultView?.expanded_project_names || new Set()
  );

  // defaultView may not be initialized by the time this context is initialized.
  useEffect(() => {
    if (defaultView) {
      setSelectedProjectIds(new Set(defaultView.project_ids));
      setSelectedTagKeys(new Set(defaultView.tags));
      setSelectedRunTagKeys(new Set(defaultView.run_tags));
      setViewTab(defaultView.view_tab || ViewTab.List);
      setSearchTerm(defaultView.search_term || '');
    }
  }, [defaultView]);

  const isSaveDefaultViewEnabled = useMemo(() => {
    if (!defaultView) {
      return true;
    }

    const userUpdatedView = {
      _id: 'default_view',
      project_ids: Array.from(selectedProjectIds),
      tags: Array.from(selectedTagKeys),
      run_tags: Array.from(selectedRunTagKeys),
      search_term: searchTerm,
      view_tab: viewTab,
    };

    if (isEqual(defaultView, { _id: 'default_view' })) {
      // default view is in an empty state.  Persist current only if it's modified
      return Boolean(
        userUpdatedView?.project_ids?.length ||
          userUpdatedView?.tags?.length ||
          userUpdatedView?.run_tags?.length ||
          userUpdatedView?.search_term?.trim() ||
          userUpdatedView?.view_tab !== ViewTab.List
      );
    }

    return !isEqual(userUpdatedView, defaultView);
  }, [defaultView, selectedProjectIds, selectedTagKeys, selectedRunTagKeys, searchTerm, viewTab]);

  const saveDefaultView = () => {
    if (!defaultView) {
      return;
    }

    if (mixpanel) {
      mixpanel.track('Save Default View');
    }

    updateDefaultView({
      _id: 'default_view',
      project_ids: Array.from(selectedProjectIds),
      tags: Array.from(selectedTagKeys),
      run_tags: Array.from(selectedRunTagKeys),
      search_term: searchTerm,
      view_tab: viewTab,
    })
      .then(() => {
        setSaveError(null);
        setSaveSuccess('Updated your default view');
      })
      .catch((error) => {
        setSaveError('Error saving your default view');
      });
  };

  const areAnyFiltersSet = useMemo(() => {
    return (
      selectedProjectIds.size > 0 ||
      selectedTagKeys.size > 0 ||
      selectedRunTagKeys.size > 0 ||
      selectedOperationKeys.size > 0 ||
      selectedPriorityIds.size > 0 ||
      selectedTypeIds.size > 0 ||
      selectedLocationIds.length > 0 ||
      selectedPOTags.length > 0 ||
      selectedPOStatuses.length > 0
    );
  }, [
    selectedProjectIds,
    selectedTagKeys,
    selectedRunTagKeys,
    selectedOperationKeys,
    selectedPriorityIds,
    selectedTypeIds,
    selectedLocationIds,
    selectedPOTags,
    selectedPOStatuses,
  ]);

  return {
    saveSuccess,
    setSaveSuccess,
    saveError,
    setSaveError,
    isSaveDefaultViewEnabled,
    saveDefaultView,
    areAnyFiltersSet,
    selectedProjectIds,
    setSelectedProjectIds,
    selectedTagKeys,
    setSelectedTagKeys,
    selectedRunTagKeys,
    setSelectedRunTagKeys,
    viewTab,
    setViewTab,
    searchTerm,
    setSearchTerm,
    expandedProjectNames,
    setExpandedProjectNames,
    selectedOperationKeys,
    setSelectedOperationKeys,
    selectedPriorityIds,
    setSelectedPriorityIds,
    selectedTypeIds,
    setSelectedTypeIds,
    selectedLocationIds,
    setSelectedLocationIds,
    selectedPOTags,
    setSelectedPOTags,
    selectedPOStatuses,
    setSelectedPOStatuses,
  };
};

export default usePersistedView;
