import { useMemo } from 'react';
import Icon, { IconType } from '../../elements/Icon';
import { CheckInIcon, CheckOutIcon } from '../../elements/SVGIcons';

const ActionIcon = ({ action }: { action: string }) => {
  const icon: IconType = useMemo(() => {
    switch (action.toLowerCase()) {
      case 'cycles':
        return 'arrows-spin';
      case 'time':
      case 'seconds':
        return 'hourglass';
      case 'check-out':
        return CheckOutIcon;
      case 'check-in':
        return CheckInIcon;
      case 'detail update':
        return 'pen-to-square';
      default:
        return 'spinner';
    }
  }, [action]);

  return <Icon className="text-slate-700 w-4" element={icon} />;
};

export default ActionIcon;
