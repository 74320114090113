import { useMemo } from 'react';
import { Link } from 'react-router-dom';
import { Part } from 'shared/lib/types/postgres/manufacturing/types';
import { useDatabaseServices } from '../../contexts/DatabaseContext';
import { inventoryItemPath } from '../../lib/pathUtil';
import useItems from '../hooks/useItems';
import { getTrackingValue } from '../lib/items';
import { Item } from '../types';
import Revision from './Revision';
import SingleLineBadge from './SingleLineBadge';

type SingleLineInventoryItemBadgeProps = {
  itemId: string;
};

const SingleLineInventoryItemBadge = ({ itemId }: SingleLineInventoryItemBadgeProps) => {
  const { getItem } = useItems();

  const item = useMemo(() => getItem(itemId), [itemId, getItem]);
  if (!item) {
    return null;
  }

  return (
    <SingleLineBadge
      attachment={item.part.image}
      title={<Title item={item} />}
      subtitle={<Subtitle part={item.part} />}
    />
  );
};

const Title = ({ item }: { item: Item }) => {
  const { currentTeamId } = useDatabaseServices();

  return (
    <div className="flex items-center gap-x-1">
      <Link className="text-blue-600" to={inventoryItemPath(currentTeamId, item.id)}>
        <div>{getTrackingValue(item)}</div>
      </Link>
      <div className="flex items-center gap-x-0.5 text-gray-500">
        <div>{item.part.part_no}</div>
        <Revision revision={item.part.rev} prefix={false} ml={0} />
      </div>
    </div>
  );
};

const Subtitle = ({ part }: { part: Part }) => {
  return <span className="text-gray-700">{part.name}</span>;
};

export default SingleLineInventoryItemBadge;
