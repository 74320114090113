import { useCallback, useEffect, useMemo, useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import Button, { BUTTON_TYPES } from '../../components/Button';
import ListHeader from '../../components/Home/ListHeader';
import { TabProps } from '../../components/TabBar/TabBar';
import { useDatabaseServices } from '../../contexts/DatabaseContext';
import { useNavState } from '../../contexts/NavContext';
import { testingPlansPath } from '../../lib/pathUtil';
import ActivePlansGrid from '../components/Plans/ActivePlansGrid';
import ArchivedPlansGrid from '../components/Plans/ArchivedPlansGrid';
import EndedPlansGrid from '../components/Plans/EndedPlansGrid';
import RunningPlansGrid from '../components/Plans/RunningPlansGrid';
import useCases from '../hooks/useCases';
import useTestingAuth from '../hooks/useTestingAuth';
import { PlanState } from '../types';
import { Filter } from '../../components/Home/FilterItems';

export const PLAN_VERTICAL_PADDING = 180;

const TABS: ReadonlyArray<TabProps<PlanState>> = [
  { id: PlanState.ACTIVE, label: 'Active' },
  { id: PlanState.RUNNING, label: 'Running' },
  { id: PlanState.ENDED, label: 'Ended' },
  { id: PlanState.ARCHIVED, label: 'Archived' },
];

const entityName = 'Test Plan';
const entitiesName = 'Test Plans';

const Plans = () => {
  const { persistedView } = useNavState();
  const history = useHistory();
  const location = useLocation();
  const { currentTeamId } = useDatabaseServices();
  const { hasCreatePermission } = useTestingAuth();
  const { createNewTestPlan } = useCases(true);

  const hashToState = (hash: string) => {
    const tab = TABS.find((t) => t.id === hash.slice(1));
    return tab ? tab.id : PlanState.ACTIVE;
  };

  const [state, setState] = useState<PlanState>(hashToState(location.hash));

  useEffect(() => {
    setState(hashToState(location.hash));
  }, [location.hash]);

  const updateTab = useCallback(
    (value: string) => {
      setState(value as PlanState);
      history.push(`${testingPlansPath(currentTeamId)}#${value}`);
    },
    [history, currentTeamId]
  );

  const onNewTestPlan = useCallback(async () => {
    createNewTestPlan();
  }, [createNewTestPlan]);

  const actions = useMemo(() => {
    if (hasCreatePermission) {
      return (
        <div className="flex flex-row gap-x-1">
          <Button type={BUTTON_TYPES.PRIMARY} onClick={onNewTestPlan} title="Create a new test plan" leadingIcon="plus">
            New {entityName}
          </Button>
        </div>
      );
    }
  }, [hasCreatePermission, onNewTestPlan]);

  return (
    <div className="flex flex-col flex-grow px-5">
      <ListHeader
        isLoading={false}
        filters={new Set([Filter.Projects])}
        tagOptions={[]}
        name={entitiesName}
        tabs={TABS}
        navigatedSection={state}
        updateTab={updateTab}
        actions={actions}
      />

      {state === PlanState.ACTIVE && (
        <ActivePlansGrid
          viewTab={persistedView.viewTab}
          searchTerm={persistedView.searchTerm}
          setSearchTerm={persistedView.setSearchTerm}
          selectedProjects={persistedView.selectedProjectIds}
        />
      )}
      {state === PlanState.RUNNING && (
        <RunningPlansGrid
          viewTab={persistedView.viewTab}
          searchTerm={persistedView.searchTerm}
          setSearchTerm={persistedView.setSearchTerm}
          selectedProjects={persistedView.selectedProjectIds}
        />
      )}
      {state === PlanState.ENDED && (
        <EndedPlansGrid
          viewTab={persistedView.viewTab}
          searchTerm={persistedView.searchTerm}
          setSearchTerm={persistedView.setSearchTerm}
          selectedProjects={persistedView.selectedProjectIds}
        />
      )}
      {state === PlanState.ARCHIVED && (
        <ArchivedPlansGrid
          viewTab={persistedView.viewTab}
          searchTerm={persistedView.searchTerm}
          setSearchTerm={persistedView.setSearchTerm}
          selectedProjects={persistedView.selectedProjectIds}
        />
      )}
    </div>
  );
};

export default Plans;
