import cloneDeep from 'lodash.clonedeep';
import { useCallback, useMemo } from 'react';
import { Part } from 'shared/lib/types/postgres/manufacturing/types';
import { BatchStepProps, KitItem, RunPartKitBlock, RunPartKitRecorded } from 'shared/lib/types/views/procedures';
import SubstepNumber from '../../components/SubstepNumber';
import { useRunContext } from '../../contexts/RunContext';
import { generateHiddenClassString } from '../../lib/styles';
import useSpecificParts from '../hooks/useSpecificParts';
import inventoryUtil from '../lib/inventoryUtil';
import { asComponentPart } from '../lib/parts';
import AddPartsButton from './AddPartsButton';
import FieldInputInventoryItem from './FieldInputInventoryItem';
import { headerClass } from './util/tableUtil';

const getPreviousTabRecorded = (itemIndex: number, previousTabContent?: RunPartKitBlock): KitItem | undefined => {
  if (!previousTabContent) {
    return;
  }
  const contentItem = previousTabContent.items[itemIndex];
  return previousTabContent.recorded?.items?.[contentItem.id];
};

type PartKitProps = {
  content: RunPartKitBlock;
  contentIndex: number;
  recorded?: RunPartKitRecorded;
  blockLabel?: string;
  onRecordValuesChanged?: (contentId: string, values: RunPartKitRecorded) => void;
  isEnabled: boolean;
  isHidden: boolean;
  isStepComplete: boolean;
  teamId: string;
  batchProps?: BatchStepProps;
  projectId?: string;
};

const PartKit = ({
  content,
  contentIndex,
  recorded,
  blockLabel,
  onRecordValuesChanged,
  isEnabled,
  isHidden,
  isStepComplete,
  teamId,
  batchProps,
  projectId,
}: PartKitProps) => {
  const { getPreviousStepInBatch } = useRunContext();
  const partIdsInBlock = useMemo(() => inventoryUtil.collectPartIdsInBlock(content, recorded), [content, recorded]);
  const {
    isLoaded: arePartsLoaded,
    getPartByRevisionId,
    getLatestPartRevision,
  } = useSpecificParts({ partIds: partIdsInBlock });

  const previousTabContent = useMemo(() => {
    if (isStepComplete || !batchProps) {
      return;
    }

    const previousTab = getPreviousStepInBatch(batchProps);
    if (!previousTab) {
      return;
    }
    const previousTabContent = previousTab.content[contentIndex];
    if (previousTabContent.type !== 'part_kit' || previousTabContent.items.length !== content.items.length) {
      return;
    }
    return previousTabContent;
  }, [isStepComplete, batchProps, getPreviousStepInBatch, contentIndex, content.items.length]);

  const selectedItemIds: string[] = useMemo(() => {
    const recordedPartKit = recorded as RunPartKitRecorded | undefined;

    const recordedKitItems: KitItem[] = [];
    if (recordedPartKit?.items) {
      recordedKitItems.push(...Object.values(recordedPartKit.items));
    }
    if (recordedPartKit?.added_items) {
      recordedKitItems.push(...recordedPartKit.added_items);
    }

    return recordedKitItems
      .filter((recordedItem) => !!recordedItem.item_id)
      .map((recordedItem) => recordedItem.item_id as string);
  }, [recorded]);

  const recordItemValuesChanged = useCallback(
    (item, values) => {
      const updated = {
        ...recorded,
        items: {
          ...recorded?.items,
          [item.id]: values,
        },
      };
      onRecordValuesChanged?.(content.id, updated);
    },
    [content.id, onRecordValuesChanged, recorded]
  );

  const recordAddedItemValuesChanged = (addedItemIndex, values) => {
    const updated = cloneDeep(recorded);
    updated.added_items[addedItemIndex] = values;
    onRecordValuesChanged?.(content.id, updated);
  };

  const onPushParts = (parts: Array<Part>) => {
    const updated = recorded ? cloneDeep(recorded) : {};
    updated.items = updated.items || {};
    updated.added_items = updated.added_items || [];
    parts.forEach((part) => {
      const component = asComponentPart(part, 1);
      updated.added_items.push({ ...component, item_id: null });
    });
    onRecordValuesChanged?.(content.id, updated);
  };

  const onRemoveAddedItem = useCallback(
    (addedItemIndex) => {
      const updated = cloneDeep(recorded);
      updated.added_items = updated.added_items.filter((_, index) => {
        return index !== addedItemIndex;
      });
      onRecordValuesChanged?.(content.id, updated);
    },
    [content.id, onRecordValuesChanged, recorded]
  );

  if (!arePartsLoaded) {
    return null;
  }

  return (
    <tr>
      <td></td>
      <td colSpan={2}>
        <div className={generateHiddenClassString('', isHidden)} />
        <div className={generateHiddenClassString('mt-3 ml-4 flex flex-wrap page-break', isHidden)}>
          <SubstepNumber blockLabel={blockLabel} hasExtraVerticalSpacing={false} />
          <div className="flex flex-col items-start w-full py-1 mr-8">
            <table className="w-full">
              <thead>
                <tr>
                  <td className={`${headerClass} w-7/12`}>Parts for Check-Out</td>
                  <td className={`${headerClass} w-2/12`}>Quantity</td>
                  <td className={`${headerClass} w-3/12`}>Inventory</td>
                  {/* Placeholder for remove button */}
                  <td className="w-5"></td>
                </tr>
              </thead>
              <tbody>
                {content?.items.map((item, index) => {
                  const part = getPartByRevisionId(item.revision_id ?? '') ?? getLatestPartRevision(item.part_id);
                  return (
                    <FieldInputInventoryItem
                      key={index}
                      item={item}
                      recorded={recorded?.items?.[item.id]}
                      part={part}
                      isEnabled={isEnabled}
                      teamId={teamId}
                      onRecordValuesChanged={(values) => recordItemValuesChanged(item, values)}
                      isStepComplete={isStepComplete}
                      selectedItemIds={selectedItemIds}
                      previousTabRecorded={getPreviousTabRecorded(index, previousTabContent)}
                    />
                  );
                })}
                {recorded?.added_items?.map((item, index) => {
                  const part = getPartByRevisionId(item.revision_id ?? '') ?? getLatestPartRevision(item.part_id);
                  return (
                    <FieldInputInventoryItem
                      key={index}
                      item={item}
                      recorded={item}
                      part={part}
                      isEnabled={isEnabled}
                      teamId={teamId}
                      onRecordValuesChanged={(values) => recordAddedItemValuesChanged(index, values)}
                      isStepComplete={isStepComplete}
                      selectedItemIds={selectedItemIds}
                      onRemove={() => onRemoveAddedItem(index)}
                    />
                  );
                })}
              </tbody>
            </table>
            {!isStepComplete && isEnabled && <AddPartsButton projectId={projectId} onAddParts={onPushParts} />}
          </div>
        </div>
      </td>
    </tr>
  );
};

export default PartKit;
