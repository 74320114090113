import { Projects } from './types/couch/settings';

export const getProjectCode = (
  projects: Projects | null,
  projectId?: string
): string | undefined => {
  if (!projectId) {
    return undefined;
  }
  return projects?.projects?.[projectId]?.code;
};

export const getProjectPathCode = ({
  projects,
  projectId,
  parentId,
  suffix,
}: {
  projects: Projects | null;
  projectId?: string;
  parentId?: string;
  suffix?: string;
}): string => {
  const projectParentCode = getProjectCode(projects, parentId);
  const projectCode = getProjectCode(projects, projectId);
  return [projectParentCode, projectCode, suffix]
    .filter((a) => Boolean(a))
    .join('-');
};
