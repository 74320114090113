import { Tag as GlobalTag } from 'shared/lib/types/api/settings/tags/models';
import Tag from '../../Tag';
import { useCallback } from 'react';
import TooltipOverlay from '../../TooltipOverlay';

const MAX_VISIBLE_TAGS = 3;

type TagsDisplayProps = {
  tags: Array<GlobalTag>;
  onClick?: (tag: GlobalTag) => Promise<void> | void;
  isClickable?: boolean;
  maxVisibleTags?: number;
};

const TagsDisplay = ({ tags, onClick, isClickable = false, maxVisibleTags = MAX_VISIBLE_TAGS }: TagsDisplayProps) => {
  const showTooltip = tags.length > maxVisibleTags;
  const visibleTags = showTooltip ? tags.slice(0, maxVisibleTags) : tags;
  const hiddenTags = showTooltip ? tags.slice(maxVisibleTags) : [];

  const getTagProps = useCallback(
    (tag: GlobalTag) => ({
      key: tag.id,
      id: tag.id,
      name: tag.name,
      color: tag.color,
      description: tag.description,
      ...(onClick && { onClick: () => onClick(tag) }),
      ...(!onClick &&
        isClickable && {
          onClick: () => {
            /* */
          },
        }),
    }),
    [onClick, isClickable]
  );

  return (
    <div className="flex flex-row flex-wrap items-center">
      {visibleTags.map((tag) => (
        <Tag {...getTagProps(tag)} />
      ))}

      {showTooltip && (
        <TooltipOverlay
          content={
            <div className="flex flex-row">
              {hiddenTags.map((tag) => (
                <Tag {...getTagProps(tag)} />
              ))}
            </div>
          }
          delayClose={true}
        >
          <span className="ml-1 cursor-pointer text-gray-500 hover:text-gray-700">...</span>
        </TooltipOverlay>
      )}
    </div>
  );
};

export default TagsDisplay;
