import { isEqual } from 'lodash';
import { useCallback, useEffect, useMemo, useState } from 'react';
import runUtil from 'shared/lib/runUtil';
import { StepState } from 'shared/lib/types/couch/procedures';
import { UsageTypes } from 'shared/lib/types/postgres/manufacturing/usage_types';
import { RunToolUsageBlock } from 'shared/lib/types/views/procedures';
import SubstepNumber from '../../../components/SubstepNumber';
import { NumberSelectOption as SelectOption } from '../../../lib/formik';
import isNumber from '../../../lib/number';
import { generateHiddenClassString } from '../../../lib/styles';
import useTool from '../../hooks/useTool';
import useUsageTypes from '../../hooks/useUsageTypes';
import { getDisplayTextForTimeBasedUsage } from '../../lib/usage';
import ToolUsageBase, { ContentErrors } from './ToolUsageBase';

const INVALID_CYCLES_ERROR = 'Enter a non-negative number';
const INVALID_TIME_ERROR = 'Enter a duration in HH:mm:ss format';

const HHmmss = /^(?:(?:([01]?\d|2[0-3]):)?([0-5]?\d):)?([0-5]?\d)$/;

interface RunToolUsageProps {
  content: RunToolUsageBlock;
  errors?: ContentErrors;
  onRecordValuesChanged?: (contentId: string, recorded: RunToolUsageBlock['recorded']) => void;
  onRecordErrorsChanged?: (errors: ContentErrors) => void;
  recorded?: RunToolUsageBlock['recorded'];
  isEnabled: boolean;
  stepState?: StepState | undefined;
  teamId: string;
  isHidden?: boolean;
  blockLabel?: string;
}

const RunToolUsage = ({
  content,
  errors,
  onRecordValuesChanged,
  onRecordErrorsChanged,
  recorded,
  isEnabled,
  stepState,
  teamId,
  isHidden,
  blockLabel,
}: RunToolUsageProps) => {
  const { tool } = useTool({ toolId: recorded?.tool_id ?? content.tool_id });
  const { usageTypes } = useUsageTypes();
  // captures what is input into the value text field
  const [usageValue, setUsageValue] = useState<string>();

  const value = useMemo(() => recorded ?? content, [content, recorded]);
  const selectedUsageType = usageTypes?.find((usageType) => usageType.id === value.usage_type_id);

  const isStepStateEnded = useMemo(() => runUtil.isStepStateEnded(stepState), [stepState]);
  const isStepRecorded = useMemo(
    () =>
      isStepStateEnded &&
      stepState !== undefined &&
      ['completed', 'failed'].includes(stepState) &&
      recorded?.value !== undefined,
    [isStepStateEnded, recorded?.value, stepState]
  );

  useEffect(() => {
    setUsageValue(recorded?.value ? formatValue(recorded.value, value.usage_type_id) : '');
  }, [recorded?.value, value.usage_type_id]);

  const onToolInstanceChange = useCallback(
    (toolInstanceOption: SelectOption) => {
      const newValue: RunToolUsageBlock['recorded'] = {
        ...value,
        tool_instance_id: toolInstanceOption?.value,
      };
      if (onRecordValuesChanged) {
        onRecordValuesChanged(value.id, newValue);
      }
    },
    [onRecordValuesChanged, value]
  );

  const checkErrors = (usageValue: string): void => {
    if (!onRecordErrorsChanged || !usageValue) {
      return;
    }
    let newError = {};
    if (value.usage_type_id === UsageTypes.Cycles) {
      if (!isNumber(usageValue) || Number(usageValue) < 0) {
        newError = { value: INVALID_CYCLES_ERROR };
      }
    } else {
      const match = usageValue.match(HHmmss);
      if (match === null) {
        newError = { value: INVALID_TIME_ERROR };
      }
    }
    if (!isEqual(errors, newError)) {
      onRecordErrorsChanged(newError);
    }
  };

  const parseSeconds = (usageValue: string) => {
    const match = usageValue.match(HHmmss);
    if (match === null) {
      return null;
    }
    return Number(match[1] ?? 0) * 3600 + Number(match[2] ?? 0) * 60 + Number(match[3]);
  };

  const toHuman = (seconds: number) => getDisplayTextForTimeBasedUsage(seconds);

  const onUsageValueChange = useCallback(
    (newUsageValue: string) => {
      const newValue: RunToolUsageBlock['recorded'] = {
        ...value,
      };
      if (newUsageValue.length > 0) {
        if (value.usage_type_id === UsageTypes.Cycles) {
          const cycles = parseInt(newUsageValue);
          if (!isNaN(cycles)) {
            newValue.value = cycles;
          }
        } else {
          const parsedSeconds = parseSeconds(newUsageValue);
          if (parsedSeconds) {
            newValue.value = parsedSeconds;
            setUsageValue(formatValue(parsedSeconds, value.usage_type_id));
          }
        }
      } else {
        newValue.value = undefined;
      }
      if (onRecordValuesChanged) {
        onRecordValuesChanged(value.id, newValue);
      }
    },
    [onRecordValuesChanged, value]
  );

  const formatValue = (value: number, usageTypeId: number) => {
    if (usageTypeId === 1) {
      return value.toString();
    }

    const HH = Math.floor(value / 3600);
    const mm = Math.floor((value % 3600) / 60);
    const ss = value % 60;

    const format = (n: number) => (n < 10 ? `0${n}` : `${n}`);
    if (HH === 0) {
      if (mm === 0) {
        return `${format(ss)}`;
      }
      return `${format(mm)}:${format(ss)}`;
    }
    return `${format(HH)}:${format(mm)}:${format(ss)}`;
  };

  const displayValue = useMemo(() => {
    // step is complete, format recorded value
    if (isStepStateEnded && recorded?.value) {
      return recorded.usage_type_id === UsageTypes.Cycles ? `${recorded.value} cycles` : toHuman(recorded.value);
    }

    // step is active and being edited, return current value
    if (usageValue !== undefined) {
      return usageValue;
    }

    if (!recorded?.value) {
      return '';
    }

    // handle page reloads or navigating away from the run and returning; display value can only be set from recorded value
    return formatValue(recorded.value, value.usage_type_id);
  }, [recorded, isStepStateEnded, usageValue, value.usage_type_id]);

  // this is displayed as helper text on the right side of the text input if the step is still active
  const computedValue = useMemo(() => {
    if (isStepStateEnded || !displayValue) {
      return;
    }
    if (value.usage_type_id === UsageTypes.Cycles) {
      return `${displayValue} cycles`;
    }

    const seconds = parseSeconds(displayValue);
    if (!seconds) {
      return;
    }

    return toHuman(seconds);
  }, [isStepStateEnded, displayValue, value.usage_type_id]);

  if (!tool || !selectedUsageType) {
    return null;
  }

  return (
    <>
      <tr>
        <td></td>
        <td colSpan={2}>
          <div className={generateHiddenClassString('', isHidden)} />
          <div className={generateHiddenClassString('mt-3 ml-4 flex flex-wrap page-break', isHidden)}>
            <SubstepNumber blockLabel={blockLabel} hasExtraVerticalSpacing={false} />
            <div className="w-full py-1 mr-8">
              <ToolUsageBase
                tool={tool}
                toolInstanceId={recorded?.tool_instance_id}
                usageValue={displayValue}
                usageType={value.usage_type_id}
                computedValue={computedValue}
                teamId={teamId}
                isStepComplete={isStepStateEnded}
                isStepRecorded={isStepRecorded}
                isEnabled={isEnabled}
                onToolInstanceChange={onToolInstanceChange}
                onUsageChange={(newValue: string) => {
                  checkErrors(newValue);
                  setUsageValue(newValue);
                }}
                onUsageBlur={(newValue: string) => {
                  onUsageValueChange(newValue);
                }}
                errors={errors}
              />
            </div>
          </div>
        </td>
      </tr>
    </>
  );
};

export default RunToolUsage;
