import { faTimesCircle } from '@fortawesome/free-solid-svg-icons';
import { PartBuildItem } from 'shared/lib/types/views/procedures';
import Button, { BUTTON_TYPES } from '../../components/Button';
import UnitDisplay from '../../components/Settings/Units/UnitDisplay';
import { StyleVariant } from '../../elements/lib/fieldStyles';
import { isPartRestricted } from '../lib/parts';
import ItemQuantityInput from './ItemQuantityInput';
import PartBadge from './PartBadge';
import RestrictedInfo, { RESTRICTED_TEXT } from './RestrictedInfo';
import { cellClass, getCellClass } from './util/tableUtil';
import useSpecificParts from '../hooks/useSpecificParts';

interface ComponentBuildItemProps {
  component: PartBuildItem;
  teamId: string;
  highlightStyle?: string;
  isEnabled?: boolean;
  onRemove?: (component: PartBuildItem) => void;
  onUpdateAmount?: (itemId: string, amount: number) => void;
  onUpdateRev?: (itemId: string, revisionLabel: string, revisionId?: string) => void;
}

const ComponentBuildItem = ({
  component,
  teamId,
  highlightStyle,
  isEnabled,
  onRemove,
  onUpdateAmount,
  onUpdateRev,
}: ComponentBuildItemProps) => {
  const { amount, part_id, revision_id } = component;
  const { getPartByRevisionId, getLatestPartRevision } = useSpecificParts({ partIds: [part_id] });
  const part = getPartByRevisionId(revision_id ?? '') ?? getLatestPartRevision(part_id);
  const partRestricted = isPartRestricted(part);

  const updateAmount = (newAmount: number | null) => {
    // ItemQuantityInput has allowNull false so this should never be null
    if (newAmount === null) return;
    onUpdateAmount?.(component.id, newAmount as number);
  };

  if (partRestricted) {
    return (
      <tr>
        <td className={`${cellClass} ${highlightStyle} p-1`}>
          <RestrictedInfo text={RESTRICTED_TEXT} />
        </td>
        <td className={`${cellClass} p-1`}>
          <RestrictedInfo />
        </td>
      </tr>
    );
  }

  return (
    <tr>
      <td className={`${cellClass} ${highlightStyle} p-1 w-9/12`}>
        <PartBadge
          inline={true}
          part={part}
          teamId={teamId}
          onRevChange={
            onUpdateRev
              ? (revisionLabel, revisionId) => onUpdateRev(component.id, revisionLabel, revisionId)
              : undefined
          }
        />
      </td>
      <td className={`${getCellClass(!isEnabled)} w-3/12`}>
        <div className="flex flex-row items-center">
          <ItemQuantityInput
            value={amount}
            allowDecimalValue={part?.allow_decimal_quantities === true}
            allowNull={false}
            onChange={updateAmount}
            disabled={!isEnabled}
            min={0}
            max={part?.tracking === 'serial' ? 1 : undefined}
            placeholder="Quantity"
            textSize="sm"
            variant={StyleVariant.Grid}
          />
          {part?.units && (
            <div className="mx-1">
              <UnitDisplay unit={part.units} />
            </div>
          )}
        </div>
      </td>
      <td className="w-5">
        {onRemove && (
          <Button
            leadingIcon={faTimesCircle}
            iconTextColor="text-gray-400"
            type={BUTTON_TYPES.TERTIARY}
            onClick={() => onRemove(component)}
          />
        )}
      </td>
    </tr>
  );
};

export default ComponentBuildItem;
