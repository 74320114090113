import { Project } from 'shared/lib/types/couch/settings';
import { UserFormUser } from '../Settings/types';
import { useMemo } from 'react';
import RolePill from './RolePill';
import userUtil from '../../lib/userUtil';
import Button from '../Button';

interface UserProjectRoleProps {
  row: UserFormUser;
  projects: Array<Project>;
  defaultAccess?: string;
  expanded?: boolean;
  setExpanded: (current: boolean) => void;
  showProjectNames?: boolean;
  showPillView?: boolean;
  abilityToExpand?: boolean;
}

const UserProjectRoles = ({
  row,
  projects,
  defaultAccess,
  expanded = true,
  setExpanded,
  showProjectNames = true,
  showPillView = true,
  abilityToExpand = true,
}: UserProjectRoleProps) => {
  const hasUniqProjectRole = useMemo(
    () => userUtil.hasUniqProjectRole(row.project_roles, projects),
    [projects, row.project_roles]
  );

  const uniqProjectRole = useMemo(
    () => (row.project_roles ? Object.values(row.project_roles)[0][0]?.name : undefined),
    [row.project_roles]
  );

  const sortedProjectRoles = useMemo(() => {
    return projects
      .sort((project1, project2) => {
        if (row.project_roles?.[project1.id]?.[0]?.name !== row.project_roles?.[project2.id]?.[0]?.name) {
          return (
            row.project_roles?.[project1.id]?.[0]?.name.localeCompare(row.project_roles?.[project2.id]?.[0]?.name) || 0
          );
        }
        return (
          projects
            .find((project) => project.id === project1.id)
            ?.name?.localeCompare(projects.find((project) => project.id === project2.id)?.name || '') || 0
        );
      })
      .map((project) => project.id);
  }, [projects, row.project_roles]);

  return (
    <div className="leading-6 group">
      {abilityToExpand && (
        <div className="absolute hidden group-hover:block right-0 top-0">
          <Button
            type="tertiary"
            leadingIcon={expanded ? 'down-left-and-up-right-to-center' : 'expand-alt'}
            onClick={() => setExpanded(expanded)}
          />
        </div>
      )}
      {hasUniqProjectRole && (
        <RolePill defaultAccess={row.workspace_role} role={uniqProjectRole} showPillView={showPillView} />
      )}
      {!hasUniqProjectRole && (
        <>
          {!expanded && <>...</>}
          {expanded &&
            sortedProjectRoles.map(
              (projectId) =>
                row.project_roles?.[projectId] && (
                  <div key={projectId}>
                    {showProjectNames && projects.find((project) => project.id === projectId)?.name}

                    <span className="ml-2">
                      <RolePill
                        defaultAccess={row.workspace_role}
                        role={row.project_roles?.[projectId]?.[0]?.name ?? defaultAccess}
                        showPillView={showPillView}
                      />
                    </span>
                  </div>
                )
            )}
        </>
      )}
    </div>
  );
};

export default UserProjectRoles;
