import { Part } from 'shared/lib/types/postgres/manufacturing/types';
import RunControlledInput from '../../elements/RunControlledInput';
import ItemSelect from './ItemSelect';
import PartBadge from './PartBadge';
import { Item } from '../types';
import { StyleVariant } from '../../elements/lib/fieldStyles';
import RestrictedInfo, { RESTRICTED_TEXT } from './RestrictedInfo';
import { cellClass, getCellClass, headerClass } from './util/tableUtil';

interface InventoryDetailBaseProps {
  part?: Part | null;
  detailName: string;
  detailValue: string;
  itemId?: string;
  isEnabled?: boolean;
  isRestricted?: boolean;
  handleChangeItem?: (item: Item) => void;
  updateDetailValue?: (value: string) => void;
  teamId: string;
}

const InventoryDetailBase = ({
  part,
  detailName,
  detailValue,
  itemId,
  isEnabled,
  isRestricted,
  handleChangeItem,
  updateDetailValue,
  teamId,
}: InventoryDetailBaseProps) => {
  if (isRestricted) {
    return <RestrictedInfo text={RESTRICTED_TEXT} />;
  }

  return (
    <table className="w-full">
      <thead>
        <tr>
          <td className={`${headerClass} w-6/12`}>Update Part Detail</td>
          <td className={`${headerClass} w-3/12`}>Serial / Lot #</td>
          <td className={`${headerClass} w-3/12`}>{detailName}</td>
        </tr>
      </thead>
      <tbody>
        <tr>
          <td className={`${cellClass} p-1`}>{part && <PartBadge inline={true} part={part} teamId={teamId} />}</td>
          <td className={getCellClass(!isEnabled)}>
            <ItemSelect
              itemId={itemId}
              partId={part?.id}
              partRevision={part?.rev}
              onChangeItem={handleChangeItem}
              isDisabled={!isEnabled}
              includeInactive={true}
              variant={StyleVariant.Grid}
            />
          </td>
          <td className={getCellClass(!isEnabled)}>
            <div className="flex flex-row items-center">
              <RunControlledInput
                type="text"
                recorded={detailValue}
                aria-label="Enter Value"
                className="border-0 disabled:bg-gray-100 w-full text-sm"
                onRecordValue={updateDetailValue}
                disabled={!isEnabled}
              />
            </div>
          </td>
        </tr>
      </tbody>
    </table>
  );
};

export default InventoryDetailBase;
