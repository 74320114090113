import { useState } from 'react';
import { useNavState } from '../../contexts/NavContext';
import { useSettings } from '../../contexts/SettingsContext';
import Icon from '../../elements/Icon';
import FieldLabel from '../../elements/internal/FieldLabel';
import {
  faFolderClosed,
  faAngleDown,
  faAngleRight,
  faFolderOpen as fasFolderOpen,
  faFolderClosed as fasFolderClosed,
} from '@fortawesome/free-solid-svg-icons';
import { getHashColor } from '../../lib/hashUtil';
import { TreeNode } from 'primereact/treenode';
import {
  TreeSelect,
  TreeSelectEventNodeEvent,
  TreeSelectExpandedEvent,
  TreeSelectExpandedKeysType,
} from 'primereact/treeselect';
import { TreeTogglerTemplateOptions } from 'primereact/tree';
import { ALL_PROJECTS, getProjectsTree } from '../../lib/projectTreeUtil';
import projectUtil from '../../lib/projectUtil';

const NavItemProjectSelector = () => {
  const navState = useNavState();
  const projectId = navState.projectsFilter?.project.id || ALL_PROJECTS;
  const projectName = navState.projectsFilter?.project.name || ALL_PROJECTS;

  const { projects: projectsDoc } = useSettings();
  const projects = projectUtil.toProjectsArray(projectsDoc);
  const options = getProjectsTree(projects);

  const [expandedKeys, setExpandedKeys] = useState<TreeSelectExpandedKeysType>({ [ALL_PROJECTS]: true });

  const onNodeSelect = (e: TreeSelectEventNodeEvent): void => {
    navState.persistedView.setSelectedProjectIds(new Set());
    if (e.node.id === ALL_PROJECTS) {
      navState.setProjectId();
      return;
    }
    navState.setProjectId(e.node.id);
  };

  const onToggle = (e: TreeSelectExpandedEvent): void => {
    setExpandedKeys(e.value);
  };

  const nodeTemplate = (node: TreeNode) => {
    const hasChildren = node.children && node.children.length > 0;
    return (
      <div data-pc-section="selector-node" className={`${hasChildren ? '' : 'pl-2'} flex flex-col leading-4`}>
        <div className="h-8 flex flex-row items-center gap-x-2">
          {!hasChildren && <Icon element={faFolderClosed} className="text-gray-600" />}
          {node.label}
        </div>
      </div>
    );
  };

  const valueTemplate = () => {
    if (navState.isCollapsed) {
      return collapsedValueTemplate();
    }
    return expandedValueTemplate();
  };

  const collapsedValueTemplate = () => {
    const title = projectName === ALL_PROJECTS ? undefined : projectName;
    const showCircle = projectId !== ALL_PROJECTS;

    return (
      <div title={title}>
        <Icon element={faFolderClosed} className="p-1 fa-fw fa-lg" />
        {showCircle && <CircleWithColorAndLetter label={projectName} />}
      </div>
    );
  };

  const expandedValueTemplate = () => {
    const showCircle = projectId !== ALL_PROJECTS;
    return (
      <div className="flex items-center gap-x-2">
        <div className="flex items-center">
          <Icon element={faFolderClosed} />
          {showCircle && <CircleWithColor label={projectName} />}
        </div>
        <div>{projectName}</div>
      </div>
    );
  };

  const togglerTemplate = (node: TreeNode, options: TreeTogglerTemplateOptions) => {
    if (node.children?.length) {
      return (
        <div className="flex flex-row items-center gap-x-1 mr-1 ">
          <div
            role="button"
            aria-expanded={options.expanded}
            data-pc-section="triggericon"
            aria-label={`toggle-${node.label}`}
            className="flex items-center justify-center justify-items-center h-8 w-6 hover:bg-gray-200 rounded"
            onClick={options.onClick}
          >
            <ExpandCollapseIcon expanded={options.expanded} />
          </div>
          {options.expanded && <Icon element={fasFolderOpen} />}
          {!options.expanded && <Icon element={fasFolderClosed} />}
        </div>
      );
    }
  };

  return (
    <div aria-label="Nav Project Selector" className={`py-1 ${navState.isCollapsed ? 'px-1' : 'px-4'}`}>
      {!navState.isCollapsed && <FieldLabel label="Project" />}
      <TreeSelect
        data-testid="Project Context Selector"
        className="text-white bg-slate-800"
        options={options}
        onNodeSelect={onNodeSelect}
        value={projectId}
        valueTemplate={valueTemplate}
        nodeTemplate={nodeTemplate}
        togglerTemplate={togglerTemplate}
        expandedKeys={expandedKeys}
        onToggle={onToggle}
        pt={{
          root: { className: `shadow-none hover:bg-slate-700 ${navState.isCollapsed ? '!border-none' : '!h-[30px]'}` },
          panel: { className: `w-64 break-all overflow-y-auto unobtrusive-sidebar` },
          trigger: { className: navState.isCollapsed ? 'hidden' : '' },
          hiddenInputWrapper: { className: navState.isCollapsed ? 'hidden' : '' },
        }}
        filter
        resetFilterOnHide
      />
    </div>
  );
};

type CircleWithColorProps = {
  label: string;
};

const CircleWithColor = ({ label }: CircleWithColorProps) => {
  const color = getHashColor(label, true);
  return <div className={`relative mt-[5px] ml-[-6px] h-2 w-2 rounded-full border border-white ${color}`} />;
};

type CircleWithColorAndLetterProps = {
  label: string;
};

const CircleWithColorAndLetter = ({ label }: CircleWithColorAndLetterProps) => {
  const color = getHashColor(label, true);
  const letter = label.charAt(0);

  return (
    <div
      className={`flex flex-col items-center relative mt-[-14px] ml-3 h-4 w-4 font-bold leading-3 pt-[1px] text-xxs rounded-full border border-white ${color}`}
    >
      {letter.toUpperCase()}
    </div>
  );
};

interface ExpandCollapseIconProps {
  expanded: boolean;
}

const ExpandCollapseIcon = ({ expanded }: ExpandCollapseIconProps) => {
  if (expanded) {
    return <Icon element={faAngleDown} />;
  }
  return <Icon element={faAngleRight} />;
};

export default NavItemProjectSelector;
