import { useRef } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Tooltip from '../../../elements/Tooltip';
import RevisionSelect from './RevisionSelect';
import { PartRevisionSummary, ReleaseState } from 'shared/lib/types/postgres/manufacturing/types';
import { OverlayPanel } from 'primereact/overlaypanel';

type RevisionHistoryProps = {
  revision: number | string;
  state: ReleaseState;
  /** The currently selected revision's approval status, if {@link RevisionHistoryProps.state} is `'in_review'` */
  isApproved?: boolean;
  onLatestRevision?: boolean;
  revisions?: PartRevisionSummary[];
  onChange?: (revisionId) => void;
};

const RevisionHistory = ({
  revision,
  state,
  isApproved,
  onLatestRevision,
  revisions,
  onChange,
}: RevisionHistoryProps) => {
  const op = useRef<OverlayPanel>(null);

  if (!revision && (!revisions || revisions.length <= 1)) {
    return null;
  }

  const stateStr = (() => {
    switch (state) {
      case 'draft':
        return 'Draft';
      case 'in_review':
        return isApproved ? 'Ready to Release' : 'In Review';
      case 'released':
        return '';
    }
  })();

  return (
    <button
      className={`flex flex-row items-center text-sm ${
        state === 'draft'
          ? 'bg-gray-200'
          : state === 'in_review'
          ? isApproved
            ? 'bg-emerald-100'
            : 'bg-yellow-100'
          : 'bg-white'
      } border ${
        state === 'draft'
          ? 'border-gray-300'
          : state === 'in_review'
          ? isApproved
            ? 'border-emerald-300'
            : 'border-yellow-300'
          : 'border-gray-200'
      } rounded-3xl ml-2 px-1.5`}
      onClick={(e) => op?.current?.toggle(e)}
    >
      {!onLatestRevision && (
        <Tooltip content="You are viewing an older revision.">
          <FontAwesomeIcon className="mr-1" icon="circle-exclamation" data-testid="old_revision_notice_icon" />
        </Tooltip>
      )}
      {revision && (
        <div className="whitespace-nowrap">
          Rev {revision}
          {stateStr && ` (${stateStr})`}
        </div>
      )}
      {revisions && revisions.length > 1 && (
        <>
          <FontAwesomeIcon icon="caret-down" className="text-sm ml-1" />
          <OverlayPanel className="p-0" ref={op}>
            <RevisionSelect options={revisions} onSelect={onChange} />
          </OverlayPanel>
        </>
      )}
    </button>
  );
};

export default RevisionHistory;
