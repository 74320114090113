import { FontAwesomeIcon, FontAwesomeIconProps } from '@fortawesome/react-fontawesome';
import pluralize from 'pluralize';
import { useMemo } from 'react';
import { ToolMaintenance } from 'shared/lib/manufacturing/tools/maintenance';
import { Tool, ToolInstance } from 'shared/lib/types/api/manufacturing/tools/models';
import Tooltip from '../../../elements/Tooltip';
import useRunNumber from '../../../hooks/useRunNumber';
import RunLabel from '../../../components/RunLabel';

const CheckedOutStatus = ({ toolInstance }) => {
  const { runNumber } = useRunNumber(toolInstance.checked_out?.run_id);

  const ariaLabel = useMemo(
    () =>
      toolInstance.checked_out
        ? `Tool is checked out in ${toolInstance.checked_out.procedure_code} ${runNumber ? `- ${runNumber}` : ``}`
        : undefined,
    [runNumber, toolInstance.checked_out]
  );

  return (
    <Tooltip
      content={<RunLabel code={toolInstance.checked_out.procedure_code} runNumber={runNumber} runNumberBg="medium" />}
    >
      <FontAwesomeIcon icon="upload" className="text-slate-600" aria-label={ariaLabel} />
    </Tooltip>
  );
};

type StatusIconsProps = {
  tool: Tool;
  toolInstance: ToolInstance;
  showCheckedOutStatus?: boolean;
};

const StatusIcons = ({ tool, toolInstance, showCheckedOutStatus = true }: StatusIconsProps) => {
  const maintenanceInfo: { icon: FontAwesomeIconProps; tooltip: string } | null = useMemo(() => {
    if (tool.maintenance_interval_days) {
      const maintenance = ToolMaintenance.from(toolInstance, tool);
      if (maintenance.approaching) {
        return {
          icon: { icon: 'screwdriver-wrench', className: 'text-slate-600' },
          tooltip: `Maintenance due in ${pluralize('day', maintenance.dueIn, true)}`,
        };
      } else if (maintenance.overdue) {
        return {
          icon: { icon: 'screwdriver-wrench', className: 'text-red-600' },
          tooltip: `Maintenance overdue by ${pluralize('day', maintenance.overdueBy, true)}`,
        };
      }
    }
    return null;
  }, [tool, toolInstance]);

  return (
    <div className="flex flex-row gap-x-2">
      {toolInstance.serial_number}
      {maintenanceInfo && (
        <Tooltip content={maintenanceInfo.tooltip}>
          <FontAwesomeIcon {...maintenanceInfo.icon} aria-label={maintenanceInfo.tooltip} />
        </Tooltip>
      )}
      {showCheckedOutStatus && toolInstance.checked_out && <CheckedOutStatus toolInstance={toolInstance} />}
    </div>
  );
};

export default StatusIcons;
