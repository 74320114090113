import { Procedure } from 'shared/lib/types/views/procedures';
import { useSettings } from '../contexts/SettingsContext';
import { useCallback, useMemo } from 'react';
import { useDatabaseServices } from '../contexts/DatabaseContext';
import { getProjectPathCode } from 'shared/lib/projectUtil';

export const ID_PLACEHOLDER_SUFFIX = '###';

interface UseAutoProcedureIdReturnType {
  generatePlaceholderId: ({
    projectId,
    parentId,
  }: {
    projectId?: string;
    parentId?: string;
  }) => string;
  tryUpdateDocWithUniqueId: (
    doc: Procedure,
    forceUpdate?: boolean
  ) => Promise<void>;
  isAutoProcedureIdEnabled: boolean;
}

const useAutoProcedureId = (
  procedure?: Procedure
): UseAutoProcedureIdReturnType => {
  const { projects, config } = useSettings();
  const { services } = useDatabaseServices();

  const isAutoProcedureIdEnabled = useMemo(
    () =>
      Boolean(
        config?.auto_procedure_id_enabled &&
          (procedure ? procedure.auto_procedure_id_enabled : true)
      ),
    [config?.auto_procedure_id_enabled, procedure]
  );

  const generatePlaceholderId = useCallback(
    ({ projectId, parentId }: { projectId?: string; parentId?: string }) => {
      if (!isAutoProcedureIdEnabled) {
        return '';
      }
      return getProjectPathCode({
        projects,
        projectId,
        parentId,
        suffix: ID_PLACEHOLDER_SUFFIX,
      });
    },
    [projects, isAutoProcedureIdEnabled]
  );

  const tryUpdateDocWithUniqueId = useCallback(
    async (doc: Procedure, forceUpdate = false) => {
      if (!doc) {
        return;
      }
      if (
        config?.auto_procedure_id_enabled &&
        doc.auto_procedure_id_enabled &&
        (forceUpdate || doc.code?.endsWith(ID_PLACEHOLDER_SUFFIX))
      ) {
        try {
          doc.code = await services.procedures.getUniqueProcedureId(
            doc.project_id
          );
        } catch {
          /* Keep placeholder ID */
        }
      }
    },
    [config?.auto_procedure_id_enabled, services.procedures]
  );

  return {
    generatePlaceholderId,
    tryUpdateDocWithUniqueId,
    isAutoProcedureIdEnabled,
  };
};

export default useAutoProcedureId;
