import localforage from 'localforage';
import {
  configureStore,
  combineReducers,
  PreloadedState,
  StoreEnhancer,
} from '@reduxjs/toolkit';
import {
  createStateSyncMiddleware,
  initMessageListener,
} from 'redux-state-sync';
import createSagaMiddleware from 'redux-saga';
import usersReducer from '../contexts/usersSlice';
import settingsReducer from '../contexts/settingsSlice';
import runsReducer from '../contexts/runsSlice';
import proceduresReducer, {
  COPY_ITEM_TO_CLIPBOARD,
} from '../contexts/proceduresSlice';
import { offline } from '@redux-offline/redux-offline';
import { Config } from '@redux-offline/redux-offline/lib/types';
import defaultOfflineConfig from '@redux-offline/redux-offline/lib/defaults';
import { retry, discard } from './offline';
import proceduresSaga from './sagas';
import { offlineEffects } from '../effects/offlineEffects';

// Retrieve type of property 'persistCallback' in the Config type.
type PersistCallback = Config['persistCallback'];

export type Store = ReturnType<typeof configureStore>;
type StoreResults = {
  store: Store;
};

// Action type for resetting the entire redux store.
export const RESET_APP_STATE = 'RESET_APP_STATE';

const MIDDLEWARE_CHECK_WARN_AFTER_MS = 256;

const appReducer = combineReducers({
  runs: runsReducer,
  settings: settingsReducer,
  users: usersReducer,
  procedures: proceduresReducer,
});

const rootReducer = (state, action) => {
  if (action.type === RESET_APP_STATE) {
    return appReducer(undefined, action);
  }

  return appReducer(state, action);
};

// Extract root state automatically from root reducer.
export type RootState = ReturnType<typeof rootReducer>;

const sagaMiddleware = createSagaMiddleware();

const stateSyncConfig = {
  whitelist: [COPY_ITEM_TO_CLIPBOARD],
  broadcastChannelOption: { type: 'native' },
};
let stateSyncMiddleware = undefined;
try {
  stateSyncMiddleware = createStateSyncMiddleware(stateSyncConfig);
} catch (e) {
  // This is to catch error thrown in tests where createStateSyncMiddleware does not work.
}

// Use concat for better typescript support (See @reduxjs/toolkit docs).
const middleware = (getDefaultMiddleware) => {
  const middlewares = getDefaultMiddleware({
    immutableCheck: { warnAfter: MIDDLEWARE_CHECK_WARN_AFTER_MS },
    serializableCheck: { warnAfter: MIDDLEWARE_CHECK_WARN_AFTER_MS },
  }).concat(sagaMiddleware);
  if (stateSyncMiddleware) {
    return middlewares.concat(stateSyncMiddleware);
  } else {
    return middlewares;
  }
};

/**
 * TODO: See if redux-offline has better support for custom effects with slices.
 * For now, use runs effect/discard as global effect/discard.
 */
const offlineEffect = offlineEffects;

const configureStoreWithCallback = (
  persistCallback?: PersistCallback,
  preloadedState?: PreloadedState<RootState>
): StoreResults => {
  const persistOptions = { storage: localforage };
  const _persistCallback = () => {
    if (persistCallback) {
      persistCallback();
    }
  };
  const offlineConfig = {
    ...defaultOfflineConfig,
    persistOptions,
    persistCallback: _persistCallback,
    effect: offlineEffect,
    discard,
    retry,
  };

  const offlineEnhancer = offline(offlineConfig) as StoreEnhancer;

  const store = configureStore({
    reducer: rootReducer,
    preloadedState,
    middleware,
    enhancers: [offlineEnhancer],
  });

  sagaMiddleware.run(proceduresSaga);
  initMessageListener(store);

  return { store };
};

export default configureStoreWithCallback;
