import pluralize from 'pluralize';
import { Dialog } from 'primereact/dialog';
import { useCallback, useEffect, useState } from 'react';
import Button, { BUTTON_TYPES } from '../../../components/Button';
import SimpleDialogFooter from '../../../elements/SimpleDialogFooter';
import { Item } from '../../types';
import SelectedItems from './SelectedItems';

type ConfirmCheckOutCheckInModalProps = {
  mode: 'Check Out' | 'Check In';
  selectedItems: Item[];
  onApplyUpdate: (itemIds: string[]) => Promise<void>;
  onCancel: () => void;
};

const ConfirmCheckOutCheckInModal = ({
  mode,
  selectedItems,
  onApplyUpdate,
  onCancel,
}: ConfirmCheckOutCheckInModalProps) => {
  const [itemsToUpdate, setItemsToUpdate] = useState(selectedItems);
  const [isDisabled, setIsDisabled] = useState(false);

  const onConfirm = useCallback(async () => {
    setIsDisabled(true);
    await onApplyUpdate(itemsToUpdate.map((item) => item.id));
    setIsDisabled(false);
  }, [itemsToUpdate, onApplyUpdate]);

  const getFooter = useCallback(() => {
    return (
      <SimpleDialogFooter>
        <Button type={BUTTON_TYPES.PRIMARY} isDisabled={isDisabled} onClick={onConfirm}>
          Confirm
        </Button>
        <Button type={BUTTON_TYPES.SECONDARY} isDisabled={isDisabled} onClick={onCancel}>
          Cancel
        </Button>
      </SimpleDialogFooter>
    );
  }, [isDisabled, onConfirm, onCancel]);

  const onRemoveItem = useCallback(
    (removedId: string) => setItemsToUpdate(itemsToUpdate.filter((item) => item.id !== removedId)),
    [itemsToUpdate]
  );

  useEffect(() => {
    if (itemsToUpdate.length === 0) {
      onCancel();
    }
  }, [itemsToUpdate, onCancel]);

  return (
    <Dialog
      header={`${mode} Items`}
      onHide={onCancel}
      visible={true}
      closable={true}
      draggable={false}
      className="w-[500px] h-[500px]"
      footer={getFooter}
    >
      <div className="flex flex-col items-start mt-4 gap-y-4">
        <div className="flex flex-col items-start w-full gap-y-1">
          <span>{`${pluralize('Item', itemsToUpdate.length, true)} for ${mode}`}</span>
          <SelectedItems selectedItems={itemsToUpdate} onRemoveItem={onRemoveItem} />
        </div>
      </div>
    </Dialog>
  );
};

export default ConfirmCheckOutCheckInModal;
