import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useMemo } from 'react';
import { useRouteMatch } from 'react-router-dom';
import { Draft, ProcedureDiff, Release } from 'shared/lib/types/views/procedures';
import diffUtil, { ChangeEntry } from '../lib/diffUtil';
import procedureUtil from '../lib/procedureUtil';
import stickyHeaderUtil from '../lib/stickyHeaderUtil';
import NavigationChanges from './Diff/NavigationChanges';
import ToggleDiff from './Diff/ToggleDiff';
import ExpandCollapseButtons from './ExpandCollapse/ExpandCollapseButtons';
import ReviewStatusLabel from './ReviewStatusLabel';
import Divider from './Toolbar/Divider';
import LeftSide from './Toolbar/LeftSide';
import RightSide from './Toolbar/RightSide';
import Spacer from './Toolbar/Spacer';
import Title from './Toolbar/Title';
import Toolbar from './Toolbar/Toolbar';

export const REVIEW_STICKY_HEADER_HEIGHT_REM = 2.5;

interface ReviewStickyHeaderProps {
  procedureDiff: ProcedureDiff | null;
  review: Draft | null;
  release: Release | null;
  showDiff: boolean;
  setShowDiff: React.Dispatch<React.SetStateAction<boolean>>;
  isApproved: boolean;
  scrollToDiff: (changeEntry: ChangeEntry) => void;
  onReview: () => void;
  onPreview: () => void;
  onExpandAll: () => void;
  onCollapseAll: () => void;
}
const ReviewStickyHeader = ({
  procedureDiff,
  review,
  release,
  showDiff,
  setShowDiff,
  isApproved,
  scrollToDiff,
  onReview,
  onPreview,
  onExpandAll,
  onCollapseAll,
}: ReviewStickyHeaderProps) => {
  const { url } = useRouteMatch();
  const isReview = useMemo<boolean>(() => url.endsWith('/review'), [url]);

  const newTitle = useMemo(() => {
    if (!review) {
      return '';
    }
    return procedureUtil.getProcedureTitle(review.code, review.name);
  }, [review]);

  const changes = useMemo(() => (procedureDiff ? diffUtil.getChangeEntries(procedureDiff) : []), [procedureDiff]);

  const hasDiff = Boolean(procedureDiff) && Boolean(release);
  const diffLoading = !procedureDiff && Boolean(release);

  return (
    <Toolbar>
      <LeftSide>
        <button
          className={stickyHeaderUtil.getButtonClassNames(false, isReview)}
          type="button"
          title="Review Draft"
          aria-label="Review Draft"
          disabled={isReview}
          onClick={onReview}
        >
          <FontAwesomeIcon icon="clipboard" />
        </button>
        <button
          className={stickyHeaderUtil.getButtonClassNames(false, false)}
          type="button"
          title="Preview Procedure"
          aria-label="Preview Procedure"
          disabled={false}
          onClick={onPreview}
        >
          <FontAwesomeIcon icon={'fa-solid fa-eye' as IconProp} />
        </button>
        <Divider ml={1} mr={2} />
        <ReviewStatusLabel isReviewApproved={isApproved} />
        <Spacer />
        <Title title={newTitle} />
      </LeftSide>
      <RightSide>
        <ExpandCollapseButtons
          buttonClasses={stickyHeaderUtil.getButtonClassNames(false, false)}
          onExpand={onExpandAll}
          onCollapse={onCollapseAll}
          isDisabled={false}
        />
        {isReview && hasDiff && !diffLoading && (
          <>
            <Divider ml={1} mr={3} />
            <NavigationChanges changes={changes} scrollToDiff={scrollToDiff} isEnabled={showDiff} />
            <Divider ml={1} mr={3} />
            <ToggleDiff showDiff={showDiff} setShowDiff={setShowDiff} />
          </>
        )}
        {isReview && diffLoading && (
          <div className="uppercase text-gray-400  font-bold flex items-center">Diff is loading</div>
        )}
      </RightSide>
    </Toolbar>
  );
};

export default React.memo(ReviewStickyHeader);
