import React, { useState, useMemo, useEffect } from 'react';
import { ACCESS } from 'shared/lib/constants/permissions';
import useProjects from '../../hooks/useProjects';
import {
  TreeSelect,
  TreeSelectChangeEvent,
  TreeSelectExpandedKeysType,
  TreeSelectExpandedEvent,
} from 'primereact/treeselect';
import { getProjectsTreeFlat } from '../../lib/projectTreeUtil';
import { useNavState } from '../../contexts/NavContext';
import projectUtil from '../../lib/projectUtil';
import Icon from '../../elements/Icon';
import { faFolderClosed, faFolderOpen } from '@fortawesome/free-solid-svg-icons';
import { Project } from 'shared/lib/types/couch/settings';
import Button, { BUTTON_TYPES } from '../Button';
import { ReactComponent as FolderArrowSvg } from '../../images/folder-arrow-side_custom-icon.svg';

interface ProjectsEditSelectProps {
  value: string;
  onUpdateProject: (project: Project | null | undefined) => void;
  projectAccessLevel?: ACCESS;
}

const ProjectsEditSelectChangeProject = ({
  value,
  onUpdateProject,
  projectAccessLevel = ACCESS.EDITOR,
}: ProjectsEditSelectProps) => {
  const { projectsFilter } = useNavState();
  const { getProjectsForAccessLevel } = useProjects();
  const projects = getProjectsForAccessLevel({ projectAccessLevel });
  const [expandedKeys, setExpandedKeys] = useState<TreeSelectExpandedKeysType>({});

  // Expand the selected project by default
  useEffect(() => {
    if (projectsFilter) {
      setExpandedKeys({ [projectsFilter.project.id]: true });
      return;
    }
    setExpandedKeys({});
  }, [projectsFilter]);

  const onToggle = (e: TreeSelectExpandedEvent): void => {
    setExpandedKeys(e.value);
  };

  const options = useMemo(() => {
    if (!projectsFilter || projectsFilter.subprojects.length < 1) {
      const tree = getProjectsTreeFlat(projects);

      const archivedProjects = projectUtil.getArchivedProjects(projects);
      const foundInArchived = archivedProjects.find((item) => item.id === value);
      if (foundInArchived) {
        tree.push({ id: foundInArchived.id, key: foundInArchived.id, label: foundInArchived.name, children: [] });
      }
      return tree;
    }

    const tree = getProjectsTreeFlat(projects);
    const node = tree.find((project) => project.id === projectsFilter.project.id);
    if (!node) {
      return [];
    }
    return [node];
  }, [projects, projectsFilter, value]);

  const disabled = Boolean(projectsFilter && projectsFilter.subprojects.length < 1);

  const onChange = (e: TreeSelectChangeEvent) => {
    const projectId = e.target.value;
    if (projectId === undefined) {
      onUpdateProject(null);
      return;
    }
    const foundProject = projects.find((project) => project.id === projectId);
    onUpdateProject(foundProject);
  };

  return (
    <div className="grow w-full">
      <div className="grow relative">
        <TreeSelect
          nodeTemplate={(option) => {
            const hasSubprojects = projectUtil.getActiveSubprojectIds(option.id as string, projects).length > 0;
            return (
              <div className={`flex items-center gap-x-2 ${!hasSubprojects ? '!-ml-5' : ''}`}>
                <Icon
                  element={
                    projectUtil.getActiveSubprojectIds(option.id as string, projects).length > 0 &&
                    expandedKeys?.[option.id || '']
                      ? faFolderOpen
                      : faFolderClosed
                  }
                  className="text-slate-600"
                />
                <div>{option.label}</div>
              </div>
            );
          }}
          aria-label="Project Context Selector"
          placeholder=""
          options={[...options]}
          onChange={onChange}
          valueTemplate={() => (
            <div className="flex flex-row  items-center">
              <Button type={BUTTON_TYPES.TERTIARY} leadingIcon={FolderArrowSvg} title="Change Project">
                Change Project
              </Button>
            </div>
          )}
          value={value}
          pt={{
            labelContainer: {
              className: `rounded-xl text-md   ${!value ? 'truncate text-gray-700' : 'bg-gray-200'}`,
            },
            root: { className: 'shadow-none flex items-center gap-x-1 rounded-xl' },
            panel: { className: 'w-64 overflow-y-auto unobtrusive-sidebar bg-white rounded-lg shadow-lg' },
            trigger: {
              className: 'hidden',
            },

            header: { className: 'flex flex-row  w-full items-center rounded-xl' },
            filterContainer: { className: 'flex flex-row gap-x-1 mt-1 items-center rounded-xl' },
            filter: { className: 'min-w-[230px] rounded-lg ml-2 p-1 border-[1px]' },
            filterIcon: { className: 'hidden' },
            closeButton: { className: 'hidden' },
          }}
          expandedKeys={expandedKeys}
          onToggle={onToggle}
          showClear={false}
          disabled={disabled}
          filter
          unstyled={true}
          filterIcon={null}
          dropdownIcon={null}
          resetFilterOnHide={true}
        ></TreeSelect>
      </div>
    </div>
  );
};

export default React.memo(ProjectsEditSelectChangeProject);
