import { TreeNode } from 'primereact/treenode';
import { Project } from 'shared/lib/types/couch/settings';
import { sortBy } from 'shared/lib/collections';
import projectUtil from './projectUtil';

export const ALL_PROJECTS = '(All)';

export const getProjectsTree = (projects: Array<Project>): Array<TreeNode> => {
  const children = getProjectsTreeFlat(projects);
  return [
    {
      id: ALL_PROJECTS,
      key: ALL_PROJECTS,
      label: ALL_PROJECTS,
      children,
    },
  ];
};

export const getProjectsTreeFlat = (
  projects: Array<Project>
): Array<TreeNode> => {
  const active = projectUtil.getActiveProjects(projects);
  const parentProjects = active.filter((project) => !project.parent_id);
  const sortedParentProjects = sortProjects(parentProjects);
  return sortedParentProjects.map((p) => parentNodeFromProject(p, projects));
};

const parentNodeFromProject = (
  project: Project,
  projects: Array<Project>
): TreeNode => {
  const childProjects = projectUtil.getActiveSubprojects(project.id, projects);
  const sortedChildProjects = sortProjects(childProjects);
  return {
    id: project.id,
    key: project.id,
    label: project.name,
    children: sortedChildProjects.map(childNodeFromProject),
  };
};

const sortProjects = (projects: Array<Project>): Array<Project> => {
  return sortBy(projects, ['name']);
};

export const childNodeFromProject = (project: Project): TreeNode => {
  return {
    id: project.id,
    key: project.id,
    label: project.name,
  };
};
