import { useMemo } from 'react';
import useRealtimeRisks from '../hooks/useRealtimeRisks';
import AssessmentIndicator from '../../components/AssessmentMatrix/AssessmentIndicator';
import Label from '../../components/Label';
import { capitalizeFirstLetter } from 'shared/lib/text';
import { Link } from 'react-router-dom';
import { riskPath } from '../../lib/pathUtil';
import { useDatabaseServices } from '../../contexts/DatabaseContext';
import { ProcedureRisk } from 'shared/lib/types/views/procedures';
import { Analysis } from 'shared/lib/types/testing';
import { Status } from 'shared/lib/types/postgres/risks';
import FieldLabel from '../../elements/internal/FieldLabel';
import Tooltip from '../../elements/Tooltip';
import { faTriangleExclamation } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

type RiskDisplay = Analysis & {
  id: number;
  title: string;
  status?: Status;
};

interface ProcedureRiskListProps {
  procedureRisks: Array<ProcedureRisk>;
}

const ProcedureRiskList = ({ procedureRisks }: ProcedureRiskListProps) => {
  const { risks, isLoading } = useRealtimeRisks();
  const { currentTeamId } = useDatabaseServices();

  const mappedRisks = useMemo<Array<RiskDisplay>>(() => {
    const offlineRisks: Array<RiskDisplay> = procedureRisks.map((procedureRisk: ProcedureRisk) => ({
      id: procedureRisk.id,
      title: procedureRisk.title,
    }));

    if (isLoading) {
      return offlineRisks;
    }

    return offlineRisks.map((displayRisk: RiskDisplay) => {
      const found = risks.find((risk) => risk.id === displayRisk.id);
      if (found) {
        displayRisk.title = found.title;
        displayRisk.status = found.status;
        displayRisk.row_value = found.assessmentMatrixRow;
        displayRisk.column_value = found.assessmentMatrixColumn;
      }
      return displayRisk;
    });
  }, [isLoading, procedureRisks, risks]);

  if (!mappedRisks) {
    return <div>Error loading Risks</div>;
  }

  return (
    <div className="w-full bg-white border border-gray-200 rounded p-2 pb-0">
      <table className="w-full ">
        <tbody>
          <tr>
            <td colSpan={3}>
              <FieldLabel label="Associated Risks" />
            </td>
            <td>
              <FieldLabel label="Status" />
            </td>
          </tr>

          {mappedRisks.map((risk) => (
            <tr className="border-t border-gray-200" key={risk.id}>
              <td className="px-2 w-4">
                {risk.row_value && risk.column_value ? (
                  <AssessmentIndicator analysis={risk} />
                ) : (
                  <Tooltip content="Risk level unavailable">
                    <FontAwesomeIcon icon={faTriangleExclamation} />
                  </Tooltip>
                )}
              </td>
              <td className="w-3 pr-1 py-2">{risk.id}</td>
              <td>
                {risk.status ? (
                  <Link
                    className="text-blue-500 hover:brightness-75"
                    to={{ pathname: riskPath(currentTeamId, `${risk.id}`) }}
                  >
                    {risk.title}
                  </Link>
                ) : (
                  risk.title
                )}
              </td>

              <td className="w-16">
                {risk.status ? (
                  <Label text={capitalizeFirstLetter(risk.status.name)} color={risk.status.color} size="xs" />
                ) : (
                  '--'
                )}
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default ProcedureRiskList;
