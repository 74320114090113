import { useMemo } from 'react';
import Select from 'react-select';
import { Tool, ToolInstance } from 'shared/lib/types/api/manufacturing/tools/models';
import { StyleVariant } from '../../../elements/lib/fieldStyles';
import { NumberSelectOption as SelectOption } from '../../../lib/formik';
import { reactSelectNoBorderStyles, reactSelectStyles } from '../../../lib/styles';
import useToolInstances from '../../hooks/useToolInstances';
import CompletedToolInstance from './CompletedToolInstance';
import StatusIcons from './StatusIcons';
import { toolInstanceToSelectOption } from './utils';

type SelectToolInstanceProps = {
  tool: Tool;
  toolInstanceId?: number;
  instanceFilter?: (toolInstance: ToolInstance) => boolean;
  onToolInstanceChange?: (toolInstanceOption: SelectOption) => void;
  isEnabled: boolean;
  isComplete: boolean;
  teamId: string;
  variant?: StyleVariant;
  type?: 'in' | 'out';
};

const SelectToolInstance = ({
  tool,
  toolInstanceId,
  instanceFilter,
  onToolInstanceChange,
  isEnabled,
  isComplete,
  teamId,
  variant = StyleVariant.Standalone,
  type,
}: SelectToolInstanceProps) => {
  const style = variant === StyleVariant.Standalone ? reactSelectStyles : reactSelectNoBorderStyles;
  const { allToolInstances } = useToolInstances();

  const toolInstancesForTool = useMemo(() => {
    return allToolInstances?.filter((clientToolInstance) => clientToolInstance.tool_id === tool.id);
  }, [allToolInstances, tool.id]);

  const toolInstance = useMemo(() => {
    if (!toolInstancesForTool || !toolInstanceId) {
      return undefined;
    }
    return toolInstancesForTool.find((toolInstance) => toolInstance.id === toolInstanceId);
  }, [toolInstanceId, toolInstancesForTool]);

  const toolInstanceOptions = useMemo(() => {
    return toolInstancesForTool?.filter(instanceFilter || ((t) => true))?.map(toolInstanceToSelectOption);
  }, [instanceFilter, toolInstancesForTool]);

  const toolInstanceOption = useMemo(() => {
    if (!toolInstance) {
      return null;
    }
    return toolInstanceToSelectOption(toolInstance);
  }, [toolInstance]);

  return (
    <>
      {!isComplete && (
        <Select
          styles={style}
          classNamePrefix="react-select"
          options={toolInstanceOptions}
          formatOptionLabel={(option: SelectOption) => {
            const toolInstance = toolInstancesForTool?.find((toolInstance) => toolInstance.id === option.value);
            return toolInstance && <StatusIcons tool={tool} toolInstance={toolInstance} showCheckedOutStatus={false} />;
          }}
          value={toolInstanceOption}
          onChange={onToolInstanceChange}
          isDisabled={!isEnabled}
          isClearable={true}
          placeholder="Search instances"
          aria-label="Select tool instance"
        />
      )}
      {isComplete && <CompletedToolInstance teamId={teamId} tool={tool} toolInstance={toolInstance} type={type} />}
    </>
  );
};

export default SelectToolInstance;
