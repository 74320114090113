import React, { useCallback, useMemo, useRef } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import AttachmentsContainer from '../../Comments/AttachmentsContainer';
import PhotoCaptureButton from '../../PhotoCaptureButton';
import { MAX_FILE_SIZE } from 'shared/lib/types/api/files/requests';
import { useDatabaseServices } from '../../../contexts/DatabaseContext';
import { DatabaseServices } from '../../../contexts/proceduresSlice';
import { AttachmentValue } from 'shared/lib/types/views/procedures';

type FieldInputAttachmentsProps = {
  attachments: AttachmentValue[];
  onChange?: (attachments: AttachmentValue[]) => void;
  onUploadingAttachments?: (uploading: boolean) => void;
  onRemove?: (attachment: AttachmentValue) => void;
  isEnabled: boolean;
};

const FieldInputAttachments = ({
  attachments,
  onChange,
  onUploadingAttachments,
  onRemove,
  isEnabled,
}: FieldInputAttachmentsProps) => {
  const { services }: { services: DatabaseServices } = useDatabaseServices();
  const fileInputRef = useRef<HTMLInputElement>(null);

  const uploadFiles = useCallback(
    (files: File[]) => {
      onUploadingAttachments && onUploadingAttachments(true);

      return Promise.all(
        files.map((file) =>
          services.attachments.uploadFile(file, 'procedures:field_input:attachment', { remote: false })
        )
      )
        .then((attachments) => {
          return attachments.filter((attachment) => Boolean(attachment)) as AttachmentValue[];
        })
        .catch((error) => {
          window.alert('Failed to upload attachments');
        })
        .finally(() => {
          onUploadingAttachments && onUploadingAttachments(false);
        });
    },
    [services.attachments, onUploadingAttachments]
  );

  const onPhotoCapture = useCallback(
    async (file: File) => {
      const previousAttachments = attachments || [];

      await uploadFiles([file]).then((newAttachments) => {
        if (!newAttachments || newAttachments.length === 0) {
          return;
        }

        onChange && onChange([...previousAttachments, ...newAttachments]);
      });
    },
    [attachments, onChange, uploadFiles]
  );

  const onFileInputChange = useCallback(
    async (event) => {
      const files = event.currentTarget.files as FileList;

      if (!files || files.length === 0) {
        return;
      }

      const filteredFiles = Array.from(files).filter((file) => file.size < MAX_FILE_SIZE);

      if (filteredFiles.length !== files.length) {
        window.alert('Some files are too large to upload');
      }

      await uploadFiles(filteredFiles).then((newAttachments) => {
        if (!newAttachments || newAttachments.length === 0) {
          return;
        }

        const previousAttachments = attachments || [];
        onChange && onChange([...previousAttachments, ...newAttachments]);
      });
    },
    [onChange, attachments, uploadFiles]
  );

  const openFilePicker = useCallback(() => {
    fileInputRef.current?.click();
  }, []);

  const hasAttachments = useMemo(() => attachments.length > 0, [attachments]);

  return (
    <div aria-label="Field Input Attachment" className="flex flex-col gap-y-2 p-2 border border-gray-300 rounded-md">
      {!hasAttachments && <div className="text-gray-500 italic">No attachments added</div>}

      {hasAttachments && <AttachmentsContainer attachments={attachments} onRemove={onRemove} />}

      {isEnabled && (
        <div className="flex flex-row flex-nowrap gap-x-1 items-center">
          <PhotoCaptureButton onPhotoCapture={onPhotoCapture} />
          <div>
            <button
              type="button"
              aria-label="Attach file"
              className="text-slate-700 bg-slate-200 hover:bg-slate-300 rounded-full px-2 py-1"
              onClick={openFilePicker}
            >
              <FontAwesomeIcon icon="paperclip" />
            </button>
            {/** Hidden file input for file picker */}
            <input
              ref={fileInputRef}
              type="file"
              multiple={true}
              className="hidden"
              onChange={onFileInputChange}
              data-testid="file_attachment_field_input"
            />
          </div>
        </div>
      )}
    </div>
  );
};

export default React.memo(FieldInputAttachments);
