import { Dispatch, SetStateAction, useCallback, useMemo, useState } from 'react';
import { RowHeightArgs, SelectColumn } from 'react-data-grid';
import { ViewTab } from 'shared/lib/types/postgres/users';
import Button from '../../components/Button';
import UnitDisplay from '../../components/Settings/Units/UnitDisplay';
import { useNavState } from '../../contexts/NavContext';
import Grid, { DEFAULT_GROUP_ROW_HEIGHT_PX, GridColumn, TextAlign } from '../../elements/Grid';
import projectGroupCell from '../../elements/renderers/ProjectGroupCell';
import ProjectRenderer from '../../elements/renderers/ProjectRenderer';
import { ColumnOverrides, applyOverrides, projectGrouping } from '../../lib/gridUtils';
import { TestCaseRow } from '../hooks/useCases';
import useTestingAuth from '../hooks/useTestingAuth';
import { calculateAssessmentLevel } from '../../components/AssessmentMatrix/matrixUtils';
import AssessmentIndicator from '../../components/AssessmentMatrix/AssessmentIndicator';

const MAIN_VERTICAL_PADDING = 165;
const BASE_ROW_HEIGHT = 35;
const ADDITIONAL_ROW_HEIGHT = 24;
const ROW_HEIGHT = (row, allExpanded = false) => {
  return (
    BASE_ROW_HEIGHT +
    (allExpanded
      ? ADDITIONAL_ROW_HEIGHT * Math.max(1, row.test_case_conditions.length - 1, (row.hazards?.length ?? 1) - 1)
      : ADDITIONAL_ROW_HEIGHT)
  );
};

interface TestCasesTableProps {
  testCases: Array<TestCaseRow>;
  selectedRows: ReadonlySet<string>;
  usedVerticalSpace?: number;
  columnOverrides?: ColumnOverrides;
  onClickedEdit?: (id: string) => void;
  onClickedRow?: (id: string) => void;
  onSelectedRowsChange: (selectedRows: Set<string>) => void;
  setSearchTerm: (searchTerm: string) => void;
  viewTab?: ViewTab;
  expandedProjectNames: ReadonlySet<string>;
  setExpandedProjectNames: Dispatch<SetStateAction<ReadonlySet<string>>>;
}

const TestCasesTable = ({
  testCases,
  selectedRows,
  usedVerticalSpace = MAIN_VERTICAL_PADDING,
  columnOverrides,
  onClickedEdit,
  onClickedRow,
  onSelectedRowsChange,
  setSearchTerm,
  viewTab = ViewTab.List,
  expandedProjectNames,
  setExpandedProjectNames,
}: TestCasesTableProps) => {
  const { projectsFilter } = useNavState();
  const [allExpanded, setAllExpanded] = useState<boolean>(true);
  const { hasEditPermission } = useTestingAuth();

  const grouping = useMemo(
    () => projectGrouping<TestCaseRow>({ viewTab, expandedProjectNames, setExpandedProjectNames }),
    [expandedProjectNames, setExpandedProjectNames, viewTab]
  );
  const rowHeightGetter = useMemo(() => {
    const nonGroupedHeightGetter = (row: TestCaseRow) => ROW_HEIGHT(row, allExpanded);
    if (viewTab === ViewTab.Tree) {
      return ({ type, row }: RowHeightArgs<TestCaseRow>) => {
        if (type === 'GROUP') {
          return DEFAULT_GROUP_ROW_HEIGHT_PX;
        }
        return nonGroupedHeightGetter(row);
      };
    }
    return nonGroupedHeightGetter;
  }, [allExpanded, viewTab]);

  const rowKeyGetter = (row: TestCaseRow) => {
    return row.id;
  };

  const onClickEdit = useCallback(
    (id: string) => {
      onClickedEdit?.(id);
    },
    [onClickedEdit]
  );

  const onClickRow = useCallback(
    (event) => {
      if (event.column?.key !== 'expand_collapse' && event.column?.key !== 'select-row') {
        onClickedRow?.(event.row.id);
      }
    },
    [onClickedRow]
  );

  const testCasesColumns = useMemo(() => {
    const baseColumns: Array<GridColumn<TestCaseRow>> = [
      SelectColumn,
      {
        key: 'name',
        name: 'Test Point',
        sortable: true,
        width: '40%',
        renderCell({ row }) {
          return <div className="text-gray-900">{row.name}</div>;
        },
      },
      {
        key: viewTab === 'list' ? 'projectName' : 'rootProjectName',
        name: 'Project',
        width: '10%',
        sortable: true,
        renderGroupCell: projectGroupCell,
        renderCell: ({ row }) => ProjectRenderer({ row, setSearchTerm }),
      },
      {
        key: 'conditions',
        name: 'Conditions',
        sortable: false,
        width: '17%',
        renderCell({ row }) {
          return (
            <div className="flex-auto flex flex-col gap-y-1 leading-4">
              {!allExpanded && row.test_case_conditions.length > 1 && (
                <>
                  <div className=" text-gray-900 flex flex-row gap-x-[2px]">
                    {`${row.test_case_conditions[0].name}: ${row.test_case_conditions[0].value}`}
                    <UnitDisplay unit={row.test_case_conditions[0].units} />
                  </div>
                  <div className=" text-gray-900">...</div>
                </>
              )}
              {(allExpanded || row.test_case_conditions.length <= 1) &&
                row.test_case_conditions.map((condition) => (
                  <div key={condition.condition_id} className=" text-gray-900 flex flex-row gap-x-[2px]">
                    {`${condition.name}: ${condition.value}`}
                    <UnitDisplay unit={condition.units} />
                  </div>
                ))}
            </div>
          );
        },
      },
      {
        key: 'hazard',
        name: 'Hazard',
        sortable: true,
        renderCell({ row }) {
          return (
            <div className="flex-auto flex flex-col gap-y-1 leading-4">
              {!allExpanded && row.hazards && row.hazards.length > 1 && (
                <>
                  <div className="flex flex-row gap-x-2 items-center">
                    <AssessmentIndicator analysis={row.hazards[0]} />
                    <div key={row.hazards[0].name} className=" text-gray-900">
                      {row.hazards[0].name}
                    </div>
                  </div>
                  <div className=" text-gray-900">...</div>
                </>
              )}
              {row.hazards &&
                (allExpanded || row.hazards.length <= 1) &&
                row.hazards.map((hazard) => (
                  <div key={hazard.name} className="flex flex-row gap-x-2 items-center">
                    <AssessmentIndicator analysis={hazard} />
                    <div className=" text-gray-900">{hazard.name}</div>
                  </div>
                ))}
            </div>
          );
        },
        comparator: (a: TestCaseRow, b: TestCaseRow) => {
          const hazardLevelA = a.hazards && a.hazards.length > 0 ? calculateAssessmentLevel(a.hazards[0]) : 0;
          const hazardLevelB = b.hazards && b.hazards.length > 0 ? calculateAssessmentLevel(b.hazards[0]) : 0;
          return hazardLevelA - hazardLevelB;
        },
      },
      {
        key: 'expand_collapse',
        name: (
          <Button
            type="tertiary"
            leadingIcon={allExpanded ? 'down-left-and-up-right-to-center' : 'expand-alt'}
            onClick={() => setAllExpanded(!allExpanded)}
          />
        ),
        align: TextAlign.Right,
        sortable: false,
        width: 100,
        renderCell({ row }) {
          return !hasEditPermission(row.project_id) ? (
            <></>
          ) : (
            <div>
              <Button
                isDisabled={selectedRows.size > 0}
                type="tertiary"
                leadingIcon="pencil-alt"
                onClick={() => onClickEdit(row.id)}
              >
                Edit
              </Button>
            </div>
          );
        },
      },
    ];
    if (columnOverrides) {
      return applyOverrides(baseColumns, columnOverrides);
    }
    return baseColumns;
  }, [allExpanded, columnOverrides, setSearchTerm, hasEditPermission, selectedRows.size, onClickEdit, viewTab]);

  return (
    <div className="mt-2">
      <Grid<TestCaseRow>
        key={projectsFilter?.project.id}
        columns={testCasesColumns}
        rows={testCases}
        rowHeight={rowHeightGetter}
        rowGrouping={grouping}
        emptyRowMessage="No matching test points found"
        usedVerticalSpace={usedVerticalSpace}
        selectedRows={selectedRows}
        onSelectedRowsChange={onSelectedRowsChange}
        rowKeyGetter={rowKeyGetter}
        onCellClick={onClickRow}
      />
    </div>
  );
};

export default TestCasesTable;
