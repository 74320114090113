import { useMemo } from 'react';
import { capitalize } from 'lodash';
import Select from 'react-select';
import CreatableSelect from 'react-select/creatable';
import { reactSelectNoBorderStyles, reactSelectStyles } from '../../lib/styles';
import { UsageType } from 'shared/lib/types/postgres/manufacturing/types';
import { StringSelectOption as SelectOption } from '../../lib/formik';
import UsageTypeLabel from './UsageTypeLabel';
import { StyleVariant } from '../../elements/lib/fieldStyles';

interface PartUsageTypeSelectProps {
  selected: UsageType | null;
  usageTypes: UsageType[];
  onChange: (selectedOption: object) => void;
  canCreate: boolean;
  isEnabled?: boolean;
  variant?: StyleVariant;
}

const PartUsageTypeSelect = ({
  selected,
  usageTypes,
  onChange,
  canCreate,
  isEnabled = true,
  variant = StyleVariant.Standalone,
}: PartUsageTypeSelectProps) => {
  const style = variant === StyleVariant.Standalone ? reactSelectStyles : reactSelectNoBorderStyles;

  const usageTypeOptions = useMemo(() => {
    if (!usageTypes) {
      return [];
    }
    const usageTypeNames = usageTypes.map((t) => t.name);
    usageTypeNames.sort();
    return usageTypeNames.map((name) => ({
      value: name,
      label: capitalize(name),
    }));
  }, [usageTypes]);

  const value = useMemo(() => {
    if (!selected || usageTypeOptions.length === 0) {
      return null;
    }

    const found = usageTypeOptions.find((op) => op.value === selected.name);
    if (!found) {
      return null;
    }
    return found;
  }, [selected, usageTypeOptions]);

  return (
    <>
      {!canCreate && (
        <Select
          styles={style}
          classNamePrefix="react-select"
          options={usageTypeOptions}
          value={value}
          formatOptionLabel={(option: SelectOption) => <UsageTypeLabel usageType={option.value} />}
          onChange={onChange}
          isDisabled={!isEnabled}
          isSearchable={true}
          placeholder="Select usage type"
        />
      )}
      {canCreate && (
        <CreatableSelect
          aria-label="Usage Types"
          classNamePrefix="react-select"
          isClearable={true}
          onChange={onChange}
          placeholder="Select or create usage type"
          options={usageTypeOptions}
          styles={style}
          value={value}
          formatOptionLabel={(option: SelectOption) => <UsageTypeLabel usageType={option.value} />}
          isDisabled={!isEnabled}
        />
      )}
    </>
  );
};

export default PartUsageTypeSelect;
