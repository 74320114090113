import { useCallback, useMemo, useState, KeyboardEvent, useEffect, useRef } from 'react';
import { DragDropContext } from 'react-beautiful-dnd';
import { cloneDeep, isEqual } from 'lodash';
import { useDispatch, useSelector } from 'react-redux';
import { Form, Formik, FormikProps } from 'formik';

import { SectionSnippet, Snippet } from 'shared/lib/types/views/procedures';
import useExpandCollapse from '../../hooks/useExpandCollapse';
import { ProcedureContextProvider } from '../../contexts/ProcedureContext';
import FieldSetProcedureSection from '../FieldSetProcedureSection';
import { useDatabaseServices } from '../../contexts/DatabaseContext';
import { ProcedureContentBlockTypes } from 'shared/lib/types/blockTypes';
import procedureUtil from '../../lib/procedureUtil';
import { Actions } from '../ContentMenu/addContentTypes';
import AddContentMenu from '../ContentMenu/AddContentMenu';
import getSectionAddContentItems from '../ContentMenu/sectionAddContentItems';
import { SelectionContextProvider } from '../../contexts/Selection';
import FlashMessage from '../FlashMessage';
import Selectable, { Boundary } from '../Selection/Selectable';
import clipboardUtil from '../../lib/clipboardUtil';
import snippetUtil from '../../lib/snippetUtil';
import { copyItemToClipboard, selectClipboardItem } from '../../contexts/proceduresSlice';
import { useMixpanel } from '../../contexts/MixpanelContext';
import { PROCEDURE_EDIT_VIRTUALIZED_ELEMENTS } from '../../screens/ProcedureEdit';
import VirtualizedAPI from '../../elements/Virtualized/VirtualizedAPI';

interface FieldSetSectionSnippetProps {
  snippet: SectionSnippet;
  validationErrors?: Record<string, unknown>;
  testingModule?: boolean;
  onChange?: (updatedSnippet: Snippet) => void;
}

const FieldSetSectionSnippet = ({
  snippet,
  validationErrors,
  testingModule,
  onChange,
}: FieldSetSectionSnippetProps) => {
  const [present, setPresent] = useState(snippet);
  const { currentTeamId } = useDatabaseServices();
  const { setIsCollapsed, isCollapsedMap, setAllStepsInSectionExpanded, areAllStepsInSectionExpanded } =
    useExpandCollapse(() => VirtualizedAPI.refresh(PROCEDURE_EDIT_VIRTUALIZED_ELEMENTS));

  const [formValues, setFormValues] = useState({
    name: present.name || '',
    description: present.description || '',
  });

  const formikRef = useRef<FormikProps<{
    name: string;
    description: string;
  }> | null>(null);

  const dispatch = useDispatch();
  const { mixpanel } = useMixpanel();
  const [flashMessage, setFlashMessage] = useState<string | null>(null);
  const clipboardItem = useSelector((state) => selectClipboardItem(state, currentTeamId));
  const clipboardStep = useMemo(() => {
    if (!clipboardItem) return undefined;
    const step = clipboardUtil.getStepFromClipboardItem(clipboardItem);
    if (!step || snippetUtil.validateStepForSnippet(step) !== undefined) {
      return undefined;
    }
    return step;
  }, [clipboardItem]);

  const mixpanelTrack = useCallback(
    (trackingKey: string) => {
      if (mixpanel && trackingKey) {
        mixpanel.track(trackingKey);
      }
    },
    [mixpanel]
  );

  const updatePresent = useCallback(
    (updated: SectionSnippet) => {
      setPresent(updated);
      onChange?.(updated);
    },
    [onChange]
  );

  const handleFormChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    const updated = cloneDeep(present);
    updated[name] = value;
    if (!isEqual(updated, present)) {
      updatePresent(updated);
    }
    if (formikRef.current) {
      formikRef.current.handleChange(e);
    }
  };

  const onCopySection = useCallback(() => {
    mixpanelTrack('Copy Section');
    const copiedSection = procedureUtil.copySection(present.section);
    const clipboardItemSection = clipboardUtil.createClipboardItemSection(copiedSection);
    dispatch(copyItemToClipboard(currentTeamId, clipboardItemSection));
    setFlashMessage('Section copied');
  }, [currentTeamId, dispatch, mixpanelTrack, present]);

  const onPasteStep = useCallback(() => {
    if (!clipboardStep) return;
    mixpanelTrack('Paste Step');
    const updatedSnippet = cloneDeep(present);
    const stepCopy = procedureUtil.copyStep(clipboardStep);
    updatedSnippet.section.steps.push(stepCopy);
    updatePresent(updatedSnippet);
  }, [clipboardStep, mixpanelTrack, present, updatePresent]);

  const allStepsInSectionExpandedMap = useMemo(
    () => present && present.section && areAllStepsInSectionExpanded && areAllStepsInSectionExpanded(present.section),
    [areAllStepsInSectionExpanded, present]
  );

  const procedureShell = useMemo(() => {
    return {
      _id: '',
      code: '',
      name: '',
      description: '',
      sections: [present.section],
    };
  }, [present]);

  const onAddStep = (stepIndex: number) => {
    const step = procedureUtil.newStep();
    const updated = cloneDeep(present);
    updated.section.steps.splice(stepIndex + 1, 0, step);
    updatePresent(updated);
  };

  const onAddSectionHeader = (sectionIndex: number) => {
    const sectionHeader = procedureUtil.newSectionHeader();
    const updated = cloneDeep(present);

    if (!updated.section.headers) {
      updated.section.headers = [];
    }

    updated.section.headers.push(sectionHeader);
    updatePresent(updated);
  };

  const onRemoveSectionHeader = useCallback(
    (headerIndex: number) => {
      const updated = cloneDeep(present);
      updated.section.headers?.splice(headerIndex, 1);
      updatePresent(updated);
    },
    [present, updatePresent]
  );

  const onSectionHeaderFormChanged = useCallback(
    (values: object, sectionIndex: number, headerIndex: number) => {
      if (!present) return;
      const updated = cloneDeep(present);
      if (present.section.headers && updated.section.headers) {
        const header = present.section.headers[headerIndex];
        updated.section.headers[headerIndex] = {
          ...header,
          ...values,
        };
        if (isEqual(present, updated)) {
          return;
        }
        updatePresent(updated);
      }
    },
    [present, updatePresent]
  );

  const onAddStepHeader = (stepIndex: number) => {
    const stepHeader = procedureUtil.newStepHeader();
    const updated = cloneDeep(present);
    if (!updated.section.steps[stepIndex].headers) {
      updated.section.steps[stepIndex].headers = [];
    }

    updated.section.steps[stepIndex].headers?.push(stepHeader);
    updatePresent(updated);
  };

  const onRemoveStepHeader = useCallback(
    (stepIndex: number) => {
      const updated = cloneDeep(present);
      updated.section.steps[stepIndex].headers?.pop();
      updatePresent(updated);
    },
    [present, updatePresent]
  );

  const onRemoveStep = (stepIndex: number) => {
    const numSteps = procedureUtil.numNonAddedStepsInSection(present.section);
    if (numSteps > 1) {
      const updated = cloneDeep(present);
      updated.section.steps.splice(stepIndex, 1);
      updatePresent(updated);
    } else {
      const updated = cloneDeep(present);
      updated.section.steps.splice(stepIndex, 1);
      updated.section.steps.unshift(procedureUtil.newStep());
      updatePresent(updated);
    }
  };

  const onStepChanged = useCallback(
    (values, sectionIndex: number, stepIndex: number) => {
      if (!present) return;
      const updated = cloneDeep(present);
      const step = present.section.steps[stepIndex];
      updated.section.steps[stepIndex] = { id: step.id, ...values };
      updatePresent(updated);
    },
    [present, updatePresent]
  );

  const onSectionChanged = useCallback(
    (values, sectionIndex: number) => {
      if (!present) return;
      const updated = cloneDeep(present);
      const section = present.section;
      updated.section = {
        id: section.id,
        steps: section.steps,
        ...(section.headers && { headers: section.headers }),
        ...values,
      };
      if (isEqual(present, updated)) {
        return;
      }
      updatePresent(updated);
    },
    [present, updatePresent]
  );

  const onDragStepEnd = useCallback(
    (results: { source; destination }) => {
      const { source, destination } = results;
      if (!source || !destination) {
        return;
      }

      const sourceStepIndex = source.index;
      const destinationStepIndex = destination.index;

      if (sourceStepIndex === destinationStepIndex) {
        return;
      }

      const updated = cloneDeep(present);
      const [removed] = updated.section.steps.splice(sourceStepIndex, 1);
      updated.section.steps.splice(destinationStepIndex, 0, removed);

      const numSteps = procedureUtil.numNonAddedStepsInSection(updated.section);
      if (numSteps === 0) {
        const step = procedureUtil.newStep();
        updated.section.steps.unshift(step);
      }

      updatePresent(updated);
    },
    [present, updatePresent]
  );

  const onDragEnd = useCallback(
    (results: { source; destination }) => {
      onDragStepEnd(results);
    },
    [onDragStepEnd]
  );

  const onContentMenuClick = (menuItem, section) => {
    switch (menuItem.action) {
      case Actions.AddSectionHeader:
        return onAddSectionHeader(0);
      case Actions.DeleteSectionHeader:
        return onRemoveSectionHeader(0);
      case Actions.CopySection:
        return onCopySection();
      case Actions.PasteStep:
        return onPasteStep();
      case Actions.CollapseAllStepsInSection:
        return setAllStepsInSectionExpanded(false, section);
      case Actions.ExpandAllStepsInSection:
        setIsCollapsed(section.id, false);
        setAllStepsInSectionExpanded(true, section);
        return;
      default:
        return;
    }
  };

  const onKeyboard = useCallback(
    (event: KeyboardEvent) => {
      if ((event.metaKey || event.ctrlKey) && event.code === 'KeyC') {
        onCopySection();
      } else if ((event.metaKey || event.ctrlKey) && event.code === 'KeyV') {
        onPasteStep();
      }
    },
    [onCopySection, onPasteStep]
  );

  useEffect(() => {
    const updated = cloneDeep(present);
    updated.name = formValues.name;
    updated.description = formValues.description;

    if (!isEqual(updated, present)) {
      updatePresent(updated);
    }
  }, [formValues, present, updatePresent]);

  useEffect(() => {
    if (formikRef.current) {
      setFormValues(formikRef.current.values);
    }
  }, [formikRef.current?.values]);

  return (
    <>
      <SelectionContextProvider>
        <Formik
          initialValues={{
            name: present.name || '',
            description: present.description || '',
          }}
          onSubmit={() => {
            /* no-op */
          }}
          innerRef={formikRef}
        >
          {({ values, handleBlur }) => {
            return (
              <Form className="mb-4">
                <div className="flex flex-col">
                  <label className="font-semibold text-sm" htmlFor="name">
                    Name
                  </label>
                  <input
                    id="name"
                    name="name"
                    type="text"
                    className="text-sm border-1 border-gray-400 rounded"
                    onChange={handleFormChange}
                    onBlur={handleBlur}
                    value={values.name}
                  />
                  {Boolean(validationErrors?.snippetName) && (
                    <div className="text-red-700">{validationErrors?.snippetName as string}</div>
                  )}
                  <label className="mt-2 font-semibold text-sm" htmlFor="description">
                    Description
                  </label>
                  <input
                    id="description"
                    name="description"
                    type="text"
                    className="text-sm border-1 border-gray-400 rounded"
                    onChange={handleFormChange}
                    onBlur={handleBlur}
                    value={values.description}
                  />
                </div>
              </Form>
            );
          }}
        </Formik>
        <Selectable boundary={Boundary.Section} block={present.section} onKeyboard={onKeyboard}>
          {({ styles }) => (
            <div aria-label="Section" role="region" className="flex flex-row mt-2 gap-x-3">
              <div className="flex flex-row gap-x-3">
                <AddContentMenu
                  menuItems={getSectionAddContentItems({
                    hasSectionHeader: (present.section?.headers?.length || 0) > 0,
                    canAddSection: false,
                    canInsertSnippet: false,
                    allStepsAreExpanded: allStepsInSectionExpandedMap,
                    isSectionCollapsed: isCollapsedMap[present.section.id],
                    canDeleteSection: false,
                    canCopySection: true,
                    canCutSection: false,
                    canPasteSection: false,
                    canPasteStep: !!clipboardStep,
                    canSaveAsSnippet: false,
                    hasDependencies: false,
                  })}
                  onClick={(menuItem) => onContentMenuClick(menuItem, present.section)}
                />
              </div>

              <div className={`flex flex-col w-full ${styles}`}>
                <DragDropContext onDragEnd={onDragEnd}>
                  <ProcedureContextProvider procedure={procedureShell} scrollTo={undefined}>
                    <FieldSetProcedureSection
                      section={present.section}
                      sectionIndex={0}
                      snippetsMap={{}}
                      onFieldRefChanged={() => undefined}
                      onStepFormChanged={onStepChanged}
                      onAddStepHeader={onAddStepHeader}
                      onRemoveStepHeader={onRemoveStepHeader}
                      onSectionHeaderFormChanged={onSectionHeaderFormChanged}
                      onSectionFormChanged={onSectionChanged}
                      onAddStep={onAddStep}
                      onAddSection={undefined}
                      errors={validationErrors}
                      onRemoveStep={onRemoveStep}
                      configurePartKitBlock={() => undefined}
                      configurePartBuildBlock={() => undefined}
                      onRemoveSectionHeader={() => onRemoveSectionHeader(0)}
                      onCollapse={setIsCollapsed}
                      isCollapsedMap={isCollapsedMap}
                      isSaveStepSnippetDisabled={true}
                      enabledContentTypes={[
                        ProcedureContentBlockTypes.Alert,
                        ProcedureContentBlockTypes.Text,
                        ProcedureContentBlockTypes.Attachment,
                        ProcedureContentBlockTypes.FieldInput,
                        ProcedureContentBlockTypes.TableInput,
                        ProcedureContentBlockTypes.ProcedureLink,
                        ProcedureContentBlockTypes.Commanding,
                        ProcedureContentBlockTypes.Telemetry,
                        ProcedureContentBlockTypes.Requirement,
                        ProcedureContentBlockTypes.ExternalItem,
                        ProcedureContentBlockTypes.FieldInputTable,
                        ...(testingModule ? [ProcedureContentBlockTypes.TestCases] : []),
                      ]}
                      onInsertStepSnippet={undefined}
                      onSaveStepSnippet={undefined}
                      comments={undefined}
                      onResolveComment={undefined}
                      onUnresolveComment={undefined}
                      onAcceptRedlineField={undefined}
                      onRejectRedlineField={undefined}
                      onAcceptRedlineBlock={undefined}
                      onRejectRedlineBlock={undefined}
                      onAcceptRedlineStep={undefined}
                      onRejectRedlineStep={undefined}
                      stepRedlineMap={undefined}
                      changedStepIdSet={undefined}
                      setChangedStepIdSet={undefined}
                      isRestrictedToSnippetContents={true}
                      areDependenciesEnabled={false}
                      onSaveReviewComment={undefined}
                    />
                  </ProcedureContextProvider>
                </DragDropContext>
              </div>
            </div>
          )}
        </Selectable>
      </SelectionContextProvider>
      <FlashMessage message={flashMessage} messageUpdater={setFlashMessage} />
    </>
  );
};

export default FieldSetSectionSnippet;
