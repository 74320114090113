import {
  ReferenceType,
  Risk as ServerRisk,
  Reference as ServerReference,
  StatusType,
  Status,
  MetadataOptions,
  RiskSettings,
} from 'shared/lib/types/postgres/risks';
import superlogin from '../../api/superlogin';
import { API_URL } from '../../config';
import {
  AddRiskRequest,
  PatchRiskRequest,
} from 'shared/lib/types/api/risks/requests';
import { Reference, Risk } from '../types';
import { Activity, Mention } from 'shared/lib/types/postgres/util';
import { AxiosResponse } from 'axios';
import { Step } from 'shared/lib/types/views/procedures';

export const referenceMapper = (
  serverReference: ServerReference
): Reference => {
  return {
    referenceType: serverReference.reference_type,
    referenceId: serverReference.reference_id,
    referenceSubtype: serverReference.reference_sub_type,
    referenceSubtypeId: serverReference.reference_sub_id,
    timestamp: serverReference.timestamp,
  };
};

const statusMapper = (status: Status) => {
  const updated = { ...status };
  updated.color = `bg-${status.color}`;
  return updated;
};

export const mapServerRiskToClient = (serverRisk: ServerRisk): Risk => {
  const clientRisk: Risk = {
    id: serverRisk.id,
    title: serverRisk.title,
    assessmentMatrixColumn: serverRisk.assessment_matrix_column,
    assessmentMatrixRow: serverRisk.assessment_matrix_row,
    matrix_metadata: serverRisk.matrix_metadata,
    status: statusMapper(serverRisk.status),
    assignee: serverRisk.assignee,
    owner: serverRisk.owner,
    createdAt: serverRisk.created_at,
    createdBy: serverRisk.created_by,
    notes: serverRisk.notes || '',
    references: (serverRisk.references || []).map(referenceMapper),
    activity: serverRisk.activity || [],
    details: serverRisk.details || [],
    steps: serverRisk.steps ?? [],
    projectId: serverRisk.project_id,
  };

  if (serverRisk.attachments && serverRisk.attachments?.length > 0) {
    clientRisk.attachments = [];
    for (const serverAttachment of serverRisk.attachments) {
      const attachment = {
        id: serverAttachment.attachment_id,
        attachment_id: serverAttachment.attachment_id,
        name: serverAttachment.name,
        content_type: serverAttachment.content_type,
      };

      // @ts-ignore - only attachment_id and name are really needed to resolve attachments
      clientRisk.attachments.push(attachment);
    }
  }

  // should really map these to a common class for better activity display
  if (serverRisk.activity && serverRisk.activity?.length > 0) {
    clientRisk.activity = [];
    for (const serverActivity of serverRisk.activity) {
      clientRisk.activity.push({
        id: serverActivity.id,
        comment: serverActivity.comment,
        user_id: serverActivity.user_id,
        timestamp: serverActivity.timestamp,
        action: serverActivity.action,
        old_value: serverActivity.old_value,
        new_value: serverActivity.new_value,
        mention_list: serverActivity.mention_list,
        updated_at: serverActivity.updated_at,
      } as Activity);
    }
  }
  return clientRisk;
};

const mapClientRiskToServer = (risk: Risk) => {
  const serverRisk: PatchRiskRequest = {
    title: risk.title,
    status_id: risk.status.id,
    assignee: risk.assignee,
    owner: risk.owner,
    notes: risk.notes?.trim() || null,
    attachments: risk.attachments,
    details: risk.details,
    steps: risk.steps,
    project_id: risk.projectId,
    assessment_matrix_column: risk.assessmentMatrixColumn,
    assessment_matrix_row: risk.assessmentMatrixRow,
    matrix_metadata: risk.matrix_metadata,
  };

  return serverRisk;
};

class RisksService {
  teamId: string;
  restUrl: string;

  constructor(teamId: string) {
    this.teamId = teamId;
    const baseUrl = `${API_URL}/teams/${this.teamId}`;
    this.restUrl = `${baseUrl}/risks`;
  }

  async listRisks(
    statusTypes?: StatusType[],
    referenceId?: string,
    referenceType?: ReferenceType
  ): Promise<ServerRisk[]> {
    const params: {
      status_types?: StatusType[];
      reference_id?: string;
      reference_type?: ReferenceType;
    } = {};
    if (statusTypes) {
      params.status_types = statusTypes;
    }
    if (referenceId) {
      params.reference_id = referenceId;
    }
    if (referenceType) {
      params.reference_type = referenceType;
    }
    const response = await superlogin.getHttp().get(this.restUrl, { params });
    return response.data.data;
  }

  async getRisk(riskId: string): Promise<Risk | undefined> {
    const url = `${this.restUrl}/${riskId}`;
    const response = await superlogin.getHttp().get(url);
    return response.data;
  }

  async createRisk(riskToCreate: AddRiskRequest): Promise<void> {
    const response = await superlogin
      .getHttp()
      .post(this.restUrl, riskToCreate);
    return response.data;
  }

  async getMetadataOptions(): Promise<MetadataOptions | undefined> {
    const url = `${this.restUrl}/metadata-options`;
    const response = await superlogin.getHttp().get(url);
    return {
      status: response.data.status.map(statusMapper),
    };
  }

  async updateRisk(risk: Risk): Promise<AxiosResponse> {
    const url = `${this.restUrl}/${risk.id}`;
    return superlogin.getHttp().patch(url, mapClientRiskToServer(risk));
  }

  async getSettings(): Promise<RiskSettings> {
    const url = `${this.restUrl}/settings`;
    const response = await superlogin.getHttp().get(url);
    return response.data;
  }

  async updateSettings(settings: RiskSettings): Promise<void> {
    const url = `${this.restUrl}/settings`;
    await superlogin.getHttp().patch(url, settings);
  }

  async addCommentToRisk(
    riskId: number,
    comment: string,
    mentionList?: Array<Mention>
  ): Promise<AxiosResponse> {
    const url = `${this.restUrl}/${riskId}/comments`;
    const body = {
      comment,
      mention_list: mentionList,
    };
    return superlogin.getHttp().post(url, body);
  }

  async editCommentInRisk(
    riskId: number,
    comment: string,
    commentId: string,
    mentionList?: Array<Mention>,
    updatedAt?: string
  ): Promise<AxiosResponse> {
    const url = `${this.restUrl}/${riskId}/comments`;
    const body = {
      comment,
      mention_list: mentionList,
      comment_id: commentId,
      updated_at: updatedAt,
    };
    return superlogin.getHttp().patch(url, body);
  }

  async signOffStep({
    riskId,
    stepIndex,
    signoffId,
    operator,
    timestamp,
  }: {
    riskId: number;
    stepIndex: number;
    signoffId: string;
    operator: string;
    timestamp: string;
  }): Promise<void> {
    const url = `${this.restUrl}/${riskId}/steps/signoff`;
    const body = {
      step_index: stepIndex,
      signoff_id: signoffId,
      operator,
      timestamp,
    };
    return superlogin.getHttp().post(url, body);
  }

  async updateRiskStep({
    riskId,
    stepIndex,
    originalStep,
    updatedStep,
  }: {
    riskId: number;
    stepIndex: number;
    originalStep: Step;
    updatedStep: Step;
  }): Promise<void> {
    const url = `${this.restUrl}/${riskId}/steps/${stepIndex}`;
    const body = { original_step: originalStep, updated_step: updatedStep };
    return superlogin.getHttp().patch(url, body);
  }
}

export default RisksService;
