import { WorkOrderSummary } from '../../api/manufacturing/work_orders/models';

export enum WorkOrderPriority {
  Critical = 'Critical',
  High = 'High',
  Medium = 'Medium',
  Low = 'Low',
}

export enum WorkOrderStatus {
  Planned = 'Planned',
  Running = 'Running',
  Complete = 'Complete',
  Canceled = 'Canceled',
}

export type WorkOrderPartRevision = {
  work_order_id: string;
  part_revision_id: string;
  quantity: number;
};

export type WorkOrderAssignee = {
  work_order_id: string;
  assignee_id: string;
};

export type WorkOrderRun = {
  work_order_id: string;
  run_id: string;
};

export type WorkOrder = {
  id: string;
  team_id: string;
  name: string;
  priority?: WorkOrderPriority;
  status: WorkOrderStatus;
  owner: string;
  project_id?: string;
  notes?: string;
};

export type DenormalizedWorkOrder = WorkOrder & {
  part_revisions: WorkOrderPartRevision[];
  assignees: WorkOrderAssignee[];
};

export type InsertWorkOrder = Omit<WorkOrder, 'id' | 'status'>;

// activity

export enum WorkOrderAction {
  Created = 'Created',
  UpdatedPriority = 'Priority Updated',
  UpdatedStatus = 'Status Updated',
  AssigneesAdded = 'Assigned',
  AssigneesRemoved = 'Unassigned',
  RunLinked = 'Run Linked',
}

export enum WorkOrderActorType {
  User = 'user',
}

export type WorkOrderActivity = {
  id: string;
  timestamp: Date;
  work_order_id: string;
  action: WorkOrderAction;
  actor_type: WorkOrderActorType;
  actor_id?: string;
  new_value?: object;
  old_value?: object;
};

export type InsertWorkOrderActivity = Omit<
  WorkOrderActivity,
  'id' | 'timestamp'
>;

export type ListWorkOrderActivities = {
  has_older: boolean;
  activities: WorkOrderActivity[];
};

export const isWorkOrderActive = (workOrder: WorkOrderSummary): boolean => {
  return (
    workOrder.status === WorkOrderStatus.Planned ||
    workOrder.status === WorkOrderStatus.Running
  );
};
