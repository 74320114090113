import { NotificationStatus } from '../notifications';
import { RealtimeData } from './realtimeUpdatesTypes';
import { Draft } from './views/procedures';
import { CreatedAt, Id, TeamId } from './postgres/util';

export enum NotificationChannels {
  Email = 'email',
  InApp = 'in_app',
  SlackChannel = 'slack_channel',
  SlackDM = 'slack_dm',
  APIClient = 'api_client',
}
export type NotificationChannel = `${NotificationChannels}`;

export enum NotificationTypes {
  Reviewers = 'reviewers',
  AtMention = 'at_mention',
  Automation = 'automation',
  UserInvite = 'user_invite',
  Tool = 'tool',
  ProcedureGeneration = 'procedure_generation',
  ProcedureGenerationFail = 'procedure_generation_fail',
  StepOperator = 'remaining_step_operators',
}
export type NotificationType = `${NotificationTypes}`;

export type NotificationPreferenceDetails = {
  slack_channel_id: string;
  slack_channel_name: string;
};

export type NotificationPreference = {
  team_id: string;
  user_id: string;
  notification_channel: NotificationChannel;
  notification_type: NotificationType;
  is_enabled: boolean;
  details?: NotificationPreferenceDetails;
};

export type SingleChannelPreferences = {
  is_enabled: boolean;
  details?: NotificationPreferenceDetails;
};

type NotificationChannelPreferences = {
  [channel in NotificationChannel]?: SingleChannelPreferences;
};

export type FormattedNotificationPreferences = {
  notification_type: NotificationType;
} & NotificationChannelPreferences;

export type NotificationChannelSettings = {
  channel: NotificationChannel;
  details?: NotificationPreferenceDetails;
};

export type SlackChannelInfo = {
  slack_channel_id: string;
  slack_channel_name: string;
};

/*
 * Context types define the information used to initialize the notification
 */

export type NotifyReviewerContext = {
  procedureId: string;
};

export type NotifyGenerationSuccessContext = {
  procedureId: string;
};

export type NotifyGenerationFailContext = {
  fileName?: string;
  procedure?: Draft;
};

export type NotifyAutomationContext = {
  runId: string;
};

export type NotifyToolContext = {
  tool_id: number;
  tool_instance_id: number;
  tool_name: string;
  tool_number: string;
  serial_number: string;
  num_days: number;
  type: 'maintenance_approaching' | 'maintenance_overdue';
};

type BasicAtMentionContext = {
  entityName: string;
  entityUrlPath: string;
};

type AtMentionReviewContext = BasicAtMentionContext & {
  procedure_id: string;
  comment_id: string;
};

type AtMentionRunContext = BasicAtMentionContext & {
  run_id: string;
  comment_id: string;
  step_id: string;
};

type AtMentionIssueContext = BasicAtMentionContext & {
  issue_id: string;
};

type AtMentionRiskContext = BasicAtMentionContext & {
  risk_id: string;
};

export type NotifyAtMentionContext =
  | BasicAtMentionContext
  | AtMentionReviewContext
  | AtMentionRunContext
  | AtMentionIssueContext
  | AtMentionRiskContext;

export type NotifyStepOperatorContext = {
  run_id: string;
  step_id: string;
  operators: Array<string>;
};

export type UserInviteContext = null;

export type NotificationContext =
  | NotifyReviewerContext
  | NotifyAutomationContext
  | NotifyAtMentionContext
  | NotifyToolContext
  | NotifyGenerationSuccessContext
  | NotifyGenerationFailContext
  | NotifyStepOperatorContext
  | UserInviteContext;

/*
 * Details types define the derived/formatted data used for the outgoing notification
 * (i.e. the data sent via email, realtime client, etc.)
 */

export type NotifyReviewerDetails = {
  review_url: string;
  procedure_id: string;
  team_name: string;
  procedure_name: string;
};

export type NotifyAutomationDetails = {
  run_id: string;
  run_name: string;
  run_url: string;
};

export type NotifyAtMentionDetails = {
  team_name: string;
  entityName: string;
  url: string;
  comment_text: string;
};

export type NotifyUserInviteDetails = {
  expires_in: number;
  web_host: string;
  token: string;
  team_name: string;
};

export type NotifyStepOperatorDetails = {
  message: string;
  run_url: string;
  section_name: string;
  step_name: string;
  remaining_operator_roles: Array<string>;
};

export type NotifyToolDetails = {
  team_name: string;
  url: string;
  tool_id: number;
  tool_instance_id: number;
  tool_name: string;
  tool_number: string;
  serial_number: string;
  num_days: number;
  type: 'maintenance_approaching' | 'maintenance_overdue';
};

export type ProcedureGenerationSuccessDetails = {
  generated_procedure_url: string;
  procedure_id: string;
  team_name: string;
  procedure_name: string;
};

export type ProcedureGenerationFailDetails = null;

export type NotificationDetails =
  | NotifyAtMentionDetails
  | NotifyAutomationDetails
  | NotifyReviewerDetails
  | NotifyToolDetails
  | NotifyUserInviteDetails
  | NotifyStepOperatorDetails
  | ProcedureGenerationSuccessDetails
  | ProcedureGenerationFailDetails;

export interface AppNotificationDisplay extends RealtimeData {
  status: NotificationStatus;
  actor_id: string;
  user_id: string;
  type: NotificationType;
  context:
    | NotifyReviewerContext
    | NotifyAutomationContext
    | NotifyAtMentionContext
    | NotifyToolContext
    | NotifyGenerationSuccessContext
    | NotifyGenerationFailContext;
  active?: boolean;
  is_archived: boolean;
}

export interface AppNotificationReviewersDisplay
  extends AppNotificationDisplay {
  type: NotificationTypes.Reviewers;
  context: NotifyReviewerContext;
}

export enum SubscriptionEnum {
  Tool = 'tool',
}
export type SubscriptionEntityType = `${SubscriptionEnum}`;

export type Subscription =
  | Id &
      TeamId & {
        user_id: string;
        entity_type: SubscriptionEntityType;
        entity_id: string;
        channel: NotificationChannel;
      } & CreatedAt;
