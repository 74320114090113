import { FieldInputProps } from 'formik';
import { useCallback, useEffect, useMemo, useState } from 'react';
import AsyncSelect from 'react-select/async';
import { SimulatedFields } from 'shared/lib/telemetry';
import { Unit } from 'shared/lib/types/api/settings/units/models';
import { TelemetryType } from 'shared/lib/types/postgres/telemetry';
import { DraftTelemetryBlock } from 'shared/lib/types/views/procedures';
import { useDatabaseServices } from '../contexts/DatabaseContext';
import { useProcedureContext } from '../contexts/ProcedureContext';
import { DatabaseServices } from '../contexts/proceduresSlice';
import useDictionaries from '../hooks/useDictionaries';
import useUnits from '../hooks/useUnits';
import apm from '../lib/apm';
import { TelemetryModel } from '../lib/models/postgres/telemetry';
import { reactSelectStyles } from '../lib/styles';

type TelemetryOption = {
  value: string | number;
  label: string;
  name?: string;
  units?: string;
  dictionary?: string;
  dictionaryId?: number;
  description?: string;
  type?: TelemetryType;
};

type ParameterUpdate = {
  name: string;
  units?: string;
  parameter_id?: string | number;
  dictionary_id?: number;
  type?: TelemetryType;
};

const getOptionLabel = (name: string, units?: string) => {
  const unitsSuffix = units ? ` (${units})` : '';
  return `${name}${unitsSuffix}`;
};

const getOption = (
  content: DraftTelemetryBlock,
  findDefinedUnit?: (u: string) => Unit | undefined
): TelemetryOption | undefined => {
  const parameterName = content.name || '';
  if (!parameterName) {
    return undefined;
  }

  let labelUnits = content.units;
  if (labelUnits && findDefinedUnit) {
    const settingsUnit = findDefinedUnit(labelUnits);
    labelUnits = settingsUnit?.abbreviation || labelUnits;
  }

  // new approach uses a number parameter_id direct reference.  Old used string parameter name
  return {
    value: content.parameter_id ? content.parameter_id : parameterName,
    label: getOptionLabel(parameterName, labelUnits),
    name: parameterName,
    units: content.units,
    dictionaryId: content.dictionary_id,
  };
};

// Return just the dictionary name and label for display in the select box
const plainOption = (option: TelemetryOption): TelemetryOption => {
  return {
    value: option.value,
    label: option.label,
    name: option.name,
    units: option.units,
    dictionary: option.dictionary,
    dictionaryId: option.dictionaryId,
  };
};

interface FieldSetTelemetrySelectProps {
  content: DraftTelemetryBlock;
  field: FieldInputProps<string>;
  onChange: (parameter: ParameterUpdate) => void;
  onClear: () => void;
  isDisabled?: boolean;
}

const FieldSetTelemetrySelect = ({
  content,
  field,
  onChange,
  onClear,
  isDisabled = false,
}: FieldSetTelemetrySelectProps) => {
  const { services }: { services: DatabaseServices } = useDatabaseServices();
  const { findDefinedUnit } = useUnits();
  const [defaultOptions, setDefaultOptions] = useState<Array<TelemetryOption>>([]);
  const [selectValue, setSelectValue] = useState<TelemetryOption | undefined | null>(
    getOption(content, findDefinedUnit)
  );

  const clearValue = useCallback(() => {
    setSelectValue(null);
    onClear();
  }, [onClear]);

  const onChangeHandler = useCallback(
    (option: TelemetryOption) => {
      if (!option) {
        clearValue();
        return;
      }

      const parameter: ParameterUpdate = {
        name: option.name || option.label,
        units: option.units,
        parameter_id: option.value,
        dictionary_id: option.dictionaryId,
        type: option.type,
      };
      onChange(parameter);
      setSelectValue(plainOption(option));
    },
    [clearValue, onChange]
  );

  const { procedure } = useProcedureContext();
  const { dictionaries } = useDictionaries();

  const dictionaryMap = useMemo<Record<number, string>>(
    () => Object.assign({}, ...dictionaries.map((dictionary) => ({ [dictionary.id]: dictionary.name }))),
    [dictionaries]
  );

  const dictionary_ids = useMemo(() => {
    if (procedure?.dictionary_id) {
      return [procedure.dictionary_id];
    }
    return [];
  }, [procedure.dictionary_id]);

  const isSimulationField = (fieldName: string) => {
    return SimulatedFields[fieldName] !== undefined;
  };

  const searchTelemetrySimulations = useCallback(
    async (searchTerm: string): Promise<Array<TelemetryOption>> => {
      if (dictionary_ids.length) {
        return Promise.resolve([]);
      }
      const results = Object.keys(SimulatedFields)
        .filter((key) => key.toLowerCase().includes(searchTerm.toLowerCase()))
        .map((key) => ({
          value: key,
          label: key,
          dictionary: 'SIM',
          description: SimulatedFields[key].description,
        }));
      return Promise.resolve(results);
    },
    [dictionary_ids.length]
  );

  const searchTelemetryService = useCallback(
    async (searchTerm: string): Promise<Array<TelemetryOption>> => {
      return services.telemetry
        .searchParameters(searchTerm, dictionary_ids)
        .then((parameters: Array<TelemetryModel>) => {
          return parameters.map((parameter) => {
            const units = parameter.units;
            const settingsUnit = units ? findDefinedUnit(units) : undefined;
            return {
              value: parameter.id,
              label: getOptionLabel(parameter.name, settingsUnit?.abbreviation || units),
              name: parameter.name,
              units: parameter.units,
              dictionary: parameter.dictionary_name,
              dictionaryId: parameter.dictionary_id,
              description: parameter.description,
              type: parameter.type,
            };
          });
        })
        .catch(() => []);
    },
    [dictionary_ids, services.telemetry, findDefinedUnit]
  );

  // Returns matching simulated telemetry, plus matching telemetry from the external API if any exist.
  const searchTelemetry = useCallback(
    async (searchTerm: string): Promise<Array<TelemetryOption>> => {
      return Promise.all([searchTelemetrySimulations(searchTerm), searchTelemetryService(searchTerm)]).then(
        ([simulated, service]) => simulated.concat(service)
      );
    },
    [searchTelemetrySimulations, searchTelemetryService]
  );

  /*
   * When dictionary selection changes, fetch params from the server to update the
   * select box default options (shown on down arrow click) and also update the
   * selection to be valid given the new dictionary selection
   */
  useEffect(() => {
    searchTelemetry('')
      .then((options) => {
        setDefaultOptions(options);
      })
      .catch((err) => apm.captureError(err));
  }, [searchTelemetry]);

  /*
   * When the telemetry content changes, we need to resolve the dictionary name from the dictionaryId
   * for display in the select since we don't store or pass dictionary name in the content for now
   */
  useEffect(() => {
    if (!selectValue) {
      return;
    }

    if (isSimulationField(selectValue.label) && !selectValue.dictionary) {
      setSelectValue((current) => {
        if (current) {
          return {
            ...current,
            dictionary: 'SIM',
          };
        }
      });
      return;
    }

    if (selectValue.dictionaryId && !selectValue.dictionary) {
      if (dictionaryMap && dictionaryMap[selectValue.dictionaryId]) {
        const val = dictionaryMap[selectValue.dictionaryId];
        setSelectValue((current) => {
          if (current) {
            return {
              ...current,
              dictionary: val,
            };
          }
        });
      }
    }
  }, [dictionaryMap, selectValue]);

  // if content changes from props, we need to update the select value
  useEffect(() => {
    if (!content.name) {
      setSelectValue(null);
    } else {
      setSelectValue(getOption(content, findDefinedUnit));
    }
  }, [content, findDefinedUnit]);

  const formatOptionLabel = ({ label, dictionary, description }: TelemetryOption) => (
    <div className="flex flex-col">
      <div className="flex flex-row gap-2 items-center">
        {dictionary && <div className="font-semibold ">{dictionary}</div>}
        <div className="">{label}</div>
      </div>
      <div className="text-sm text-gray-400 truncate">{description}</div>
    </div>
  );

  return (
    <div className="grow w-full">
      <div className="grow relative">
        <div>
          <AsyncSelect
            styles={reactSelectStyles}
            classNamePrefix="react-select"
            name={field.name}
            loadOptions={searchTelemetry}
            onBlur={field.onBlur}
            onChange={onChangeHandler}
            placeholder="Search by name"
            value={selectValue}
            formatOptionLabel={formatOptionLabel}
            defaultOptions={defaultOptions}
            isDisabled={isDisabled}
          />
        </div>
      </div>
    </div>
  );
};

export default FieldSetTelemetrySelect;
