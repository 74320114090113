import { Part, UsageType } from 'shared/lib/types/postgres/manufacturing/types';
import { DraftPartUsageBlock, RunPartUsageRecorded } from 'shared/lib/types/views/procedures';
import { StyleVariant } from '../../elements/lib/fieldStyles';
import { PartSelection } from './PartAndRevisionIdSelect';
import PartSelect from './PartSelect';
import PartUsageTypeSelect from './PartUsageTypeSelect';
import { getCellClass } from './util/tableUtil';
import PartBadge from './PartBadge';
import Button, { BUTTON_TYPES } from '../../components/Button';
import { faTimesCircle } from '@fortawesome/free-solid-svg-icons';
import { useCallback } from 'react';
import { cloneDeep } from 'lodash';

interface PartAndUsageTypeSelectProps {
  content?: DraftPartUsageBlock;
  onChangePartDuringRun?: (part: Part | null) => void;
  onChangeUsage?: (usageType: UsageType) => void;
  recorded?: Array<RunPartUsageRecorded>;
  projectId?: string;
  isEnabled: boolean;
  isStepComplete: boolean;
}

const PartAndUsageTypeSelect = ({
  content,
  onChangePartDuringRun,
  onChangeUsage,
  recorded,
  projectId,
  isEnabled,
  isStepComplete,
}: PartAndUsageTypeSelectProps) => {
  const onChangePartSelection = (partSelection: PartSelection | null) => {
    onChangePartDuringRun?.(partSelection?.part ?? null);
  };

  const usageTypeSelected = (recorded ? recorded?.[0]?.usage_type : content?.usage_types?.[0]) ?? null;
  const partSelected = recorded ? recorded[0]?.part : content?.part;

  const isPartSelectorEnabled = isEnabled;
  const isUsageSelectorEnabled = partSelected ? isEnabled && true : false;

  const usageTypes: Array<UsageType> = [];
  if (content?.part?.usage_types?.length) {
    usageTypes.push(...content.part.usage_types);
  }

  if (recorded && recorded[0] && recorded[0].part && recorded[0].part.usage_types?.length) {
    const { usage_types } = recorded[0].part;
    if (usage_types) {
      usageTypes.push(...usage_types);
    }
  }

  const onRevChange = useCallback(
    (revisionLabel: string) => {
      if (!partSelected || !onChangePartDuringRun) {
        return;
      }
      const updated = cloneDeep(partSelected);
      updated.rev = revisionLabel;
      onChangePartDuringRun(updated);
    },
    [onChangePartDuringRun, partSelected]
  );

  const onChangeUsageType = (option) => {
    if (!option) {
      return;
    }
    const usageType = usageTypes.find((t) => t.name === option.value);

    if (!usageType) {
      return;
    }
    onChangeUsage && onChangeUsage(usageType);
  };

  return (
    <>
      <td className={getCellClass(!isPartSelectorEnabled)}>
        {partSelected && (
          <div className="flex px-1 justify-between">
            <PartBadge
              inline={true}
              part={partSelected}
              teamId=""
              partRestricted={false}
              onRevChange={isEnabled && !isStepComplete ? onRevChange : undefined}
            />
            {!isStepComplete && (
              <Button
                leadingIcon={faTimesCircle}
                iconTextColor="text-gray-400"
                type={BUTTON_TYPES.TERTIARY}
                onClick={() => onChangePartSelection(null)}
              />
            )}
          </div>
        )}
        {!partSelected && (
          <PartSelect
            isDisabled={!isPartSelectorEnabled}
            projectId={projectId}
            selected={null}
            onSelect={onChangePartSelection}
            variant={StyleVariant.Grid}
          />
        )}
      </td>
      <td className={getCellClass(!isUsageSelectorEnabled)}>
        <PartUsageTypeSelect
          selected={usageTypeSelected}
          usageTypes={usageTypes}
          onChange={onChangeUsageType}
          canCreate={false}
          isEnabled={isUsageSelectorEnabled}
          variant={StyleVariant.Grid}
        />
      </td>
    </>
  );
};

export default PartAndUsageTypeSelect;
