import { useCallback, useMemo } from 'react';
import runUtil, { StepState } from 'shared/lib/runUtil';
import { RunToolCheckInBlock, RunToolCheckOutBlock } from 'shared/lib/types/views/procedures';
import SubstepNumber from '../../../components/SubstepNumber';
import { NumberSelectOption as SelectOption } from '../../../lib/formik';
import { generateHiddenClassString } from '../../../lib/styles';
import useTool from '../../hooks/useTool';
import ToolCheckOutInBase from './ToolCheckOutInBase';

interface RunToolCheckOutInProps {
  content: RunToolCheckOutBlock | RunToolCheckInBlock;
  type: 'out' | 'in';
  onRecordValuesChanged?: (
    contentId: string,
    recorded: RunToolCheckOutBlock['recorded'] | RunToolCheckInBlock['recorded']
  ) => void;
  recorded?: RunToolCheckOutBlock['recorded'] | RunToolCheckInBlock['recorded'];
  isEnabled: boolean;
  stepState: StepState | undefined;
  teamId: string;
  blockLabel?: string;
  isHidden?: boolean;
}

const RunToolCheckOutIn = ({
  content,
  type,
  onRecordValuesChanged,
  recorded,
  isEnabled,
  stepState,
  teamId,
  blockLabel,
  isHidden,
}: RunToolCheckOutInProps) => {
  const { tool } = useTool({ toolId: recorded?.tool_id ?? content.tool_id });
  const value = useMemo(() => recorded ?? content, [content, recorded]);
  const isStepStateEnded = useMemo(() => runUtil.isStepStateEnded(stepState), [stepState]);

  const onToolInstanceChange = useCallback(
    (toolInstanceOption: SelectOption) => {
      const newValue: RunToolCheckOutBlock['recorded'] | RunToolCheckInBlock['recorded'] = {
        ...value,
        tool_instance_id: toolInstanceOption?.value,
      };
      if (onRecordValuesChanged) {
        onRecordValuesChanged(value.id, newValue);
      }
    },
    [onRecordValuesChanged, value]
  );

  return (
    <tr>
      <td></td>
      <td colSpan={2}>
        <div className={generateHiddenClassString('', isHidden)} />
        <div className={generateHiddenClassString('mt-3 ml-4 flex flex-wrap page-break', isHidden)}>
          <SubstepNumber blockLabel={blockLabel} hasExtraVerticalSpacing={false} />
          <div className="w-full py-1 mr-8">
            <ToolCheckOutInBase
              tool={tool}
              type={type}
              toolInstanceId={recorded?.tool_instance_id}
              teamId={teamId}
              isEnabled={isEnabled}
              isStepStateEnded={isStepStateEnded}
              onToolInstanceChange={onToolInstanceChange}
            />
          </div>
        </div>
      </td>
    </tr>
  );
};

export default RunToolCheckOutIn;
