import React, { Fragment, useRef, useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { ControlledMenu, MenuItem, useClick } from '@szhsin/react-menu';
import Icon from '../../elements/Icon';
import { ContentMenuItem, MenuActionItem, isLabel, isAction } from './addContentTypes';

interface ContentMenuProps {
  menuItems: Array<ContentMenuItem>;
  onClick: (menuItem: MenuActionItem) => void;
  includeTextLabel?: boolean;
}

const menuItemStyle =
  'text-gray-600 hover:bg-blue-100 cursor-pointer rounded py-2 px-4 flex flex-row flex-nowrap gap-x-2 place-items-center';

const AddContentMenu = ({ menuItems, onClick, includeTextLabel = false }: ContentMenuProps) => {
  const ref = useRef(null);
  const [isOpen, setOpen] = useState(false);
  const anchorProps = useClick(isOpen, setOpen);

  return (
    <div aria-label="Content Menu">
      <button
        ref={ref}
        {...anchorProps}
        type="button"
        aria-label="Add Content"
        className="hover:brightness-50 cursor-pointer h-6 focus:opacity-100"
      >
        <FontAwesomeIcon className="text-blue-500 opacity-100" icon="circle-plus" />
        {includeTextLabel && <span className=" font-semibold ml-1">Add Content</span>}
      </button>
      {isOpen && (
        <ControlledMenu
          state={isOpen ? 'open' : 'closed'}
          anchorRef={ref}
          onClose={() => setOpen(false)}
          direction="bottom"
          align="start"
          offsetX={8}
          menuClassName="bg-white shadow-xl rounded-md border border-gray-200 m-2 pb-1 z-50 text-black max-h-[30rem] overflow-y-auto"
        >
          {menuItems.map((menuItem, index) => (
            <Fragment key={menuItem.key ?? menuItem.label}>
              {isLabel(menuItem) && (
                <div
                  key={menuItem.key ?? menuItem.label}
                  className={`text-sm text-gray-500 ${index > 0 ? 'border-t' : ''} border-gray-200 pt-1 pl-2`}
                >
                  {menuItem.label !== 'Actions' && menuItem.label}
                </div>
              )}

              {/* need to actually be more explicit here to handle action menus and non blocktype menus */}
              {isAction(menuItem) && !menuItem.hidden && menuItem.group !== 'Actions' && (
                <MenuItem
                  key={menuItem.key ?? menuItem.label}
                  className={`${menuItemStyle} m-1 h-18`}
                  onClick={() => onClick(menuItem)}
                >
                  <div className="flex place-items-center place-content-center border border-gray-300 w-8 h-8 rounded">
                    {menuItem.icon && <Icon element={menuItem.icon} className="w-4 self-center text-lg" />}
                  </div>
                  <div className="flex flex-col w-52">
                    <div aria-label={menuItem.label} className=" whitespace-nowrap font-semibold">
                      {menuItem.label}
                    </div>
                    <div className="text-sm brightness-110">{menuItem.description}</div>
                  </div>
                </MenuItem>
              )}

              {isAction(menuItem) && !menuItem.hidden && menuItem.group === 'Actions' && (
                <MenuItem
                  key={menuItem.key ?? menuItem.label}
                  className={`${menuItemStyle} mx-1 h-8`}
                  onClick={() => onClick(menuItem)}
                >
                  <div className="flex flex-row place-items-center gap-x-3 h-8 w-52">
                    {menuItem.icon && <Icon element={menuItem.icon} className="w-4 self-center text-lg" />}
                    <div aria-label={menuItem.label} className=" whitespace-nowrap font-semibold">
                      {menuItem.label}
                    </div>
                  </div>
                  <div className="flex-grow text-right text-sm brightness-150">{menuItem.keyboardShortcut}</div>
                </MenuItem>
              )}
            </Fragment>
          ))}
        </ControlledMenu>
      )}
    </div>
  );
};

export default React.memo(AddContentMenu);
