/*
 * This file is meant for building paths that are used for front-end navigation.
 * This file is not meant for building URLs for requests to the API.
 */
import { getScrollToUrlParams } from 'shared/lib/scrollToUtil';

const TEAM_SCREEN_BASE_PATH = '/app/team';
const basePath = (teamId: string) => `${TEAM_SCREEN_BASE_PATH}/${teamId}`;
const buildTeamPath = (teamId: string, ...additionalPath: Array<string>) =>
  [`${basePath(teamId)}`, ...additionalPath].join('/');

const ORG_SCREEN_BASE_PATH = '/app/organizations';
const baseOrgPath = (orgId: string) => `${ORG_SCREEN_BASE_PATH}/${orgId}`;
const buildOrgPath = (orgId: string, ...additionalPath: Array<string>) =>
  [`${baseOrgPath(orgId)}`, ...additionalPath].join('/');

const addQueryParams = (path: string, queryParams: URLSearchParams): string => {
  const queryString = queryParams.toString();
  if (queryString) {
    return `${path}?${queryString}`;
  }
  return path;
};

const anchorPath = (teamId: string, root: string, anchor?: string): string => {
  const path = buildTeamPath(teamId, root);
  if (anchor) {
    return `${path}#${anchor.replace('#', '')}`;
  } else {
    return path;
  }
};

// Home Path
export const homePath = (teamId: string, anchor?: string): string =>
  buildTeamPath(teamId, '');

// Search Path
export const searchPath = (teamId: string): string =>
  buildTeamPath(teamId, 'search');

// Procedure Paths
export const proceduresPath = (teamId: string, anchor?: string): string =>
  anchorPath(teamId, 'procedures', anchor);
export const runsPath = (teamId: string, anchor?: string): string =>
  anchorPath(teamId, 'runs', anchor);

export const snippetsPath = (teamId: string): string =>
  buildTeamPath(teamId, 'snippets');

export const snippetViewPath = (
  teamId = ':teamId',
  snippetId = ':id',
  isTestingModule = false
): string => {
  const link = buildTeamPath(teamId, 'snippets', snippetId);

  return isTestingModule ? `${link}?isTestingModule=true` : link;
};

export const newSnippetViewPath = (
  teamId: string,
  type: string,
  isTestingModule = false
): string => {
  const path = buildTeamPath(teamId, 'snippets', 'new');
  const typeParam = `type=${type}`;
  const searchParams = isTestingModule
    ? `${typeParam}&isTestingModule=true`
    : typeParam;

  return addQueryParams(path, new URLSearchParams(searchParams));
};

export const procedureImportPath = (
  teamId: string,
  importUUID: string
): string => buildTeamPath(teamId, 'procedures', 'import', importUUID);
export const procedureViewPath = (
  teamId: string,
  procedureId: string
): string => buildTeamPath(teamId, 'procedures', procedureId);
export const procedureViewVersionPath = (
  teamId: string,
  procedureId: string,
  version: string
): string =>
  buildTeamPath(teamId, 'procedures', procedureId, 'version', version);

export const procedureViewStepPath = (
  teamId: string,
  procedureId: string,
  stepId: string
): string =>
  addQueryParams(
    buildTeamPath(teamId, 'procedures', procedureId),
    new URLSearchParams(`scrollToId=${stepId}&scrollToType=step`)
  );

export const procedureViewSectionPath = (
  teamId: string,
  procedureId: string,
  sectionId: string
): string =>
  addQueryParams(
    buildTeamPath(teamId, 'procedures', procedureId),
    new URLSearchParams(`scrollToId=${sectionId}&scrollToType=section`)
  );

export const procedureEditPath = (
  teamId: string,
  procedureId: string
): string => buildTeamPath(teamId, 'procedures', procedureId, 'edit');
export const procedurePendingPath = (
  teamId: string,
  procedureId: string
): string => buildTeamPath(teamId, 'procedures', procedureId, 'pending');
export const procedureReviewPath = (
  teamId: string,
  procedureId: string
): string => buildTeamPath(teamId, 'procedures', procedureId, 'review');

export const procedureDiffPath = (teamId: string): string =>
  buildTeamPath(teamId, 'diff');

export const procedureRunsPath = (
  teamId: string,
  procedureId: string
): string => buildTeamPath(teamId, 'procedures', procedureId, 'runs');
export const procedureSnapshotViewPathWithSourceUrl = ({
  teamId,
  procedureId,
  sectionId,
  sourceUrl,
  sourceName,
}: {
  teamId: string;
  procedureId: string;
  sectionId?: string;
  sourceUrl: string;
  sourceName: string;
}): string => {
  const encodedSourceUrl = encodeURIComponent(sourceUrl);
  const encodedSourceName = encodeURIComponent(sourceName);

  let url = `${buildTeamPath(
    teamId,
    'procedures',
    procedureId
  )}?sourceUrl=${encodedSourceUrl}&sourceName=${encodedSourceName}&view=snapshot`;
  if (sectionId) {
    url += `&sectionId=${sectionId}`;
  }
  return url;
};

// Operations Paths
export const operationsDashboardPath = (teamId: string): string =>
  buildTeamPath(teamId, 'planning', 'operations');
export const operationViewPath = (
  teamId: string,
  operationId: string,
  autoencode = true
): string => {
  // The operation id may contain unsavory characters.  Encoding them breaks the react router, but not encoding them breaks actual usage
  return buildTeamPath(
    teamId,
    'planning',
    'operations',
    autoencode ? encodeURIComponent(operationId) : operationId
  );
};

// Run Paths
export const runIndexPath = (teamId: string): string =>
  buildTeamPath(teamId, '/');
export const runViewPath = (teamId: string, runId: string): string =>
  buildTeamPath(teamId, 'runs', runId);
export const runSummaryPath = (teamId: string, runId: string): string =>
  buildTeamPath(teamId, 'runs', runId, 'summary');
export const runViewStepPath = (
  teamId: string,
  runId: string,
  stepId: string
): string =>
  addQueryParams(
    buildTeamPath(teamId, 'runs', runId),
    new URLSearchParams(`scrollToId=${stepId}&scrollToType=step`)
  );

// Projects Path
export const projectSettingsPath = (
  teamId: string,
  projectId: string
): string => buildTeamPath(teamId, 'projects', projectId, 'settings');

// Team Settings
export const teamSettingsPath = (teamId: string): string =>
  buildTeamPath(teamId, 'settings');
export const teamSettingsProjectsPath = (teamId: string): string =>
  buildTeamPath(teamId, 'settings#projects');
export const teamSettingsUsersPath = (teamId: string): string =>
  buildTeamPath(teamId, 'settings#users');
export const teamSettingsSnippetsPath = (teamId: string): string =>
  buildTeamPath(teamId, 'settings#snippets');

// Org Settings
export const orgSettingsPath = (orgId: string): string =>
  buildOrgPath(orgId, 'settings');
export const orgSettingsBillingPath = (orgId: string): string =>
  buildOrgPath(orgId, 'settings#billing');

// Data & Analytics Path
export const storagePath = (teamId: string): string =>
  buildTeamPath(teamId, 'storage');

// Testing Paths.
export const testingPath = (teamId: string): string =>
  buildTeamPath(teamId, 'testing');
export const testingSettingsPath = (teamId: string, anchor?: string): string =>
  anchorPath(teamId, 'testing/settings', anchor);

export const testingRequirementsPath = (teamId: string): string =>
  buildTeamPath(teamId, 'testing/requirements');

export const testingRequirementsViewPath = (
  teamId: string,
  requirementId: string
): string => buildTeamPath(teamId, 'testing', 'requirements', requirementId);

export const testingRequirementsEditPath = (
  teamId: string,
  requirementId: string
): string =>
  buildTeamPath(teamId, 'testing', 'requirements', requirementId, 'edit');

export const testingRequirementsReviewPath = (
  teamId: string,
  requirementId: string
): string =>
  buildTeamPath(teamId, 'testing', 'requirements', requirementId, 'review');

export const testingRequirementsVersionPath = (
  teamId: string,
  requirementId: string,
  versionId: number
): string =>
  buildTeamPath(
    teamId,
    'testing',
    'requirements',
    requirementId,
    'versions',
    `${versionId}`
  );

export const testingConditionsPath = (teamId: string): string =>
  buildTeamPath(teamId, 'testing/conditions');
export const testingConditionsSettingsPath = (teamId: string): string =>
  buildTeamPath(teamId, 'testing/settings#conditions');
export const testingConditionsViewPath = (
  teamId: string,
  conditionId: string
): string => buildTeamPath(teamId, 'testing', 'conditions', conditionId);

export const testingHazardsPath = (teamId: string): string =>
  buildTeamPath(teamId, 'testing/hazards');
export const testingHazardsViewPath = (
  teamId: string,
  hazardId: string
): string => buildTeamPath(teamId, 'testing', 'hazards', hazardId);
export const testingTestCasesPath = (teamId: string): string =>
  buildTeamPath(teamId, 'testing', 'test-cases');

export const testingTestCasesViewPath = (
  teamId: string,
  caseId: string
): string => buildTeamPath(teamId, 'testing', 'test-cases', caseId);

export const testingTestCasesEditPath = (
  teamId: string,
  caseId: string
): string => buildTeamPath(teamId, 'testing', 'test-cases', caseId, 'edit');

export const testingTestCasesReviewPath = (
  teamId: string,
  caseId: string
): string => buildTeamPath(teamId, 'testing', 'test-cases', caseId, 'review');

export const testingTestCasesVersionPath = (
  teamId: string,
  caseId: string,
  versionId: number
): string =>
  buildTeamPath(
    teamId,
    'testing',
    'test-cases',
    caseId,
    'versions',
    `${versionId}`
  );
export const testingPlansPath = (teamId: string): string =>
  buildTeamPath(teamId, 'testing', 'plans');
export const testingTestConditionsMatrixPath = (teamId: string): string =>
  buildTeamPath(teamId, 'testing', 'test-cases', 'matrix');
export const testingNewTestCasePath = (teamId: string): string =>
  buildTeamPath(teamId, 'testing', 'test-cases', 'new');
export const testingHazardVersionPath = (
  teamId: string,
  hazardId: string,
  versionId: number
): string =>
  buildTeamPath(
    teamId,
    'testing',
    'hazards',
    hazardId,
    'versions',
    `${versionId}`
  );
export const testingNewHazardPath = (teamId: string): string =>
  buildTeamPath(teamId, 'testing', 'hazards', 'new');
export const testingNewConditionPath = (teamId: string): string =>
  buildTeamPath(teamId, 'testing', 'conditions', 'new');
export const testingNewRequirementPath = (teamId: string): string =>
  buildTeamPath(teamId, 'testing', 'requirements', 'new');

export const testingSnippetListPath = (teamId: string): string =>
  buildTeamPath(teamId, 'testing', 'settings#testSnippets');

// Manufacturing Paths.
export const manufacturingPath = (teamId: string): string =>
  buildTeamPath(teamId, 'builds');
export const partIndexPath = (teamId: string): string =>
  buildTeamPath(teamId, 'builds', 'parts');
export const partPath = (
  teamId: string,
  partId: string,
  revisionId?: string
): string => {
  const path = buildTeamPath(teamId, 'builds', 'parts', partId);
  if (revisionId) {
    return addQueryParams(
      path,
      new URLSearchParams(`revisionId=${revisionId}`)
    );
  }
  return path;
};
export const partNewPath = (teamId: string): string =>
  buildTeamPath(teamId, 'builds', 'parts', 'new');
export const partEditPath = (teamId: string, partId: string): string =>
  buildTeamPath(teamId, 'builds', 'parts', partId, 'edit');
export const workOrdersIndexPath = (teamId: string): string =>
  buildTeamPath(teamId, 'builds', 'work-orders');
export const workOrderIndexPath = (
  teamId: string,
  workOrderId: string
): string => buildTeamPath(teamId, 'builds', 'work-orders', workOrderId);
export const inventoryIndexPath = (teamId: string): string =>
  buildTeamPath(teamId, 'builds', 'inventory');
export const inventoryAddPath = (teamId: string): string =>
  buildTeamPath(teamId, 'builds', 'inventory', 'add');
export const inventoryItemPath = (teamId: string, itemId: string): string =>
  buildTeamPath(teamId, 'builds', 'inventory', itemId);
export const inventoryEditPath = (teamId: string, itemId: string): string =>
  buildTeamPath(teamId, 'builds', 'inventory', itemId, 'edit');

// Manufacturing - Tools Paths.
export const toolsIndexPath = (teamId: string): string =>
  buildTeamPath(teamId, 'builds', 'tools');
export const toolDetailPath = (teamId: string, toolId: number): string =>
  buildTeamPath(teamId, 'builds', 'tools', toolId.toString());
export const toolInstanceDetailPath = (
  teamId: string,
  toolId: number,
  toolInstanceId: number
): string =>
  buildTeamPath(
    teamId,
    'builds',
    'tools',
    toolId.toString(),
    'instances',
    toolInstanceId.toString()
  );
export const toolNewPath = (teamId: string): string =>
  buildTeamPath(teamId, 'builds', 'tools', 'new');
export const toolEditPath = (teamId: string, toolId: number): string =>
  buildTeamPath(teamId, 'builds', 'tools', toolId.toString(), 'edit');
export const toolInstanceNewPath = (teamId: string, toolId: number): string =>
  buildTeamPath(
    teamId,
    'builds',
    'tools',
    toolId.toString(),
    'instances',
    'new'
  );
export const toolInstanceEditPath = (
  teamId: string,
  toolId: number,
  toolInstanceId: number
): string =>
  buildTeamPath(
    teamId,
    'builds',
    'tools',
    toolId.toString(),
    'instances',
    toolInstanceId.toString(),
    'edit'
  );

// Manufacturing - Orders Paths.
export const ordersIndexPath = (teamId: string): string =>
  buildTeamPath(teamId, 'builds', 'orders');
export const orderPath = (teamId: string, orderId: string): string =>
  buildTeamPath(teamId, 'builds', 'orders', orderId);
export const orderNewPath = (teamId: string): string =>
  buildTeamPath(teamId, 'builds', 'orders', 'new');
export const orderEditPath = (teamId: string, orderId: string): string =>
  buildTeamPath(teamId, 'builds', 'orders', orderId, 'edit');
export const orderCheckInPath = (teamId: string, orderId: string): string =>
  buildTeamPath(teamId, 'builds', 'orders', orderId, 'checkin');
export const vendorsIndexPath = (teamId: string): string =>
  buildTeamPath(teamId, 'builds', 'vendors');
export const locationsIndexPath = (teamId: string): string =>
  buildTeamPath(teamId, 'builds', 'locations');
export const locationsEditPath = (teamId: string): string =>
  buildTeamPath(teamId, 'builds', 'locations', 'edit');
export const manufacturingSettingsPath = (teamId: string): string =>
  buildTeamPath(teamId, 'builds', 'settings');
export const manufacturingInsightsPath = (teamId: string): string =>
  buildTeamPath(teamId, 'builds', 'insights');

// Schedule Path
export const schedulePath = (teamId: string): string =>
  buildTeamPath(teamId, 'planning', 'schedule');
export const eventPath = (teamId: string, eventId: string): string =>
  buildTeamPath(teamId, 'planning', 'event', eventId);
export const legacyEventPath = (teamId: string, eventId: string): string =>
  buildTeamPath(teamId, 'planning', 'event', eventId, 'edit');

// Reports and Insights Path
export const insightsPath = (teamId: string): string =>
  buildTeamPath(teamId, 'insights');

export const issuesPath = (teamId: string): string =>
  buildTeamPath(teamId, 'issues');
export const issuesPathWithStatus = (teamId: string, status: string): string =>
  addQueryParams(
    buildTeamPath(teamId, 'issues'),
    new URLSearchParams(`status=${status}`)
  );
export const issuePath = (teamId: string, issueId: string): string =>
  buildTeamPath(teamId, 'issues', issueId);
export const issuesSettingsPath = (teamId: string): string =>
  buildTeamPath(teamId, 'issues', 'settings');

export const notificationsPath = (teamId: string): string =>
  buildTeamPath(teamId, 'notifications');

export const risksPath = (teamId: string): string =>
  buildTeamPath(teamId, 'risks');
export const riskPath = (teamId: string, riskId: string): string =>
  buildTeamPath(teamId, 'risks', riskId);
export const risksPathWithStatus = (teamId: string, status: string): string =>
  addQueryParams(
    buildTeamPath(teamId, 'risks'),
    new URLSearchParams(`status=${status}`)
  );
export const risksSettingsPath = (teamId: string): string =>
  buildTeamPath(teamId, 'risks', 'settings');

export const getRedlineSourceStepPath = ({
  teamId,
  redline,
}: {
  teamId: string;
  redline: { run_id: string; source_step_id?: string };
}): string => {
  if (!redline.source_step_id) {
    return runViewPath(teamId, redline.run_id);
  }

  return `${runViewPath(teamId, redline.run_id)}${getScrollToUrlParams({
    id: redline.source_step_id,
    type: 'step',
  })}`;
};

export const getSourcePath = ({
  teamId,
  runId,
  sectionId,
  stepId,
}: {
  teamId: string;
  runId: string;
  sectionId?: string;
  stepId?: string;
}): string => {
  if (stepId) {
    return `${runViewPath(teamId, runId)}${getScrollToUrlParams({
      id: stepId,
      type: 'step',
    })}`;
  }

  if (sectionId) {
    return `${runViewPath(teamId, runId)}${getScrollToUrlParams({
      id: sectionId,
      type: 'section',
    })}`;
  }

  return runViewPath(teamId, runId);
};
