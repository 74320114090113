import React, { useCallback, useContext, useEffect, useState } from 'react';
import { Project } from 'shared/lib/types/couch/settings';
import { useHistory } from 'react-router-dom';
import projectUtil, { ProjectsFilter, ItemWithProject } from '../lib/projectUtil';
import { useSettings } from './SettingsContext';
import usePersistedView, { usePersistedViewReturns } from '../components/Home/usePersistedView';
import { ViewTab } from 'shared/lib/types/postgres/users';

interface Props {
  children: React.ReactNode;
}

export type NavState = {
  isCollapsed: boolean;
  setIsCollapsed: (isCollapsed: boolean) => void;
  setProjectId: (projectId?: string) => void;
  persistedView: usePersistedViewReturns;
  filterByProject: FilterByProjectFunction;
  projectsFilter: ProjectsFilter | null;
};

export type FilterByProjectFunction = <T extends ItemWithProject>(items: Array<T>) => Array<T>;

const getProjectsFilter = (projectId: string, projects: Array<Project>): ProjectsFilter | null => {
  const project = projectUtil.getProjectStub(projectId, projects);
  if (!project) {
    return null;
  }
  const subprojects = projectUtil.getActiveSubprojects(projectId, projects);
  const subprojectIds = subprojects.map((p) => p.id);
  const projectIds = [project.id, ...subprojectIds];

  return {
    project,
    subprojects,
    projectIds,
  };
};

const shouldBeCollapsed = (): boolean => {
  return window.innerWidth < 1280;
};

const NavContext = React.createContext<undefined | NavState>(undefined);

const NavProvider = (props: Props) => {
  const { projects: projectsDoc } = useSettings();
  const projects = projectUtil.toProjectsArray(projectsDoc);
  const persistedView = usePersistedView();
  const history = useHistory();
  const [isCollapsed, setStateIsCollapsed] = useState(shouldBeCollapsed());
  const [projectId, setStateProjectId] = useState<string | undefined>(() => {
    return localStorage.getItem('projectId') ?? undefined;
  });
  let projectsFilter: ProjectsFilter | null = null;
  if (projects && projectId) {
    projectsFilter = getProjectsFilter(projectId, projects);
  }

  const filterByProject = <T extends ItemWithProject>(items: Array<T>): Array<T> => {
    if (!projectsFilter) {
      return projectUtil.filterByActive(items, projects);
    }
    return projectUtil.filterByProject(items, projectsFilter);
  };

  useEffect(() => {
    if (projectId) {
      localStorage.setItem('projectId', projectId);
    } else {
      localStorage.removeItem('projectId');
    }
  }, [projectId]);

  const setProjectId = useCallback((projectId?: string) => {
    setStateProjectId(projectId);
  }, []);

  const setIsCollapsed = useCallback((isCollapsed: boolean) => {
    setStateIsCollapsed(isCollapsed);
  }, []);

  // Switch back to list view if project selected in nav
  useEffect(() => {
    if (!projectsFilter) {
      return;
    }
    persistedView.setViewTab(ViewTab.List);
  }, [projectsFilter, persistedView]);

  useEffect(() => {
    const initialSearchParams = new URLSearchParams(history.location.search);
    const urlProject = initialSearchParams.get('project-id');
    if (urlProject) {
      if (urlProject !== projectId) {
        setStateProjectId(urlProject);
      }
      initialSearchParams.delete('project-id');
      history.replace({
        search: initialSearchParams.toString(),
      });
    }
  }, [history, history.location.search, projectId]);

  return (
    <NavContext.Provider
      value={{
        isCollapsed,
        setIsCollapsed,
        projectsFilter,
        filterByProject,
        setProjectId,
        persistedView,
      }}
    >
      {props.children}
    </NavContext.Provider>
  );
};

const useNavState = (): NavState => {
  const context = useContext(NavContext);

  if (context === undefined) {
    throw new Error('useNavState must be used within a NavProvider');
  }

  return context;
};

export { NavProvider, useNavState };
