import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { FormikHelpers, FormikValues } from 'formik';
import cloneDeep from 'lodash.clonedeep';
import { Part } from 'shared/lib/types/postgres/manufacturing/types';
import { DraftPartBuildBlock, PartBuildItem } from 'shared/lib/types/views/procedures';
import FieldSetCheckbox from '../../components/FieldSetCheckbox';
import { useRunContext } from '../../contexts/RunContext';
import Tooltip from '../../elements/Tooltip';
import idUtil from '../../lib/idUtil';
import { BaseContentBlockErrors } from '../../lib/types';
import { asComponentPart } from '../lib/parts';
import AddPartsButton from './AddPartsButton';
import ComponentBuildItem from './ComponentBuildItem';
import { headerClass } from './util/tableUtil';

const TOOLTIP_MESSAGE = 'Additional parts for check-in may be added during a run';

interface PartBuildFieldSetProps {
  content: DraftPartBuildBlock;
  contentErrors?: BaseContentBlockErrors;
  path?: string;
  teamId: string;
  setFieldValue: FormikHelpers<FormikValues>['setFieldValue'];
  projectId?: string;
}

const PartBuildFieldSet = ({
  content,
  contentErrors,
  path,
  teamId,
  setFieldValue,
  projectId,
}: PartBuildFieldSetProps) => {
  const { isRun } = useRunContext();

  const onAddItems = (parts: Array<Part>) => {
    const updatedItems = [...content.items];

    parts.forEach((part) => {
      const component = asComponentPart(part, 1);
      updatedItems.push({ ...component, id: idUtil.generateUuidEquivalentId() });
    });

    const value = {
      ...content,
      items: updatedItems,
    };
    setFieldValue(path ? path : '', value);
  };

  const onUpdateRev = (itemId: string, newRevLabel: string, newRevId?: string) => {
    const updated: DraftPartBuildBlock = cloneDeep(content);
    const index = updated.items.findIndex((component) => component.id === itemId);
    updated.items[index].revision = newRevLabel;
    updated.items[index].revision_id = newRevId;
    setFieldValue(path ? path : '', updated);
  };

  const onUpdateAmount = (itemId: string, newQuantity: number) => {
    const updated = cloneDeep(content);
    const index = updated.items.findIndex((component) => component.id === itemId);
    updated.items[index].amount = newQuantity;
    setFieldValue(path ? path : '', updated);
  };

  const onRemoveItem = (item: PartBuildItem) => {
    const updated = cloneDeep(content);
    updated.items = updated.items.filter((component) => component.id !== item.id);
    setFieldValue(path ? path : '', updated);
  };

  return (
    <div className="flex flex-col grow">
      <table className="w-full">
        <thead>
          <tr>
            <td className={headerClass}>
              <span>Parts for Check-In</span>
              <Tooltip content={TOOLTIP_MESSAGE}>
                <FontAwesomeIcon icon="circle-info" className="text-sm text-gray-400 ml-1" />
              </Tooltip>
            </td>
            <td className={headerClass}>Quantity</td>
            {/* Placeholder for remove button */}
            <td className="w-5"></td>
          </tr>
        </thead>
        <tbody>
          {content?.items.map((component, index) => (
            <ComponentBuildItem
              key={index}
              component={component}
              teamId={teamId}
              onRemove={onRemoveItem}
              isEnabled={component.tracking === 'lot'}
              onUpdateAmount={onUpdateAmount}
              onUpdateRev={onUpdateRev}
            />
          ))}
          <tr>
            <td colSpan={4}>
              {contentErrors?.error && <div className="text-red-700">{contentErrors.error}</div>}
              <div className="flex flex-row justify-between">
                <AddPartsButton projectId={projectId} onAddParts={onAddItems} />
                <div className="ml-2 mt-2 flex flex-row items-center">
                  <FieldSetCheckbox
                    text="Include in Summary"
                    fieldName={`${path}.include_in_summary`}
                    setFieldValue={setFieldValue}
                    isDisabled={isRun}
                  />
                </div>
              </div>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  );
};

export default PartBuildFieldSet;
