import { Tool, ToolInstance } from 'shared/lib/types/api/manufacturing/tools/models';
import { StyleVariant } from '../../../elements/lib/fieldStyles';
import { NumberSelectOption } from '../../../lib/formik';
import { cellClass, headerClass } from '../util/tableUtil';
import SelectToolInstance from './SelectToolInstance';
import ToolBadge from './ToolBadge';
import { isToolInstanceAvailable } from './utils';
import { useRunContext } from '../../../contexts/RunContext';
import inventoryUtil from '../../lib/inventoryUtil';
import { useCallback } from 'react';

interface ToolCheckOutInBaseProps {
  tool?: Tool;
  toolInstanceId?: number;
  teamId: string;
  onToolInstanceChange?: (toolInstanceOption: NumberSelectOption) => void;
  isEnabled: boolean;
  isStepStateEnded: boolean;
  type: 'out' | 'in';
}

const ToolCheckOutInBase = ({
  tool,
  type,
  toolInstanceId,
  teamId,
  isEnabled,
  isStepStateEnded,
  onToolInstanceChange,
}: ToolCheckOutInBaseProps) => {
  const { run } = useRunContext();

  const filter = useCallback(
    (toolInstance: ToolInstance) => {
      const checkedOut = inventoryUtil.checkedOutToolInstanceIds(run);
      return isToolInstanceAvailable(toolInstance, type) || checkedOut.includes(toolInstance.id);
    },
    [run, type]
  );

  return (
    <table className="w-full">
      <thead>
        <tr>
          <td className={`${headerClass} w-7/12`}>Tool for {`${type === 'out' ? 'Check-Out' : 'Check-In'}`}</td>
          <td className={`${headerClass} w-5/12`}>Instance</td>
        </tr>
      </thead>
      <tbody>
        <tr>
          <td className={`${cellClass} p-1 max-w-12 overflow-hidden`}>
            {tool && <ToolBadge tool={tool} teamId={teamId} />}
            {!tool && <div className="text-gray-400 italic">Loading Tool...</div>}
          </td>
          <td className={`${cellClass}`}>
            {tool && (
              <SelectToolInstance
                tool={tool}
                toolInstanceId={toolInstanceId}
                instanceFilter={filter}
                onToolInstanceChange={onToolInstanceChange}
                isEnabled={isEnabled}
                isComplete={isStepStateEnded}
                teamId={teamId}
                variant={StyleVariant.Grid}
                type={type}
              />
            )}
          </td>
        </tr>
      </tbody>
    </table>
  );
};

export default ToolCheckOutInBase;
