import React, { ReactNode, useContext } from 'react';
import { DISABLE_USER_TRACKING, RELEASE_VERSION } from '../config';

interface MixpanelContextProps {
  mixpanel?: {
    track: (
      event_name: string,
      properties?: object,
      options?: {
        transport: string;
        send_immediately: boolean;
      },
      callback?: () => void
    ) => void;
  } | null;
}
/*
 * This stub exists to stub out the mixpanel methods we use when we want
 * mixpanel disabled.  If you call new methods in mixpanel they need to
 * be added here.
 */
// eslint-disable-next-line @typescript-eslint/no-empty-function
const disabledMixPanelracker = {
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  identify: () => {},
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  set_group: () => {},
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  get_group: () => {
    // eslint-disable-next-line @typescript-eslint/no-empty-function
    return { set: () => {} };
  },
  people: () => {
    // eslint-disable-next-line @typescript-eslint/no-empty-function
    return { set: () => {} };
  },
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  reset: () => {},

  track: (
    event_name: string,
    properties?: object,
    options?: {
      transport: string;
      send_immediately: boolean;
    },
    callback?: () => void
    // eslint-disable-next-line @typescript-eslint/no-empty-function
  ) => {},
};
const MixpanelContext = React.createContext<MixpanelContextProps>({});

const MixpanelProvider = ({ token, children }: { token?: string; children: ReactNode }) => {
  // null case exists for tests
  const mixPanelProps: MixpanelContextProps = { mixpanel: token ? disabledMixPanelracker : null };
  if (!DISABLE_USER_TRACKING) {
    import('mixpanel-browser')
      .then((module) => {
        const mixpanel = module.default;
        mixpanel.init(token);
        mixpanel.register({ 'Spock Version': RELEASE_VERSION });
        mixPanelProps.mixpanel = mixpanel;
      })
      .catch((error) => {
        // eslint-disable-next-line no-console
        console.error('mixpanel not initialized', error);
      });
  }
  return <MixpanelContext.Provider value={mixPanelProps}>{children}</MixpanelContext.Provider>;
};

const useMixpanel = () => {
  const context = useContext(MixpanelContext);

  if (context === undefined) {
    throw new Error('useMixpanel must be used within a MixpanelProvider');
  }
  return context;
};

export { MixpanelProvider, useMixpanel };
