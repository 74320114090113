import { Dialog } from 'primereact/dialog';
import SelectedItems from './SelectedItems';
import pluralize from 'pluralize';
import { Item, Location } from '../../types';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { getLocationLabel } from '../../lib/locations';
import SimpleDialogFooter from '../../../elements/SimpleDialogFooter';
import Button, { BUTTON_TYPES } from '../../../components/Button';

type ConfirmLocationUpdateModalProps = {
  location: Location;
  selectedItems: Item[];
  onApplyUpdate: (itemIds: string[]) => Promise<void>;
  onCancel: () => void;
};

const ConfirmLocationUpdateModal = ({
  location,
  selectedItems,
  onApplyUpdate,
  onCancel,
}: ConfirmLocationUpdateModalProps) => {
  const [itemsToUpdate, setItemsToUpdate] = useState(selectedItems);

  const locationLabel = useMemo(() => getLocationLabel(location), [location]);
  const [isDisabled, setIsDisabled] = useState(false);

  const onConfirm = useCallback(async () => {
    setIsDisabled(true);
    await onApplyUpdate(itemsToUpdate.map((item) => item.id));
    setIsDisabled(false);
  }, [itemsToUpdate, onApplyUpdate]);

  const getFooter = useCallback(() => {
    return (
      <SimpleDialogFooter>
        <Button type={BUTTON_TYPES.PRIMARY} isDisabled={isDisabled} onClick={onConfirm}>
          Confirm
        </Button>
        <Button type={BUTTON_TYPES.SECONDARY} isDisabled={isDisabled} onClick={onCancel}>
          Cancel
        </Button>
      </SimpleDialogFooter>
    );
  }, [isDisabled, onConfirm, onCancel]);

  const onRemoveItem = useCallback(
    (removedId: string) => setItemsToUpdate(itemsToUpdate.filter((item) => item.id !== removedId)),
    [itemsToUpdate]
  );

  useEffect(() => {
    if (itemsToUpdate.length === 0) {
      onCancel();
    }
  }, [itemsToUpdate, onCancel]);

  return (
    <Dialog
      header="Change Location"
      onHide={onCancel}
      visible={true}
      closable={true}
      draggable={false}
      className="w-[500px] h-[500px]"
      footer={getFooter}
    >
      <div className="flex flex-col items-start mt-4 gap-y-4">
        <span className="inline-flex flex-wrap gap-x-1">
          <span>Location for</span>
          <span className="font-medium">{pluralize('item', itemsToUpdate.length, true)}</span>
          <span>will be updated to</span>
          <span className="font-medium">{locationLabel}</span>
        </span>

        <div className="flex flex-col items-start w-full gap-y-1">
          <span>{`Selected ${pluralize('Item', itemsToUpdate.length)}`}</span>
          <SelectedItems selectedItems={itemsToUpdate} onRemoveItem={onRemoveItem} />
        </div>
      </div>
    </Dialog>
  );
};

export default ConfirmLocationUpdateModal;
