import React, { useEffect, useMemo, useState } from 'react';
import sharedDiffUtil from 'shared/lib/diffUtil';
import { Part } from 'shared/lib/types/postgres/manufacturing/types';
import { InventoryDetailInputBlockDiffElement } from 'shared/lib/types/views/procedures';
import DiffContainer from '../../../components/Diff/DiffContainer';
import SubstepNumber from '../../../components/SubstepNumber';
import { useDatabaseServices } from '../../../contexts/DatabaseContext';
import { generateHiddenClassString } from '../../../lib/styles';
import useLatestParts from '../../hooks/useLatestParts';
import { getPartIdForRevisionId, isPartRestricted } from '../../lib/parts';
import { ItemDetail } from '../../types';
import InventoryDetailBase from '../InventoryDetailBase';

interface InventoryDetailInputProps {
  content: InventoryDetailInputBlockDiffElement;
  blockLabel: string;
  isEnabled?: boolean;
  isHidden?: boolean;
  teamId: string;
}

const ReviewInventoryDetailInput = React.memo(
  ({ content, blockLabel, isEnabled, isHidden, teamId }: InventoryDetailInputProps) => {
    const { services } = useDatabaseServices();
    const { parts, getLatestPartRevision } = useLatestParts();
    const [part, setPart] = useState<Part | null>(null);
    const [itemDetails, setItemDetails] = useState<Array<ItemDetail>>([]);
    const restrictedPart = isPartRestricted(part || undefined);

    const partRevisionId = sharedDiffUtil.getDiffValue(content, 'part_revision_id', 'new') as string;
    const detailId = sharedDiffUtil.getDiffValue(content, 'detail_id', 'new') as string;

    useEffect(() => {
      services.manufacturing
        .listItemDetails()
        .then(setItemDetails)
        .catch(() => {
          /* no-op */
        });
    }, [services.manufacturing]);

    // Part load
    useEffect(() => {
      if (parts && partRevisionId && !part) {
        // Note: getPartByRevisionId actually returns the latest rev, regardless of the part_revision_id
        const partId = getPartIdForRevisionId(partRevisionId, parts);
        const latestRev = partId ? getLatestPartRevision(partId) : undefined;
        if (latestRev) {
          services.manufacturing.getPart(latestRev.id, { revisionId: partRevisionId }).then(setPart);
        }
      }
    }, [parts, getLatestPartRevision, part, partRevisionId, services.manufacturing]);

    const detailName = useMemo(() => {
      const detail = itemDetails.find((detail) => detail.id === detailId);
      return detail?.name ?? '';
    }, [itemDetails, detailId]);

    const isLoading = useMemo(() => !detailName || !part, [detailName, part]);

    return isLoading ? null : (
      <div className={generateHiddenClassString('mt-3 ml-4 flex flex-wrap page-break', isHidden)}>
        <SubstepNumber blockLabel={blockLabel} hasExtraVerticalSpacing={false} />
        <div className="w-full py-1 mr-8">
          <DiffContainer
            label="Inventory detail input"
            diffChangeState={content.diff_change_state}
            isTextSticky={false}
          >
            <InventoryDetailBase
              part={part}
              detailName={detailName}
              detailValue=""
              isEnabled={isEnabled}
              isRestricted={restrictedPart}
              teamId={teamId}
            />
          </DiffContainer>
        </div>
      </div>
    );
  }
);

export default ReviewInventoryDetailInput;
