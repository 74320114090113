import { EntityType, Tag as GlobalTag, TagStatus } from 'shared/lib/types/api/settings/tags/models';
import { useDatabaseServices } from '../../../contexts/DatabaseContext';
import { DatabaseServices } from '../../../contexts/proceduresSlice';
import TagsDisplay from './TagsDisplay';
import { useCallback, useMemo, useState } from 'react';
import TagSelector from './TagSelector';
import TagModal, { SubmitTagFormData } from './TagModal';
import RemovableTagsDisplay from './RemovableTagsDisplay';

interface TagManagerProps {
  entityTags: GlobalTag[];
  readOnlyEntityTags?: GlobalTag[];
  globalTags: GlobalTag[];
  entityId: string;
  entityType: EntityType;
  isDisabled?: boolean;
  removePadding?: boolean;
  onSelect?: (tagId: string) => void;
  onRemove?: (tagId: string) => void;
  onUpdateTag?: (tag: SubmitTagFormData) => void;
}

const TagManager = ({
  entityTags,
  globalTags,
  entityId,
  entityType,
  readOnlyEntityTags = [],
  isDisabled = false,
  removePadding = false,
  onSelect,
  onRemove,
  onUpdateTag,
}: TagManagerProps) => {
  const { services }: { services: DatabaseServices } = useDatabaseServices();
  const [tagModalState, setTagModalState] = useState<{
    newTagName: string | undefined;
    selectedTag: GlobalTag | null;
    showModal: boolean;
  }>({
    newTagName: undefined,
    selectedTag: null,
    showModal: false,
  });

  const tagOptions = useMemo(() => {
    return globalTags.filter(
      (tag) =>
        tag.status === TagStatus.ACTIVE &&
        ![...entityTags, ...readOnlyEntityTags].some((entityTag) => entityTag.id === tag.id)
    );
  }, [globalTags, entityTags, readOnlyEntityTags]);

  const onSelectTag = useCallback(
    (tagId: string) => {
      if (onSelect) {
        return onSelect(tagId);
      }
      return services.entityTags.addTagToEntity(entityType, entityId, tagId);
    },
    [entityId, entityType, services, onSelect]
  );

  const onRemoveTag = useCallback(
    (tagId: string) => {
      if (onRemove) {
        return onRemove(tagId);
      }
      return services.entityTags.removeTagFromEntity(entityType, entityId, tagId);
    },
    [entityId, entityType, services, onRemove]
  );

  const onCreateTag = useCallback((tagName: string) => {
    setTagModalState({
      newTagName: tagName,
      showModal: true,
      selectedTag: null,
    });
  }, []);

  const onEditTag = useCallback((tag: GlobalTag) => {
    setTagModalState({
      newTagName: undefined,
      showModal: true,
      selectedTag: tag,
    });
  }, []);

  return (
    <div className={`flex flex-row flex-wrap items-center ${removePadding ? '' : 'gap-y-1 min-h-[3rem]'}`}>
      {tagModalState.showModal && (
        <TagModal
          selectedTag={tagModalState.selectedTag}
          newTagName={tagModalState.newTagName}
          tags={globalTags}
          onClose={() => setTagModalState({ newTagName: undefined, selectedTag: null, showModal: false })}
          entityId={entityId}
          entityType={entityType}
          onUpdateTag={onUpdateTag}
        />
      )}
      <TagsDisplay tags={readOnlyEntityTags} />
      <RemovableTagsDisplay tags={entityTags} onRemove={(tag) => onRemoveTag(tag.id)} isDisabled={isDisabled} />
      {!isDisabled && (
        <TagSelector
          tagOptions={tagOptions}
          disallowedTags={globalTags.map((tag) => tag.name)}
          onCreateTag={onCreateTag}
          onSelectTag={onSelectTag}
          onEditTag={onEditTag}
        />
      )}
    </div>
  );
};

export default TagManager;
