import labels from 'shared/lib/labelUtil';
import { isNotTestingProcedure } from 'shared/lib/procedureUtil';
import { RunMetadata } from 'shared/lib/types/couch/procedures';
import { Config, Projects } from 'shared/lib/types/couch/settings';
import GridComparators from '../../elements/lib/gridComparators';
import { runSummaryPath, runViewPath } from '../../lib/pathUtil';
import runUtil from '../../lib/runUtil';
import {
  ColumnOverrides,
  applyOverrides,
  filterBySearchTerm,
} from '../../lib/gridUtils';
import renderDateTime from './Renderers/DateTime';
import renderOperation from './Renderers/Operation';
import renderParticipants from './Renderers/Participants';
import renderProject from './Renderers/Project';
import renderRunLink from './Renderers/RunLink';
import renderRunLinkWithIcon from './Renderers/RunLinkWithIcons';
import renderRunProgressBar from './Renderers/RunProgress';
import renderRunStatus from './Renderers/Status';
import {
  HeaderDefinition,
  RunLink,
  RunLinkWithIcon,
  RunProgress,
  RunTableMetadata,
  RunsTableRowModel,
} from './types';
import projectUtil from '../../lib/projectUtil';
import { CouchLikeOperationSummary } from 'shared/lib/types/operations';
import { FilterOption } from '../lib/SearchFilter';
import { Tag as GlobalTag } from 'shared/lib/types/api/settings/tags/models';
import { FilterByProjectFunction } from '../../contexts/NavContext';

interface RunMappingContext {
  projects: Projects | null;
  config: Config | null;
  currentTeamId: string;
  isGlobalTagsEnabled: () => boolean;
  globalTags: Record<string, GlobalTag>;
}

const _mapToRunsTableRowModel = (
  summary: RunMetadata,
  context: RunMappingContext
): RunsTableRowModel => {
  const id = summary._id;
  const operation = summary.operation;
  const status = runUtil.getStatus(summary.state, summary.status);
  const startTime = summary.starttime;
  const completedTime = summary.completedAt;
  const participantUserIds = summary.participant_user_ids || [];
  const sourceRun = summary.source_run;
  const runProgress: RunProgress = {
    summary,
    label: runUtil.getRunSummaryPercent(summary), // used for sorting
  };

  const globalTags =
    context.isGlobalTagsEnabled() && context.globalTags
      ? [
          ...((summary.global_procedure_tag_ids || [])
            .map((tagId) => context.globalTags[tagId])
            .filter(Boolean) as GlobalTag[]),
          ...((summary.global_run_tag_ids || [])
            .map((tagId) => context.globalTags[tagId])
            .filter(Boolean) as GlobalTag[]),
        ]
      : [];

  const runLink: RunLink = {
    code: summary.code,
    run_number: summary.run_number,
    name: runUtil.displayName(summary, context.config),
    link: runViewPath(context.currentTeamId, summary._id),
    tags: context.isGlobalTagsEnabled() ? [] : summary.tags,
    runTags: context.isGlobalTagsEnabled() ? [] : summary.run_tags,
    globalTags,
  };
  const runLinkWithIcon: RunLinkWithIcon = {
    code: summary.code,
    run_number: summary.run_number,
    name: runUtil.displayName(summary, context.config),
    link: runViewPath(context.currentTeamId, summary._id),
    icon: 'file-alt',
    iconLink: runSummaryPath(context.currentTeamId, summary._id),
    iconTitle: 'Go to run summary',
    tags: context.isGlobalTagsEnabled() ? [] : summary.tags,
    runTags: context.isGlobalTagsEnabled() ? [] : summary.run_tags,
    globalTags,
  };

  return {
    id,
    participantUserIds,
    runLink,
    runLinkWithIcon,
    status,
    sourceRun,
    operation,
    startTime,
    completedTime,
    runProgress,
    projectName: projectUtil.getProjectName(
      context.projects,
      summary.project_id
    ),
    rootProjectName: projectUtil.getRootProjectName(
      context.projects,
      summary.project_id
    ),
  };
};

const _mapRunsMetadataToRows = (
  runs: Array<RunMetadata>,
  context: RunMappingContext
) => {
  return runs
    .filter((run) => isNotTestingProcedure(run.procedure_type))
    .map((run) => _mapToRunsTableRowModel(run, context));
};

const _filterRunsForSelectedOperations = (
  runs: Array<RunMetadata>,
  selectedOperationKeys: ReadonlySet<string | null>
) => {
  return selectedOperationKeys.size > 0
    ? runs.filter(
        (run) =>
          run.operation &&
          selectedOperationKeys.has(labels.getLabelKey(run.operation))
      )
    : runs;
};

export const getRows = ({
  runs,
  filterByProject,
  searchTerm,
  selectedOperationKeys = new Set<string>(),
  context,
}: {
  runs: Array<RunMetadata>;
  filterByProject: FilterByProjectFunction;
  searchTerm: string;
  selectedOperationKeys?: ReadonlySet<string | null>;
  context: RunMappingContext;
}): Array<RunTableMetadata> => {
  const projectRuns = filterByProject(runs);

  const filteredAndMappedRuns = _mapRunsMetadataToRows(
    _filterRunsForSelectedOperations(projectRuns, selectedOperationKeys),
    context
  );

  return filterBySearchTerm({
    searchTerm,
    allData: filteredAndMappedRuns,
    getStrings: (run: RunsTableRowModel) => {
      const tagNames = context.isGlobalTagsEnabled()
        ? run.runLink.globalTags?.map((tag) => tag.name) || []
        : [
            ...(run.runLink.tags?.map((tag) => tag.name) || []),
            ...(run.runLink.runTags?.map((tag) => tag.name) || []),
          ];
      return [
        run.runLink.code,
        run.runLink.name,
        tagNames.join(' , '),
        run.projectName,
        run.status,
        run.participantUserIds.join(' , '),
      ];
    },
  });
};

type GetRunColumnsProps = {
  state: 'running' | 'ended';
  view: 'list' | 'tree';
};

const getRunColumns = ({
  state,
  view,
}: GetRunColumnsProps): Array<HeaderDefinition> => {
  if (state === 'running') {
    return [
      {
        name: 'Procedure',
        key: 'runLink',
        componentType: 'link',
        width: '25%',
        renderCell: renderRunLink,
        comparator: (a: RunsTableRowModel, b: RunsTableRowModel) =>
          a.runLink.code
            .toLowerCase()
            .localeCompare(b.runLink.code.toLowerCase()),
      },
      {
        name: 'Operation',
        key: 'operation',
        componentType: 'ops_label',
        width: '10%',
        renderCell: ({ row }) => renderOperation(row.operation),
      },
      {
        name: 'Project',
        key: view === 'list' ? 'projectName' : 'rootProjectName',
        componentType: 'project_label',
        width: '15%',
        renderCell: ({ row }) => renderProject(row.projectName),
        comparator: GridComparators.project,
      },
      {
        name: 'Status',
        key: 'status',
        componentType: 'status',
        width: '10%',
        renderCell: ({ row }) => renderRunStatus(row.status),
      },
      {
        name: 'Participants',
        key: 'participantUserIds',
        componentType: 'participants',
        sortable: false,
        width: '12%',
        renderCell: ({ row }) => renderParticipants(row.participantUserIds),
      },
      {
        name: 'Start Time',
        key: 'startTime',
        componentType: 'timestamp',
        width: '15%',
        renderCell: ({ row }) => renderDateTime(row.startTime),
      },
      {
        name: 'Progress',
        key: 'runProgress',
        componentType: 'run_progress',
        width: '13%',
        renderCell: ({ row }) => renderRunProgressBar(row.runProgress),
        comparator: (a: RunsTableRowModel, b: RunsTableRowModel) =>
          +a.runProgress.label - +b.runProgress.label,
      },
    ];
  } else {
    return [
      {
        name: 'Run',
        key: 'runLinkWithIcon',
        componentType: 'link_with_icon',
        width: '25%',
        renderCell: renderRunLinkWithIcon,
        comparator: (a: RunsTableRowModel, b: RunsTableRowModel) =>
          a.runLinkWithIcon.code
            .toLowerCase()
            .localeCompare(b.runLinkWithIcon.code.toLowerCase()),
      },
      {
        name: 'Operation',
        key: 'operation',
        componentType: 'ops_label',
        width: '10%',
        renderCell: ({ row }) => renderOperation(row.operation),
      },
      {
        name: 'Project',
        key: view === 'list' ? 'projectName' : 'rootProjectName',
        componentType: 'project_label',
        width: '15%',
        renderCell: ({ row }) => renderProject(row.projectName),
        comparator: GridComparators.project,
      },
      {
        name: 'Status',
        key: 'status',
        componentType: 'status',
        width: '10%',
        renderCell: ({ row }) => renderRunStatus(row.status),
      },
      {
        name: 'Start Time',
        key: 'startTime',
        componentType: 'timestamp',
        width: '14%',
        renderCell: ({ row }) => renderDateTime(row.startTime),
      },
      {
        name: 'End Time',
        key: 'completedTime',
        componentType: 'timestamp',
        width: '14%',
        renderCell: ({ row }) => renderDateTime(row.completedTime),
      },
      {
        name: 'Progress',
        key: 'runProgress',
        componentType: 'run_progress',
        width: '12%',
        renderCell: ({ row }) => renderRunProgressBar(row.runProgress),
        comparator: (a: RunsTableRowModel, b: RunsTableRowModel) =>
          +a.runProgress.label - +b.runProgress.label,
      },
    ];
  }
};

export const getColumns = ({
  state = 'running',
  includeOperations = false,
  view,
}: {
  state: 'running' | 'ended';
  includeOperations?: boolean;
  view: 'list' | 'tree';
}): Array<HeaderDefinition> => {
  const overrides: Record<string, ColumnOverrides> = {
    Running: {
      operation: { hidden: true },
      runLink: { width: '27%' },
      projectName: { width: '12%' },
      status: { width: null },
      participants: { width: '17%' },
      startTime: { width: '16%' },
      runProgress: { width: '16%' },
    },
    Ended: {
      operation: { hidden: true },
      runLinkWithIcon: { width: '27%' },
      projectName: { width: '15%' },
      status: { width: null },
      startTime: { width: '15%' },
      completedTime: { width: '15%' },
      runProgress: { width: '16%' },
    },
  };

  const columns = getRunColumns({ state, view });
  if (includeOperations) {
    return columns;
  }
  return applyOverrides(columns, overrides[state]);
};

export const getOperationsAsOptions = (
  operations: Array<CouchLikeOperationSummary>,
  includeEnded: boolean
): Array<FilterOption<string>> => {
  const states = ['running', ...(includeEnded ? ['ended'] : [])];
  return (operations || [])
    .filter((operation) => states.includes(operation.state))
    .map((operation) => ({
      id: operation.key,
      label: operation.name,
    }));
};
