import React, { useMemo } from 'react';
import useUsers from '../hooks/useUsers';
import AvatarStack from '../components/AvatarStack';

interface UsersStackProps {
  userIds: string[];
  emptyLabel?: string;
}

const UsersStack = ({ userIds, emptyLabel = 'Unassigned' }: UsersStackProps) => {
  const { getUserDisplayText } = useUsers();

  const avatarStack = useMemo(() => {
    return userIds.map(getUserDisplayText);
  }, [getUserDisplayText, userIds]);

  if (userIds.length === 0) {
    return <div className=" truncate italic text-gray-400">{emptyLabel}</div>;
  }
  return <AvatarStack userIds={avatarStack} />;
};

export default React.memo(UsersStack);
