import JumpToEditSelect from '../JumpTo/JumpToEditSelect';

interface TargetFieldProps {
  name: string;
  value: string;
  setFieldValue: (path: string, jumpToId: string) => void;
  stepId: string;
  disabled: boolean;
}

const TargetField = ({ name, value, setFieldValue, stepId, disabled }: TargetFieldProps) => {
  return (
    <div className="w-full">
      <JumpToEditSelect
        includeSections={false}
        path={name}
        value={value}
        onChange={setFieldValue}
        containingStepId={stepId}
        isDisabled={disabled}
      />
    </div>
  );
};

export default TargetField;
