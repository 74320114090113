import React, { useCallback, useRef, useMemo, useEffect } from 'react';
import { OverlayPanel } from 'primereact/overlaypanel';

import ListWithSearch, { BasicOption, OptionType } from './ListWithSearch';

interface PopupListWithSearchProps<T, U> {
  title?: string;
  Components: {
    Trigger: () => JSX.Element;
    TitleActionButton?: () => JSX.Element;
    ListItem: (option: OptionType<T, U>, searchTerm: string) => JSX.Element;
  };
  options: Array<OptionType<T, U>>;
  disallowedTerms?: string[];
  onSelect?: (option: OptionType<T, U>) => Promise<void>;
  onCreate?: (name: string) => Promise<void>;
  disableSearch?: boolean;
  pt?: {
    Trigger: React.HTMLAttributes<HTMLDivElement>;
  };
  dismissOnSelect?: boolean;
  showCursorPointer?: boolean;
  closeOnScroll?: boolean;
  maxItemLength?: number;
  isDisabled?: boolean;
}

const PopupListWithSearch = <T, U = BasicOption<T>>({
  title,
  Components,
  options,
  disallowedTerms = [],
  onSelect,
  onCreate,
  disableSearch = false,
  pt,
  dismissOnSelect = true,
  showCursorPointer,
  closeOnScroll = false,
  maxItemLength = undefined,
  isDisabled = false,
}: PopupListWithSearchProps<T, U>) => {
  const overlayPanelRef = useRef<OverlayPanel>(null);

  const hideList = useCallback(() => {
    overlayPanelRef.current?.hide();
  }, []);

  const showList = useCallback((e) => {
    overlayPanelRef.current?.show(e, e.currentTarget);
  }, []);

  useEffect(() => {
    if (closeOnScroll && overlayPanelRef.current) {
      document.addEventListener('scroll', hideList, { capture: true });
    }
    // Remove listener when menu visibility changes or component unmounts
    return () => {
      document.removeEventListener('scroll', hideList, { capture: true });
    };
  }, [closeOnScroll, hideList]);

  const _onSelect = useCallback(
    (option) => {
      if (!onSelect) {
        return Promise.resolve();
      }
      if (dismissOnSelect) {
        return onSelect(option).then(hideList);
      } else {
        return onSelect(option);
      }
    },
    [onSelect, hideList, dismissOnSelect]
  );

  const _onCreate = useCallback(
    (name) => {
      if (!onCreate) {
        return Promise.resolve();
      }

      return onCreate(name).then(hideList);
    },
    [onCreate, hideList]
  );

  const triggerProps = useMemo(() => {
    if (!pt || !pt.Trigger) {
      return {};
    }

    return pt.Trigger;
  }, [pt]);

  return (
    <div className="relative">
      <div {...triggerProps} onClick={showList} className={`w-fit ${isDisabled ? 'pointer-events-none' : ''}`}>
        <Components.Trigger />
      </div>
      <OverlayPanel className="p-0" ref={overlayPanelRef} pt={{ content: { className: 'shadow-2xl' } }}>
        <ListWithSearch<T>
          title={title}
          options={options}
          onSelect={_onSelect}
          onCreate={onCreate && _onCreate}
          disallowedTerms={disallowedTerms}
          focusOnMount={true}
          disableSearch={disableSearch}
          Components={{
            ListItem: Components.ListItem,
            TitleActionButton: Components.TitleActionButton,
          }}
          fitContent={true}
          showCursorPointer={showCursorPointer}
          maxItemLength={maxItemLength}
        />
      </OverlayPanel>
    </div>
  );
};

export default React.memo(PopupListWithSearch) as typeof PopupListWithSearch;
