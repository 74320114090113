import { useMemo, useState } from 'react';
import { Tag, TagStatus } from 'shared/lib/types/api/settings/tags/models';
import { useSettings } from '../contexts/SettingsContext';
import { GlobalTags } from 'shared/lib/types/settings';

type UseTagsProps = {
  includeDeleted: boolean;
};

type UseTagsReturns = {
  tags: Tag[];
  isLoading: boolean;
};

export const useTags = ({ includeDeleted }: UseTagsProps): UseTagsReturns => {
  const { globalTags }: { globalTags: GlobalTags | null } = useSettings();
  const [isLoading, setIsLoading] = useState(true);

  const tags = useMemo(() => {
    if (globalTags === null) {
      return [];
    }
    setIsLoading(false);
    return Object.values(globalTags.tags).filter(
      (tag) => includeDeleted || tag.status !== TagStatus.DELETED
    );
  }, [globalTags, includeDeleted]);

  return { tags, isLoading };
};
