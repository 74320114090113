import sharedDiffUtil from 'shared/lib/diffUtil';
import { ReviewToolCheckInBlock, ReviewToolCheckOutBlock } from 'shared/lib/types/views/procedures';
import DiffContainer from '../../../components/Diff/DiffContainer';
import SubstepNumber from '../../../components/SubstepNumber';
import diffUtil from '../../../lib/diffUtil';
import { generateHiddenClassString } from '../../../lib/styles';
import useTool from '../../hooks/useTool';
import ToolCheckOutInBase from './ToolCheckOutInBase';

interface ReviewToolCheckOutInProps {
  content: ReviewToolCheckOutBlock | ReviewToolCheckInBlock;
  type: 'out' | 'in';
  teamId: string;
  blockLabel?: string;
  isHidden?: boolean;
}

const ReviewToolCheckOutIn = ({ content, type, teamId, blockLabel, isHidden }: ReviewToolCheckOutInProps) => {
  const toolId = sharedDiffUtil.getDiffValue<number>(content, 'tool_id', 'new');
  const { tool } = useTool({ toolId });

  if (!tool) {
    return null;
  }

  const diffChangeState =
    content.diff_change_state === '~'
      ? diffUtil.getDiffChangeStateForChangesOnly(content, 'tool_id')
      : diffUtil.getDiffChangeStateForAddedRemovedOnly(content);

  return (
    <div className={generateHiddenClassString('mt-3 ml-4 flex flex-wrap page-break', isHidden)}>
      <SubstepNumber blockLabel={blockLabel} hasExtraVerticalSpacing={false} />
      <div className="w-full py-1 mr-8">
        <DiffContainer label={`Tool check-${type}`} diffChangeState={diffChangeState} isTextSticky={false}>
          <ToolCheckOutInBase tool={tool} type={type} teamId={teamId} isEnabled={false} isStepStateEnded={false} />
        </DiffContainer>
      </div>
    </div>
  );
};

export default ReviewToolCheckOutIn;
