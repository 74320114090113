import { ARRAY_CHANGE_SYMBOLS } from 'shared/lib/diffUtil';
import { WithDiffChange } from 'shared/lib/types/views/procedures';

export const headerClass = 'bg-gray-100 border border-gray-300 text-sm py-2 px-1';
export const cellClass = 'border border-gray-300 p-0';
export const getCellClass = (isDisabled = false): string =>
  `border border-gray-300 p-0 ${isDisabled ? 'bg-gray-100' : 'bg-white'}`;
export const getRowStyle = <T,>(item: WithDiffChange<T>): string => {
  return item.diff_change_state === ARRAY_CHANGE_SYMBOLS.ADDED
    ? 'bg-emerald-100 text-emerald-800'
    : item.diff_change_state === ARRAY_CHANGE_SYMBOLS.REMOVED
    ? 'bg-red-100 text-red-600'
    : 'bg-white';
};
