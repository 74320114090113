import { useCallback, useMemo, useState, useRef } from 'react';
import { useSelector, useStore } from 'react-redux';
import { RUN_STATE } from 'shared/lib/runUtil';
import { NotificationTypes, NotifyAutomationContext } from 'shared/lib/types/notifications';
import Button, { BUTTON_TYPES } from '../../components/Button';
import renderDateTime from '../../components/Home/Renderers/DateTime';
import AtMentionNotificationLink from '../../components/Notifications/AtMentionNotificationLink';
import AutomationNotificationLink from '../../components/Notifications/AutomationNotificationLink';
import ProcedureGenerationSuccessLink from '../../components/Notifications/ProcedureGenerationSuccessLink';
import ProcedureGenerationFailLink from '../../components/Notifications/ProcedureGenerationFailLink';
import ReviewerNotificationLink from '../../components/Notifications/ReviewerNotificationLink';
import ToolNotificationLink from '../../components/Notifications/ToolNotificationLink';
import { useDatabaseServices } from '../../contexts/DatabaseContext';
import { useNotifications } from '../../contexts/NotificationContext';
import { DatabaseServices, selectProceduresMetadata } from '../../contexts/proceduresSlice';
import { selectActiveRunById } from '../../contexts/runsSlice';
import { useSettings } from '../../contexts/SettingsContext';
import { useUserInfo } from '../../contexts/UserContext';
import apm from '../../lib/apm';
import notificationsLib from '../../lib/notifications';
import { NotificationRow } from '../types';
import { Menu } from 'primereact/menu';
import { NOTIFICATION_STATUS } from 'shared/lib/notifications';
import _ from 'lodash';
import { MenuItem } from 'primereact/menuitem';
import ListHeader from '../../components/Home/ListHeader';
import { useNavState } from '../../contexts/NavContext';
import Grid from '../../elements/Grid';

const ROW_HEIGHT = 45;
const MAIN_VERTICAL_PADDING = 160;

const TABS = [
  { id: 'notifications', label: 'Notifications' },
  { id: 'archived', label: 'Archived' },
];

const EditMenu = ({ row }: { row: NotificationRow }) => {
  const { services }: { services: DatabaseServices } = useDatabaseServices();
  const menuRef = useRef<Menu>(null);

  const menuItems: Array<MenuItem> = [
    {
      label: row.isArchived ? 'Unarchive' : 'Archive',
      command: () =>
        services.notifications
          .updateNotifications({ ids: [row.id], isArchived: !row.isArchived })
          .catch((err) => apm.captureError(err)),
    },
    {
      label: row.status === NOTIFICATION_STATUS.RESOLVED ? 'Mark Unread' : 'Mark Read',
      command: () =>
        services.notifications
          .updateNotifications({
            ids: [row.id],
            status:
              row.status === NOTIFICATION_STATUS.RESOLVED
                ? NOTIFICATION_STATUS.UNRESOLVED
                : NOTIFICATION_STATUS.RESOLVED,
          })
          .catch((err) => apm.captureError(err)),
    },
  ];

  return (
    <div className="w-full flex justify-end">
      <Menu model={menuItems} popup ref={menuRef} style={{ width: '140px' }} />
      <Button
        type={BUTTON_TYPES.TERTIARY}
        leadingIcon="ellipsis"
        iconTextColor="text-gray-500"
        size="xl"
        removePadding={true}
        ariaLabel="Notification Edit"
        onClick={(e) => menuRef.current?.toggle(e)}
      />
    </div>
  );
};

const NotificationsList = () => {
  const { services, currentTeamId }: { services: DatabaseServices; currentTeamId: string } = useDatabaseServices();
  const { users } = useSettings();
  const { userInfo } = useUserInfo();
  const { notifications } = useNotifications();
  const { persistedView } = useNavState();
  const store = useStore();
  const proceduresMetadata = useSelector((state) => {
    const procedures = selectProceduresMetadata(state, currentTeamId);
    return procedures;
  });
  const [activeTab, setActiveTab] = useState('notifications');

  const NOTIFICATION_LINK_MAP = useMemo(() => {
    return {
      [NotificationTypes.Reviewers]: ReviewerNotificationLink,
      [NotificationTypes.ProcedureGeneration]: ProcedureGenerationSuccessLink,
      [NotificationTypes.ProcedureGenerationFail]: ProcedureGenerationFailLink,
      [NotificationTypes.Automation]: AutomationNotificationLink,
      [NotificationTypes.AtMention]: AtMentionNotificationLink,
      [NotificationTypes.Tool]: ToolNotificationLink,
    };
  }, []);

  const MESSAGE_TYPE_MAP = useMemo(() => {
    return {
      [NotificationTypes.Reviewers]: 'Review',
      [NotificationTypes.ProcedureGeneration]: 'Import',
      [NotificationTypes.ProcedureGenerationFail]: 'Import',
      [NotificationTypes.Automation]: 'Action',
      [NotificationTypes.AtMention]: 'Mention',
      [NotificationTypes.Tool]: 'Tool',
    };
  }, []);

  const handleOnClick = useCallback(
    (ids: Array<number>) => {
      if (!services) {
        return;
      }
      services.notifications
        .updateNotifications({ ids, status: NOTIFICATION_STATUS.RESOLVED })
        .catch((err) => apm.captureError(err));
    },
    [services]
  );

  const notificationsWithActiveProperty = useMemo(() => {
    for (const notification of notifications) {
      let isActive = false;
      switch (notification.type) {
        case 'reviewers':
          isActive = notificationsLib.isReviewerNotificationActive(notification, userInfo, users, proceduresMetadata);
          break;
        case 'automation': {
          const runId = (notification.context as NotifyAutomationContext).runId;
          const run = selectActiveRunById(store.getState(), currentTeamId, runId);
          isActive = Boolean(run && run.automation_status === 'paused' && run.state === RUN_STATE.RUNNING);
          break;
        }
        default:
          break;
      }

      if (isActive) {
        notification['active'] = true;
      }
    }
    return notifications;
  }, [notifications, proceduresMetadata, userInfo, users, currentTeamId, store]);

  const uniqueNotificationsSorted = useMemo(() => {
    return {
      uniqueLatestSorted: notificationsLib.getUniqueLatestSortedNotifications(notificationsWithActiveProperty),
      map: notificationsLib.getNotificationIdMap(notificationsWithActiveProperty),
    };
  }, [notificationsWithActiveProperty]);

  const columns = useMemo(() => {
    return [
      {
        key: 'Notification',
        name: 'Notification',
        sortable: false,
        width: '38%',
        renderCell({ row }: { row: NotificationRow }) {
          const NotificationLink = NOTIFICATION_LINK_MAP[row.type];
          return (
            NotificationLink && (
              <NotificationLink
                key={row.id}
                notification={row}
                handleOnClick={handleOnClick}
                duplicateIds={uniqueNotificationsSorted.map[notificationsLib.getUniqueIdentifier(row)]}
                truncateCode={false}
                teamId={currentTeamId}
              />
            )
          );
        },
      },
      {
        key: 'type',
        name: 'Type',
        sortable: false,
        renderCell({ row }: { row: NotificationRow }) {
          return <div className="flex flex-col">{MESSAGE_TYPE_MAP[row.type]}</div>;
        },
      },
      {
        key: 'createdAt',
        name: 'Time',
        sortable: true,
        renderCell({ row }: { row: NotificationRow }) {
          return renderDateTime(row.createdAt);
        },
      },
      {
        key: 'actorId',
        name: 'Sender',
        sortable: true,
        renderCell({ row }: { row: NotificationRow }) {
          return <div className="flex">{row.actorId}</div>;
        },
      },
      {
        key: 'menu',
        name: '',
        width: '5%',
        renderCell: ({ row }: { row: NotificationRow }) => {
          return <EditMenu row={row} />;
        },
      },
    ];
  }, [MESSAGE_TYPE_MAP, NOTIFICATION_LINK_MAP, currentTeamId, handleOnClick, uniqueNotificationsSorted.map]);

  const rows = useMemo(() => {
    const notificationList: Array<NotificationRow> =
      uniqueNotificationsSorted.uniqueLatestSorted?.map((notification) => {
        return {
          id: notification.id,
          status: notification.status,
          actorId: notification.actor_id,
          createdAt: notification.created_at,
          userId: notification.user_id,
          type: notification.type,
          context: notification.context,
          active: notification.active,
          isArchived: notification.is_archived,
        };
      }) ?? [];

    let partitionedRows = notificationList;
    const [notifications, archivedNotifications] = _.partition(partitionedRows, (row) => !row.isArchived);
    if (activeTab === 'notifications') {
      partitionedRows = notifications;
    } else {
      partitionedRows = archivedNotifications;
    }

    if (!persistedView.searchTerm) {
      return partitionedRows;
    }

    const keywords = persistedView.searchTerm.toLowerCase().split(/[\s]+/);
    return partitionedRows.filter((notification) => {
      const combined = [notification.type, notification.userId, notification.actorId].join(' ').toLowerCase();
      for (const keyword of keywords) {
        if (!combined.includes(keyword)) {
          return false;
        }
      }
      return true;
    });
  }, [uniqueNotificationsSorted.uniqueLatestSorted, activeTab, persistedView.searchTerm]);

  const markAllRead = useCallback(async () => {
    await services.notifications.updateNotifications({
      ids: rows.map((n) => n.id),
      status: NOTIFICATION_STATUS.RESOLVED,
    });
  }, [rows, services.notifications]);

  return (
    <div className="flex flex-col flex-grow px-5">
      <ListHeader
        name="Notifications"
        isLoading={false}
        tabs={TABS}
        updateTab={(tab) => setActiveTab(tab)}
        navigatedSection={activeTab}
        tagOptions={[]}
        filters={new Set()}
        actions={
          <Button
            type={BUTTON_TYPES.TERTIARY}
            title="Mark All Notifications Read"
            onClick={markAllRead}
            leadingIcon="check"
            isDisabled={!rows.some((row) => row.status === 'unresolved')}
          >
            Mark All Read
          </Button>
        }
        showViewTabToggle={false}
      />
      <Grid<NotificationRow>
        columns={columns}
        rows={rows}
        rowHeight={ROW_HEIGHT}
        usedVerticalSpace={MAIN_VERTICAL_PADDING}
        emptyRowMessage="No Notifications Found"
      />
    </div>
  );
};

export default NotificationsList;
