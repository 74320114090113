import React, { useCallback, useEffect, useMemo, useState } from 'react';
import Select, { StylesConfig, Option } from 'react-select';
import { reactSelectStyles } from '../../lib/styles';
import { FormikProps } from 'formik';
import { REVIEW_TYPES_KEY, useSettings } from '../../contexts/SettingsContext';
import { ReviewType } from 'shared/lib/types/settings';
import { Draft } from 'shared/lib/types/views/procedures';

const REVIEW_TYPE_SELECT_STYLES: StylesConfig = {
  ...reactSelectStyles,
  singleValue: (base) => ({
    ...base,
    position: 'static',
    transform: 'none',
    minWidth: 'fit-content',
    maxWidth: 'none',
  }),
  menu: (base) => ({
    ...base,
    minWidth: '200px',
    width: 'max-content',
  }),
  control: (base) => ({
    ...base,
    minWidth: '200px',
  }),
};

interface ReviewSettingsSelectReviewTypeProps {
  form: FormikProps<Draft>;
  reviewId?: string;
}

const HardcodeReviewSettingsSelectReviewType = React.memo(({ form, reviewId }: ReviewSettingsSelectReviewTypeProps) => {
  const { getSetting } = useSettings();
  const configReviewTypes: ReviewType[] = getSetting<ReviewType[]>(REVIEW_TYPES_KEY, []);
  const configReviewTypesDisplay = useMemo(
    () =>
      configReviewTypes?.map((reviewType) => ({
        ...reviewType,
        label: reviewType.name,
      })),
    [configReviewTypes]
  );

  const matchedOption = useMemo(() => {
    const match = configReviewTypesDisplay.find((option) => option.id === reviewId) || null;
    return match;
  }, [configReviewTypesDisplay, reviewId]);

  const [selectedOption, setSelectedOption] = useState<Option | null>(matchedOption);

  useEffect(() => {
    if (!selectedOption || reviewId !== selectedOption.id) {
      setSelectedOption(matchedOption);
    }
  }, [reviewId, matchedOption, selectedOption]);

  const onChangeHandler = useCallback(
    (selected: Option) => {
      if (!selected) return;

      // Prevent Formik re-render loop
      if (form.values.review_group_id === selected.id) {
        return;
      }

      setSelectedOption(selected);

      setTimeout(() => {
        form.setFieldValue('review_group_id', selected.id);
        form.setFieldValue('review_type', { id: selected.id, name: selected.name });
        form.setFieldValue(`reviewerGroupMap.${selected.id}`, selected.reviewer_groups);
        form.setFieldValue(`reviewer_groups`, selected.reviewer_groups);
      }, 0); // Defer Formik update
    },
    [form]
  );

  return (
    <Select
      classNamePrefix="react-select"
      className="w-fit"
      placeholder="Select Review Type"
      onChange={onChangeHandler}
      getOptionLabel={(option) => option.label}
      getOptionValue={(option) => option.id}
      options={configReviewTypesDisplay}
      value={selectedOption}
      aria-label="Select Review Type"
      styles={REVIEW_TYPE_SELECT_STYLES}
    />
  );
});

export default HardcodeReviewSettingsSelectReviewType;
