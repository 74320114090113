import { useQuery } from '@tanstack/react-query';
import { useCallback } from 'react';
import { ToolInstance } from 'shared/lib/types/api/manufacturing/tools/models';
import { useDatabaseServices } from '../../contexts/DatabaseContext';
import { DatabaseServices } from '../../contexts/proceduresSlice';
import fromClient from '../lib/tools/fromClient';

const QUERY_KEY = 'toolInstances';

interface ToolInstancesState {
  allToolInstances: Array<ToolInstance> | undefined;
  getToolInstance: (toolInstanceId: number) => ToolInstance | undefined;
}

const useToolInstances = (): ToolInstancesState => {
  const { services }: { services: DatabaseServices } = useDatabaseServices();

  const fetchToolInstances = async () => {
    const clientToolInstances = await services.tools.getToolInstances();
    const toolInstances = clientToolInstances.map(fromClient.toToolInstance);
    return { toolInstances };
  };

  const { data } = useQuery({
    queryKey: [QUERY_KEY],
    queryFn: fetchToolInstances,
  });

  const allToolInstances = data?.toolInstances;

  const getToolInstance = useCallback(
    (toolInstanceId: number): ToolInstance | undefined => {
      return (allToolInstances ?? []).find(
        (toolInstance) => toolInstance.id === toolInstanceId
      );
    },
    [allToolInstances]
  );

  return {
    allToolInstances,
    getToolInstance,
  };
};

export default useToolInstances;
