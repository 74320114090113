import { Dialog } from 'primereact/dialog';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { ViewTab } from 'shared/lib/types/postgres/users';
import Button from '../../components/Button';
import { useNavState } from '../../contexts/NavContext';
import { useProcedureContext } from '../../contexts/ProcedureContext';
import { useSettings } from '../../contexts/SettingsContext';
import SearchInput from '../../elements/SearchInput';
import SimpleDialogFooter from '../../elements/SimpleDialogFooter';
import { ColumnOverrides } from '../../lib/gridUtils';
import projectUtil, { DEFAULT_PROJECT_NAME } from '../../lib/projectUtil';
import useCases from '../hooks/useCases';
import { TestCase } from '../types';
import TestCasesTable from './TestCasesTable';

const MAIN_VERTICAL_PADDING = 320;

const columnOverrides: ColumnOverrides = {
  expand_collapse: { hidden: true },
  name: { width: null },
  conditions: { width: '30%' },
  hazard: { width: '30%' },
  projectName: { hidden: false },
};

interface TestPointSelectionModalProps {
  visible: boolean;
  onAdd: (testPoints: Array<TestCase>) => void;
  onCancel: () => void;
}

const TestPointSelectionModal = ({ visible, onAdd, onCancel }: TestPointSelectionModalProps) => {
  const { isLoading, filteredTestCases, selectedRows, setSearchTerm, setSelectedRows } = useCases(true);
  const [expandedProjectNames, setExpandedProjectNames] = useState<ReadonlySet<string>>(new Set([]));
  const { projectsFilter } = useNavState();
  const { projects } = useSettings();
  const { procedure } = useProcedureContext();

  const currentProjectName = useMemo(() => {
    const currentProjectId = projectsFilter?.project.id || procedure?.project_id;
    return projectUtil.getProjectName(projects, currentProjectId) ?? DEFAULT_PROJECT_NAME;
  }, [procedure?.project_id, projectsFilter, projects]);

  useEffect(() => {
    const expanded = new Set<string>([]);
    expanded.add(currentProjectName);
    setExpandedProjectNames(expanded);
  }, [currentProjectName]);

  const sortedRows = useMemo(() => {
    const projectId = procedure?.project_id;
    if (projectId) {
      return filteredTestCases.sort((a, b) => {
        if (a.project_id === projectId) {
          return -1;
        }
        if (b.project_id === projectId) {
          return 1;
        }
        return (a.name ?? '').localeCompare(b.name ?? '');
      });
    }
    return filteredTestCases;
  }, [filteredTestCases, procedure?.project_id]);

  useEffect(() => {
    if (visible) {
      setSearchTerm('');
      setSelectedRows(new Set());
    }
  }, [setSearchTerm, setSelectedRows, visible]);

  const onClickedRow = useCallback(
    (id: string) => {
      setSelectedRows((current) => {
        const updated = new Set(current);
        updated.add(id);
        return updated;
      });
    },
    [setSelectedRows]
  );

  const onAddSelected = useCallback(() => {
    onAdd(filteredTestCases.filter((testCase) => selectedRows.has(testCase.id)));
  }, [onAdd, selectedRows, filteredTestCases]);

  const getFooter = useCallback(() => {
    return (
      <SimpleDialogFooter>
        <Button type="primary" isDisabled={selectedRows.size === 0} onClick={onAddSelected}>
          Add Selected Test Points
        </Button>
        <Button type="secondary" onClick={onCancel}>
          Cancel
        </Button>
      </SimpleDialogFooter>
    );
  }, [onAddSelected, onCancel, selectedRows.size]);

  if (isLoading) {
    return <></>;
  }

  return (
    <Dialog
      header="Add Test Points"
      onHide={onCancel}
      visible={visible}
      closable={false}
      draggable={false}
      className="w-4/5 md:w-3/4 transition-all"
      footer={getFooter}
    >
      <div className="pt-1">
        <SearchInput placeholder="Search test points" onChange={(term) => setSearchTerm(term)} />
        <TestCasesTable
          testCases={sortedRows}
          selectedRows={selectedRows}
          usedVerticalSpace={MAIN_VERTICAL_PADDING}
          columnOverrides={columnOverrides}
          viewTab={ViewTab.Tree}
          setSearchTerm={setSearchTerm}
          onSelectedRowsChange={setSelectedRows}
          onClickedRow={onClickedRow}
          expandedProjectNames={expandedProjectNames}
          setExpandedProjectNames={setExpandedProjectNames}
        />
      </div>
    </Dialog>
  );
};

export default TestPointSelectionModal;
