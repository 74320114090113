import React, { useMemo, useState } from 'react';
import ModalBase from './ModalBase';
import ModalActionBar from './ModalActionBar';
import ModalContent from './ModalContent';
import Button, { BUTTON_TYPES } from './Button';
import FieldSetProcedureDetails from './FieldSetProcedureDetails';
import validateUtil from '../lib/validateUtil';
import procedureUtil from '../lib/procedureUtil';
import { useDatabaseServices } from '../contexts/DatabaseContext';
import { cloneDeep, isEmpty } from 'lodash';
import { useMixpanel } from '../contexts/MixpanelContext';
import { useHistory } from 'react-router-dom';
import { ACCESS, PERM } from '../lib/auth';
import { useAuth } from '../contexts/AuthContext';
import { procedurePendingPath } from '../lib/pathUtil';
import { useSelector } from 'react-redux';
import { selectProceduresMetadata } from '../contexts/proceduresSlice';
import useAutoProcedureId from '../hooks/useAutoProcedureId';
import useProjects from '../hooks/useProjects';

const ModalDuplicateProcedure = ({ procedure, onCancel }) => {
  const history = useHistory();
  const { auth } = useAuth();
  const { services, currentTeamId } = useDatabaseServices();
  const { mixpanel } = useMixpanel();
  const { getProjectsForAccessLevel } = useProjects();

  const proceduresMetadata = useSelector((state) => selectProceduresMetadata(state, currentTeamId));
  const { generatePlaceholderId, tryUpdateDocWithUniqueId, isAutoProcedureIdEnabled } = useAutoProcedureId(procedure);
  const [docs, setDocs] = useState({
    draft: procedureUtil.copyProcedureAsDraft({
      procedure,
      generatePlaceholderId,
      projects: getProjectsForAccessLevel({ projectAccessLevel: ACCESS.EDITOR }),
    }),
    errors: {},
  });

  const hasErrors = useMemo(() => {
    return !isEmpty(docs.errors);
  }, [docs.errors]);

  const canDuplicate = useMemo(() => {
    const releasedPerms = procedure && auth.hasPermission(PERM.PROCEDURES_EDIT, procedure.project_id);
    const pendingPerms = docs.draft && auth.hasPermission(PERM.PROCEDURES_EDIT, docs.draft.project_id);
    return releasedPerms && pendingPerms;
  }, [auth, docs, procedure]);

  const onProcedureDetailsFormChanged = (values) => {
    const updated = cloneDeep(docs);
    updated.draft = {
      ...docs.draft,
      ...values,
    };
    updated.errors = validateUtil.validateProcedureDetailsOnDuplicate(
      updated.draft,
      proceduresMetadata,
      isAutoProcedureIdEnabled
    );
    setDocs(updated);
  };

  const submitDraft = (draft) => {
    services.procedures
      .updateProcedure(draft)
      .then(() => {
        if (mixpanel) {
          mixpanel.track('Procedure Duplicated');
        }
        const procedureId = procedureUtil.getProcedureId(draft);
        history.push(procedurePendingPath(currentTeamId, procedureId));
      })
      .catch(() => {
        /* no-op */
      });
  };

  const onSubmit = async () => {
    const errors = validateUtil.validateProcedureDetailsOnDuplicate(docs.draft, proceduresMetadata);

    // Has errors, show them
    if (!isEmpty(errors)) {
      setDocs({
        ...docs,
        errors,
      });
      return;
    }

    let draftDoc = docs.draft;
    if (isAutoProcedureIdEnabled) {
      draftDoc = cloneDeep(draftDoc);
      await tryUpdateDocWithUniqueId(draftDoc);
    }

    // No errors, submit
    submitDraft(draftDoc);
  };

  return (
    <ModalBase size="lg">
      <ModalContent title="Duplicate Procedure">
        <div className="w-full">
          <FieldSetProcedureDetails
            procedure={docs.draft}
            errors={docs.errors}
            onProcedureDetailsFormChanged={onProcedureDetailsFormChanged}
            onFieldRefChanged={() => {
              /* no-op */
            }}
          />
        </div>
      </ModalContent>
      <ModalActionBar>
        <div className="flex gap-x-2">
          <Button type={BUTTON_TYPES.SECONDARY} ariaLabel="Cancel" isDisabled={false} onClick={onCancel}>
            Cancel
          </Button>
          <Button
            type={BUTTON_TYPES.PRIMARY}
            ariaLabel="Submit"
            isDisabled={hasErrors || !canDuplicate}
            onClick={onSubmit}
          >
            Submit
          </Button>
        </div>
      </ModalActionBar>
    </ModalBase>
  );
};

export default ModalDuplicateProcedure;
