import { NodeModel } from '@minoru/react-dnd-treeview';
import { Location, LocationMap, Item } from '../types';

export type LocationNode = Location & {
  children: LocationNode[];
};

export type NodeMap = {
  [id: string]: NodeModel<Location>;
};

export type LocationErrors = {
  code?: string;
  name?: string;
};

type NodeLookup = { [key: string]: LocationNode };

const lookupOrSet = (
  lookup: NodeLookup,
  locationId: string,
  location: Location
): LocationNode => {
  let node = lookup[locationId];
  if (!node) {
    node = { ...location, children: [] };
    lookup[node.id] = node;
  }
  return node;
};

export const getLocationGraph = (locationMap: LocationMap): LocationNode[] => {
  const roots: LocationNode[] = [];
  const nodeLookup: NodeLookup = {};
  for (const location of Object.values(locationMap)) {
    const node = lookupOrSet(nodeLookup, location.id, location);
    if (node.parent) {
      const parentId = node.parent;
      const parent = lookupOrSet(nodeLookup, parentId, locationMap[parentId]);
      parent.children.push(node);
    } else {
      roots.push(node);
    }
  }
  return roots;
};

// TODO add unit tests for this
export const getPrefix = (
  location: NodeModel<Location>,
  locationMap: NodeMap
): string => {
  let prefix = '';
  let currentNode = location;
  while (currentNode.parent) {
    currentNode = locationMap[currentNode.parent];
    prefix = `${currentNode.data?.code || ''}-${prefix}`;
  }
  return prefix;
};

export const getFullCode = (location: Location): string =>
  `${location.parent}${location.code}`;

export const getFullName = (location: Location): string =>
  `${location.parent}${location.name}`;

export const getLocationId = (location: Location): string =>
  location ? location.full_code || location.code : '';

export const anyItemsInLocations = (
  items: Array<Item>,
  locationIds: Array<string>
): boolean => {
  for (const item of items) {
    if (
      item.amount > 0 &&
      item.location_id !== undefined &&
      locationIds.includes(item.location_id)
    ) {
      return true;
    }
  }
  return false;
};

export const getLocationLabel = (location: Location): string =>
  `${location.full_code} ${location.name}`;

export const getAssignableLocations = (
  allLocations?: LocationMap
): Location[] => {
  return Object.values(allLocations ?? {})
    .filter((location) => location.items_enabled === true)
    .sort((loc1, loc2) =>
      getLocationLabel(loc1).localeCompare(getLocationLabel(loc2))
    );
};
