import { faClone, faTimesCircle } from '@fortawesome/free-solid-svg-icons';
import { noop } from 'lodash';
import { ChangeEvent, useEffect, useMemo, useState } from 'react';
import { isEmptyValue } from 'shared/lib/text';
import { PartAutoNumbering } from 'shared/lib/types/couch/settings';
import { Part } from 'shared/lib/types/postgres/manufacturing/types';
import {
  PartBuildItem,
  PartBuildRecordedItem as RecordedItem,
  ReviewPartBuildItem,
} from 'shared/lib/types/views/procedures';
import Button, { BUTTON_TYPES } from '../../components/Button';
import UnitDisplay from '../../components/Settings/Units/UnitDisplay';
import { StyleVariant } from '../../elements/lib/fieldStyles';
import Tooltip from '../../elements/Tooltip';
import { StringSelectOption as SelectOption } from '../../lib/formik';
import { Item } from '../lib/inventoryUtil';
import { Location } from '../types';
import CheckedInItemLink from './CheckedInItemLink';
import ItemOverlay from './ItemOverlay';
import ItemQuantityInput from './ItemQuantityInput';
import LocationSelect from './LocationSelect';
import FullNumberInput from './Numbering/FullNumberInput';
import PartBadge from './PartBadge';
import { isPartRestricted } from '../lib/parts';
import RestrictedInfo, { RESTRICTED_TEXT } from './RestrictedInfo';
import { cellClass, getCellClass } from './util/tableUtil';

const showItemLink = (recorded?: RecordedItem): boolean => !!recorded?.item_id;

type FieldInputBuildSingleItemSerialProps = {
  part: Part;
  item: PartBuildItem | ReviewPartBuildItem;
  recordedItem?: RecordedItem;
  isEnabled: boolean;
  teamId: string;
  onChangePrefix: (prefix: string) => void;
  onChangeItem: (event: ChangeEvent<HTMLInputElement>) => void;
  onChangeLocation: (location: Location | null) => void;
  onRemoveItem: () => void;
  canRemoveItem: boolean;
  isDuplicateSerialNumber: boolean;
  isExistingSerialNumber: boolean;
  autoNumbering?: PartAutoNumbering;
  onAddSerial?: (itemId?: string, prefix?: string) => void;
  onClearSerial?: (itemId: string | undefined) => void;
  onRecordErrorsChanged: (errorObj: { [key: string]: string }) => void;
  onDuplicate?: () => void;
  onRevChange?: (revisionLabel: string, revisionId?: string) => void;
  checkedOutItems?: Item[];
};

const FieldInputBuildSingleItemSerial = ({
  part,
  item,
  recordedItem,
  isEnabled,
  teamId,
  onChangePrefix,
  onChangeItem,
  onChangeLocation,
  onRemoveItem,
  canRemoveItem,
  isDuplicateSerialNumber,
  isExistingSerialNumber,
  autoNumbering,
  onAddSerial,
  onClearSerial,
  onRecordErrorsChanged,
  onDuplicate,
  onRevChange,
  checkedOutItems,
}: FieldInputBuildSingleItemSerialProps) => {
  const prefix = useMemo(() => recordedItem?.prefix ?? item.prefix ?? '', [item.prefix, recordedItem?.prefix]);
  const [serial, setSerial] = useState(() => recordedItem?.serial ?? item.serial ?? '');
  const partRestricted = isPartRestricted(part);
  const [showItemOverlay, setShowItemOverlay] = useState(false);

  useEffect(() => {
    setSerial(recordedItem?.serial ?? item.serial ?? '');
  }, [item.serial, recordedItem?.serial]);

  const handleChangePrefix = (option: SelectOption | null) => {
    onChangePrefix(option?.value ?? '');
  };

  const handleChangeItem = (event: ChangeEvent<HTMLInputElement>) => {
    setShowItemOverlay(false);
    onChangeItem(event);
  };

  useEffect(() => {
    if (isEmptyValue(serial.trim())) {
      onRecordErrorsChanged({ serial: 'Empty Serial #' });
    } else {
      onRecordErrorsChanged({});
    }
    // including 'onRecordErrorsChanged' causes an infinite render cycle
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [serial]);

  if (partRestricted) {
    return (
      <tr>
        <td className={`${cellClass} p-1`}>
          <RestrictedInfo text={RESTRICTED_TEXT} />
        </td>
        <td className={`${cellClass} p-1`}>
          <RestrictedInfo />
        </td>
        <td className={`${cellClass} p-1`}>
          <RestrictedInfo />
        </td>
        <td className={`${cellClass} p-1`}>
          <RestrictedInfo />
        </td>
      </tr>
    );
  }

  return (
    <tr>
      <td className={`${cellClass} p-1 group`}>
        <div className="flex flex-row items-center justify-between">
          <PartBadge
            inline={true}
            part={part}
            teamId={teamId}
            onRevChange={isEnabled && canRemoveItem && onRevChange ? onRevChange : undefined}
          />
          {onDuplicate && (
            <div className="invisible group-hover:visible">
              <Tooltip content="Duplicate Part">
                <Button
                  ariaLabel="Duplicate Part"
                  type={BUTTON_TYPES.TERTIARY}
                  leadingIcon={faClone}
                  onClick={onDuplicate}
                />
              </Tooltip>
            </div>
          )}
        </div>
      </td>
      <td className={getCellClass(true)}>
        <div className="flex flex-row items-center">
          <ItemQuantityInput
            value={recordedItem?.amount ?? item.amount}
            allowDecimalValue={false}
            onChange={noop}
            disabled={true}
            textSize="sm"
            variant={StyleVariant.Grid}
          />
          {part?.units && (
            <div className="mx-1">
              <UnitDisplay unit={part.units} />
            </div>
          )}
        </div>
      </td>
      <td className={getCellClass(!isEnabled)}>
        {(isEnabled || !showItemLink(recordedItem)) && (
          <div className="relative">
            <FullNumberInput
              type="serials"
              autoNumbering={autoNumbering}
              prefix={prefix}
              onPrefixChange={handleChangePrefix}
              value={serial}
              onInputBlur={handleChangeItem}
              onInputChange={(e) => setSerial(e.target.value)}
              entityId={item.id}
              onAdd={(itemId) => onAddSerial && onAddSerial(itemId, recordedItem?.prefix)}
              onClear={(itemId) => onClearSerial && onClearSerial(itemId)}
              disabled={!isEnabled}
              useFormik={false}
              onNumberInputClick={() => setShowItemOverlay(true)}
              showCaret={checkedOutItems && checkedOutItems.length > 0}
              variant={StyleVariant.Grid}
            />
            {showItemOverlay && (
              // ItemOverlay shows a menu of the run's checked out parts for easier check-in
              <ItemOverlay
                items={checkedOutItems}
                filter={serial}
                onSelectItem={(item) => {
                  item.serial && setSerial(item.serial);
                  setShowItemOverlay(false);
                }}
              />
            )}
          </div>
        )}
        {!isEnabled && showItemLink(recordedItem) && (
          <CheckedInItemLink teamId={teamId} itemId={recordedItem?.item_id as string} trackingId={serial || '--'} />
        )}
        {isDuplicateSerialNumber && <div className="pl-0.5 field-error text-red-700 ">Duplicate Serial #</div>}
        {!isDuplicateSerialNumber && isExistingSerialNumber && (
          <div className="pl-0.5 field-error text-red-700 ">Existing Serial #</div>
        )}
      </td>
      <td className={getCellClass(!isEnabled)}>
        {part && (
          <LocationSelect
            locationId={recordedItem?.location_id || ''}
            onChangeLocation={onChangeLocation}
            isDisabled={!isEnabled}
            variant={StyleVariant.Grid}
          />
        )}
      </td>
      {isEnabled && canRemoveItem && (
        <td className="w-5">
          <Button
            leadingIcon={faTimesCircle}
            iconTextColor="text-gray-400"
            type={BUTTON_TYPES.TERTIARY}
            onClick={onRemoveItem}
          />
        </td>
      )}
    </tr>
  );
};

export default FieldInputBuildSingleItemSerial;
