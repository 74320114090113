import { useCallback, useMemo, useState } from 'react';
import Button, { BUTTON_TYPES } from '../../../components/Button';
import { useDatabaseServices } from '../../../contexts/DatabaseContext';
import { DatabaseServices } from '../../../contexts/proceduresSlice';
import Tooltip from '../../../elements/Tooltip';
import { Item, ItemStatus } from '../../types';
import ConfirmCheckOutCheckInModal from './ConfirmCheckOutCheckInModal';
import { faDownload, faUpload } from '@fortawesome/free-solid-svg-icons';

const CHECK_OUT_BUTTON_TOOLTIP = 'Select a set of serial tracked items to enable';
const CHECK_IN_BUTTON_TOOLTIP = 'Select a set of checked out items to enable';

enum Mode {
  CheckOut = 'Check Out',
  CheckIn = 'Check In',
}

type BulkCheckOutCheckInButtonProps = {
  mode: Mode;
  selectedItems: Item[];
  onSuccessfulUpdate: (updatedCount: number) => void;
  onFailedUpdate: () => void;
};

const BulkCheckOutCheckInButton = ({
  mode,
  selectedItems,
  onSuccessfulUpdate,
  onFailedUpdate,
}: BulkCheckOutCheckInButtonProps) => {
  const [showConfirmModal, setShowConfirmModal] = useState(false);
  const { services }: { services: DatabaseServices } = useDatabaseServices();

  const isDisabled = useMemo(() => {
    for (const item of selectedItems) {
      if (item.part.tracking !== 'serial') {
        return true;
      }
      if (mode === Mode.CheckOut && item.status !== ItemStatus.InInventory) {
        return true;
      }
      if (mode === Mode.CheckIn && item.status !== ItemStatus.CheckedOut) {
        return true;
      }
    }
    return selectedItems.length === 0;
  }, [mode, selectedItems]);

  const tooltipText = useMemo(
    () => (mode === Mode.CheckIn ? CHECK_IN_BUTTON_TOOLTIP : CHECK_OUT_BUTTON_TOOLTIP),
    [mode]
  );

  const onApplyUpdate = useCallback(
    async (itemIds: string[]) => {
      try {
        await services.manufacturing.bulkUpdateItems({
          item_ids: itemIds,
          update: { quantity: mode === Mode.CheckIn ? 1 : 0 },
        });
        setShowConfirmModal(false);
        onSuccessfulUpdate(itemIds.length);
      } catch (error) {
        onFailedUpdate();
      }
    },
    [services.manufacturing, mode, onSuccessfulUpdate, onFailedUpdate]
  );

  return (
    <>
      {showConfirmModal && (
        <ConfirmCheckOutCheckInModal
          mode={mode}
          selectedItems={selectedItems}
          onApplyUpdate={onApplyUpdate}
          onCancel={() => setShowConfirmModal(false)}
        />
      )}
      <Tooltip visible={isDisabled} content={tooltipText}>
        <Button
          isDisabled={isDisabled}
          type={BUTTON_TYPES.TERTIARY}
          leadingIcon={mode === Mode.CheckIn ? faDownload : faUpload}
          ariaLabel={`Bulk ${mode}`}
          onClick={() => setShowConfirmModal(true)}
        >
          {mode}
        </Button>
      </Tooltip>
    </>
  );
};

export const BulkCheckOutButton = (props: Omit<BulkCheckOutCheckInButtonProps, 'mode'>) => (
  <BulkCheckOutCheckInButton mode={Mode.CheckOut} {...props} />
);

export const BulkCheckInButton = (props: Omit<BulkCheckOutCheckInButtonProps, 'mode'>) => (
  <BulkCheckOutCheckInButton mode={Mode.CheckIn} {...props} />
);
