import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Menu } from 'primereact/menu';
import { MouseEvent, useCallback, useMemo, useRef } from 'react';
import { useHistory } from 'react-router-dom';
import { NOTIFICATION_STATUS } from 'shared/lib/notifications';
import Button from '../../components/Button';
import AtMentionNotificationLink from '../../components/Notifications/AtMentionNotificationLink';
import AutomationNotificationLink from '../../components/Notifications/AutomationNotificationLink';
import ProcedureGenerationSuccessLink from '../../components/Notifications/ProcedureGenerationSuccessLink';
import ProcedureGenerationFailLink from '../../components/Notifications/ProcedureGenerationFailLink';
import ReviewerNotificationLink from '../../components/Notifications/ReviewerNotificationLink';
import ToolNotificationLink from '../../components/Notifications/ToolNotificationLink';
import SingleDigitNotificationBadge from '../../components/SingleDigitNotificationBadge';
import { CHANGELOG_URL } from '../../config';
import { useDatabaseServices } from '../../contexts/DatabaseContext';
import { useMixpanel } from '../../contexts/MixpanelContext';
import { useNotifications } from '../../contexts/NotificationContext';
import { DatabaseServices } from '../../contexts/proceduresSlice';
import { useSettings } from '../../contexts/SettingsContext';
import apm from '../../lib/apm';
import notificationsLib from '../../lib/notifications';
import { NotificationTypes } from 'shared/lib/types/notifications';

const MAX_DISPLAYED_NOTIFICATIONS = 10;

const NOTIFICATION_MENU_ITEM = {
  [NotificationTypes.Reviewers]: ReviewerNotificationLink,
  [NotificationTypes.ProcedureGeneration]: ProcedureGenerationSuccessLink,
  [NotificationTypes.ProcedureGenerationFail]: ProcedureGenerationFailLink,
  [NotificationTypes.Automation]: AutomationNotificationLink,
  [NotificationTypes.AtMention]: AtMentionNotificationLink,
  [NotificationTypes.Tool]: ToolNotificationLink,
};

interface NavItemProfileProps {
  label: string;
  icon: IconProp;
  hideLabels: boolean;
  to?: string;
  onClick: () => void;
}

const NavItemProfile = ({ label, icon, hideLabels, to, onClick }: NavItemProfileProps) => {
  const { config } = useSettings();
  const { mixpanel } = useMixpanel();
  const history = useHistory();
  const { services, currentTeamId }: { services: DatabaseServices; currentTeamId: string } = useDatabaseServices();
  const menuUserProfile = useRef<Menu>(null);
  const menuNotifications = useRef<Menu>(null);
  const { notifications } = useNotifications();

  const uniqueNotifications = useMemo(
    () =>
      notificationsLib
        .getUniqueLatestSortedNotifications(notifications)
        .filter((notification) => notification.is_archived === false),
    [notifications]
  );

  const unresolvedNotificationCount = useMemo(
    () => uniqueNotifications.filter((notification) => notification.status === NOTIFICATION_STATUS.UNRESOLVED).length,
    [uniqueNotifications]
  );

  const notificationIdMap = useMemo(() => notificationsLib.getNotificationIdMap(notifications), [notifications]);

  const displayedNotifications = useMemo(
    () => uniqueNotifications.slice(0, MAX_DISPLAYED_NOTIFICATIONS),
    [uniqueNotifications]
  );

  const showNotificationsMenu = (e: MouseEvent<HTMLButtonElement>) => {
    if (mixpanel) {
      mixpanel.track('Nav Click Profile');
    }
    menuNotifications?.current?.toggle(e);
    menuUserProfile?.current?.hide(e);
  };

  const handleOnClick = useCallback(
    (ids: Array<number>) => {
      if (!services) {
        return;
      }
      services.notifications
        .updateNotifications({ ids, status: NOTIFICATION_STATUS.RESOLVED })
        .catch((err) => apm.captureError(err));
    },
    [services]
  );

  const showMenu = (e: MouseEvent<HTMLButtonElement>) => {
    if (mixpanel) {
      mixpanel.track('Nav Click Profile');
    }
    menuUserProfile?.current?.toggle(e);
    menuNotifications?.current?.hide(e);
  };

  const notificationMenuItems = useMemo(() => {
    const items = displayedNotifications.map((notification) => ({
      template: () => {
        const MenuItemComponent = NOTIFICATION_MENU_ITEM[notification.type];
        return (
          MenuItemComponent && (
            <div key={notification.id} aria-label="Notification Item" className="hover:bg-gray-100">
              <MenuItemComponent
                notification={notification}
                duplicateIds={notificationIdMap[notificationsLib.getUniqueIdentifier(notification)]}
                handleOnClick={handleOnClick}
                truncateCode={true}
                teamId={currentTeamId}
              />
            </div>
          )
        );
      },
    }));
    items.push({
      template: () => {
        return (
          <div className="text-slate-800 hover:bg-gray-100 ">
            <Button to={to} type="tertiary" width="full">
              <span className="text-blue-500">See more notifications...</span>
            </Button>
          </div>
        );
      },
    });
    return items;
  }, [currentTeamId, displayedNotifications, handleOnClick, notificationIdMap, to]);

  const hasToolNotifications = useMemo(
    () => displayedNotifications.some((n) => n.type === 'tool'),
    [displayedNotifications]
  );

  const width = useMemo(() => (hasToolNotifications ? '20.3rem' : '19rem'), [hasToolNotifications]);

  const profileMenuItems = [
    {
      label: 'Profile',
      command: () => history.push('/profile'),
    },
    {
      label: 'Latest updates',
      url: CHANGELOG_URL,
      target: '_blank',
    },
    ...(config?.ldap_enabled
      ? []
      : [
          {
            label: 'Change Password',
            command: () => {
              history.push('/password-change');
            },
          },
        ]),
    {
      label: 'Sign out',
      command: onClick,
    },
  ];

  return (
    <div className="focus:bg-slate-700 focus:outline-none hover:bg-slate-700  mx-1 rounded-md">
      <Menu model={profileMenuItems} popup ref={menuUserProfile} id="menuUserProfile" />
      <div className="flex flex-row items-center">
        <button
          onClick={showMenu}
          className={`${
            menuUserProfile?.current?.state?.visible ? 'bg-slate-600' : ''
          } w-full py-2 pl-4 grid grid-cols-6 gap-2 items-center`}
        >
          <div className="flex flex-row justify-between col-span-6 items-center">
            <div title={`User Profile: ${label}`} className="flex flex-row gap-2 truncate">
              <div className="text-left">
                <FontAwesomeIcon fixedWidth={true} icon={icon} size="lg" />
              </div>
              {!hideLabels && <div className="text-left truncate">{label}</div>}
            </div>
          </div>
        </button>

        {(!hideLabels || unresolvedNotificationCount > 0) && (
          <button
            className="w-12 h-8"
            onClick={showNotificationsMenu}
            aria-label={`Number of Unread Notifications: ${unresolvedNotificationCount}`}
            title="Notifications"
          >
            <Menu
              style={{ width }}
              model={notificationMenuItems}
              popup
              ref={menuNotifications}
              id="menuNotifications"
            />
            <div className="relative right-3.5 -top-[0.29rem]">
              <SingleDigitNotificationBadge count={unresolvedNotificationCount} />
            </div>
          </button>
        )}
      </div>
    </div>
  );
};

export default NavItemProfile;
