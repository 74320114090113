import { Link } from 'react-router-dom';
import { Tool } from 'shared/lib/types/api/manufacturing/tools/models';
import AttachmentImage from '../../../components/Attachments/AttachmentImage';
import { toolDetailPath } from '../../../lib/pathUtil';
import toDisplay from '../../lib/tools/toDisplay';

interface ToolBadgeProps {
  tool: Tool;
  teamId: string;
}

const ToolBadge = ({ tool, teamId }: ToolBadgeProps) => {
  return (
    <div className="flex flex-row items-center gap-x-2">
      <div className="h-5 w-5 rounded shrink-0">
        <AttachmentImage attachment={toDisplay.fromToolImageProps(tool)} />
      </div>
      <div className="flex flex-row items-center gap-x-2 min-w-0">
        <Link className="inline-block whitespace-nowrap" to={toolDetailPath(teamId, tool.id)}>
          <div className="text-blue-600">{tool.tool_number}</div>
        </Link>
        <div className="truncate">{tool.name}</div>
      </div>
    </div>
  );
};

export default ToolBadge;
