import sharedDiffUtil from 'shared/lib/diffUtil';
import { KitPart, WithDiffChange } from 'shared/lib/types/views/procedures';
import DiffContainer from '../../../components/Diff/DiffContainer';
import SubstepNumber from '../../../components/SubstepNumber';
import diffUtil from '../../../lib/diffUtil';
import { generateHiddenClassString } from '../../../lib/styles';
import ComponentBuildItem from '../ComponentBuildItem';
import { getRowStyle, headerClass } from '../util/tableUtil';

const ReviewPartKit = ({ content, blockLabel, teamId, isHidden }) => {
  const items: Array<WithDiffChange<KitPart>> = (sharedDiffUtil.getDiffValue(content, 'items', 'new') ?? []) as Array<
    WithDiffChange<KitPart>
  >;

  return (
    <div className={generateHiddenClassString('mt-3 ml-4 flex flex-wrap page-break', isHidden)}>
      <SubstepNumber blockLabel={blockLabel} hasExtraVerticalSpacing={false} />
      <DiffContainer
        label="Part kit"
        diffChangeState={diffUtil.getDiffChangeStateForAddedRemovedOnly(content)}
        isTextSticky={false}
        width="fit"
      >
        <div className="flex items-start w-full py-1 mr-8">
          <table className="w-full">
            <thead>
              <tr>
                <td className={headerClass}>Parts for Check-Out</td>
                <td className={headerClass}>Quantity</td>
                {/* Placeholder for remove button */}
                <td className="w-5"></td>
              </tr>
            </thead>
            <tbody>
              {items.map((item, index) => {
                return (
                  <ComponentBuildItem key={index} component={item} teamId={teamId} highlightStyle={getRowStyle(item)} />
                );
              })}
            </tbody>
          </table>
        </div>
      </DiffContainer>
    </div>
  );
};

export default ReviewPartKit;
