import { Dialog } from 'primereact/dialog';
import { useCallback, useMemo, useState } from 'react';
import { FormProvider, useForm, useFormContext } from 'react-hook-form';
import Select from 'react-select';
import {
  EntityType,
  Tag,
  TagStatus,
  TagWithMetadata,
  UpsertTagParams,
} from 'shared/lib/types/api/settings/tags/models';
import { useDatabaseServices } from '../../../contexts/DatabaseContext';
import { DatabaseServices } from '../../../contexts/proceduresSlice';
import SimpleDialogFooter from '../../../elements/SimpleDialogFooter';
import Button, { BUTTON_TYPES } from '../../Button';
import ColorPicker from './ColorPicker';
import apm from '../../../lib/apm';
import ids from '../../../lib/idUtil';
const statusOptions = [
  {
    value: 'active',
    label: 'Active',
    color: 'bg-green-400',
  },
  {
    value: 'inactive',
    label: 'Inactive',
    color: 'bg-gray-400',
  },
];

export const DEFAULT_COLOR = 'bg-red-200';
const DEFAULT_STATUS = TagStatus.ACTIVE;

const useTagForm = (selectedTag: Tag | null, newTagName: string | undefined, tags: Tag[]) => {
  const defaultValues = useMemo(
    () => ({
      name: selectedTag?.name || newTagName || '',
      description: selectedTag?.description || '',
      color: selectedTag?.color || DEFAULT_COLOR,
      status: selectedTag?.status || DEFAULT_STATUS,
    }),
    [selectedTag, newTagName]
  );

  const methods = useForm<TagFormData>({ defaultValues });

  const validateUniqueName = useCallback(
    (value: string) => {
      const existingTag = tags.find(
        (tag) => tag.name.toLowerCase() === value.toLowerCase() && tag.id !== selectedTag?.id
      );
      return !existingTag || 'A tag with this name already exists';
    },
    [tags, selectedTag]
  );

  return { methods, validateUniqueName };
};

const TagFormFields = ({
  selectedTag,
  validateUniqueName,
}: {
  selectedTag: Tag | null;
  validateUniqueName: (value: string) => string | true;
}) => {
  const {
    register,
    formState: { errors },
    watch,
    setValue,
  } = useFormContext<TagFormData>();

  const currentStatus = watch('status');

  return (
    <div className="flex flex-col gap-2">
      <div className="flex">
        <div className="flex flex-col gap-1 w-5/6">
          <label htmlFor="name">Tag Name*</label>
          <input
            id="name"
            type="text"
            className="p-2 border rounded"
            {...register('name', {
              required: 'Name is required',
              maxLength: { value: 100, message: 'Tag name cannot exceed 100 characters' },
              validate: validateUniqueName,
            })}
          />
          {errors.name && <span className="text-red-500 text-sm">{errors.name.message}</span>}
        </div>

        <div className="w-1/6">
          <ColorPicker />
        </div>
      </div>

      <div className="flex flex-col gap-1">
        <label htmlFor="description">Description</label>
        <textarea
          id="description"
          className="p-2 border rounded"
          {...register('description', {
            maxLength: { value: 255, message: 'Description cannot exceed 255 characters' },
          })}
        />
        {errors.description && <span className="text-red-500 text-sm">{errors.description.message}</span>}
      </div>

      {selectedTag && (
        <div className="flex flex-col gap-1 w-1/4">
          <label htmlFor="status">Status</label>
          <Select
            id="status"
            classNamePrefix="react-select"
            aria-label="Status"
            {...register('status')}
            options={statusOptions}
            value={statusOptions.find((option) => option.value === currentStatus)}
            onChange={(option) => setValue('status', option?.value)}
            formatOptionLabel={({ _value, label, color }) => (
              <div className="flex items-center gap-2">
                <div className={`w-2 h-2 rounded-full ${color}`} />
                <span>{label}</span>
              </div>
            )}
          />
        </div>
      )}
    </div>
  );
};

type TagFormData = {
  name: string;
  description: string;
  color: string;
  status: TagStatus;
};
export type SubmitTagFormData = TagFormData & { id?: string };

interface TagModalProps {
  selectedTag: Tag | TagWithMetadata | null;
  newTagName?: string;
  tags: Tag[] | TagWithMetadata[];
  onClose: () => void;
  entityId?: string;
  entityType?: EntityType;
  onUpdateTag?: (tag: SubmitTagFormData) => void;
  onSave?: (tag: SubmitTagFormData) => void;
  onEdit?: (tag: SubmitTagFormData) => void;
}

const TagModal = ({
  selectedTag,
  newTagName,
  tags,
  onClose,
  entityId,
  entityType,
  onUpdateTag,
  onSave,
  onEdit,
}: TagModalProps) => {
  const { services }: { services: DatabaseServices } = useDatabaseServices();
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [errorMessage, setErrorMessage] = useState<string | null>(null);
  const { methods, validateUniqueName } = useTagForm(selectedTag, newTagName, tags);

  const updateTag = useCallback(
    async (data: UpsertTagParams, entityId: string | undefined, entityType: EntityType | undefined) => {
      if (entityId && entityType) {
        await services.tags.upsertTag(data);
        return services.entityTags.addTagToEntity(entityType, entityId, data.id);
      }
      return services.tags.upsertTag(data);
    },
    [services]
  );

  const createTag = useCallback(
    async (data: UpsertTagParams, entityId: string | undefined, entityType: EntityType | undefined) => {
      if (entityId && entityType) {
        await services.tags.upsertTag(data);
        return services.entityTags.addTagToEntity(entityType, entityId, data.id);
      }
      return services.tags.upsertTag(data);
    },
    [services]
  );

  const onSubmit = useCallback(
    async (data: TagFormData) => {
      setIsSubmitting(true);
      setErrorMessage(null); // clear any previous error messages

      const tagData: TagFormData = {
        name: data.name,
        description: data.description,
        color: data.color,
        status: data.status || TagStatus.ACTIVE,
      };

      try {
        let tagId = selectedTag?.id;
        if (onUpdateTag) {
          await onUpdateTag({
            id: tagId,
            ...tagData,
          });
        } else if (selectedTag) {
          await updateTag({ ...tagData, id: selectedTag.id }, entityId, entityType);
          onEdit?.({
            id: selectedTag.id,
            ...tagData,
          });
        } else {
          tagId = ids.generateLargeId();
          await createTag({ ...tagData, id: tagId }, entityId, entityType);
          onSave?.({
            id: tagId,
            ...tagData,
          });
        }

        setIsSubmitting(false);
        onClose();
      } catch (error) {
        apm.captureError(error);
        setErrorMessage('An unexpected error occurred when creating the tag.');
        setIsSubmitting(false);
      }
    },
    [selectedTag, onClose, entityId, entityType, updateTag, createTag, onUpdateTag, onSave, onEdit]
  );

  const saveButtonTitle = useMemo(() => {
    if (isSubmitting) {
      return 'Saving...';
    }
    if (entityId && entityType) {
      return 'Save and Add Tag';
    }
    return 'Save';
  }, [isSubmitting, entityId, entityType]);

  return (
    <FormProvider {...methods}>
      <Dialog
        className={`w-1/2 ${selectedTag ? 'h-1/2' : ''}`}
        header={selectedTag ? 'Edit Tag' : 'New Tag'}
        visible={true}
        onHide={onClose}
        footer={
          <Footer onSubmit={onSubmit} onClose={onClose} isSubmitting={isSubmitting} saveButtonTitle={saveButtonTitle} />
        }
      >
        <form onSubmit={methods.handleSubmit(onSubmit)} className="flex flex-col gap-2 p-2">
          {errorMessage && (
            <div className="p-3 mb-3 text-red-700 bg-red-100 border border-red-200 rounded-md">{errorMessage}</div>
          )}
          <TagFormFields selectedTag={selectedTag} validateUniqueName={validateUniqueName} />
        </form>
      </Dialog>
    </FormProvider>
  );
};

type FooterProps = {
  onSubmit: (data: TagFormData) => void;
  onClose: () => void;
  isSubmitting: boolean;
  saveButtonTitle: string;
};

const Footer = ({ onSubmit, onClose, isSubmitting, saveButtonTitle }: FooterProps) => {
  const { handleSubmit } = useFormContext<TagFormData>();
  return (
    <SimpleDialogFooter>
      <Button
        type={BUTTON_TYPES.PRIMARY}
        title="Save Tag"
        ariaLabel="Save Tag"
        onClick={handleSubmit(onSubmit)}
        isDisabled={isSubmitting}
      >
        {saveButtonTitle}
      </Button>
      <Button type={BUTTON_TYPES.SECONDARY} title="Cancel" ariaLabel="Cancel" onClick={onClose}>
        Cancel
      </Button>
    </SimpleDialogFooter>
  );
};

export default TagModal;
