import { cloneDeep } from 'lodash';
import { Part, UsageType } from 'shared/lib/types/postgres/manufacturing/types';
import SubstepNumber from '../../components/SubstepNumber';
import { generateHiddenClassString } from '../../lib/styles';
import useSpecificParts from '../hooks/useSpecificParts';
import { getPartRevisionId, isPartRestricted } from '../lib/parts';
import { Item } from '../types';
import PartUsageBase from './PartUsageBase';
import { RunPartUsageBlock } from 'shared/lib/types/views/procedures';
import { PartUsageContentErrors } from '../../lib/types';
import { useMemo } from 'react';

interface PartUsageProps {
  content: RunPartUsageBlock;
  errors?: PartUsageContentErrors;
  recorded?: RunPartUsageBlock['recorded'];
  isStepComplete: boolean;
  blockLabel?: string;
  teamId: string;
  onRecordValuesChanged?: (contentId: string, recorded: RunPartUsageBlock['recorded']) => void;
  isEnabled: boolean;
  isHidden?: boolean;
  projectId?: string;
}

const PartUsage = ({
  content,
  errors,
  recorded,
  isStepComplete,
  blockLabel,
  teamId,
  onRecordValuesChanged,
  isEnabled,
  isHidden,
  projectId,
}: PartUsageProps) => {
  const partIds = content?.part?.id ? [content.part.id] : [];
  const { isLoaded: arePartsLoaded, getLatestPartRevision, getPartByRevisionId } = useSpecificParts({ partIds });

  const EmptyRunPartUsageRecorded: RunPartUsageBlock['recorded'] = useMemo(
    () => [
      {
        amount: null,
        usage_type: null,
        part: content.part ?? undefined,
      },
    ],
    [content.part]
  );

  if (!arePartsLoaded) {
    return null;
  }

  const partRevisionId = content?.part ? getPartRevisionId(content.part) : undefined;

  let retrievedPart;
  // Use the specified revision if we can find it
  if (partRevisionId) {
    retrievedPart = getPartByRevisionId(partRevisionId);
  }
  // Fall back to using the current revision
  if (!retrievedPart && content?.part?.id) {
    retrievedPart = getLatestPartRevision(content?.part?.id);
  }

  // recorded is available when part is added at runtime
  const partRestricted = recorded
    ? isPartRestricted(recorded[0]?.part)
    : !retrievedPart || isPartRestricted(retrievedPart);

  const selectedPartforUsage = recorded && recorded[0]?.part;
  const selectedPartIdforUsage = recorded && recorded[0]?.part_id;
  const selectedUsageType = recorded && recorded[0]?.usage_type;

  let usageTypeSelected;
  if ((content && content.usage_types && content.usage_types.length > 0) || selectedUsageType) {
    usageTypeSelected = content.usage_types[0] || selectedUsageType;
  }

  const onChangeItem = (item: Item): void => {
    const updated = recorded ? cloneDeep(recorded) : EmptyRunPartUsageRecorded;

    updated[0].item_id = item?.id;
    updated[0].item = item;
    updated[0].amount = recorded?.[0]?.amount ?? null;

    onRecordValuesChanged?.(content?.id, updated);
  };

  const onChangePart = (part: Part | null): void => {
    if (part === null) {
      onRecordValuesChanged?.(content?.id, [
        {
          amount: null,
          usage_type: null,
          part: undefined,
        },
      ]);
      return;
    }
    const updated = recorded ? cloneDeep(recorded) : EmptyRunPartUsageRecorded;
    updated[0].part_id = part?.id;
    updated[0].part = part;

    if (updated[0].usage_type) {
      updated[0].usage_type = null;
    }
    if (updated[0].amount) {
      updated[0].amount = null;
    }
    onRecordValuesChanged?.(content?.id, updated);
  };

  const onChangeUsage = (usage_type: UsageType): void => {
    const updated = recorded ? cloneDeep(recorded) : EmptyRunPartUsageRecorded;
    updated[0].usage_type = usage_type;

    onRecordValuesChanged?.(content?.id, updated);
  };

  const onRecordAmount = (amount: string): void => {
    const updated = recorded ? cloneDeep(recorded) : EmptyRunPartUsageRecorded;

    // the amount is a number-typed html input
    updated[0].amount = amount as unknown as number;

    onRecordValuesChanged?.(content?.id, updated);
  };

  const itemIdSelected = recorded?.[0]?.item_id;
  const contentPartNo = content?.part?.part_no || selectedPartforUsage?.part_no || '';
  const contentPartId = content?.part_id || selectedPartIdforUsage || '';
  const contentPartRev = content?.part?.rev !== undefined ? content.part.rev : selectedPartforUsage?.rev;

  return (
    <tr>
      <td></td>
      <td colSpan={2}>
        <div className={generateHiddenClassString('', isHidden)} />
        <div className={generateHiddenClassString('mt-3 ml-4 flex flex-wrap page-break', isHidden)}>
          <SubstepNumber blockLabel={blockLabel} hasExtraVerticalSpacing={false} />
          <div className="w-full py-1 mr-8">
            <PartUsageBase
              content={content}
              part={retrievedPart}
              projectId={projectId}
              contentPartId={contentPartId}
              contentPartRev={contentPartRev}
              itemIdSelected={itemIdSelected}
              usageTypeSelected={usageTypeSelected}
              recorded={recorded}
              isStepComplete={isStepComplete}
              isPartSelectorEnabled={isEnabled}
              isUsageDisabled={!isEnabled || (contentPartNo === '' ? true : false)}
              teamId={teamId}
              isPartRestricted={partRestricted}
              errors={errors}
              onChangePart={onChangePart}
              onChangeItem={onChangeItem}
              onChangeUsage={onChangeUsage}
              onRecordAmount={onRecordAmount}
            />
          </div>
        </div>
      </td>
    </tr>
  );
};

export default PartUsage;
