const commonFieldStyles =
  'border-[1px] border-gray-300 rounded-md transition duration-200 ease-in-out focus:outline-none focus-border focus:shadow focus:ring-2 focus:ring-opacity-25 disabled:opacity-60';

export const fieldStyles = `${commonFieldStyles} focus:ring-blue-600`;
export const errorFieldStyles = `${commonFieldStyles} focus:border-red-500 focus:outline-transparent focus:ring-red-500`;

export enum StyleVariant {
  Grid,
  Standalone,
}
