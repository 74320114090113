import { cloneDeep } from 'lodash';
import sharedRunUtil from 'shared/lib/runUtil';
import {
  AttachmentValue,
  RedlinedStep,
  Run,
  RunFieldInputAttachmentBlock,
  RunFieldInputRecorded,
  RunStep,
  StepDoc,
  StepDocBlock,
  V2RunVariable,
  V2RunVariableAttachmentBlock,
} from 'shared/lib/types/views/procedures';
import { Attachment } from '../hooks/useAttachment';
import { getRecordedAttachments } from 'shared/lib/runStepUtil';

export type VariableLocation = { variable_id: string };
export type StepLocation = { section_id: string; step_id: string };
export type StepBlockLocation = StepLocation & { content_id: string };
export type StepCommentLocation = StepLocation & { comment_id: string };
export type Location = { order: number } & (
  | VariableLocation
  | StepBlockLocation
  | StepCommentLocation
);

export interface AttachmentListMetadata<L extends Location = Location> {
  description: string;
  attachment: Attachment;
  location: L;
  timestamp?: string;
  uploaded_by?: string;
}

const runUtil = {
  replaceStep: ({
    run,
    sectionId,
    stepId,
    updatedStep,
  }: {
    run: Run;
    sectionId: string;
    stepId: string;
    updatedStep: RunStep | RedlinedStep;
  }): Run => {
    const updatedRun = cloneDeep(run);
    const { sectionIndex, stepIndex } = sharedRunUtil.getSectionAndStepIndices(
      updatedRun,
      sectionId,
      stepId
    );
    updatedRun.sections[sectionIndex].steps[stepIndex] = {
      id: stepId,
      ...updatedStep,
    };

    return updatedRun;
  },

  getAllAttachmentMetadata: ({
    run,
    runStepMap,
  }: {
    run: Run;
    runStepMap: {
      [stepId: string]: StepDoc;
    };
  }): Array<AttachmentListMetadata> => {
    const attachmentMetadataList: Array<AttachmentListMetadata> = [];
    let order = 1;
    (run.variables ?? []).forEach((variable, variableIndex) => {
      if (
        variable.type === 'input' &&
        (variable as V2RunVariable).inputType === 'attachment' &&
        variable.value
      ) {
        const attachments = getRecordedAttachments(
          variable as V2RunVariableAttachmentBlock
        );

        attachments.forEach((attachment) => {
          attachmentMetadataList.push({
            description: variable.name,
            attachment: attachment as AttachmentValue,
            location: {
              order: order++,
              variable_id:
                (variable as V2RunVariable).id ??
                `legacy-run-variable-${variableIndex}`,
            },
            /*
             *TODO: Get timestamp and created_by from the files.files postgres db
             * timestamp,
             * created_by,
             */
          });
        });
      }
    });

    (run.sections ?? []).forEach((section) =>
      section.steps.forEach((step) => {
        step.content.forEach((block) => {
          if (
            block.type === 'input' &&
            block.inputType === 'attachment' &&
            runStepMap[step.id]
          ) {
            const runStep = runStepMap[step.id];
            const runStepBlock = runStep.content.find(
              (
                runStepBlock
              ): runStepBlock is StepDocBlock<
                RunFieldInputAttachmentBlock,
                RunFieldInputRecorded<AttachmentValue>
              > => runStepBlock.id === block.id
            );
            if (!runStepBlock) {
              return;
            }
            const lastAction = runStepBlock.actions?.at(-1);
            if (!runStepBlock.recorded?.value) {
              return;
            }

            const attachments = getRecordedAttachments(runStepBlock.recorded);

            attachments.forEach((attachment) => {
              attachmentMetadataList.push({
                description: block.name,
                attachment: {
                  attachment_id: attachment.attachment_id,
                  name: attachment.name,
                  content_type: attachment.content_type,
                },
                location: {
                  order: order++,
                  section_id: section.id,
                  step_id: step.id,
                  content_id: block.id,
                },
                timestamp: lastAction?.timestamp,
                uploaded_by: lastAction?.user_id,
              });
            });
          }
        });

        (step.comments ?? []).forEach((comment) => {
          if (comment.attachment) {
            attachmentMetadataList.push({
              attachment: {
                attachment_id: (comment.attachment as Attachment).attachment_id,
                name: (comment.attachment as Attachment).name,
                content_type: (comment.attachment as Attachment).content_type,
              },
              description: comment.text ?? '',
              location: {
                order: order++,
                section_id: section.id,
                step_id: step.id,
                comment_id: comment.id,
              },
              timestamp: comment.timestamp,
              uploaded_by: comment.user,
            });
          }
          if (comment.attachments) {
            attachmentMetadataList.push(
              ...comment.attachments.map((attachment: Attachment) => ({
                attachment: {
                  attachment_id: attachment.attachment_id,
                  name: attachment.name,
                  content_type: attachment.content_type,
                },
                description: comment.text ?? '',
                location: {
                  order: order++,
                  section_id: section.id,
                  step_id: step.id,
                  comment_id: comment.id,
                },
                timestamp: comment.timestamp,
                uploaded_by: comment.user,
              }))
            );
          }
        });
      })
    );

    return attachmentMetadataList;
  },
};

export default runUtil;
