import React, { useMemo, useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import useAttachment, { Attachment } from '../../hooks/useAttachment';
import sharedDiffUtil from 'shared/lib/diffUtil';
import ProcedureDiffText from '../ProcedureDiffText';
import { AttachmentBlockDiffElement } from 'shared/lib/types/views/procedures';
import Modal from '../Modal';
import { ReactComponent as CloudDownload } from '../../images/cloud-download-line.svg';
import { ReactComponent as VideoFile } from '../../images/video-file.svg';
import Button, { BUTTON_TYPES } from '../Button';
import { faPlayCircle } from '@fortawesome/free-solid-svg-icons';
import Icon from '../../elements/Icon';

// For security reasons, we will force these content types to be downloaded instead of shown in the browser.
const INSECURE_TYPES = new Set([
  'application/xhtml+xml',
  'application/x-shockwave-flash',
  'application/svg+xml',
  'image/svg+xml',
  'text/html',
]);

interface ThumbnailFileProps {
  attachment?: Attachment | AttachmentBlockDiffElement;
  file?: File;
  canDownload?: boolean;
}

const checkIsSupportedVideoContent = (contentType: string) => {
  /*
   *  Quicktime (.mov) files are not officially supported by most browsers.
   *  However, depending on the codec, the browser might still play it
   */
  if (contentType === 'video/quicktime') {
    return true;
  }

  const vid = document.createElement('video');
  return vid.canPlayType(contentType) !== '';
};

const ThumbnailFile = ({ attachment, file, canDownload = true }: ThumbnailFileProps) => {
  const { fetchAttachment, downloadAttachment, url, isFetching } = useAttachment({
    // @ts-ignore - https://linear.app/epsilon3/issue/EPS-3423/update-attachment-type
    attachment,
    file,
    isDiff: true, // TODO split out when review version of this component is created
  });

  const contentType = attachment
    ? sharedDiffUtil.getDiffValue<string>(attachment, 'content_type', 'old')?.toLowerCase()
    : '';
  const isPending = useMemo(() => Boolean(file), [file]);
  const isInsecure = INSECURE_TYPES.has(contentType);
  const name = attachment ? sharedDiffUtil.getDiffValue<string>(attachment, 'name', 'old') : file ? file.name : '';
  const nameField = attachment && attachment.name ? attachment.name : file ? file.name : '';
  const [showModal, setShowModal] = useState<boolean>(false);
  const isSupportedVideoContent = checkIsSupportedVideoContent(contentType);

  const downloadButton = useMemo(() => {
    if (isFetching) {
      return <FontAwesomeIcon icon="spinner" className="fa-spin" size="xs" />;
    }
    return (
      <Button
        type={BUTTON_TYPES.TERTIARY}
        leadingIcon={CloudDownload}
        onClick={url ? downloadAttachment : fetchAttachment}
        ariaLabel={name && `Download ${name}`}
        removePadding={true}
      />
    );
  }, [isFetching, url, name, downloadAttachment, fetchAttachment]);

  return (
    <div className="flex items-center flex-row space-x-1">
      {showModal && (
        <Modal
          title={nameField.toString()}
          primaryActionTitle="Close"
          onPrimaryAction={() => {
            setShowModal(false);
          }}
        >
          <video src={url} controls={true} disablePictureInPicture={true} controlsList="nodownload" />
        </Modal>
      )}

      {isSupportedVideoContent && (
        <div className={url && `hover:brightness-50`}>
          <Button
            type={BUTTON_TYPES.TERTIARY}
            onClick={() => {
              setShowModal(true);
            }}
            ariaLabel={name && `Play ${name}`}
            isDisabled={!url}
            removePadding={true}
          >
            {!url ? <VideoFile /> : <Icon element={faPlayCircle} className="text-slate-400" />}
            <div className={url && 'text-blue-600 underline'}>
              <ProcedureDiffText diffValue={nameField} />
            </div>
          </Button>
        </div>
      )}
      {isPending && <ProcedureDiffText diffValue={nameField} />}
      {!isPending && (
        <>
          {isInsecure && (
            <button
              type="button"
              className="link"
              onClick={downloadAttachment}
              aria-label={name && `Download link for ${name}`}
            >
              <ProcedureDiffText diffValue={nameField} />
            </button>
          )}
          {!isInsecure && !isSupportedVideoContent && (
            <>
              {/**
               * Using 'noopener' preserves the security considerations, while
               * avoiding the Chrome 'this file could harm your computer' for
               * files with application/octet-stream content type. Sending
               * the Referrer header seems OK since this is our server.
               *
               * More: https://web.dev/external-anchors-use-rel-noopener/
               */}
              {/*eslint-disable-next-line react/jsx-no-target-blank*/}
              <a className="link" target="_blank" rel="noopener" href={url} download={name}>
                <ProcedureDiffText diffValue={nameField} />
              </a>
            </>
          )}
          {canDownload && downloadButton}
        </>
      )}
    </div>
  );
};

export default ThumbnailFile;
