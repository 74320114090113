import { faLocationDot } from '@fortawesome/free-solid-svg-icons';
import { useCallback, useMemo, useState } from 'react';
import Button, { BUTTON_TYPES } from '../../../components/Button';
import { useDatabaseServices } from '../../../contexts/DatabaseContext';
import { DatabaseServices } from '../../../contexts/proceduresSlice';
import PopupListWithSearch from '../../../elements/PopupListWithSearch';
import useLocations from '../../hooks/useLocations';
import { getAssignableLocations, getLocationLabel } from '../../lib/locations';
import { Item, Location } from '../../types';
import ConfirmLocationUpdateModal from './ConfirmLocationUpdateModal';

const ChangeLocationButton = ({ isDisabled }: { isDisabled: boolean }) => (
  <Button
    isDisabled={isDisabled}
    type={BUTTON_TYPES.TERTIARY}
    leadingIcon={faLocationDot}
    ariaLabel="Bulk Update Location"
  >
    Change Location
  </Button>
);

type BulkChangeLocationButtonProps = {
  selectedItems: Item[];
  onSuccessfulUpdate: (updatedCount: number) => void;
  onFailedUpdate: () => void;
};

const BulkChangeLocationButton = ({
  selectedItems,
  onSuccessfulUpdate,
  onFailedUpdate,
}: BulkChangeLocationButtonProps) => {
  const [selectedLocation, setSelectedLocation] = useState<Location>();
  const { services }: { services: DatabaseServices } = useDatabaseServices();
  const { locations } = useLocations();

  const assignableLocations = useMemo(() => getAssignableLocations(locations), [locations]);

  const onApplyUpdate = useCallback(
    async (itemIds: string[]) => {
      if (!selectedLocation) {
        throw new Error('Cannot apply undefined location');
      }

      try {
        await services.manufacturing.bulkUpdateItems({
          item_ids: itemIds,
          update: { location_id: selectedLocation.id },
        });
        setSelectedLocation(undefined);
        onSuccessfulUpdate(itemIds.length);
      } catch (error) {
        onFailedUpdate();
      }
    },
    [selectedLocation, services.manufacturing, onSuccessfulUpdate, onFailedUpdate]
  );

  return (
    <>
      {selectedLocation !== undefined && (
        <ConfirmLocationUpdateModal
          location={selectedLocation}
          selectedItems={selectedItems}
          onApplyUpdate={onApplyUpdate}
          onCancel={() => setSelectedLocation(undefined)}
        />
      )}
      <PopupListWithSearch
        Components={{
          Trigger: () => <ChangeLocationButton isDisabled={selectedItems.length === 0} />,
          ListItem: (option) => <span>{option.name}</span>,
        }}
        options={assignableLocations.map((location) => ({
          id: location.id,
          name: getLocationLabel(location),
          value: location,
        }))}
        onSelect={async (option) => setSelectedLocation(option.value)}
      />
    </>
  );
};

export default BulkChangeLocationButton;
